import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {averageIncdientsAgeSelector} from '../../../../../store/state/incident-overview/selectors'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'
import {formatAverageAgeIntoReadableTime} from './data-helper/format-average-age-into-readable-time'
import {FormattedAge} from './data-helper/formatted-age.class'
interface Props {
    gridLayout: GridLayout
}
export function AverageAgeIncidents({gridLayout}: Props): JSX.Element {
    const averageAge = useTypedSelector(averageIncdientsAgeSelector)

    const formattedAverageAge = formatAverageAgeIntoReadableTime(
        !averageAge ? undefined : new FormattedAge(averageAge),
    )

    return (
        <ValueForType
            type="Avg. Age"
            value={formattedAverageAge}
            gridLayout={gridLayout}
            id="incident-response-headline-values-avg-age"
        />
    )
}
