import * as Styles from './network-filter.styled'
import {ExpandDetailsButton} from '../shared/expand-details-button/expand-details-button'
import {AppliedFilterIndicator} from '../shared/applied-filter-indicator/applied-filter-indicator'
import {ViewAllNetworkRow} from './view-all-network-row'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {NetworkRow} from './network-row'
import {NetworkType} from '../../../context/types/network-type'
import {clickViewAllNetworksEvent} from '../../../../../core/google-tag-manager/unknown-assets-beta/click-view-all-networks-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {toggleAllNetworks} from '../../../../../store/state/network-assets-filter/action-creators'
import {useDispatch} from 'react-redux'
import {HeadingRowType} from '../../../../../store/state/network-assets-filter/state'

function determineViewAllState(
    allNetworks: NetworkType[],
    selected: NetworkType[] | undefined,
): TriStateButtonState {
    if (!selected) {
        return TriStateButtonState.FULLY_SELECTED
    }
    if (selected.length === 0) {
        return TriStateButtonState.NOT_SELECTED
    }

    const numberOfNetworkType = allNetworks.length

    return numberOfNetworkType === selected.length
        ? TriStateButtonState.FULLY_SELECTED
        : TriStateButtonState.PARTIAL_SELECTION
}

export function NetworkFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {filterExpanded, networks} = useTypedSelector(networkAssetsFilterSelector)
    const numOfAppliedFilter = !networks ? undefined : networks.length
    const allNetworks = new Array<NetworkType>(
        NetworkType.NetworkTypeBusiness,
        NetworkType.NetworkTypeCrew,
        NetworkType.NetworkTypeOT,
        NetworkType.NetworkTypeOther,
    )
    const viewAll = determineViewAllState(allNetworks, networks)

    function setViewAllState(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleAllNetworks(true))
        } else if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleAllNetworks(false))
        }
        clickViewAllNetworksEvent(viewAll, newState)
    }

    const showFilteringIndicator =
        filterExpanded && !filterExpanded.networkExpanded && numOfAppliedFilter != undefined

    const showClearButton = numOfAppliedFilter != 0

    return (
        <Styles.Networks>
            <ExpandDetailsButton headingRow={HeadingRowType.FilterBarNetworks} />
            {showFilteringIndicator && (
                <AppliedFilterIndicator numOfAppliedFilter={numOfAppliedFilter} />
            )}
            {filterExpanded && filterExpanded.networkExpanded && (
                <>
                    <ViewAllNetworkRow
                        state={viewAll}
                        setState={setViewAllState}
                        showClearButton={showClearButton}
                    />
                    <NetworkRow networkLabel={NetworkType.NetworkTypeBusiness} />
                    <NetworkRow networkLabel={NetworkType.NetworkTypeCrew} />
                    <NetworkRow networkLabel={NetworkType.NetworkTypeOT} />
                    <NetworkRow networkLabel={NetworkType.NetworkTypeOther} />
                </>
            )}
        </Styles.Networks>
    )
}
