import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import NodeRecord from '../../../../../values/nodes/reselectors/types/node-record'
import {getGridLayout} from '../../general/helpers'
import {WidgetWrapper} from './widget-wrapper'
import {ScrollableList} from './scrollable-list.styled'
import {AssetDetails} from '../../general/asset-details/asset-details'
import {ContentArea} from './content-area.styled'
import {AssetDetailsWrapper} from './asset-details-wrapper.styled'

interface PopulatedDataProps {
    nodes: NodeRecord[]
}

export function PopulatedData({nodes}: PopulatedDataProps): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)

    return (
        <WidgetWrapper gridLayout={gridLayout}>
            <ContentArea gridLayout={gridLayout}>
                <ScrollableList id="scroallable-list-watched-nodes">
                    {nodes.map((node) => {
                        return (
                            <AssetDetailsWrapper
                                key={node.assetId}
                                id={`asset-details-wrapper-${node.assetId}`}
                            >
                                <AssetDetails assetId={node.assetId} where="dashboard-watchlist" />
                            </AssetDetailsWrapper>
                        )
                    })}
                </ScrollableList>
            </ContentArea>
        </WidgetWrapper>
    )
}
