import {Api, QuerySpecification} from '../../../api/Api'
import * as uuid from 'uuid'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {QueryCancellation} from '../../../websocket/Queries'
import ActionType from './action-type'
import {IncidentsOverview} from './types/incidents-overview'
import {LocationIdType} from '../locations/state'

const QUERY_PATH = 'incidentsOverview'
const QUERY_SCHEDULE = '1m'

function incidentsOverviewQuery(activeLocation: LocationIdType | null): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: `${QUERY_PATH}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
        params: {
            location: activeLocation,
        },
    }
}

export function registerIncidentsOverviewQuery(
    activeLocation: LocationIdType | null,
): ThunkAction<QueryCancellation, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): QueryCancellation => {
        dispatch(requestIncidentsOverview())

        const query: QuerySpecification = incidentsOverviewQuery(activeLocation)
        api.newQuery(query, (data) => {
            dispatch(receiveIncidentsOverview(data))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

function requestIncidentsOverview(): Actions.RequestIncidentsOverview {
    return {
        type: ActionType.REQUEST_INCIDENTS_OVERVIEW,
    }
}

function receiveIncidentsOverview(data: IncidentsOverview): Actions.ReceiveIncidentsOverview {
    return {
        type: ActionType.RECEIVE_INCIDENTS_OVERVIEW,
        payload: data,
    }
}
