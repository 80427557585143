import {AssetOutputModel} from '../../../models/asset.model'
import {viewAssetPopupEvent} from '../../../../../core/google-tag-manager/incidents/view-asset-popup-event'
import {closeAssetPopupEvent} from '../../../../../core/google-tag-manager/incidents/close-asset-popup-event'
import {AssetPopover} from '../../../../../components/asset-popover/asset-popover'
import {AssetSquare} from './_styled/assets-list.styled'
import {DisplayFormatType} from '../../../../../core/google-tag-manager/helpers/display-format-type'

interface IncidentAssetProps {
    incidentStatus: string
    displayFormat: DisplayFormatType
    asset: AssetOutputModel
}

export function IncidentAsset({
    incidentStatus,
    displayFormat,
    asset,
}: IncidentAssetProps): JSX.Element {
    return (
        <AssetPopover
            id={asset.assetId}
            openGTM={() =>
                viewAssetPopupEvent(incidentStatus, displayFormat, asset.value, asset.threatScore)
            }
            directCloseGTM={() =>
                closeAssetPopupEvent(
                    incidentStatus,
                    displayFormat,
                    asset.value,
                    asset.threatScore,
                    'direct',
                )
            }
            outsideCloseGTM={() =>
                closeAssetPopupEvent(
                    incidentStatus,
                    displayFormat,
                    asset.value,
                    asset.threatScore,
                    'outside',
                )
            }
        >
            <AssetSquare asset={asset} key={asset.assetId} id={asset.alias}>
                {asset.alias}
            </AssetSquare>
        </AssetPopover>
    )
}
