import {GuidType} from '../../../../../values/generic-type-defintions'
import {PolicyNameType} from '../../policy-management/type/policy-management-state'

export type EnforcementPolicy = {
    id: GuidType
    name: string | PolicyNameType
    isDefault: boolean
    createdTimestamp: string
    updatedTimestamp: string
    locations: Location2Policy[]
    networkProtection: NetworkProtection
    usbProtection: UsbProtection
}

export type EnforcementPolicyToCreateOrUpdate = {
    id: GuidType | undefined
    isDefault: boolean
    name: string
    networkProtection: NetworkProtection
    usbProtection: UsbProtection
}

export type NetworkProtection = {
    isEnabled: boolean
}

export type UsbProtection = {
    isEnabled: boolean
    policyForNewDevices: policyForNewDevicesEnum
    policyForMasterOverride: policyForMasterOverrideEnum
    policyForMasterOverrideDuration: string | null
    isBlockAccessToOfflineAssetsEnabled: boolean
    isAllowAnyOnMasterEnabled: boolean
}

export enum policyForMasterOverrideEnum {
    NEVER = 'NEVER',
    TIME_LIMIT = 'TIME_LIMIT',
    UNTIL_REVIEWED = 'UNTIL_REVIEWED',
}

export enum policyForNewDevicesEnum {
    ALWAYS_UNWANTED = 'ALWAYS_UNWANTED',
    RISK_BASED = 'RISK_BASED',
    ALLOW_ACCESS = 'ALLOW_ACCESS',
}

export type Location2Policy = {
    locationId: GuidType
    policyId: GuidType
}
