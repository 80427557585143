import * as styled from '../common/data-actions.styled'
import usedTypeSelector from '../../../../../hooks/use-typed-selector'
import latestEventTimeStampReselector from '../common/latest-event-timestamp-reselector'
import {usePagedIndicators} from '../../../contexts/indicators/use-paged-indicators'
import {RefreshButton} from '../common/refresh-button/refresh-button'
import {LatestEventWrapper} from './latest-event/latest-event-wrapper.styled'
import {Label} from './latest-event/label.styled'
import {Value} from './latest-event/value.styled'
import {useDispatch} from 'react-redux'
import {fetchIndicators} from '../../../../../store/state/indicators/action-creators'

export function DataActions(): JSX.Element {
    const dispatch = useDispatch()
    const latestEventTimestamp = usedTypeSelector(latestEventTimeStampReselector)
    const {loading, refreshData, totalNumberOfIndicators} = usePagedIndicators()

    function refresh(): void {
        dispatch(fetchIndicators())
        refreshData()
    }
    return (
        <styled.DataActions>
            <LatestEventWrapper>
                <Label>Latest Event:</Label>
                <Value id="indicators_latest_event">{latestEventTimestamp}</Value>
            </LatestEventWrapper>
            <RefreshButton
                id="indicators_refresh"
                totalNumberOfRecords={totalNumberOfIndicators}
                loading={loading}
                refreshData={refresh}
                state="indicators"
            />
        </styled.DataActions>
    )
}
