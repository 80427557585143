import {ZOOM_LEVEL_TYPE} from '../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {addEvent} from '../add-event'

export function changeZoomLevelEvent(
    zoomLevel: ZOOM_LEVEL_TYPE,
    interaction: 'filter' | 'double-clicking vessel',
): boolean {
    return addEvent('changeZoomLevel', {
        newZoomLevel: zoomLevel,
        interaction,
    })
}
