import {useDispatch} from 'react-redux'
import {resetFilterEvent} from '../../../../../core/google-tag-manager/reports/reset-filter-event'
import {resetFilter} from '../../../../../store/state/incidents-filter/action-creators'
import {resetVesselFilter} from '../../../../../store/state/vessel-filter/action-creators'
import * as Styles from './reset-filter-button.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'

export function ResetFilterButton(): JSX.Element {
    const dispatch = useDispatch()
    const isInactive = useTypedSelector(isInactiveSelector)

    return (
        <Styles.ButtonWrapper isInactive={isInactive}>
            <Styles.Button
                id="reset-button-incidents"
                onClick={(e) => {
                    e.preventDefault()
                    resetFilterEvent()
                    dispatch(resetFilter())
                    dispatch(resetVesselFilter())
                }}
            >
                Reset
            </Styles.Button>
        </Styles.ButtonWrapper>
    )
}
