import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 6px;
    background-color: ${(props) => props.theme.myVessels.actionButton.background};
    color: ${(props) => props.theme.myVessels.actionButton.text};
    margin: 20px 50px;
    padding: ${spacing(1)} ${spacing(2)};
`

export const Text = styled.span`
    margin-left: ${spacing(2)};
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.28px;
`
