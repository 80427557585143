import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useRef, useState} from 'react'
import {X} from 'react-feather'
import {searchVesselTagsEvent} from '../../../../core/google-tag-manager/my-vessels/search-for-vessel-tags-in-filter-event'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {vesselTagsSelector} from '../../../../store/state/vessel-tags/selectors'
import * as Styles from './tag-search.styled'
import {VesselTag} from '../../../vessel-tag/vessel-tag'
import {findRecommendationFromEntryTag} from '../../data-helper'
import {useDispatch} from 'react-redux'
import {
    setSearchVesselNameTerm,
    setSearchVesselTagTerm,
    setSearchVesselTerm,
    setShowXButton,
} from '../../../../store/state/vessel-filter/action-creators'
import {
    pageTypeSelector,
    vesselFilterSelector,
} from '../../../../store/state/vessel-filter/selectors'
import {setSearchIncidentNo} from '../../../../store/state/incidents-filter/action-creators'
import {isInactiveSelector} from '../../../../store/state/saved-filters/selectors'
import {StrictMode} from '../../../../store/state/users/state'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'

export function VesselTagSearch(): JSX.Element {
    const dispatch = useDispatch()
    const [searchTag, setSearchTag] = useState<string>('')
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const existingTags = useTypedSelector(vesselTagsSelector)
    const {searchVesselTagTerm, searchVesselTerm, showXButton} =
        useTypedSelector(vesselFilterSelector)
    const pageType = useTypedSelector(pageTypeSelector)
    const isInactive = useTypedSelector(isInactiveSelector)
    const {strictMode} = useTypedSelector(currentUserSelector)

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setRecommendationOpen(true)
        setSearchTag(e.currentTarget.value)
        dispatch(setSearchVesselTerm(e.currentTarget.value))
        if (e.currentTarget.value.length === 0) {
            dispatch(setShowXButton(false))
            dispatch(setSearchVesselNameTerm(e.currentTarget.value, pageType))
        }
    }

    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            dispatch(setSearchVesselNameTerm(searchVesselTerm, pageType))
            dispatch(setSearchIncidentNo(''))
            setRecommendationOpen(false)
            dispatch(setShowXButton(true))
        }
    }

    function onSearchClick() {
        dispatch(setSearchVesselNameTerm(searchVesselTerm, pageType))
        dispatch(setSearchIncidentNo(''))
        setRecommendationOpen(false)
        dispatch(setShowXButton(true))
    }

    function onXClick() {
        dispatch(setSearchVesselTerm(''))
        dispatch(setSearchVesselNameTerm('', pageType))
        dispatch(setShowXButton(false))
    }

    function onDeleteClick(tagToDelete: string) {
        const newsearchTagList = searchVesselTagTerm?.filter((element) => element !== tagToDelete)
        dispatch(setSearchVesselTagTerm(newsearchTagList, pageType, isDefaultTagUser))
        searchVesselTagsEvent(newsearchTagList)
    }

    const filteredUniqueResult = findRecommendationFromEntryTag(existingTags, searchTag).filter(
        (element) =>
            element.name !==
            searchVesselTagTerm.find((inTheFilterElement) => inTheFilterElement === element.name),
    )

    const showRecomandations = recommendationOpen && filteredUniqueResult?.length !== 0

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setRecommendationOpen(false))
    const isDefaultTagUser = strictMode === StrictMode.DefaultVisibility

    return (
        <Styles.Container isInactive={isInactive}>
            <Styles.SearchInput
                id="tags-search"
                placeholder="Filter by tag, vessel name"
                autoComplete="off"
                value={searchVesselTerm}
                onChange={onChangeFn}
                onKeyPress={onEnterClick}
            />
            {showXButton && (
                <Styles.XIcon onClick={() => onXClick()} id="x-icon">
                    <X onClick={() => onXClick()} height={20} width={20} />
                </Styles.XIcon>
            )}
            <Styles.SearchIcon onClick={() => onSearchClick()} id="search-icon">
                <Icon glyph="Search" height={20} width={20} />
            </Styles.SearchIcon>
            <Styles.TagResultsWrapper ref={clickOutside}>
                {showRecomandations && (
                    <Styles.TagResultsPopUp>
                        {filteredUniqueResult?.map((tag, index) => (
                            <VesselTag
                                key={index}
                                tagName={tag.name}
                                onClickFn={() => {
                                    dispatch(
                                        setSearchVesselTagTerm(
                                            [...searchVesselTagTerm, tag.name],
                                            pageType,
                                            isDefaultTagUser,
                                        ),
                                    )
                                    dispatch(setSearchVesselTerm(''))
                                    searchVesselTagsEvent([...searchVesselTagTerm, tag.name])
                                    setRecommendationOpen(false)
                                }}
                            />
                        ))}
                    </Styles.TagResultsPopUp>
                )}
                {searchVesselTagTerm?.map((tag, index) => (
                    <VesselTag key={index} tagName={tag} onDeleteFn={() => onDeleteClick(tag)} />
                ))}
            </Styles.TagResultsWrapper>
        </Styles.Container>
    )
}
