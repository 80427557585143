import {MouseEvent} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {Button} from './button.styled'
import {Text} from './text.styled'
import LoadingState from '../../../../../../values/loading-state-enum'
import {DisabledButton} from './disabled-button.styled'
import {refreshFeedEvent} from '../../../../../../core/google-tag-manager/asset-modal/refresh-feed-event'
import {categoryName} from '../../../../../../core/google-tag-manager/asset-modal/helper'

interface RefreshButtonProps {
    id: string
    loading: LoadingState
    totalNumberOfRecords: number | undefined
    refreshData: () => void
    state: categoryName
}

export function RefreshButton({
    id,
    refreshData,
    loading,
    totalNumberOfRecords,
    state,
}: RefreshButtonProps): JSX.Element | null {
    function onClickHandler(e: MouseEvent) {
        refreshData()
        e.preventDefault()
        refreshFeedEvent(state)
    }
    if (
        loading === LoadingState.NotPopulated ||
        (totalNumberOfRecords == undefined && loading !== LoadingState.Errored)
    ) {
        return null
    }

    if (loading === LoadingState.RequestingData) {
        return (
            <DisabledButton>
                <Icon glyph="Reset" height={16} width={16} />
                <Text>Refresh Feed</Text>
            </DisabledButton>
        )
    }

    return (
        <Button id={id} onClick={onClickHandler}>
            <Icon glyph="Reset" height={16} width={16} />
            <Text>Refresh Feed</Text>
        </Button>
    )
}
