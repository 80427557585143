import {MouseEvent} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import * as Styles from './button.styled'
import {useOTLocations} from '../contexts/hooks/use-ot-locations'

/**
 * Button component for triggering synchronization of locations and re-fetch
 * locations from ot-inventory
 */
export function RefreshButton(): JSX.Element {
    const {width} = useDimensions()
    const {refreshData} = useOTLocations()

    function refreshFunction(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        refreshData()
    }

    return (
        <Styles.Button id="refresh-button" onClick={refreshFunction}>
            <Icon glyph="Reset" height={16} width={16} />
            {width > 400 && <Styles.Text>{width > 550 ? 'Refresh Data' : 'Refresh'}</Styles.Text>}
        </Styles.Button>
    )
}
