import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {filterByStatusEvent} from '../../../../../core/google-tag-manager/incidents-beta/filter-by-status-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setFilteredIncidentStatus} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {narrowLayoutFn} from '../../cards/grid-helper'
import {FilterCard, FilterCount, FilterText} from './filter-card.styled'
import {avaliableStatuses} from '../../filter-bar/incident-status/incident-status-filter'
import {remove} from 'lodash'

export function ClosedIncidentsFilter(): JSX.Element {
    const {closedIncidents} = usePagedIncidents()
    const dispatch = useDispatch()
    let {filterByStatus} = useTypedSelector(incidentsFilterSelector)
    const {width} = useDimensions()
    const narrowLayout = narrowLayoutFn(width)
    const filter = [
        IncidentStatus.CLOSED,
        IncidentStatus.CLOSED_FALSE_POSITIVE,
        IncidentStatus.CLOSED_MONITOR,
    ]
    function onClickWrapper() {
        if (!filterByStatus || filterByStatus.length === avaliableStatuses.flat().length) {
            filterByStatus = []
        }
        filterByStatus = [...filterByStatus]
        if (
            filterByStatus?.includes(IncidentStatus.CLOSED) &&
            filterByStatus?.includes(IncidentStatus.CLOSED_FALSE_POSITIVE) &&
            filterByStatus?.includes(IncidentStatus.CLOSED_MONITOR)
        ) {
            remove(filterByStatus, (value) => filter.includes(value))
            dispatch(setFilteredIncidentStatus(filterByStatus))
            filterByStatusEvent(filterByStatus)
        } else {
            dispatch(setFilteredIncidentStatus([...filter, ...filterByStatus]))
            filterByStatusEvent([...filter, ...filterByStatus])
        }
    }
    return (
        <FilterCard
            width={width}
            incidentStatus={IncidentStatus.CLOSED}
            onClick={onClickWrapper}
            activeFilter={
                (filterByStatus?.includes(IncidentStatus.CLOSED) &&
                    filterByStatus?.includes(IncidentStatus.CLOSED_FALSE_POSITIVE) &&
                    filterByStatus?.includes(IncidentStatus.CLOSED_MONITOR)) ||
                filterByStatus?.length === 0 ||
                filterByStatus == undefined
            }
            id="filter-card-incident-status-closed"
        >
            <FilterCount id="filter-card-status-closed-count">{closedIncidents}</FilterCount>
            <FilterText id="filter-card-status-closed-text">
                {narrowLayout ? 'CLOSED' : 'CLOSED INCIDENT(S)'}
            </FilterText>
        </FilterCard>
    )
}
