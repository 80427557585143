import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'
import {SoftwareInventoryCard} from './software-inventory-card'
import {CardArea} from './_styled/software-inventory-cards.styled'
import {addQueryParam} from '../../../../helpers/navigation'
import {getFormattedSoftwareInventoryData, SOFTWARE_INVENTORY_DETAILS} from '../data-helpers'
import {openSoftwareDetailsModalEvent} from '../../../../core/google-tag-manager/software-inventory/open-software-details-modal'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../store/state/audit-log/state'

export function SoftwareInventoryCardsPopulated(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()

    const {dataSoftwareInventory, displaySoftwareDetailsModal} = usePagedSoftwareInventory()

    return (
        <CardArea width={width} id="card-data-grid-style">
            {dataSoftwareInventory?.map((software) => {
                const formattedSoftwareInventoryData = getFormattedSoftwareInventoryData(software)
                return (
                    <SoftwareInventoryCard
                        key={software.identity}
                        software={software}
                        onClickFn={() => {
                            addQueryParam(
                                SOFTWARE_INVENTORY_DETAILS,
                                formattedSoftwareInventoryData.identity,
                            )
                            displaySoftwareDetailsModal(formattedSoftwareInventoryData.identity)
                            openSoftwareDetailsModalEvent(formattedSoftwareInventoryData)
                            dispatch(
                                logDetailsOpen(
                                    AuditLogPageType.SOFTWARE_INVENTORY,
                                    AuditLogAuditType.DETAIL_PANEL_ACCESS,
                                    formattedSoftwareInventoryData.identity,
                                ),
                            )
                        }}
                    />
                )
            })}
        </CardArea>
    )
}
