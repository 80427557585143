import LoadingState from '../../../../values/loading-state-enum'
import {IncidentResponse} from '../../../incidents-v3/contexts/types/incident-response'
import {
    IncidentAssignmentsCounts,
    IncidentAvgPerformanceType,
    IncidentCountsType,
    IncidentTrendsType,
    IncidentVesselsPerformanceType,
} from './incidents-report-output'
import {PagedIncidentsReportState} from './paged-incidents-report-state'

export const DEFAULT_PAGED_INCIDENTS_REPORT_STATE: PagedIncidentsReportState = {
    loadingPopulatedDataState: {
        incidentsDetails: LoadingState.NotPopulated,
        incidentsCounts: LoadingState.NotPopulated,
        incidentsAssignments: LoadingState.NotPopulated,
        incidentsTrends: LoadingState.NotPopulated,
        incidentsAvgPerformance: LoadingState.NotPopulated,
        incidentsVesselsPerformance: LoadingState.NotPopulated,
    },
    showFilterBar: false,
    isInactive: false,
    highlightedFilerValue: 'None',
    filteredDataCounts: {} as IncidentCountsType,
    filteredDataContent: new Array<IncidentResponse>(),
    filteredAssignementsCounts: {} as IncidentAssignmentsCounts,
    incidentsTrends: new Array<IncidentTrendsType>(),
    incidentsAvgPerformance: new Array<IncidentAvgPerformanceType>(),
    incidentsVesselsPerformance: {} as IncidentVesselsPerformanceType,
    incidentDetailExpandedId: null,
}
