import {spacing} from '../../../theme/spacing'
import styled from 'styled-components'
import {Colors} from '../../../theme/colours'

export const TextInput = styled.input`
    width: 180px;
    border: none;
    border-bottom: 1px solid ${Colors.grey2};
    padding: ${spacing(1)};
    &:focus {
        border: none;
        border-bottom: 1px solid ${Colors.blue2};
    }
    &:focus-visible {
        outline: none;
    }
`

export const Field = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${spacing(1)};
    justify-content: space-between;
    align-items: center;
    gap: ${spacing(2)};
`

export const Cell = styled.td`
    padding: ${spacing(3)};
    min-width: 100px;
`

export const Row = styled.tr`
    background-color: ${Colors.white};
`

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
`
export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
`

export const DropdownRow = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 5px;
`

export const FormRowActions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: ${spacing(4)};
    gap: ${spacing(2)};
`

export const FormRowActionsButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
`

export const FormErrorMessage = styled.div`
    padding: ${spacing(1)} ${spacing(2)} ${spacing(1)} ${spacing(2)};
    background-color: #fad4da;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
`

export const HR = styled.hr`
    margin: ${spacing(4)} 0;
    border: 1px solid #e2e5e9;
`

interface ButtonProps {
    primary: boolean
    color: string
    onClick?: () => void
}

export const Button = styled.button<ButtonProps>`
    background-color: ${(props: ButtonProps) => (props.primary ? props.color : 'transparent')};
    border: 1px solid ${(props: ButtonProps) => props.color};
    border-radius: 4px;
    color: ${(props: ButtonProps) => (props.primary ? 'white' : props.color)};
    padding: 12px;
    min-width: 74px;
    display: flex;
    gap: ${spacing(1)};
    justify-content: center;
    &:focus-visible {
        text-decoration: underline;
        outline: none;
    }
`

export const ErrorMessage = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${spacing(2)};
    justify-content: start;
    padding: ${spacing(1)} ${spacing(2)} ${spacing(1)} ${spacing(2)};
    background-color: #fad4da;
    font-size: 12px;
    max-width: 180px;
    white-space: pre-wrap;
`

export const FormInputContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(1)};
`

export const FormLabel = styled.label`
    align-self: start;
`

export const AdditionalFieldTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-start: ${spacing(4)};
`

export const AdditionalFieldContent = styled.div`
    display: flex;
    gap: ${spacing(2)};
    flex-direction: column;
`

export const AdditionalFieldRow = styled.div`
    display: flex;
    gap: ${spacing(2)};
`

export const AdditionalFieldTextInput = styled.input`
    width: 180px;
    border: 2px solid #a7b0be;
    border-radius: 3px;
    padding: ${spacing(1)};
    &:focus-visible {
        outline: none;
    }
`
