import {BasicPaging} from '../../../../../components/paging/basic/basic-paging'
import {
    getMaximumNumberOfPagesToShow,
    getPagesToShow,
    getPagingButtonsToShow,
} from '../../../../../components/paging/basic/paging-helpers'
import {SpacingBlank} from '../../../../../components/paging/basic/styling/spacing-blank.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedAlerts} from '../../../contexts/alerts/use-paged-alerts'

function AlertsPaging(): JSX.Element {
    const {selectedPage, totalNumberOfPages, selectPage, pageSize} = usePagedAlerts()
    const {width} = useDimensions()

    if (
        selectPage == null ||
        selectedPage == undefined ||
        totalNumberOfPages == undefined ||
        pageSize == undefined
    ) {
        return <SpacingBlank />
    }
    const maximumPagesToShow = getMaximumNumberOfPagesToShow(totalNumberOfPages)

    return (
        <BasicPaging
            gtmEventPrefix="asset-alerts"
            selectedPage={selectedPage}
            totalNumberOfPages={maximumPagesToShow}
            selectPage={selectPage}
            pagesToShow={getPagesToShow(width)}
            pageSize={pageSize}
            showButtons={getPagingButtonsToShow(width, maximumPagesToShow)}
        />
    )
}

export default AlertsPaging
