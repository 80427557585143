import {addEvent} from '../add-event'
import {getAttachmentType} from '../helpers/attachment-type-helper'
import {getRatingStars} from '../helpers/compliance-rating-helper'

export function viewAttachmentEvent(
    contentType: string,
    pageOfReport: number,
    rating: number,
    reportType: string,
): boolean {
    return addEvent('reportsPageViewAttachment', {
        TypeOfAttachmen: getAttachmentType(contentType),
        pageOfReport,
        stars: getRatingStars(rating),
        reportType,
    })
}
