import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {
    getBetaPageGridTemplateColumns,
    getBetaPageReducedGridTemplateColumns,
    getGridTemplateColumns,
    getReducedGridTemplateColumns,
} from '../../helpers/data-helpers'
interface Props {
    width: number
    betaPage?: boolean
    showReducedGrid: boolean
}

export const TableDataGridStyle = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.betaPage
            ? props.showReducedGrid
                ? getBetaPageReducedGridTemplateColumns(props.width)
                : getBetaPageGridTemplateColumns(props.width)
            : props.showReducedGrid
              ? getReducedGridTemplateColumns(props.width)
              : getGridTemplateColumns(props.width)};
    gap: ${spacing(2)};
    padding: ${(props) =>
        props.showReducedGrid
            ? `0 ${spacing(2)} ${spacing(2)} ${spacing(2)}`
            : `0 ${spacing(4)} ${spacing(2)} ${spacing(4)}`};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${(props) => props.theme.unknownAssets.dataArea.background};
    ${(props) => (props.showReducedGrid ? '' : 'flex: 1;')}
`
