import styled from 'styled-components'
import {Colors} from '../../../theme/colours'
import LoadingState from '../../../values/loading-state-enum'
import {spacing} from '../../../theme/spacing'

export const Dialog = styled.dialog`
    min-width: 232px;
    max-width: 232px;
    border: 2px solid ${Colors.grey6};
    &::backdrop {
        background: hsl(0 0% 0% / 50%);
    }
    padding-inline: ${spacing(5)};
    padding-block: ${spacing(3)};
`

interface ModalTextInputProps {
    loadingState: LoadingState
}

export const ModalTextInput = styled.input`
    width: 100%;
    border: ${(props: ModalTextInputProps) => {
        switch (props.loadingState) {
            case LoadingState.Errored:
                return '2px solid #F34663'
            case LoadingState.Loaded:
                return '2px solid #0E7A0E'
            default:
                return '2px solid #a7b0be'
        }
    }};
    padding: 10px;
    border-radius: 3px;
    &:focus {
        outline: none;
        border: 2px solid #1f88e5;
    }
`

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(5)};
`

export const FormLabel = styled.label`
    align-self: start;
    font-weight: 600;
`

export const SuccessMessage = styled.div`
    align-self: center;
`

interface ButtonProps {
    primary: boolean
    color: string
    onClick?: () => void
}

export const Button = styled.button<ButtonProps>`
    background-color: ${(props: ButtonProps) => (props.primary ? props.color : 'transparent')};
    border: 1px solid ${(props: ButtonProps) => props.color};
    border-radius: 4px;
    color: ${(props: ButtonProps) => (props.primary ? 'white' : props.color)};
    padding: 12px;
    min-width: 74px;
    display: flex;
    gap: ${spacing(1)};
    justify-content: center;
    &:focus-visible {
        text-decoration: underline;
        outline: none;
    }
    align-self: center;
`

export const Header = styled.div`
    max-height: 30px;
    border-bottom: 1px solid ${Colors.grey3};
`
