import {createSelector} from 'reselect'
import {fetchingLatestEventTimestampsSelector} from '../../../../../store/state/latest-event-timestamps/selectors'
import {locationsLoadingSelector} from '../../../../../store/state/locations/selectors'
import {nodesFetchingSelector} from '../../../../../store/state/nodes/selectors'
import {fetchingSfmScoresSelector} from '../../../../../store/state/sfm-scores/selectors'
import {threatMeasuresFetchingSelector} from '../../../../../store/state/threat-measures/selectors'

export const isLoadingReselector = createSelector(
    locationsLoadingSelector,
    nodesFetchingSelector,
    fetchingSfmScoresSelector,
    fetchingLatestEventTimestampsSelector,
    threatMeasuresFetchingSelector,
    (
        loadingLocations,
        loadingNodes,
        loadingSfmScores,
        loadingLatestEventTimestamps,
        loadingThreatMeasures,
    ): boolean => {
        return (
            loadingLocations ||
            loadingNodes ||
            loadingSfmScores ||
            loadingLatestEventTimestamps ||
            loadingThreatMeasures
        )
    },
)
