import {PlaceholderType} from '../../../values/placeholder'
import {addEvent} from '../add-event'

export function expandVesselDetailsEvent(
    where: PlaceholderType,
    score: number | undefined,
): boolean {
    return addEvent('expandVesselDetails', {
        where: where,
        nodeScore: score,
    })
}
