import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {refreshSystemPageEvent} from '../../../../core/google-tag-manager/system-page/refresh-system-page-event'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {configSelector} from '../../../../store/state/config/selectors'
import {fetchEmailRecipients} from '../../../../store/state/email-recipient-management/action-creators'
import {
    hasDataSelector,
    isFetchingSelector,
    loadingSelector,
} from '../../../../store/state/email-recipient-management/selectors'
import {fetchUsers} from '../../../../store/state/users/action-creators'
import {usersLoadingStateSelector} from '../../../../store/state/users/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {Header} from '../shared/header/header'
import {NoData} from '../shared/no-data/no-data'
import {AddNewUserArea} from './add-new-user-area'
import {EmailManagementList} from './email-management-list'

export function EmailManagement(): JSX.Element {
    const config = useTypedSelector(configSelector)
    const title: string = config.tenantDisplayName ? config.tenantDisplayName : 'Medulla'
    const isLoading = useTypedSelector(isFetchingSelector)
    const loadingState = useTypedSelector(loadingSelector)

    const hasData = useTypedSelector(hasDataSelector)
    const dispatch = useDispatch()

    function refresh() {
        dispatch(fetchUsers())
        dispatch(fetchEmailRecipients())
        refreshSystemPageEvent('EmailRecipients')
    }

    useEffect(() => {
        if (loadingState === LoadingState.NotPopulated) {
            dispatch(fetchEmailRecipients())
        }
    }, [dispatch, loadingState])
    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)

    useEffect(() => {
        if (usersLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchUsers())
        }
    }, [dispatch, usersLoadingState])

    return (
        <>
            <Header text={`Email recipients management for ${title}`} refreshFunction={refresh} />
            <AddNewUserArea />
            {isLoading ? (
                <DataLoading />
            ) : hasData ? (
                <EmailManagementList />
            ) : (
                <NoData message={`There are no email recipients for ${title}`} />
            )}
        </>
    )
}
