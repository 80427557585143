import {NodeValue} from '../../../values/nodes/NodeData'
import {addEvent} from '../add-event'
import {DisplayFormatType} from '../helpers/display-format-type'

export function closeAssetPopupEvent(
    status: string,
    displayFormat: DisplayFormatType,
    value: NodeValue,
    score: number | undefined,
    clickType: 'direct' | 'outside',
): boolean {
    return addEvent('closeIncidentAssetPopup', {
        incidentStatus: status,
        displayFormat,
        nodeValue: value,
        nodeScore: score,
        clickType,
    })
}
