import * as Styles from './_styles/nav-bar-link.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useRef, useState} from 'react'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {DropdownMenuWrap} from './_styles/location-dropdown.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {LocationDropDownButtonStyle} from './location-dropdown-button/location-dropdown-button.styled'
import {NavBarLink} from './nav-bar-link'
import {viewNetworkAssetsEvent} from '../../../../core/google-tag-manager/navigation/view-network-assets-event'
import {viewUsbDevicesEvent} from '../../../../core/google-tag-manager/navigation/view-usb-devices-event'
import {useLocation} from 'react-router-dom'
import {viewSoftwareEvent} from '../../../../core/google-tag-manager/navigation/view-software-event'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'

interface InventoryLinkProps {
    gtmLogger: ((trigger: string) => void) | null
}
export function InventoryLink({gtmLogger = null}: InventoryLinkProps): JSX.Element {
    const [inventoryDropDown, setInventoryDropDown] = useState(false)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const fleetConfig = useTypedSelector(fleetConfigSelector)

    const {width} = useDimensions()

    function menuClicked() {
        if (gtmLogger != null) {
            gtmLogger('mainNavigation')
        }
        setInventoryDropDown(!inventoryDropDown)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clickOutside = useRef() as any
    useOnClickOutside(clickOutside, () => setInventoryDropDown(false))

    const pathname = useLocation().pathname
    return (
        <Styles.NavigationLinkDiv
            id="navigation-inventory"
            width={width}
            activeLink={
                pathname.startsWith('/network-assets') ||
                pathname.startsWith('/usb-devices') ||
                pathname.startsWith('/software') ||
                pathname.startsWith('/nmea')
            }
            onClick={menuClicked}
            ref={clickOutside}
        >
            Inventory
            <DropdownMenuWrap>
                <LocationDropDownButtonStyle id="navigation-inventory-toggle">
                    <Icon
                        glyph={inventoryDropDown ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </LocationDropDownButtonStyle>
                {inventoryDropDown && (
                    <Styles.DropdownContentStyle width={width}>
                        {(isInternalUser || fleetConfig.networkAssets) && (
                            <NavBarLink
                                id="navigation-network-assets"
                                navLinkText=" Network Assets"
                                path="/network-assets"
                                gtmLogger={() => viewNetworkAssetsEvent('mainNavigation')}
                            />
                        )}
                        {(isInternalUser || fleetConfig.usbInventory) && (
                            <NavBarLink
                                id="navigation-usb-devices"
                                navLinkText="USB Devices"
                                path="/usb-devices"
                                gtmLogger={() => viewUsbDevicesEvent('mainNavigation')}
                            />
                        )}
                        {(isInternalUser || fleetConfig.softwareInventory) && (
                            <NavBarLink
                                id="navigation-software"
                                navLinkText="Software"
                                path="/software"
                                gtmLogger={() => viewSoftwareEvent('mainNavigation')}
                            />
                        )}
                        {(isInternalUser || fleetConfig.nmeaInventory) && (
                            <NavBarLink
                                id="navigation-nmea"
                                navLinkText="NMEA"
                                path="/nmea"
                                gtmLogger={() => viewSoftwareEvent('mainNavigation')}
                            />
                        )}
                    </Styles.DropdownContentStyle>
                )}
            </DropdownMenuWrap>
        </Styles.NavigationLinkDiv>
    )
}
