import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../..'
import AppState from '../../types/app-state'
import {Api} from '../../../api/Api'

const NODES_URL = '/api/v1/nodeWatch'

const setError = (error: string): Actions.SetError => ({
    type: ActionType.SET_ERROR,
    payload: error,
})

const setLoading = (): Actions.SetLoading => ({
    type: ActionType.SET_LOADING,
})

const setWatchedNodes = (nodes: string[]): Actions.SetWatchedNodes => ({
    type: ActionType.SET_WATCHED_NODES,
    payload: nodes,
})

export const fetchWatchedNodes = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(setLoading())
        REST.get(NODES_URL)
            .then((response) => {
                dispatch(setWatchedNodes(response.data))
            })
            .catch((err) => dispatch(setError(err)))
    }
}

export const addNodeToWatchList = (
    node: string,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(setLoading())
        REST.post(`${NODES_URL}/${node}`).then(() => {
            dispatch(fetchWatchedNodes())
        })
    }
}

export const deleteNodeFromWatchList = (
    node: string,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(setLoading())
        REST.delete(`${NODES_URL}/${node}`).then(() => {
            dispatch(fetchWatchedNodes())
        })
    }
}
