import {NodeValue} from '../../../../../values/nodes/NodeData'
import {ProcessingStatus} from '../../../../../values/processing-save-state-enum'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function setChangeHostname(value: string): Actions.SetChangeHostnameAction {
    return {type: ActionType.SET_CHANGE_HOSTNAME, payload: value}
}

export function setChangeAssetValue(value: NodeValue): Actions.SetChangeAssetValueAction {
    return {type: ActionType.SET_CHANGE_ASSET_VALUE, payload: value}
}

export function setSaveProcessingStatus(
    value: ProcessingStatus,
): Actions.SetSaveProcessingStatusAction {
    return {
        type: ActionType.SET_SAVE_PROCESSING_STATUS,
        payload: value,
    }
}
