import {GuidType} from '../../../values/generic-type-defintions'
import {addEvent} from '../add-event'
import {DisplayFormatType} from '../helpers/display-format-type'

export function updateIncidentNoteEvent(
    noteIdentity: GuidType,
    displayFormat: DisplayFormatType,
): boolean {
    return addEvent('updateIncidentNoteEvent', {noteIdentity: noteIdentity, displayFormat})
}
