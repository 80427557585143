import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../../store/state/session-data/actions'
import SessionActionType from '../../../../../store/state/session-data/action-type'
import LoadingState from '../../../../../values/loading-state-enum'
import {DEFAULT_METRICS_POLICY_STATE} from '../type/default-metrics-policy-state'
import {MetricsPolicyState} from '../type/metrics-policy-state'
import {
    ArrayOfMetricsToCreateOrUpdate,
    MetricsPolicy,
    PolicyToCreateOrUpdate,
} from '../type/metrics-policy'
import {ConfigTypes, MetricsSchema, ValuesOutput} from '../type/metrics-policy-schema'
import {isEqual} from 'lodash'

export const metricsPolicyReducer = produce(
    (draft: MetricsPolicyState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_POLICY_DATA_ACTION:
                draft.loadingRequestedMetricsDataState = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_METRICS_SCHEMA_ACTION:
                if (!isEqual(draft.metricsSchema, action.payload.receivedMetricsSchema)) {
                    draft.metricsSchema = action.payload.receivedMetricsSchema
                }
                break
            case ActionType.RECEIVED_REQUESTED_POLICY_DATA_ACTION:
                draft.loadingRequestedMetricsDataState = LoadingState.Loaded
                if (!isEqual(draft.selectedPolicy, action.payload.receivedPolicy)) {
                    draft.selectedPolicy = action.payload.receivedPolicy
                }
                if (!isEqual(draft.policyToCreateOrUpdate, action.payload.receivedPolicy)) {
                    draft.policyToCreateOrUpdate = populatePolicyToCreateOrUpdate(
                        draft.metricsSchema,
                        action.payload.receivedPolicy,
                    )
                }
                draft.isMetricsUpdated = DEFAULT_METRICS_POLICY_STATE.isMetricsUpdated
                break
            case ActionType.SET_DEFAULT_POLICY_VALUES:
                draft.selectedPolicy = DEFAULT_METRICS_POLICY_STATE.selectedPolicy
                draft.policyToCreateOrUpdate = DEFAULT_METRICS_POLICY_STATE.policyToCreateOrUpdate
                draft.isMetricsUpdated = DEFAULT_METRICS_POLICY_STATE.isMetricsUpdated
                break
            case ActionType.SET_CHANGE_POLICY_NAME:
                draft.isMetricsUpdated = action.payload.isValueChanged
                draft.policyToCreateOrUpdate = populatePolicyToCreateOrUpdate(
                    draft.metricsSchema,
                    draft.selectedPolicy,
                )
                draft.policyToCreateOrUpdate.name = action.payload.newName
                break
            case ActionType.SET_CHANGE_TARGETED_ASSETS_VALUE:
                updateMetricPolicy(
                    draft,
                    action.payload.metricConfigId,
                    (findRightMetric) => ({
                        ...findRightMetric,
                        target: action.payload.targetAssets,
                    }),
                    {
                        metricConfigId: action.payload.metricConfigId,
                        target: action.payload.targetAssets,
                        enabled: false,
                        settings: {},
                    },
                )
                draft.isMetricsUpdated = action.payload.isValueChanged
                break
            case ActionType.SET_CHANGE_METRIC_ENABLED_VALUE:
                updateMetricPolicy(
                    draft,
                    action.payload.metricConfigId,
                    (findRightMetric) => ({
                        ...findRightMetric,
                        enabled: action.payload.enabled,
                    }),
                    {
                        metricConfigId: action.payload.metricConfigId,
                        target: 100,
                        enabled: action.payload.enabled,
                        settings: {},
                    },
                )
                draft.isMetricsUpdated = action.payload.isValueChanged
                break
            case ActionType.SET_CHANGE_SUBMETRIC_ENABLED_VALUE:
                updateMetricPolicy(
                    draft,
                    action.payload.metricConfigId,
                    (findRightMetric) => {
                        const updatedSettings: Record<string, ValuesOutput> = {
                            ...findRightMetric.settings,
                            [action.payload.configName]: action.payload.enabled,
                        }
                        return {
                            ...findRightMetric,
                            settings: updatedSettings,
                        }
                    },
                    {
                        metricConfigId: action.payload.metricConfigId,
                        enabled: false,
                        target: 100,
                        settings: {
                            [action.payload.configName]: action.payload.enabled,
                        },
                    },
                )
                draft.isMetricsUpdated = action.payload.isValueChanged
                break
            case ActionType.SET_CHANGE_DROP_DOWN_CONFIG_VALUE:
                updateMetricPolicy(
                    draft,
                    action.payload.metricConfigId,
                    (findRightMetric) => {
                        const updatedSettings: Record<string, ValuesOutput> = {
                            ...findRightMetric.settings,
                            [action.payload.configName]: action.payload.selectedValue,
                        }
                        return {
                            ...findRightMetric,
                            settings: updatedSettings,
                        }
                    },
                    {
                        metricConfigId: action.payload.metricConfigId,
                        enabled: false,
                        target: 100,
                        settings: {
                            [action.payload.configName]: action.payload.selectedValue,
                        },
                    },
                )
                draft.isMetricsUpdated = action.payload.isValueChanged
                break

            case ActionType.SET_CHANGE_ONBOARD_CONFIG_VALUE:
                updateMetricPolicy(
                    draft,
                    action.payload.metricConfigId,
                    (findRightMetric) => {
                        const updatedSettings: Record<string, ValuesOutput> = {
                            ...findRightMetric.settings,
                            [action.payload.configName]: action.payload.selectedValue,
                        }
                        return {
                            ...findRightMetric,
                            settings: updatedSettings,
                        }
                    },
                    {
                        metricConfigId: action.payload.metricConfigId,
                        enabled: false,
                        target: 100,
                        settings: {
                            [action.payload.configName]: action.payload.selectedValue,
                        },
                    },
                )
                draft.isMetricsUpdated = action.payload.isValueChanged
                break
            case ActionType.SET_CHANGE_NUMERIC_CONFIG_VALUE:
                updateMetricPolicy(
                    draft,
                    action.payload.metricConfigId,
                    (findRightMetric) => {
                        const updatedSettings: Record<string, ValuesOutput> = {
                            ...findRightMetric.settings,
                            [action.payload.configName]: action.payload.selectedValue,
                        }
                        return {
                            ...findRightMetric,
                            settings: updatedSettings,
                        }
                    },
                    {
                        metricConfigId: action.payload.metricConfigId,
                        enabled: false,
                        target: 100,
                        settings: {
                            [action.payload.configName]: action.payload.selectedValue,
                        },
                    },
                )
                draft.isMetricsUpdated = action.payload.isValueChanged
                break
            case ActionType.SET_CHANGE_TEXT_CONFIG_VALUE:
                updateMetricPolicy(
                    draft,
                    action.payload.metricConfigId,
                    (findRightMetric) => {
                        const updatedSettings: Record<string, ValuesOutput> = {
                            ...findRightMetric.settings,
                            [action.payload.configName]: action.payload.selectedValue,
                        }
                        return {
                            ...findRightMetric,
                            settings: updatedSettings,
                        }
                    },
                    {
                        metricConfigId: action.payload.metricConfigId,
                        enabled: false,
                        target: 100,
                        settings: {
                            [action.payload.configName]: action.payload.selectedValue,
                        },
                    },
                )
                draft.isMetricsUpdated = action.payload.isValueChanged
                break
            case ActionType.SET_DISCARD_CHANGES:
                draft.discardChanges = action.payload
                draft.isMetricsUpdated = false
                draft.policyToCreateOrUpdate = populatePolicyToCreateOrUpdate(
                    draft.metricsSchema,
                    draft.selectedPolicy,
                )
                break
            case ActionType.SET_POLICY_AFTER_UPDATE:
                draft.policyToCreateOrUpdate = action.payload.receivedPolicy
                draft.selectedPolicy = action.payload.receivedPolicy
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_METRICS_POLICY_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
function populatePolicyToCreateOrUpdate(
    metricsSchema: MetricsSchema[],
    receivedPolicy: MetricsPolicy | undefined,
): PolicyToCreateOrUpdate {
    if (!receivedPolicy) {
        return {
            id: '',
            isDefault: false,
            name: '',
            metricPolicies: formatMetricsPolicy(
                metricsSchema,
                new Array<ArrayOfMetricsToCreateOrUpdate>(),
            ),
        }
    }
    return {
        id: receivedPolicy.id,
        isDefault: receivedPolicy.isDefault,
        name: receivedPolicy.name,
        metricPolicies: formatMetricsPolicy(metricsSchema, receivedPolicy.metricPolicies),
    }
}

function updateMetricPolicy(
    draft: MetricsPolicyState,
    metricConfigId: string,
    updateCallback: (
        findRightMetric: ArrayOfMetricsToCreateOrUpdate,
    ) => ArrayOfMetricsToCreateOrUpdate,
    objectToUpdate: ArrayOfMetricsToCreateOrUpdate,
) {
    if (!draft.policyToCreateOrUpdate.metricPolicies) {
        draft.policyToCreateOrUpdate.metricPolicies = []
    }

    const updatedMetricPolicies = draft.policyToCreateOrUpdate.metricPolicies.map(
        (findRightMetric) => {
            if (findRightMetric.metricConfigId === metricConfigId) {
                return updateCallback(findRightMetric)
            }
            return findRightMetric
        },
    )
    const existingMetricPolicyIndex = updatedMetricPolicies.findIndex(
        (metricPolicy) => metricPolicy.metricConfigId === metricConfigId,
    )

    if (existingMetricPolicyIndex === -1) {
        updatedMetricPolicies.push(objectToUpdate)
    }

    draft.policyToCreateOrUpdate = {
        ...draft.policyToCreateOrUpdate,
        metricPolicies: updatedMetricPolicies,
    }
}
function formatMetricsPolicy(
    metricsSchema: MetricsSchema[],
    metricPolicies: ArrayOfMetricsToCreateOrUpdate[],
): ArrayOfMetricsToCreateOrUpdate[] {
    const formattedMetricPolicies: ArrayOfMetricsToCreateOrUpdate[] = []

    metricPolicies?.forEach((metricPolicy) => {
        const metricConfigFromSchema = metricsSchema.find(
            (schemaElement) => schemaElement.identity === metricPolicy.metricConfigId,
        )

        if (metricConfigFromSchema) {
            const settings =
                metricPolicy.settings ?? getDefaultSettings(metricConfigFromSchema.policySchema)

            formattedMetricPolicies.push({
                metricConfigId: metricPolicy.metricConfigId,
                enabled: metricPolicy.enabled,
                target: metricPolicy.target,
                settings,
            })
        }
    })

    metricsSchema.forEach((schemaElement) => {
        const existingMetricPolicy = formattedMetricPolicies.find(
            (policy) => policy.metricConfigId === schemaElement.identity,
        )

        if (!existingMetricPolicy) {
            formattedMetricPolicies.push({
                metricConfigId: schemaElement.identity,
                enabled: true,
                target: 100,
                settings: getDefaultSettings(schemaElement.policySchema),
            })
        }
    })

    return formattedMetricPolicies
}

function getDefaultSettings(policySchema: ConfigTypes[]): Record<string, ValuesOutput> {
    return policySchema.reduce(
        (acc, schema) => {
            acc[schema.name] = schema.default
            return acc
        },
        {} as Record<string, ValuesOutput>,
    )
}
