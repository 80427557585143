import {useEffect, useRef} from 'react'
import {DataLoading} from '../../components/data-loading/data-loading'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {Header} from './components/header'
import * as Styled from './vessel-management.styled'
import useTypedSelector from '../../hooks/use-typed-selector'
import {isLocationsLoadingReSelector} from './reselectors/is-loading-reselector'
import {VesselManagementProvider} from './contexts/vessel-management-provider'
import {useVesselManagement} from './contexts/use-vessel-management'
import {acEnabledSelector} from '../../store/state/config/selectors'
import {useDispatch} from 'react-redux'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../store/state/audit-log/state'
import {PolicyManagementProvider} from '../policy-management/contexts/policy-management/policy-management-provider'
import {usePolicyManagement} from '../policy-management/contexts/policy-management/use-policy-management'
import {OTSourceModalDisplay} from './components/ot-sources-modal/ot-sources-modal'
import {FilterBar} from './components/filter-bar/filter-bar'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {fetchVesselManagementPrefsIfExist} from '../../store/state/vessel-management-filter/action-creators'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {StrictMode} from '../../store/state/users/state'
import {showFilterBarOnScreen} from './reselectors/helper'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {CloseFilterButton} from './components/filter-bar/close-filter-button/close-filter-button'
import {FilteredContents} from './components/filtered-contents'
import {useEnforcementPolicy} from '../policy-management/contexts/enforcement-policy/use-enforcement-policy'
import {EnforcementPolicyProvider} from '../policy-management/contexts/enforcement-policy/enforcement-policy-provider'

export default function VesselManagement(): JSX.Element {
    const acEnabled = useTypedSelector(acEnabledSelector)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.VESSEL_MANAGEMENT, AuditLogAuditType.PAGE_ACCESS))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <EnforcementPolicyProvider>
            <PolicyManagementProvider>
                <VesselManagementProvider hasActiveControl={acEnabled}>
                    <VesselManagementPage />
                </VesselManagementProvider>
            </PolicyManagementProvider>
        </EnforcementPolicyProvider>
    )
}

function VesselManagementPage(): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const {
        getLocationsInIsolationMode,
        getDeploymentStatus,
        setDeploymentStatus,
        showFilterBar,
        displayFilterBar,
        isGetDeploymentStatusLoading,
        isVesselDeploymentStatusLoading,
        showOTSourceModalDisplay,
        getPolicyLicenses,
    } = useVesselManagement()

    const isLoading =
        useTypedSelector(isLocationsLoadingReSelector) ||
        isGetDeploymentStatusLoading ||
        isVesselDeploymentStatusLoading

    const layoutRef = useRef(null)
    const {fetchPolicyData} = usePolicyManagement()
    const {fetchAllEnforcementPolicies} = useEnforcementPolicy()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const showFilterBarOn = showFilterBarOnScreen(width)

    useEffect(() => {
        getLocationsInIsolationMode()
        fetchPolicyData()
        getDeploymentStatus()
        getPolicyLicenses()
        fetchAllEnforcementPolicies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGetDeploymentStatusLoading) {
            setDeploymentStatus()
        }
    }, [isGetDeploymentStatusLoading])

    useEffect(() => {
        dispatch(setPageType(PageType.VESSEL_MANAGEMENT))
        dispatch(fetchVesselManagementPrefsIfExist(strictMode === StrictMode.DefaultVisibility))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    if (!showFilterBarOn && showFilterBar) {
        return (
            <ScrollablePageTemplate onClick={() => displayFilterBar(false)}>
                <Styled.FilterBarWrapper width={width} onClick={(e) => e.stopPropagation()}>
                    <FilterBar />
                    <Styled.BottomCloseFilterBar>
                        <CloseFilterButton text="Close Filter" />
                    </Styled.BottomCloseFilterBar>
                </Styled.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <FixedPageTemplate>
                <Styled.WideContentHeaderArea width={width}>
                    <Header text="Vessel Management" />
                </Styled.WideContentHeaderArea>
                <Styled.WideContentArea width={width} fullHeight={true}>
                    {showFilterBar && (
                        <Styled.LHFilterBar>
                            <FilterBar />
                        </Styled.LHFilterBar>
                    )}
                    <Styled.ScrollableContent ref={layoutRef} flexGrow>
                        {isLoading ? <DataLoading /> : <FilteredContents />}
                    </Styled.ScrollableContent>
                </Styled.WideContentArea>
                {showOTSourceModalDisplay && <OTSourceModalDisplay />}
            </FixedPageTemplate>
        )
    }
}
