import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {collapseUserDetailsEvent} from '../../../../../core/google-tag-manager/user-management/collapse-user-details-event'
import {expandUserDetailsEvent} from '../../../../../core/google-tag-manager/user-management/expand-user-details-event'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {Button} from './button.styled'
import {ButtonText} from './button-text.styled'
import {useVesselManagement} from '../../../contexts/use-vessel-management'
import {useDispatch} from 'react-redux'
import {logVesselSettings} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../../store/state/audit-log/state'

interface ToggleDetailsButtonProps {
    id: GuidType
}

export function ToggleDetailsButton({id}: ToggleDetailsButtonProps): JSX.Element {
    const {showVesselDetailModal, closeVesselDetailModal, activeVesselId} = useVesselManagement()
    const isExpanded = id === activeVesselId
    const dispatch = useDispatch()

    function onClickWrapper() {
        if (isExpanded) {
            closeVesselDetailModal()
            collapseUserDetailsEvent('card')
        } else {
            expandUserDetailsEvent('card')
            showVesselDetailModal(id)
            dispatch(
                logVesselSettings(
                    AuditLogPageType.VESSEL_MANAGEMENT,
                    AuditLogAuditType.VESSEL_SETTINGS_ACCESS,
                    id,
                ),
            )
        }
    }

    return (
        <Button onClick={onClickWrapper} id={`view-details-button_${id}`}>
            <Icon glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            <ButtonText>See details here</ButtonText>
        </Button>
    )
}
