import {useDispatch} from 'react-redux'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {clickViewAllNetworkAssetStateEvent} from '../../../../../core/google-tag-manager/unknown-assets-beta/click-view-all-network-asset-state-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {toggleAllNetworkAssetState} from '../../../../../store/state/network-assets-filter/action-creators'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {getAssetStatusForNoAc} from '../../../context/helpers'
import {NetworkAssetState} from '../../../context/types/isolated-assets-type'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {allNetworkAssetStatus, allNetworkAssetStatusForAc} from '../../helpers/data-helpers'
import {AppliedFilterIndicator} from '../shared/applied-filter-indicator/applied-filter-indicator'
import {ExpandDetailsButton} from '../shared/expand-details-button/expand-details-button'
import * as Styles from './network-asset-state-filter.styled'
import {NetworkAssetStateRow} from './network-asset-state-row'
import {ViewAllNetworkAssetStateRow} from './view-all-network-asset-state-row'
import {HeadingRowType} from '../../../../../store/state/network-assets-filter/state'

function determineViewAllState(
    allAssetStatus: NetworkAssetState[],
    selected: NetworkAssetState[] | undefined,
): TriStateButtonState {
    if (!selected) {
        return TriStateButtonState.FULLY_SELECTED
    }
    if (selected.length === 0) {
        return TriStateButtonState.NOT_SELECTED
    }

    const numberOfNetworkAssetStateType = allAssetStatus.length

    return numberOfNetworkAssetStateType === selected.length
        ? TriStateButtonState.FULLY_SELECTED
        : TriStateButtonState.PARTIAL_SELECTION
}

export function NetworkAssetStateFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {filterExpanded, states} = useTypedSelector(networkAssetsFilterSelector)
    const {acEnabled} = usePagedUnknownAssetsBeta()

    const selectedNetworkAssetStateForNoAc = getAssetStatusForNoAc(acEnabled, states)

    const numOfAppliedFilter = acEnabled
        ? !states
            ? undefined
            : states.length
        : !selectedNetworkAssetStateForNoAc || selectedNetworkAssetStateForNoAc?.length === 3
          ? undefined
          : selectedNetworkAssetStateForNoAc.length

    const viewAll = acEnabled
        ? determineViewAllState(allNetworkAssetStatus, states)
        : determineViewAllState(allNetworkAssetStatusForAc, getAssetStatusForNoAc(false, states))

    function setViewAllState(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleAllNetworkAssetState(true))
        } else if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleAllNetworkAssetState(false))
        }
        clickViewAllNetworkAssetStateEvent(viewAll, newState)
    }

    const showFilteringIndicator =
        filterExpanded && !filterExpanded.assetExpanded && numOfAppliedFilter != undefined

    const showClearButton = numOfAppliedFilter != 0

    return (
        <Styles.AssetStatus>
            <ExpandDetailsButton headingRow={HeadingRowType.FilterBarAssetState} />
            {showFilteringIndicator && (
                <AppliedFilterIndicator numOfAppliedFilter={numOfAppliedFilter} />
            )}
            {filterExpanded && filterExpanded.assetExpanded && (
                <>
                    <ViewAllNetworkAssetStateRow
                        state={viewAll}
                        setState={setViewAllState}
                        showClearButton={showClearButton}
                    />
                    {acEnabled ? (
                        <>
                            <NetworkAssetStateRow statusLabel={NetworkAssetState.MONITORED} />
                            <NetworkAssetStateRow statusLabel={NetworkAssetState.TRUSTED} />
                            <NetworkAssetStateRow statusLabel={NetworkAssetState.BLOCKED} />
                            <NetworkAssetStateRow statusLabel={NetworkAssetState.UNKNOWN} />
                        </>
                    ) : (
                        <>
                            <NetworkAssetStateRow statusLabel={NetworkAssetState.TRUSTED} />
                            <NetworkAssetStateRow statusLabel={NetworkAssetState.MONITORED} />
                            <NetworkAssetStateRow statusLabel={NetworkAssetState.UNKNOWN} />
                        </>
                    )}
                </>
            )}
        </Styles.AssetStatus>
    )
}
