import {StatusAnnotation, TableDataCell} from './_styled/table-data-cell.styled'
import {TableDataRow} from './_styled/table-data-row.styled'
import {TableDataRowTop} from './_styled/table-data-row-top.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {BlankRow} from './_styled/blank-row.styled'
import {RaisedEvent} from './event/raised-event'
import {SeverityLevel} from '../shared/severity-level/severity-level'
import {IncidentType} from './incident-type/incident-type'
import {INCIDENT_DETAILS, addAnnotation, displayStatus} from '../../data-helpers'
import {IncidentResponse} from '../../contexts/types/incident-response'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {rolesSelector} from '../../../../store/state/roles/selectors'
import {usersSelector} from '../../../../store/state/users/selectors'
import {IncidentOutputModel} from '../../models/incident.model'
import {IncidentStatus} from '../../contexts/types/incident-status'
import {AssignedTo} from './assigned-to/assigned-to-row'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {WatchListButton} from '../shared/row-actions/watchlist-button/watchlist-button'
import {VesselDataCell} from './vessel-data-cell'
import {vesselTagsForLocationsSelector} from '../../../../store/state/vessel-tags/selectors'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {AuditLogAuditType, AuditLogPageType} from '../../../../store/state/audit-log/state'
import {addQueryParam, hasQueryParam} from '../../../../helpers/navigation'
import {isNoteCreationSelector} from '../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {displayModal} from '../../../../store/state/unsaved-incident-note-alert-modal/action-creators'
import {expandIncidentEvent} from '../../../../core/google-tag-manager/incidents/expand-incident-event'
import {UpdatedEvent} from './event/updated-event'

interface TableRowProps {
    incident: IncidentResponse
    index: number
    showDetailsBtn: boolean
}

function TableRow({incident, index, showDetailsBtn}: TableRowProps): JSX.Element | null {
    const gridRow = index * 3 + 1
    const locationMap = useTypedSelector(locationMapSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const users = useTypedSelector(usersSelector)
    const vesselTags = useTypedSelector(vesselTagsForLocationsSelector)
    const model = new IncidentOutputModel(incident, locationMap, userRoles, users, vesselTags)

    const {
        incidentModalId,
        updateLastViewed,
        showFilterBar,
        displayFilterBar,
        displayIncidentDetailsModal,
    } = usePagedIncidents()
    const activeIncident = model.id === incidentModalId
    const dispatch = useDispatch()
    const {user} = useTypedSelector(currentUserSelector)
    const noteCreationStatus = useTypedSelector(isNoteCreationSelector)

    function onClickWrapper(e?: React.MouseEvent<HTMLDivElement>) {
        e?.stopPropagation()
        dispatch(
            logDetailsOpen(
                AuditLogPageType.INCIDENTS,
                AuditLogAuditType.DETAIL_PANEL_ACCESS,
                model.id,
            ),
        )
        const timeOutId = setTimeout(() => {
            const shouldUpdate = hasQueryParam(INCIDENT_DETAILS, model.id)
            if (shouldUpdate) {
                updateLastViewed(model.id, user)
            }
        }, 5000)
        if (noteCreationStatus) {
            dispatch(displayModal())
            return () => clearTimeout(timeOutId)
        } else if (showFilterBar) {
            displayFilterBar(false)
            expandIncidentEvent(status, 'table')
            addQueryParam(INCIDENT_DETAILS, model.id)
            displayIncidentDetailsModal(model.id)
            return () => clearTimeout(timeOutId)
        } else {
            expandIncidentEvent(status, 'table')
            addQueryParam(INCIDENT_DETAILS, model.id)
            displayIncidentDetailsModal(model.id)
            return () => clearTimeout(timeOutId)
        }
    }

    return (
        <>
            <TableDataRow
                gridRow={gridRow}
                span={2}
                id={`data-row_${model.id}`}
                onClick={onClickWrapper}
            />
            <TableDataRowTop
                gridRow={gridRow}
                incidentStatus={model.status}
                activeIncident={activeIncident}
                onClick={onClickWrapper}
                id={`data-row-top_${model.id}`}
            />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`status-value_${model.id}`}
                onClick={onClickWrapper}
            >
                {displayStatus(model.status)}
                <StatusAnnotation>{addAnnotation(model.status)}</StatusAnnotation>
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`incident-number-value_${model.id}`}
                onClick={onClickWrapper}
            >
                {model.number}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`title-value_${model.id}`}
                onClick={onClickWrapper}
            >
                {model.title}
            </TableDataCell>
            <SeverityLevel
                gridRow={gridRow}
                incidentStatus={IncidentStatus.NEW}
                severityLevel={model.severity}
                id={`severity-value_${model.id}`}
                onClickFn={onClickWrapper}
            />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                id={`incident-type-value_${model.id}`}
                onClick={onClickWrapper}
            >
                <IncidentType incidentType={model.type} />
            </TableDataCell>
            <VesselDataCell index={index} model={model} onClickFn={onClickWrapper} />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={8}
                id={`raised-value_${model.id}`}
                onClick={onClickWrapper}
            >
                <RaisedEvent data={model.raised} />
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={9}
                id={`assigned-to-value_${model.id}`}
                onClick={onClickWrapper}
            >
                <AssignedTo
                    assignedToName={model.assignedToVesselEmail ? 'Vessel' : model.assignedToName}
                />
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={10}
                id={`updated-value_${model.id}`}
                onClick={onClickWrapper}
            >
                <UpdatedEvent data={model.updated} numberOfNewItems={model.numberOfNewItems} />
            </TableDataCell>
            {showDetailsBtn && (
                <TableDataCell
                    gridRow={gridRow}
                    gridColumn={11}
                    id={`incident-actions_${model.id}`}
                    onClick={onClickWrapper}
                >
                    <WatchListButton incidentId={model.id} />
                </TableDataCell>
            )}
            <BlankRow gridRow={gridRow + 2} />
        </>
    )
}

export default TableRow
