import {DisplayFormatType} from '../../../../../core/google-tag-manager/helpers/display-format-type'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {AssetOutputModel} from '../../../models/asset.model'
import {IncidentAsset} from './incident-asset'
import {AssetsListWrapper, NoAssetsText} from './_styled/assets-list.styled'
import _ from 'lodash'

interface Props {
    incidentStatus: IncidentStatus
    assets: AssetOutputModel[]
    displayFormat: DisplayFormatType
}

export function IncidentAssetsList({incidentStatus, assets, displayFormat}: Props): JSX.Element {
    const filteredArray = _.remove(assets, (asset) => !!asset.assetId)
    if (filteredArray?.length === 0) {
        return <NoAssetsText>No assets</NoAssetsText>
    }
    return (
        <AssetsListWrapper id="assets-list-wrapper">
            {filteredArray?.map((asset, index) => (
                <IncidentAsset
                    incidentStatus={incidentStatus}
                    asset={asset}
                    displayFormat={displayFormat}
                    key={`${asset.incidentId}-${index}`}
                />
            ))}
        </AssetsListWrapper>
    )
}
