import {ThreatMeasure} from '../../../values/ThreatMeasure'

export type ScoreColorProps = 'Red' | 'Amber' | 'Blue' | 'N/A'

export function getScoreColor(
    score: number | 'No data',
    threatMeasure: ThreatMeasure | undefined,
): ScoreColorProps {
    if (score === 0 || score === 'No data') {
        return 'N/A'
    } else {
        if (threatMeasure && score >= threatMeasure.upperThreshold) {
            return 'Red'
        } else if (threatMeasure && score >= threatMeasure.lowerThreshold) {
            return 'Amber'
        }
        return 'Blue'
    }
}
