import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentSeverityValues} from '../../../../../values/incident-response-values'
import {IncidentResponsePolicy} from '../type/incident-response-policy'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function requestIncidentPolicyData(): Actions.RequestPolicyDataAction {
    return {type: ActionType.REQUEST_POLICY_DATA_ACTION}
}

export function receivedRequestedIncidentPolicyData(
    incidentResponsePolicyIdentity: GuidType,
    allIncidentTypes: string[],
    receivedPolicy: IncidentResponsePolicy,
): Actions.ReceivedRequestedPolicyDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_POLICY_DATA_ACTION,
        payload: {incidentResponsePolicyIdentity, allIncidentTypes, receivedPolicy},
    }
}

export function setChangePolicyName(value: string): Actions.SetChangePolicyNameAction {
    return {type: ActionType.SET_CHANGE_POLICY_NAME, payload: value}
}

export function setChangeCcVesselEmailsTo(
    value: string[],
): Actions.SetChangeCcVesselEmailsToAction {
    return {type: ActionType.SET_CHANGE_CC_VESSEL_EMAILS_TO, payload: value}
}

export function setChangeReassignUser(value: GuidType | null): Actions.SetChangeReassignUserAction {
    return {type: ActionType.SET_CHANGE_REASSIGN_USER, payload: value}
}

export function setChangeAutoAssignUser(
    value: GuidType | null,
): Actions.SetChangeAutoAssignUserAction {
    return {type: ActionType.SET_CHANGE_AUTO_ASSIGN_USER, payload: value}
}

export function enableAutoAssign(value: boolean): Actions.EnableAutoAssignAction {
    return {type: ActionType.ENABLE_AUTO_ASSIGN, payload: value}
}

export function selectSeverity(
    incidentType: string,
    severity: IncidentSeverityValues,
    value: boolean,
): Actions.SelectSeverityAction {
    return {type: ActionType.SELECT_SEVERITY, payload: {incidentType, severity, value}}
}

export function enableIncidentReminder(value: boolean): Actions.EnableIncidentReminderAction {
    return {type: ActionType.ENABLE_INCIDENT_REMINDER, payload: value}
}

export function setChangeReminderDuration(
    value: number | null,
): Actions.SetChangeReminderDurationAction {
    return {type: ActionType.SET_CHANGE_REMINDER_DURATION, payload: value}
}

export function setChangeEscalateToUser(
    value: GuidType | null,
): Actions.SetChangeEscalateToUserAction {
    return {type: ActionType.SET_CHANGE_ESCALATE_TO_USER, payload: value}
}
export function setDefaultPolicyData(
    allIncidentTypes: string[],
): Actions.SetDefaultPolicyValuesAction {
    return {type: ActionType.SET_DEFAULT_POLICY_VALUES, payload: allIncidentTypes}
}
