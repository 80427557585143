import ActionType from './action-type'
import * as Actions from './actions'
import {Api} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {REST} from '../../..'
import {EmailRecipientData} from './types/email-recipient-data'
import {GuidType} from '../../../values/generic-type-defintions'

const EMAIL_RECIPIENT_REST_URL = '/api/v1/emailRecipients'

export function fetchEmailRecipients(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestEmailRecipientsAction())
        REST.get(EMAIL_RECIPIENT_REST_URL).then((response) => {
            dispatch(receiveEmailRecipientsAction(response.data))
        })
    }
}

function requestEmailRecipientsAction(): Actions.RequestEmailRecipientsAction {
    return {
        type: ActionType.REQUEST_EMAIL_RECIPIENTS,
    }
}

function receiveEmailRecipientsAction(
    emailRecipientData: EmailRecipientData[],
): Actions.ReceiveEmailRecipientsAction {
    return {
        type: ActionType.RECEIVE_EMAIL_RECIPIENTS,
        payload: emailRecipientData,
    }
}
export const addNewEmailRecipient = (
    newEmailRecipient: string | undefined,
    newExternalUser: string | undefined,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        REST.post(EMAIL_RECIPIENT_REST_URL, {
            notificationType: 'NEW_INCIDENT',
            email: newEmailRecipient ? newEmailRecipient : undefined,
            user: newExternalUser ? newExternalUser : undefined,
        }).then(() => {
            dispatch(fetchEmailRecipients())
        })
    }
}

export const deleteEmailRecipient = (
    emailIdToDelete: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        REST.delete(`${EMAIL_RECIPIENT_REST_URL}/${emailIdToDelete}`).then(() => {
            dispatch(fetchEmailRecipients())
        })
    }
}
