import {useDispatch} from 'react-redux'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import * as Styles from './usb-device-status-filter.styled'
import {UsbDeviceStatusRow} from './usb-device-status-row'
import {ViewAllUsbDeviceStatusRow} from './view-all-usb-device-status-row'
import {DeviceStatus, FilterBarType} from '../../../../../store/state/usb-inventory-filter/state'
import {usbInventoryFilterSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {allUsbDeviceStatus} from '../../data-helpers'
import {ToggleAllUsbDeviceStatus} from '../../../../../store/state/usb-inventory-filter/action-creators'
import {ExpandFilterDetailsButton} from '../shared/expand-filter-details-button/expand-filter-details-button'
import {AppliedFilterIndicator} from '../../../../../components/page-filter/components/shared/applied-filter-indicator/applied-filter-indicator'

function determineViewAllState(
    allUsbDeviceStatus: DeviceStatus[],
    selected: DeviceStatus[] | undefined,
): TriStateButtonState {
    if (!selected) {
        return TriStateButtonState.FULLY_SELECTED
    }
    if (selected.length === 0) {
        return TriStateButtonState.NOT_SELECTED
    }

    const numberOfUsbDeviceStatusType = allUsbDeviceStatus.length

    return numberOfUsbDeviceStatusType === selected.length
        ? TriStateButtonState.FULLY_SELECTED
        : TriStateButtonState.PARTIAL_SELECTION
}

export function UsbDeviceStatusFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {selectedUsbDeviceStatus, filterExpanded} = useTypedSelector(usbInventoryFilterSelector)

    const numOfAppliedFilter = !selectedUsbDeviceStatus ? undefined : selectedUsbDeviceStatus.length

    const viewAll = determineViewAllState(allUsbDeviceStatus, selectedUsbDeviceStatus)

    function setViewAllState(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(ToggleAllUsbDeviceStatus(true))
        } else if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(ToggleAllUsbDeviceStatus(false))
        }
    }

    const showFilteringIndicator =
        filterExpanded && !filterExpanded.deviceStatus && numOfAppliedFilter != undefined

    const showClearButton = numOfAppliedFilter != 0

    return (
        <Styles.AssetStatus id="UsbDeviceStatusFilter">
            <ExpandFilterDetailsButton headingRow={FilterBarType.UsbDeviceStatus} />
            {showFilteringIndicator && (
                <AppliedFilterIndicator numOfAppliedFilter={numOfAppliedFilter} />
            )}
            {filterExpanded && filterExpanded.deviceStatus && (
                <>
                    <ViewAllUsbDeviceStatusRow
                        state={viewAll}
                        setState={setViewAllState}
                        showClearButton={showClearButton}
                    />
                    <>
                        <UsbDeviceStatusRow statusLabel={DeviceStatus.trusted} />
                        <UsbDeviceStatusRow statusLabel={DeviceStatus.unknown} />
                        <UsbDeviceStatusRow statusLabel={DeviceStatus.rejected} />
                    </>
                </>
            )}
        </Styles.AssetStatus>
    )
}
