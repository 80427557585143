import {LocationIdType} from '../../../../../store/state/locations/state'
import {GuidType} from '../../../../../values/generic-type-defintions'
import LoadingState from '../../../../../values/loading-state-enum'

export interface PolicyManagementState {
    loadingDataState: LoadingState
    allPolicyData: AllPolicyData[]
    selectedPolicyId: GuidType | null
    selectedPolicyType: PolicyType | null
    showPolicyModal: boolean
    dataToBeFetched: boolean
    showAssignPolicyDialog: boolean
    assignPolicyDialogState: AssignPolicyDialogState
    locationsAssignedToIncidentPolicies: LocationIdType[]
    locationsAssignedToMetricsPolicies: LocationIdType[]
    locationsAssignedToEnforcementPolicies: LocationIdType[]
}
export interface AssignPolicyDialogState {
    selectedPolicyId: GuidType | undefined
    newLocations: Set<LocationIdType> | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
    showXButton: boolean
}

export interface AllPolicyData {
    identity: GuidType
    policyType: PolicyType
    policyName: string | PolicyNameType
    lastUpdate: string
    locations?: GuidType[]
    // defaultPolicy: boolean
    // FIXME: where can I get this number..?
    // appliedVessels: number
}

export enum PolicyType {
    INCIDENT = 'Incident',
    METRICS = 'Metrics',
    ENFORCEMENT = 'Enforcement',
    AUTOMATION = 'Automation',
}

export enum PolicyNameType {
    DEFAULT = 'Default',
}
