import {useState, useEffect, useRef} from 'react'
import * as Style from './dropdown.styled'
import Chevron from '../../../@assets/icons/chevron.svg'

export type DropdownOption = {
    label: string
    value: string
}

type DropdownProps = {
    options: DropdownOption[]
    selectedOption: DropdownOption
    onChage: (option: DropdownOption) => void
    placeholder: string
    required?: boolean
}

export function Dropdown(props: DropdownProps): JSX.Element {
    const [showOptions, setShowOptions] = useState(false)
    const [searchFilter, setSearchFilter] = useState('')

    const boxElem = useRef<HTMLDivElement>(null)
    const selectedElem = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        const close = (event: MouseEvent) => {
            if (!boxElem.current) {
                return
            }

            if (!boxElem.current.contains(event.target as Node)) {
                setShowOptions(false)
            }
        }

        window.addEventListener('click', close, true)

        return () => {
            window.removeEventListener('click', close)
        }
    }, [])

    useEffect(() => {
        if (!selectedElem.current || showOptions) {
            return
        }

        if (props.selectedOption.label === '') {
            return
        }

        selectedElem.current.focus()
    }, [props.selectedOption.label, showOptions])

    // Escape regex special characters and create a regex for search filter
    const filterRegex = new RegExp(searchFilter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i')

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(e.target.value)
    }

    const handleOptionClick = (event: React.MouseEvent) => {
        event.preventDefault()
        setShowOptions(!showOptions)
    }

    const options = props.required ? props.options : [{label: 'None', value: ''}, ...props.options]
    let renderOptions = options
        .filter((option) => filterRegex.test(option.label))
        .map((option) => (
            <Style.Option
                key={option.value}
                onClick={(event) => {
                    event.preventDefault()
                    setShowOptions(false)
                    props.onChage(option)
                }}
                href="#"
            >
                {option.label}
            </Style.Option>
        ))

    if (renderOptions.length === 0) {
        renderOptions = [<Style.Option key="no-option">No options</Style.Option>]
    }

    const selectedOption = props.selectedOption.label ? (
        <Style.Label>{props.selectedOption.label}</Style.Label>
    ) : (
        <Style.Placeholder>{props.placeholder}</Style.Placeholder>
    )

    return (
        <Style.Box ref={boxElem}>
            <Style.Panel ref={selectedElem} onClick={handleOptionClick} href="#">
                {selectedOption}
                <img src={Chevron} alt="arrow down" />
            </Style.Panel>
            {showOptions && (
                <Style.DropdownContainer>
                    <Style.SearchInput
                        placeholder="Search"
                        value={searchFilter}
                        onChange={handleSearchChange}
                    />
                    <Style.OptionContainer>{renderOptions}</Style.OptionContainer>
                </Style.DropdownContainer>
            )}
        </Style.Box>
    )
}
