import produce from 'immer'
import type {Page} from '../../location-inventory-page-context'
import type {Action} from './actions'
import ActionType from './action-type'

export const PageReducer = produce((draft: Page['state'], action: Action) => {
    switch (action.type) {
        case ActionType.DISABLE_ADD_BUTTON:
            draft.isDisabledAddButton = action.payload.disabled
            break

        case ActionType.TOGGLE_SHOW_ASSET_CREATION_FORM:
            draft.isOpenAssetCreationForm = action.payload.showForm
            break

        default:
            break
    }
})
