import {NodeValue} from '../../../values/nodes/NodeData'
import {addEvent} from '../add-event'
import {DisplayFormatType} from '../helpers/display-format-type'

export function viewAssetPopupEvent(
    status: string,
    displayFormat: DisplayFormatType,
    value: NodeValue,
    score: number | undefined,
): boolean {
    return addEvent('viewIncidentAssetPopup', {
        incidentStatus: status,
        displayFormat,
        nodeValue: value,
        nodeScore: score,
    })
}
