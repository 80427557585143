import moment, {Moment} from 'moment'
import {getUserDescription} from '../../../helpers/getUserDescription'
import {LocationIdType, LocationMap} from '../../../store/state/locations/state'
import {User} from '../../../store/state/users/state'
import {spacing} from '../../../theme/spacing'
import {CustomerReportsNotes} from '../../../values/customer-reports'
import {ReportsFilter} from '../../../values/user-preferences/reports-filter'
import {ReportsFilterReduxState} from '../../../store/state/reports-filter/state'
import {isEqual} from 'lodash'

export function showCards(width: number): boolean {
    return width < 1500
}
export function showFilterBarOnScreen(width: number): boolean {
    return width > 720
}

export function narrowFilterLayout(width: number): boolean {
    return width > 400
}

export function getGridTemplateColumns(width: number): string {
    if (width > 1590) {
        return `${spacing(1)} 250px 120px 95px 120px 125px 120px 120px auto ${spacing(1)}`
    }
    if (width > 1530) {
        return `${spacing(1)} 230px 100px 95px 110px 120px 120px 120px auto ${spacing(1)}`
    }
    return `${spacing(1)} 210px 110px 95px 110px 110px 110px 120px auto ${spacing(1)}`
}

export function getRatingBoundarybuttons(): number[] {
    return [10, 33, 66, 90, 101]
}
export function showHamburgerMenu(width: number): boolean {
    return width < 1250
}
export function getHorizontalPagePadding(width: number): string {
    if (width > 1660) {
        return '0'
    }
    if (width > 1655) {
        return spacing(1)
    }
    if (width > 1650) {
        return spacing(2)
    }
    if (width > 1645) {
        return spacing(3)
    }
    if (width < 1025) {
        return spacing(3)
    }
    return spacing(4)
}
export const BREAK_POINT_CARD_REDUCED_TEXT = 1160

export function getNotesFormatted(
    notes: CustomerReportsNotes[] | undefined,
    users: User[],
    isInternalUser: boolean,
): CustomerReportsNotes[] {
    if (!notes) {
        return []
    }

    return notes
        .map((item: CustomerReportsNotes) => {
            return {
                ...item,
                createdBy: getUserDescription(users, item.createdBy, undefined, isInternalUser),
            }
        })
        .sort((a: CustomerReportsNotes, b: CustomerReportsNotes) => {
            return a.createdWhen.localeCompare(b.createdWhen)
        })
}

export interface FilterTimeRangeSelectOptionProps {
    label: string
    value: string
}

export function getReportMonths(
    startDate: Moment,
    endDate: Moment,
    endOfMonth?: boolean,
): FilterTimeRangeSelectOptionProps[] {
    const reportMonths = []
    const fromDate = endOfMonth
        ? moment(startDate).clone().utc().endOf('month').endOf('day')
        : moment(startDate).clone().utc().startOf('month').startOf('day')
    const toDate = endOfMonth
        ? moment(endDate).clone().utc().endOf('month').endOf('day')
        : moment(endDate).clone().utc().startOf('month').startOf('day')

    while (toDate > fromDate || fromDate.format('YYYY MMMM') === toDate.format('YYYY MMMM')) {
        reportMonths.push({
            label: fromDate.format('YYYY MMMM'),
            value: fromDate.utc().format(),
        })
        fromDate.add(1, 'month')
    }

    return reportMonths
}
export function getFormattedSelectedVessels(
    selectedVessels: Set<LocationIdType> | undefined,
): LocationIdType[] {
    if (!selectedVessels) {
        return []
    }
    return Array.from(selectedVessels)
}
const FLEET_VALUE = 'Whole fleet'
export const getLocationDescription = (
    locations: LocationMap,
    location: LocationIdType,
): string | typeof FLEET_VALUE => {
    if (!location) {
        return FLEET_VALUE
    }
    return locations?.get(location)?.description || FLEET_VALUE
}

export class FormattedNewFilterModel {
    public readonly selectedReportType: string | undefined
    public readonly selectedRating: number
    public readonly selectedFromTimeRange: string | undefined
    public readonly selectedToTimeRange: string | undefined
    public readonly includeWholeFleetReports: boolean

    public constructor(newFilter: ReportsFilterReduxState | ReportsFilter) {
        this.selectedReportType =
            newFilter.selectedReportType === '' ? undefined : newFilter.selectedReportType
        this.selectedRating = newFilter.selectedRating
        this.selectedFromTimeRange =
            newFilter.selectedFromTimeRange === '' ? undefined : newFilter.selectedFromTimeRange
        this.selectedToTimeRange =
            newFilter.selectedToTimeRange === '' ? undefined : newFilter.selectedToTimeRange
        this.includeWholeFleetReports = newFilter.includeWholeFleetReports
    }
}

export function compareFilters(
    currentFilter: ReportsFilter,
    newFilter: ReportsFilterReduxState,
): boolean {
    const formattedNewFilter = new FormattedNewFilterModel(newFilter)
    const formattedCurrentFilter = new FormattedNewFilterModel(currentFilter)
    return !isEqual(formattedNewFilter, formattedCurrentFilter)
}
