import {createSelector} from 'reselect'
import {isFetchingRolesSelector, rolesSelector} from '../../../store/state/roles/selectors'

export const extendedDataIsLoadedReselector = createSelector(
    isFetchingRolesSelector,
    rolesSelector,
    (rolesLoading): boolean => {
        if (rolesLoading) {
            return false
        }

        // We no longer preload this data
        /*if (checkIfIsInternalUser(userRoles)) {
            if (engineStatusesLoading || smeEventsLoading || smeRulesLoading) {
                return false
            }
        }*/

        return true
    },
)
