import {Line} from 'recharts'
import {CyberOwlTheme} from '../../../../theme/theme'
import {MetricTrendsArrayForUIDisplay} from '../../contexts/types/metrics-summary'

// use like a custom component, but must be a function as recharts will render data returned by a function, not a custom component
export function dataLine(theme: CyberOwlTheme, data: MetricTrendsArrayForUIDisplay[]): JSX.Element {
    return (
        <Line
            isAnimationActive={false}
            type="monotone"
            strokeWidth={2}
            connectNulls={true}
            data={data}
            dataKey="totalAssetsOkScore"
            name="Trends"
            stroke={theme.colors.graph.primary}
            id="data-line"
        />
    )
}
