import {ChangeEvent, MouseEvent, useState} from 'react'
import {useDispatch} from 'react-redux'
import {DisplayFormatType} from '../../../../../core/google-tag-manager/helpers/display-format-type'
import {updateIncidentNoteEvent} from '../../../../../core/google-tag-manager/incidents/update-incident-note-event'
import {
    setSaveEditNoteAction,
    setProcessingEditNoteAction,
} from '../../../../../store/state/unsaved-incident-note-alert-modal/action-creators'

import {GuidType} from '../../../../../values/generic-type-defintions'
import {CommentEntry} from '../add-note/comment-entry'
import * as Styles from './edit-message.styled'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {noteToEditSelector} from '../../../../../store/state/unsaved-incident-note-alert-modal/selectors'

interface Props {
    comment: string
    noteIdentity: GuidType
    incidentidentity: GuidType
    setNoteToDisplay: (value: string) => void
    displayFormat: DisplayFormatType
}
export function EditableComment({
    comment,
    noteIdentity,
    incidentidentity,
    setNoteToDisplay,
    displayFormat,
}: Props): JSX.Element {
    const {updateNoteOfIncident, setSaveMessage, toggleNoteEdit} = usePagedIncidents()
    const noteToEditMap = useTypedSelector(noteToEditSelector)
    const noteToEdit = noteToEditMap.get(noteIdentity)
    const noteToDisplay = noteToEdit != undefined && noteToEdit.length !== 0 ? noteToEdit : comment
    const [newNote, setNewNote] = useState<string>(noteToDisplay)

    const isSaveDisabled = newNote === comment
    const dispatch = useDispatch()
    function noteChangeHandler(e: ChangeEvent<HTMLTextAreaElement>) {
        setNewNote(e.target.value)
        dispatch(setProcessingEditNoteAction(noteIdentity, e.target.value))
    }

    function onSaveHandler(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        setNoteToDisplay(newNote)
        updateNoteOfIncident(incidentidentity, noteIdentity, newNote, dispatch, dispatch)
        updateIncidentNoteEvent(noteIdentity, displayFormat)
    }
    function onCancelHandler() {
        dispatch(setSaveEditNoteAction(noteIdentity))
        toggleNoteEdit(noteIdentity)
        setNewNote(comment)
        setSaveMessage(undefined)
    }
    return (
        <Styles.AddNoteContainer id={`incident-note-EditableComment-${noteIdentity}`}>
            <Styles.AddNoteBorder isSaveDisabled={isSaveDisabled}>
                <CommentEntry
                    id={`note-entry_${noteIdentity}`}
                    value={newNote}
                    onChange={noteChangeHandler}
                />
            </Styles.AddNoteBorder>
            <Styles.ButtonWrapper>
                <Styles.CancelButton id={`close-note_${noteIdentity}`} onClick={onCancelHandler}>
                    Cancel
                </Styles.CancelButton>
                <Styles.SaveButton
                    disabled={isSaveDisabled}
                    id={`save-note_${noteIdentity}`}
                    value="Add"
                    onClick={(e) => onSaveHandler(e)}
                >
                    Save changes
                </Styles.SaveButton>
            </Styles.ButtonWrapper>
        </Styles.AddNoteContainer>
    )
}
