import {useDispatch} from 'react-redux'
import {resetFilterEvent} from '../../../../../core/google-tag-manager/unknown-assets-beta/reset-filter-event'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {resetFilter} from '../../../../../store/state/network-assets-filter/action-creators'
import {resetVesselFilter} from '../../../../../store/state/vessel-filter/action-creators'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {NETWORK_ASSET_DETAILS} from '../../helpers/data-helpers'
import * as Styles from './reset-filter-button.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function ResetFilterButton(): JSX.Element {
    const dispatch = useDispatch()
    const {closeNetworkAssetDetailsModal} = usePagedUnknownAssetsBeta()
    const isInactive = useTypedSelector(isInactiveSelector)

    return (
        <Styles.ButtonWrapper isInactive={isInactive}>
            <Styles.Button
                id={`${PageType.NETWORK_ASSETS}_filter-bar_reset-button`}
                onClick={(e) => {
                    e.preventDefault()
                    resetFilterEvent()
                    dispatch(resetVesselFilter())
                    dispatch(resetFilter())
                    removeQueryParam(NETWORK_ASSET_DETAILS)
                    closeNetworkAssetDetailsModal()
                }}
            >
                Reset
            </Styles.Button>
        </Styles.ButtonWrapper>
    )
}
