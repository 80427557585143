import * as Styles from './metrics-details-expanded.styled'
import CLOSE_ICON from '../../../../../../@assets/icons/close-panel.svg'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {MetricsDetailsSection} from '../../metrics-details-modal/metrics-details/metrics-details'
import {ActivitySection} from '../../metrics-details-modal/activity/activity-section'
import {TrendsSection} from '../../metrics-details-modal/trends-section/trends-section'

export function MetricsDetailsExpanded(): JSX.Element {
    const {closeMetricsDetailsModal, findMetricDetailsForModal} = usePagedMetricsBeta()

    return (
        <Styles.DetailsSection id={`metrics-details-card-${findMetricDetailsForModal?.metricName}`}>
            <Styles.DetailsContent
                id={`details-content-1-${findMetricDetailsForModal?.metricName}`}
            >
                <MetricsDetailsSection />
            </Styles.DetailsContent>
            <Styles.DetailsContent
                id={`details-content-2-${findMetricDetailsForModal?.metricName}`}
            >
                <ActivitySection />
            </Styles.DetailsContent>
            <Styles.DetailsContent
                id={`details-content-3-${findMetricDetailsForModal?.metricName}`}
            >
                <TrendsSection />
            </Styles.DetailsContent>
            <Styles.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closeMetricsDetailsModal()
                }}
                id="close-details-icon"
            />
        </Styles.DetailsSection>
    )
}
