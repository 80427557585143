import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {filterByCompanyNameEvent} from '../../../../../core/google-tag-manager/software-inventory/filter-by-company-name-event'
import * as Styles from './company-name-filter.styled'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import {setSearchedCompanyName} from '../../../../../store/state/software-inventory-filter/action-creators'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function CompanyNameFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {searchedCompanyName} = useTypedSelector(softwareInventoryFilterSelector)

    return (
        <Styles.Wrapper id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_vendor-filter`}>
            <Styles.Label>Company:</Styles.Label>
            <Styles.Container>
                <Styles.SearchInput
                    id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_searched-company-value`}
                    type="text"
                    placeholder=" Search company name "
                    value={searchedCompanyName ?? ''}
                    onChange={(e) => {
                        dispatch(setSearchedCompanyName(e.target.value))
                        filterByCompanyNameEvent(e.target.value)
                    }}
                />
                <Styles.SearchIcon>
                    <Icon glyph="Search" height={15} width={15} />
                </Styles.SearchIcon>
            </Styles.Container>
        </Styles.Wrapper>
    )
}
