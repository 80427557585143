import styled from 'styled-components'
import {GridLayout} from '../grid-layout.enum'

interface ContentAreaProps {
    gridLayout: GridLayout
    tagWidget?: boolean
}

function getMargin(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '10px 20px'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '10px 20px'
        default:
            return '18px 30px'
    }
}

export const ContentArea = styled.div<ContentAreaProps>`
    padding: ${(props) => (props.tagWidget ? '5px 10px' : getMargin(props.gridLayout))};
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${(props) =>
        props.tagWidget
            ? props.theme.softwareInventory.dataArea.background
            : props.theme.colors.background};
    box-shadow: 0 1px 2px #00000034;
    position: relative;
    overflow: auto;
`
