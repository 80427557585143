import {createSelector} from 'reselect'
import {
    activeLocationSelector,
    locationMapSelector,
} from '../../../../../store/state/locations/selectors'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {WidgetOutputModel} from '../widget.model'

import {latestIncidentResponseSelector} from '../../../../../store/state/incident-overview/selectors'

export const incidentResponsesWidgetReselector = createSelector(
    latestIncidentResponseSelector,
    locationMapSelector,
    activeLocationSelector,
    nodesSelector,
    (latestIncident, locationMap, activeLocation, nodes): WidgetOutputModel => {
        return new WidgetOutputModel(latestIncident, locationMap, activeLocation, nodes)
    },
)
