import {useState} from 'react'
import * as Styled from './_styled/nav-bar-content.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {DropdownMenuStyle} from './_styled/location-expanded.styled'
import {NavBarLink} from './nav-bar-link'
import {viewNetworkAssetsEvent} from '../../../../core/google-tag-manager/navigation/view-network-assets-event'
import {viewUsbDevicesEvent} from '../../../../core/google-tag-manager/navigation/view-usb-devices-event'
import {useLocation} from 'react-router-dom'
import {LocationExpandedButtonStyle} from './location-expanded-button/location-expanded-button.styled'
import {viewSoftwareEvent} from '../../../../core/google-tag-manager/navigation/view-software-event'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'

interface InventoryLinkProps {
    gtmLogger: ((trigger: string) => void) | null
}
export function InventoryLink({gtmLogger = null}: InventoryLinkProps): JSX.Element {
    const [inventoryDropDown, setInventoryDropDown] = useState(false)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const fleetConfig = useTypedSelector(fleetConfigSelector)

    function menuClicked() {
        if (gtmLogger != null) {
            gtmLogger('hamburgerMenu')
        }
        setInventoryDropDown(!inventoryDropDown)
    }
    const pathname = useLocation().pathname

    return (
        <Styled.DashboardLinkStyle onClick={menuClicked}>
            <Styled.NavigationLinkDiv
                id="navigation-inventory"
                activeLink={
                    pathname.startsWith('/network-assets') ||
                    pathname.startsWith('/usb-devices') ||
                    pathname.startsWith('/software') ||
                    pathname.startsWith('/nmea')
                }
            >
                <LocationExpandedButtonStyle id="navigation-inventory-toggle">
                    <Icon
                        glyph={inventoryDropDown ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </LocationExpandedButtonStyle>
                Inventory
            </Styled.NavigationLinkDiv>
            {inventoryDropDown && (
                <DropdownMenuStyle>
                    {(isInternalUser || fleetConfig.networkAssets) && (
                        <NavBarLink
                            id="navigation-network-assets"
                            path="/network-assets"
                            navLinkText="Network Assets"
                            gtmLogger={() => viewNetworkAssetsEvent('hamburgerMenu')}
                        />
                    )}
                    {(isInternalUser || fleetConfig.usbInventory) && (
                        <NavBarLink
                            id="navigation-usb-devices"
                            path="/usb-devices"
                            navLinkText="USB Devices"
                            gtmLogger={() => viewUsbDevicesEvent('hamburgerMenu')}
                        />
                    )}
                    {(isInternalUser || fleetConfig.softwareInventory) && (
                        <NavBarLink
                            id="navigation-software"
                            navLinkText="Software"
                            path="/software"
                            gtmLogger={() => viewSoftwareEvent('hamburgerMenu')}
                        />
                    )}
                    {(isInternalUser || fleetConfig.nmeaInventory) && (
                        <NavBarLink
                            id="navigation-nmea"
                            navLinkText="NMEA"
                            path="/nmea"
                            gtmLogger={() => viewSoftwareEvent('hamburgerMenu')}
                        />
                    )}
                </DropdownMenuStyle>
            )}
        </Styled.DashboardLinkStyle>
    )
}
