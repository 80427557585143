import {NetworkAssetState} from '../../../pages/unknown-assets-v2/context/types/isolated-assets-type'
import {addEvent} from '../add-event'
import {DisplayFormatType} from '../helpers/display-format-type'

export function expandNetworkAssetEvent(
    status: NetworkAssetState,
    displayFormat: DisplayFormatType,
): boolean {
    return addEvent('expandNetworkAsset', {networkAssetStatus: status, displayFormat})
}
