import {createSelector} from 'reselect'
import {isIncidentsOverviewLoadingSelector} from '../../../../../store/state/incident-overview/selectors'
import {locationsLoadingSelector} from '../../../../../store/state/locations/selectors'
import {nodesFetchingSelector} from '../../../../../store/state/nodes/selectors'

export const loadingReselector = createSelector(
    locationsLoadingSelector,
    isIncidentsOverviewLoadingSelector,
    nodesFetchingSelector,
    (
        locationsLoading: boolean,
        incidentsOverviewLoading: boolean,
        nodesFetching: boolean,
    ): boolean => {
        return locationsLoading || incidentsOverviewLoading || nodesFetching
    },
)
