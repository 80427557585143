import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const PageTitle = styled.h2`
    font-size: 30px;
    line-height: 40.85px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: ${spacing(4)} 50px;
    color: black;
`
