import {SortableColumn} from './sortable-column'
import {TagsComponentSortType} from '../../../contexts/types/tags-component-filter'

export function TableHeader(): JSX.Element {
    return (
        <>
            <SortableColumn
                gridColumn={2}
                text="Tag name"
                colName={TagsComponentSortType.TAG_NAME}
            />
            {/*<SortableColumn
                gridColumn={3}
                text="avg. score"
                colName={TagsComponentSortType.AVG_SCORE}
            />*/}
            <SortableColumn
                gridColumn={3}
                text="Max score"
                colName={TagsComponentSortType.MAX_SCORE}
            />
            <SortableColumn
                gridColumn={4}
                text="Incidents"
                colName={TagsComponentSortType.INCIDENTS}
            />
            <SortableColumn
                gridColumn={5}
                text="Asset discovery"
                colName={TagsComponentSortType.ASSET_DISCOVERY}
            />
        </>
    )
}
