import {useEffect, useState} from 'react'
import {batch, useDispatch} from 'react-redux'
import {api} from '../../store/configure-store'
import useTypedSelector from '../../hooks/use-typed-selector'
import {loggedIn, loggingOut} from '../../store/state/session-data/action-creators'
import {allDataIsLoadedReselector} from './reselect/all-data-is-loaded-reselector'
import {fetchInitialData} from '../../store/state/auth/action-creators'
import * as Styled from './system-reinitialising.styled'
import {LoadingSpinner} from '../../components/loading/loading'
import {fetchConfig} from '../../store/state/config/action-creators'
import {LogoGroup} from '../login-v2/components/header/LogoGroup'
// import {fetchExtendedData} from '../../store/state/auth/action-creators'
// import {isFetchingRolesSelector, rolesSelector} from '../../store/state/roles/selectors'
// import {checkIfIsInternalUser} from '../incidents-v2/reselectors/incident-responses-widget-reselector'

enum Status {
    UNVERIFIED = 'UNVERIFIED',
    VERIFIED = 'VERIFIED',
}

export function SystemReinitialising(): JSX.Element {
    const dispatch = useDispatch()
    const allDataIsLoaded = useTypedSelector(allDataIsLoadedReselector)
    const [status, setStatus] = useState(Status.UNVERIFIED)

    useEffect(() => {
        switch (status) {
            case Status.UNVERIFIED:
                try {
                    api.getAuth(
                        '/api/v1/auth/verify',
                        () => {
                            api.initWs()
                            dispatch(fetchConfig())
                            setStatus(Status.VERIFIED)
                        },
                        () => {
                            dispatch(loggingOut())
                        },
                    )
                } catch {
                    dispatch(loggingOut())
                }

                break

            case Status.VERIFIED:
                dispatch(fetchInitialData())
        }
    }, [status, dispatch])

    /* NOTE: this is how we'd call extended data */
    // const useRolesLoading = useTypedSelector(isFetchingRolesSelector)
    // const userRoles = useTypedSelector(rolesSelector)
    // useEffect(() => {
    //     if (!useRolesLoading) {
    //         if (checkIfIsInternalUser(userRoles)) {
    //             dispatch(fetchExtendedData())
    //         }
    //     }
    // }, [useRolesLoading, userRoles, dispatch])

    useEffect(() => {
        if (allDataIsLoaded) {
            batch(() => {
                dispatch(loggedIn())
            })
        }
    }, [allDataIsLoaded, dispatch])

    return (
        <Styled.Screen>
            <Styled.NavigationBar>
                <Styled.NavigationContent>
                    <LogoGroup />
                </Styled.NavigationContent>
            </Styled.NavigationBar>
            <Styled.Loading>
                <LoadingSpinner size={150} />
            </Styled.Loading>
        </Styled.Screen>
    )
}
