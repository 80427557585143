import {useDispatch} from 'react-redux'
import * as Styled from './period-filter.styled'
import {useRef, useState} from 'react'

import {ChevronDown, ChevronUp} from 'react-feather'
import {useOnClickOutside} from '../../../../../../hooks/useOnClickOutside'
import {isInactiveSelector} from '../../../../../../store/state/saved-filters/selectors'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'

import {networkAssetsFilterSelector} from '../../../../../../store/state/network-assets-filter/selectors'
import {setSearchedLastSeen} from '../../../../../../store/state/network-assets-filter/action-creators'
import {TimestampFilterType} from '../../../../../../store/state/network-assets-filter/state'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

export function getTypeDisplayName(types: TimestampFilterType | undefined): string {
    switch (types) {
        case TimestampFilterType.LAST_24_H:
            return 'Last 24 hours'
        case TimestampFilterType.LAST_7_DAYS:
            return 'Last 7 days'
        case TimestampFilterType.LAST_30_DAYS:
            return 'Last 30 days'
        case TimestampFilterType.MORE_7_DAYS:
            return 'More than 7 days ago'
        case TimestampFilterType.MORE_30_DAYS:
            return 'More than 30 days ago'
        default:
            return 'All'
    }
}

export function PeriodFilter(): JSX.Element {
    const dispatch = useDispatch()
    const [optionsVisible, setOptionsVisible] = useState(false)
    const isInactive = useTypedSelector(isInactiveSelector)
    const {fromRelativeLastSeen} = useTypedSelector(networkAssetsFilterSelector)

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })

    const allPeriodType = [
        TimestampFilterType.LAST_24_H,
        TimestampFilterType.LAST_7_DAYS,
        TimestampFilterType.LAST_30_DAYS,
        TimestampFilterType.MORE_7_DAYS,
        TimestampFilterType.MORE_30_DAYS,
    ]

    return (
        <Styled.Container ref={clickOutside}>
            <Styled.FilterSearchWrapper
                onClick={() => setOptionsVisible(!optionsVisible)}
                isInactive={isInactive}
                id={`${PageType.NETWORK_ASSETS}_filter-bar_period-filter_wrapper`}
            >
                <Styled.SearchInput
                    id={`${PageType.NETWORK_ASSETS}_filter-bar_period-filter_display-name`}
                >
                    {getTypeDisplayName(fromRelativeLastSeen)}
                </Styled.SearchInput>
                <Styled.SearchIcon>
                    {optionsVisible ? (
                        <ChevronUp height={13} width={13} />
                    ) : (
                        <ChevronDown height={13} width={13} />
                    )}
                </Styled.SearchIcon>
            </Styled.FilterSearchWrapper>
            <Styled.SavedFiltersWrapper ref={clickOutside}>
                {optionsVisible && (
                    <Styled.PeriodDropdown>
                        <Styled.ValueOption
                            onClick={() => {
                                dispatch(setSearchedLastSeen(undefined))
                                setOptionsVisible(false)
                            }}
                            id={`${PageType.NETWORK_ASSETS}_filter-bar_period-filter_all-value`}
                        >
                            All
                        </Styled.ValueOption>
                        {allPeriodType?.map((value, index) => (
                            <Styled.ValueOption
                                key={index}
                                onClick={() => {
                                    dispatch(setSearchedLastSeen(value))
                                    setOptionsVisible(false)
                                }}
                                id={`${PageType.NETWORK_ASSETS}_filter-bar_period-filter_${value}-value`}
                            >
                                {getTypeDisplayName(value)}
                            </Styled.ValueOption>
                        ))}
                    </Styled.PeriodDropdown>
                )}
            </Styled.SavedFiltersWrapper>
        </Styled.Container>
    )
}
