import {GuidType} from '../../../../../../values/generic-type-defintions'
import {watchIncidentsListSelector} from '../../../../../../store/state/watched-incidents/selectors'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import * as Styled from './watchlist-button.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import {unwatchIncidentEvent} from '../../../../../../core/google-tag-manager/incidents-beta/unwatch-incident-event'
import {watchIncidentEvent} from '../../../../../../core/google-tag-manager/incidents-beta/watch-incident-event'
import {
    addIncidentToWatchList,
    deleteIncidentFromWatchList,
} from '../../../../../../store/state/watched-incidents/action-creators'

interface WatchListButtonProps {
    incidentId: GuidType
}
export function WatchListButton({incidentId}: WatchListButtonProps): JSX.Element | null {
    const watchedIncidents = useTypedSelector(watchIncidentsListSelector)
    const isIncludedInWatchList = watchedIncidents.includes(incidentId)
    const action = isIncludedInWatchList ? 'Unwatch' : 'Watch'
    const dispatch = useDispatch()
    function watchIncident(incidentId: GuidType): void {
        if (incidentId) {
            watchIncidentEvent(incidentId)
            dispatch(addIncidentToWatchList(incidentId))
        }
    }
    function unwatchIncident(incidentId: GuidType): void {
        if (incidentId) {
            unwatchIncidentEvent(incidentId)
            dispatch(deleteIncidentFromWatchList(incidentId))
        }
    }

    const onClickWrapper = (e: React.MouseEvent<unknown>) => {
        e.stopPropagation()
        isIncludedInWatchList ? unwatchIncident(incidentId) : watchIncident(incidentId)
    }

    return (
        <Styled.Button id={`watch-incident-button-${incidentId}`} onClick={onClickWrapper}>
            <Icon glyph={action} height={16} width={16} />
            <Styled.Text id={`watch-incident-text-${incidentId}`}>{action} incident</Styled.Text>
        </Styled.Button>
    )
}
