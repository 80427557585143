import {collapseUserDetailsEvent} from '../../../../../../core/google-tag-manager/user-management/collapse-user-details-event'
import {useUserManagement} from '../../../../context/use-user-management'
import {ButtonText} from './button-text.styled'
import {BottomContentWrapper, Button} from './button.styled'

export function CloseButton(): JSX.Element {
    const {closeUserDetailModal} = useUserManagement()
    function onClickWrapper() {
        collapseUserDetailsEvent('table')
        closeUserDetailModal()
    }

    return (
        <BottomContentWrapper>
            <Button id="close-button" onClick={onClickWrapper}>
                <ButtonText>Close</ButtonText>
            </Button>
        </BottomContentWrapper>
    )
}
