import {isEqual} from 'lodash'
import {LoggingOutAction} from '../session-data/actions'
import ActionType from './action-type'
import {Action} from './actions'
import SessionActionType from '../session-data/action-type'
import {DEFAULT_INCIDENTS_OVERVIEW, IncidentsOverviewReduxState} from './state'
import produce from 'immer'
import LoadingState from '../../../values/loading-state-enum'

const incidentsOverviewReducer = produce(
    (
        draft: IncidentsOverviewReduxState = DEFAULT_INCIDENTS_OVERVIEW,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_INCIDENTS_OVERVIEW:
                draft.loading = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_INCIDENTS_OVERVIEW:
                draft.loading = LoadingState.Loaded
                if (!isEqual(draft, action.payload)) {
                    draft.overview = action.payload
                }
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_INCIDENTS_OVERVIEW
                break
            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default incidentsOverviewReducer
