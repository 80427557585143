import {normaliseScore} from '../../../helpers/formatting'
import {LatestLocationThreatScores} from '../latest-location-threat-scores/state'
import {LocationIdType} from './state'

export function getLocationScore(
    locationId: LocationIdType,
    locationThreatScores: LatestLocationThreatScores,
): number {
    return Math.round(normaliseScore(locationThreatScores[locationId]?.score || 0))
}
