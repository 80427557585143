import {useDispatch} from 'react-redux'
import {changeZoomLevelEvent} from '../../../../../../core/google-tag-manager/my-vessels/change-zoom-level-event'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {setZoomLevel} from '../../../../../../store/state/my-vessels-filters/action-creators'
import {myVesselsZoomLevelSelector} from '../../../../../../store/state/my-vessels-filters/selectors'
import {ZOOM_LEVEL_TYPE} from '../../../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import * as Styles from './button.styled'

interface Props {
    zoomLevel: ZOOM_LEVEL_TYPE
}

export function Button({zoomLevel}: Props): JSX.Element {
    const dispatch = useDispatch()
    const selectedZoomLevel = useTypedSelector(myVesselsZoomLevelSelector)

    function changeZoomLevel() {
        dispatch(setZoomLevel(zoomLevel))
        changeZoomLevelEvent(zoomLevel, 'filter')
    }

    if (selectedZoomLevel === zoomLevel) {
        return (
            <Styles.Selected>
                <Styles.Text>{zoomLevel}</Styles.Text>
            </Styles.Selected>
        )
    }
    return (
        <Styles.Button
            onClick={() => changeZoomLevel()}
            id={`zoom-level-level-${zoomLevel}-button`}
        >
            <Styles.Text id={`zoom-level-text-${zoomLevel}`}>{zoomLevel}</Styles.Text>
        </Styles.Button>
    )
}
