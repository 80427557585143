import {useState} from 'react'
import * as Styled from './_styled/nav-bar-content.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {DropdownMenuStyle} from './_styled/location-expanded.styled'
import {NavBarLink} from './nav-bar-link'
import {useLocation} from 'react-router-dom'
import {LocationExpandedButtonStyle} from './location-expanded-button/location-expanded-button.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'
import {viewEngineStatusPageEvent} from '../../../../core/google-tag-manager/navigation/view-engine-status-page-event'
import {viewUserManagementEvent} from '../../../../core/google-tag-manager/navigation/view-user-management-event'
import {rolesSelector} from '../../../../store/state/roles/selectors'
import {isUserAllowed} from '../../../../values/Role'
import {viewSwaggerLinksPageEvent} from '../../../../core/google-tag-manager/navigation/view-swagger-links-page-event'

interface SystemLinkProps {
    gtmLogger: ((trigger: string) => void) | null
}
export function SystemLink({gtmLogger = null}: SystemLinkProps): JSX.Element {
    const [systemDropDown, setSystemDropDown] = useState(false)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const isUserRoleAllowed = isUserAllowed(userRoles, ['DEVELOPER', 'THREAT_ANALYST'])
    const isAllowed = isInternalUser && isUserRoleAllowed

    function menuClicked() {
        if (gtmLogger != null) {
            gtmLogger('hamburgerMenu')
        }
        setSystemDropDown(!systemDropDown)
    }
    const pathname = useLocation().pathname

    return (
        <Styled.DashboardLinkStyle onClick={menuClicked}>
            <Styled.NavigationLinkDiv
                id="navigation-system"
                activeLink={
                    pathname.startsWith('/policy-management') ||
                    pathname.startsWith('/vessel-management') ||
                    pathname.startsWith('/user-management') ||
                    pathname.startsWith('/engine-status') ||
                    pathname.startsWith('/mona-engine-mode') ||
                    pathname.startsWith('/self-monitoring-engine-status') ||
                    pathname.startsWith('/email-recipients') ||
                    pathname.startsWith('/swagger-links')
                }
            >
                <LocationExpandedButtonStyle id="navigation-system-toggle">
                    <Icon
                        glyph={systemDropDown ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </LocationExpandedButtonStyle>
                System
            </Styled.NavigationLinkDiv>
            {systemDropDown && (
                <DropdownMenuStyle>
                    {isUserAllowed(userRoles, [
                        'DEVELOPER',
                        'THREAT_ANALYST',
                        'CUSTOMER_ADMIN',
                    ]) && (
                        <NavBarLink
                            id="navigation-policy-management"
                            navLinkText="Policy Management"
                            path="/policy-management"
                            gtmLogger={() => viewUserManagementEvent('hamburgerMenu')}
                        />
                    )}
                    {isUserAllowed(userRoles, [
                        'DEVELOPER',
                        'THREAT_ANALYST',
                        'CUSTOMER_ADMIN',
                    ]) && (
                        <NavBarLink
                            id="navigation-vessel-management"
                            navLinkText="Vessel Management"
                            path="/vessel-management"
                            gtmLogger={() => viewUserManagementEvent('hamburgerMenu')}
                        />
                    )}
                    <NavBarLink
                        id="navigation-user-management"
                        navLinkText=" User Management"
                        path="/user-management"
                        gtmLogger={() => viewUserManagementEvent('hamburgerMenu')}
                    />
                    {isAllowed && (
                        <NavBarLink
                            id="navigation-engine-status"
                            path="/engine-status"
                            navLinkText="Engine Status"
                            gtmLogger={() => viewEngineStatusPageEvent('hamburgerMenu')}
                        />
                    )}
                    {isAllowed && (
                        <NavBarLink
                            id="navigation-mona-engine-mode"
                            path="/mona-engine-mode"
                            navLinkText="Mona Engine Mode"
                            gtmLogger={() => viewEngineStatusPageEvent('hamburgerMenu')}
                        />
                    )}
                    {isAllowed && (
                        <NavBarLink
                            id="navigation-self-monitoring-engine-status"
                            path="/self-monitoring-engine-status"
                            navLinkText="Self-monitioring Engine Status"
                            gtmLogger={() => viewEngineStatusPageEvent('hamburgerMenu')}
                        />
                    )}
                    {isAllowed && (
                        <NavBarLink
                            id="navigation-email-recipients-management"
                            path="/email-recipients"
                            navLinkText="Email Recipient Management"
                            gtmLogger={() => viewEngineStatusPageEvent('hamburgerMenu')}
                        />
                    )}
                    {isAllowed && (
                        <NavBarLink
                            id="navigation-swagger-links"
                            path="/swagger-links"
                            navLinkText="Swagger Links"
                            gtmLogger={() => viewSwaggerLinksPageEvent('hamburgerMenu')}
                        />
                    )}
                </DropdownMenuStyle>
            )}
        </Styled.DashboardLinkStyle>
    )
}
