import {Checkbox} from '../../../../../../components/form-elements/checkbox'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../../store/state/locations/selectors'
import {LocationIdType} from '../../../../../../store/state/locations/state'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import * as Styles from './_styled/vessel-filters.styled'

interface Props {
    locationId: LocationIdType
    checked: boolean
    setChecked: (locationId: LocationIdType, checked: boolean) => void
    currentAssignedVessels: GuidType[] | undefined
}

export function VesselRow({
    locationId,
    checked,
    setChecked,
    currentAssignedVessels,
}: Props): JSX.Element {
    const location = useTypedSelector(locationMapSelector).get(locationId)
    const isInactive = currentAssignedVessels?.includes(locationId)

    return (
        <Styles.VesselRow isInactive={isInactive}>
            <Checkbox
                id={`filter-${locationId}`}
                checked={checked}
                onChange={() => setChecked(locationId, !checked)}
                label={location?.description || 'Unknown'}
            />
        </Styles.VesselRow>
    )
}
