import {EnforcementPolicyToggle} from '../toggle/enforcement-policy-detail-toggle'
import * as Styled from './details-section.styled'
import {useEnforcementPolicy} from '../../../../../contexts/enforcement-policy/use-enforcement-policy'
import {PolicyForNewDevicesDropDown} from '../dropdown/policy-for-new-devices-select-dropdown'
import {PolicyForMasterOverrideDropDown} from '../dropdown/policy-for-master-override-dropdown'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {acEnabledSelector} from '../../../../../../../store/state/config/selectors'

export function DetailsSection(): JSX.Element {
    const {
        changeNetworkProtection,
        changeRemovablesEnforcement,
        changeBlockAllUserForOfflineAssets,
        changeAllowAnyOnMaster,
        selectedEnforcementPolicy,
    } = useEnforcementPolicy()
    const acEnabledFleet = useTypedSelector(acEnabledSelector)
    const acEnabled = acEnabledFleet
        ? selectedEnforcementPolicy?.networkProtection.isEnabled ?? false
        : acEnabledFleet
    const tooltip = acEnabledFleet
        ? ''
        : 'Network enforcement not included with your subscription please contact support'
    return (
        <Styled.Panel>
            <Styled.DetailsLayout>
                <Styled.Details>
                    <Styled.Label>Network enforcement</Styled.Label>
                    <EnforcementPolicyToggle
                        label={'Protect assets from new unknown devices on vessel network'}
                        enabled={acEnabled}
                        setChange={changeNetworkProtection}
                        disabled={!acEnabledFleet}
                        tooltip={tooltip}
                    />
                </Styled.Details>
                <Styled.Details>
                    <Styled.Label>Removables enforcement</Styled.Label>
                    <EnforcementPolicyToggle
                        label={'Block access to unwanted USB devices'}
                        enabled={selectedEnforcementPolicy?.usbProtection.isEnabled ?? false}
                        setChange={changeRemovablesEnforcement}
                    />
                    <PolicyForNewDevicesDropDown />
                    <PolicyForMasterOverrideDropDown />
                    <EnforcementPolicyToggle
                        label={'Block access to all USBs on offline assets'}
                        enabled={
                            selectedEnforcementPolicy?.usbProtection
                                .isBlockAccessToOfflineAssetsEnabled ?? false
                        }
                        setChange={changeBlockAllUserForOfflineAssets}
                    />
                    <EnforcementPolicyToggle
                        label={`Allow any USB on Master's computer irrespective of the trust status`}
                        enabled={
                            selectedEnforcementPolicy?.usbProtection.isAllowAnyOnMasterEnabled ??
                            false
                        }
                        setChange={changeAllowAnyOnMaster}
                    />
                </Styled.Details>
            </Styled.DetailsLayout>
        </Styled.Panel>
    )
}
