import {MouseEvent, useEffect} from 'react'
import {formatDate} from '../../../../helpers/formatting'
import {removeMultipleQueryParam} from '../../../../helpers/navigation'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {usePagedAlerts} from '../../contexts/alerts/use-paged-alerts'
import {usePagedIndicators} from '../../contexts/indicators/use-paged-indicators'
import {usePagedUniqueFlows} from '../../contexts/unique-flows/use-paged-unique-flows'
import * as Styled from './_styled/filter-status.styled'
import {clickSetFilterButtonEvent} from '../../../../core/google-tag-manager/asset-modal/click-set-filter-button-event'
import {categoryName} from '../../../../core/google-tag-manager/asset-modal/helper'
import {changeFilterEvent} from '../../../../core/google-tag-manager/asset-modal/change-filter-event'
import {clearFilterEvent} from '../../../../core/google-tag-manager/asset-modal/clear-filter-event'

function isEmpty(value: string | null | undefined): boolean {
    return value == null || value == undefined || value.length === 0
}
export function FilterStatus(): JSX.Element {
    const {
        fromDate,
        toDate,
        clearDateFilter: clearAlertsDateFilter,
        setDateFilter: setAlertsDateFilter,
        displayDateFilter,
    } = usePagedAlerts()
    const {clearDateFilter: clearIndicatorsDateFilter, setDateFilter: setIndicatorsDateFilter} =
        usePagedIndicators()
    const {clearDateFilter: clearUniqueFlowsDateFilter, setDateFilter: setUniqueFlowsDateFilter} =
        usePagedUniqueFlows()

    const queryString = useTypedSelector(queryStringObjectSelector)
    const queryStringFromDate = queryString ? (queryString.fromDate as string) : undefined
    const queryStringToDate = queryString ? (queryString.toDate as string) : undefined
    const queryStringCategory = queryString ? (queryString.category as categoryName) : undefined

    useEffect(() => {
        if (isEmpty(queryStringFromDate) && isEmpty(queryStringToDate)) {
            if (!isEmpty(fromDate) || !isEmpty(toDate)) {
                clearAlertsDateFilter()
                clearIndicatorsDateFilter()
                clearUniqueFlowsDateFilter()
            }
        } else {
            if (fromDate !== queryStringFromDate || toDate !== queryStringToDate) {
                setAlertsDateFilter(queryStringFromDate, queryStringToDate)
                setIndicatorsDateFilter(queryStringFromDate, queryStringToDate)
                setUniqueFlowsDateFilter(queryStringFromDate, queryStringToDate)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryStringFromDate, queryStringToDate, fromDate, toDate])

    function clearFilter(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        removeMultipleQueryParam(['fromDate', 'toDate'])
        clearFilterEvent('header')
    }

    if (!fromDate && !toDate) {
        return (
            <Styled.FilterContent>
                <Styled.PrimaryButton
                    id="filter_status_set"
                    onClick={() => {
                        displayDateFilter(true), clickSetFilterButtonEvent(queryStringCategory)
                    }}
                >
                    Set Filter
                </Styled.PrimaryButton>
            </Styled.FilterContent>
        )
    }

    return (
        <Styled.FilterContent>
            <Styled.FilterDetails>
                <Styled.Title>Filter Details:</Styled.Title>
                {fromDate && (
                    <Styled.DetailRow>
                        <Styled.Label>from:</Styled.Label>
                        <span id="filter_status_from_date">{formatDate(fromDate)}</span>
                    </Styled.DetailRow>
                )}
                {toDate && (
                    <Styled.DetailRow>
                        <Styled.Label>to:</Styled.Label>
                        <span id="filter_status_to_date">{formatDate(toDate)}</span>
                    </Styled.DetailRow>
                )}
            </Styled.FilterDetails>
            <Styled.Actions>
                <Styled.PrimaryButton
                    id="filter_status_change"
                    onClick={() => {
                        displayDateFilter(true), changeFilterEvent()
                    }}
                >
                    Change
                </Styled.PrimaryButton>
                <Styled.SecondaryButton id="filter_status_clear" onClick={clearFilter}>
                    Clear
                </Styled.SecondaryButton>
            </Styled.Actions>
        </Styled.FilterContent>
    )
}
