import {ProcessingStatus} from '../../../../../values/processing-save-state-enum'
import {ModalEditAssetState} from './modal-edit-asset-state'

export const DEFAULT_MODAL_EDIT_ASSET_STATE: ModalEditAssetState = {
    assetId: null,
    isProcessingSave: ProcessingStatus.NOT_PROCESSING,
    currentAssetValue: undefined,
    currentHostname: '',
    newAssetValue: undefined,
    newHostname: '',
} as ModalEditAssetState
