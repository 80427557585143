import {SwaggerListItem} from '../swagger-list-item/swagger-list-item'
import {useEffect, useState} from 'react'
import {SwaggerLinksWrapper} from './swagger-link-area.styled'

export function SwaggerLinkArea(): JSX.Element {
    const [link, setLink] = useState<string | undefined>(undefined)
    const swaggerSuffix = '/swagger/index.html'

    useEffect(() => {
        async function fetchSwaggerLinks() {
            try {
                const result = await fetch(
                    `${process.env.PUBLIC_URL}/config/${
                        process.env.REACT_APP_ALT_BACKEND ?? 'medulla-ui'
                    }.json`,
                )
                const response = await result.json()
                setLink(`${response.baseUrl}`)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
        fetchSwaggerLinks()
    }, [])

    return (
        <SwaggerLinksWrapper>
            <SwaggerListItem
                id={'active-control-links'}
                appName={'Active Control'}
                swaggerLink={link + '/active-control' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'aimee-links'}
                appName={'Aimee'}
                swaggerLink={link + '/aimee' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'audit-module-links'}
                appName={'Audit Module'}
                swaggerLink={link + '/audit' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'basal-ganglia-links'}
                appName={'Basal Ganglia'}
                swaggerLink={link + '/swagger-ui.html'}
            />
            <SwaggerListItem
                id={'medulla-metrics-links'}
                appName={'Medulla Metrics'}
                swaggerLink={link + '/metrics' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'network-asset-aggregations-links'}
                appName={'Network Asset Aggregation'}
                swaggerLink={link + '/network-asset-aggregation' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'network-behaviour-links'}
                appName={'Network Behaviour'}
                swaggerLink={link + '/network-behaviour' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'search-definitions-links'}
                appName={'Search Definitions'}
                swaggerLink={link + '/search-definitions' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'software-inventory-links'}
                appName={'Software Inventory'}
                swaggerLink={link + '/software-inventory' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'usb-inventory-links'}
                appName={'USB Inventory'}
                swaggerLink={link + '/usb-inventory' + swaggerSuffix}
            />
            <SwaggerListItem
                id={'VCMM-links'}
                appName={'VCMM'}
                swaggerLink={link + '/vcmm' + swaggerSuffix}
            />
        </SwaggerLinksWrapper>
    )
}
