import {ContentArea} from '../../general/content-area/content-area.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {WidgetWrapper} from './widget-wrapper'
import {ContentContainerMiddle} from '../../../../../templates/fixed-page/content-container-middle.styled'
import {TableHeaderRow} from './table/header/table-header-row'
import {useTheme} from 'styled-components'
import {BottomContentSection} from '../../general/content-area/bottom-content-section'
import {TagsComponentTableContent} from './table/tags-component-table-content'

export function TagsDataTable(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const gridLayout = getGridLayout(width)

    return (
        <>
            <WidgetWrapper gridLayout={gridLayout}>
                <ContentArea gridLayout={gridLayout} tagWidget>
                    <BottomContentSection>
                        <ContentContainerMiddle
                            width={width}
                            backgroundColor={theme.softwareInventory.dataArea.background}
                        >
                            <TableHeaderRow />
                        </ContentContainerMiddle>
                        <ContentContainerMiddle
                            width={width}
                            scrollable
                            backgroundColor={theme.softwareInventory.dataArea.background}
                        >
                            <TagsComponentTableContent />
                        </ContentContainerMiddle>
                    </BottomContentSection>
                </ContentArea>
            </WidgetWrapper>
        </>
    )
}
