import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {rolesSelector} from '../../../../../store/state/roles/selectors'
import {usersSelector} from '../../../../../store/state/users/selectors'
import {CustomerReports} from '../../../../../values/customer-reports'
import {isUserAllowed} from '../../../../../values/Role'
import {getNotesFormatted} from '../../data-helpers'
import {Notes} from '../../shared/report-details/notes/notes'
import * as Styles from './reports-details.styled'

interface ReportsDetailsProps {
    gridRow: number
    detailsExpanded: boolean
    report: CustomerReports
}
export function ReportsDetailsTable({
    gridRow,
    detailsExpanded,
    report,
}: ReportsDetailsProps): JSX.Element {
    const users = useTypedSelector(usersSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const isInternalUser = isUserAllowed(userRoles, ['DEVELOPER', 'THREAT_ANALYST'])

    return (
        <Styles.TableNotesRow gridRow={gridRow}>
            {detailsExpanded && (
                <Styles.DetailsSection id={`notes-value-${report.identity}`}>
                    <Notes
                        identity={report.identity}
                        notes={getNotesFormatted(report.notes, users, isInternalUser)}
                        reportType={report.reportType}
                        rating={report.rating}
                    />
                </Styles.DetailsSection>
            )}
        </Styles.TableNotesRow>
    )
}
