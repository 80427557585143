import {DataLoading} from '../../../../components/data-loading/data-loading'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {useDispatch} from 'react-redux'
import {SelfMonitoringEngineLoaded} from './self-monitoring-engine-loaded'
import {fetchSelfMonitoringEngineEvents} from '../../../../store/state/self-monitoring-events/action-creators'
import {fetchSelfMonitoringEngineRules} from '../../../../store/state/self-monitoring-rules/action-creators'
import {lastUpdatedSelector} from '../../../../store/state/self-monitoring-events/selectors'
import {Header} from '../shared/header/header'
import {isLoadingReselector} from './reselect/is-loading-reselector'
import {configSelector} from '../../../../store/state/config/selectors'
import {refreshSystemPageEvent} from '../../../../core/google-tag-manager/system-page/refresh-system-page-event'
import {useEffect} from 'react'
import {isSMEDataNotPopulatedReselector} from './reselect/is-sme-data-not-populated-reselector'
import {fetchLocations} from '../../../../store/state/locations/action-creators'

export function SelfMonitoringEngine(): JSX.Element {
    const isLoading = useTypedSelector(isLoadingReselector)
    const isSMEDataNotPopulated = useTypedSelector(isSMEDataNotPopulatedReselector)
    const config = useTypedSelector(configSelector)
    const title: string = config.tenantDisplayName ? config.tenantDisplayName : 'Medulla'

    const dispatch = useDispatch()
    const lastUpdate = useTypedSelector(lastUpdatedSelector)
    function refresh() {
        dispatch(fetchLocations())
        dispatch(fetchSelfMonitoringEngineEvents())
        dispatch(fetchSelfMonitoringEngineRules())
        refreshSystemPageEvent('SMEData')
    }

    useEffect(() => {
        if (isSMEDataNotPopulated) {
            dispatch(fetchSelfMonitoringEngineEvents())
            dispatch(fetchSelfMonitoringEngineRules())
        }
    }, [dispatch, isSMEDataNotPopulated])

    return (
        <>
            <Header
                text={`Self-monitoring engine for ${title}`}
                refreshFunction={refresh}
                lastUpdate={lastUpdate}
            />
            {isLoading ? <DataLoading /> : <SelfMonitoringEngineLoaded environment={title} />}
        </>
    )
}
