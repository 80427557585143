import {ApiCreateOrAssign} from '../../../store/state/vessel-tags/helper'
import {addEvent} from '../add-event'

export function createOrAssignLocationTagEvent(
    tagToCreateOrAssign: ApiCreateOrAssign | null,
): boolean {
    return addEvent('createOrAssignLocationTag', {
        locationTag: tagToCreateOrAssign,
    })
}
