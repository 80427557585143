import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)} 0 ${spacing(2)} ${spacing(3)};
    height: 300px;
`

export const Title = styled.div`
    font-size: 16px;
    line-height: 22px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
