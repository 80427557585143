import {useDispatch} from 'react-redux'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {clickIndividualNetworkAssetStateEvent} from '../../../../../core/google-tag-manager/unknown-assets-beta/click-individual-network-asset-state-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {toggleNetworkAssetState} from '../../../../../store/state/network-assets-filter/action-creators'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {NetworkAssetState} from '../../../context/types/isolated-assets-type'
import {allNetworkAssetStatus, allNetworkAssetStatusForAc} from '../../helpers/data-helpers'
import {StatusRowWrapper, TextRow} from './network-asset-state-filter.styled'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {acEnabledSelector} from '../../../../../store/state/config/selectors'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

function getDetail(status: NetworkAssetState): string {
    switch (status) {
        case NetworkAssetState.MONITORED:
            return 'monitored'
        case NetworkAssetState.TRUSTED:
            return 'accepted on the network'
        case NetworkAssetState.BLOCKED:
            return 'isolated from the network'
        case NetworkAssetState.UNKNOWN:
            return 'discovered on the network'
        default:
            return ''
    }
}

function numberOfOtherNetworkAssetStateSelected(
    selectedNetworkAssetState: NetworkAssetState,
    networkAssetStateSelected: NetworkAssetState[] | undefined,
): number {
    if (!networkAssetStateSelected || networkAssetStateSelected.length === 0) {
        return 0
    }
    return networkAssetStateSelected.includes(selectedNetworkAssetState)
        ? networkAssetStateSelected.length - 1
        : networkAssetStateSelected.length
}

interface Props {
    statusLabel: NetworkAssetState
}
export function NetworkAssetStateRow({statusLabel}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {states} = useTypedSelector(networkAssetsFilterSelector)
    const acEnabled = useTypedSelector(acEnabledSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    function isChecked(givenValue: NetworkAssetState): boolean {
        try {
            return !states ? true : states.includes(givenValue)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error})
            return true
        }
    }

    function setChecked(
        currentSelectedNetworkAssetState: NetworkAssetState,
        newValue: boolean,
    ): void {
        if (acEnabled) {
            dispatch(
                toggleNetworkAssetState(
                    allNetworkAssetStatus,
                    currentSelectedNetworkAssetState,
                    newValue,
                    acEnabled,
                ),
            )
        } else {
            dispatch(
                toggleNetworkAssetState(
                    allNetworkAssetStatusForAc,
                    currentSelectedNetworkAssetState,
                    newValue,
                    acEnabled,
                ),
            )
        }

        clickIndividualNetworkAssetStateEvent(
            newValue,
            numberOfOtherNetworkAssetStateSelected(currentSelectedNetworkAssetState, states),
        )
    }

    return (
        <StatusRowWrapper>
            <Checkbox
                id={`${PageType.NETWORK_ASSETS}_filter-bar_state-filter_${statusLabel}`}
                label={statusLabel}
                checked={isChecked(statusLabel)}
                onChange={(newValue) => setChecked(statusLabel, newValue)}
                isInactive={isInactive ?? false}
            />
            <TextRow id={`${PageType.NETWORK_ASSETS}_filter-bar_assets-detail_${statusLabel}`}>
                assets ({getDetail(statusLabel)})
            </TextRow>
        </StatusRowWrapper>
    )
}
