import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {closeUSBDeviceModalEvent} from '../../../../../core/google-tag-manager/usb-devices/close-usb-device-modal'
import {USB_DEVICES_DETAILS} from '../../data-helpers'

export function DetailPanelHeader(): JSX.Element {
    const {usbDetailsOutput, setIdForUSBDetailsModal} = usePagedUSBDevices()

    return (
        <Styled.DetailPanelHeader id="detail-panel-header">
            <Styled.Title id="detail-panel-header_title">
                {usbDetailsOutput.productName}
            </Styled.Title>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    setIdForUSBDetailsModal(null)
                    removeQueryParam(USB_DEVICES_DETAILS)
                    closeUSBDeviceModalEvent()
                }}
                id="close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
