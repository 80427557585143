import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {GridLayout} from '../../general/grid-layout.enum'

interface ContentAreaProps {
    gridLayout: GridLayout
}

function getMargin(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return `${spacing(1)} 0 ${spacing(2)} 0`
        default:
            return '9px 0 18px 0'
    }
}

export const ContentArea = styled.div<ContentAreaProps>`
    background-color: ${(props) => props.theme.dashboard.vesselList.backgroundColor};
    padding: ${(props) => getMargin(props.gridLayout)};
    height: 100%;
    overflow: hidden;
`
