import {useDispatch} from 'react-redux'
import {searchedLastActiveValueEvent} from '../../../../../core/google-tag-manager/software-inventory/searched-last-seen-value-event'
import {TimestampFilter} from '../../../contexts/types/software-inventory-filter'
import * as Styled from './timestamp.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import {setSearchedLastSeen} from '../../../../../store/state/software-inventory-filter/action-creators'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function LastActiveFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {searchedLastActive} = useTypedSelector(softwareInventoryFilterSelector)

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        searchedLastActiveValueEvent(
            searchedLastActive,
            event.currentTarget.value as TimestampFilter,
        )
        dispatch(setSearchedLastSeen(event.target.value as TimestampFilter))
    }
    const allAvailableTimestampOptions: TimestampFilter[] = [
        'Last 24h',
        'Last 7 days',
        'Last 30 days',
    ]
    return (
        <Styled.Wrapper id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_last-active-filter`}>
            <Styled.Label>Last Active:</Styled.Label>
            <div>
                <Styled.Input>
                    <Styled.Select
                        onChange={onChangeWrapper}
                        value={searchedLastActive}
                        id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_searched-last-active-value`}
                    >
                        <option
                            key="default-option"
                            value="All"
                            id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_last-active_default-option`}
                        >
                            - All -
                        </option>
                        {allAvailableTimestampOptions?.map((value) => (
                            <option
                                key={value}
                                value={value}
                                id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_last-active_${value}-option`}
                            >
                                {value}
                            </option>
                        ))}
                    </Styled.Select>
                </Styled.Input>
            </div>
        </Styled.Wrapper>
    )
}
