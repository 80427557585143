import {NetworkAssetsFilterCriteria} from '../user-preferences/network-assets-filter'
import {SoftwareInventoryFilterCriteria} from '../user-preferences/software-inventory-filter'
import {SoftwareStatus} from '../../store/state/software-inventory-filter/state'
import {UsbInventoryFilterCriteria} from '../user-preferences/usb-inventory-filter'

export enum ReportType {
    NETWORK_ASSET_INVENTORY = 'NETWORK_ASSET_INVENTORY',
    SOFTWARE_INVENTORY = 'SOFTWARE_INVENTORY',
    USB_INVENTORY = 'USB_INVENTORY',
}

type searchCriteria<
    T extends
        | SoftwareInventoryFilterCriteria
        | Omit<NetworkAssetsFilterCriteria, 'properties' | 'fromRelativeLastSeen'>
        | UsbInventoryFilterCriteria,
    R extends ReportType,
> = {
    criteria: Omit<T, 'pagination'> & {
        type: R
        pagination: {
            pageSize: number
            pageOffset: number
        }
        locations?: string[]
    }
    note?: string
}

export type NetworkAssetsExtendedReportPayload = searchCriteria<
    Omit<NetworkAssetsFilterCriteria, 'properties'>,
    ReportType.NETWORK_ASSET_INVENTORY
> & {
    criteria: {
        toRelativeLastSeen?: string
        locationCode?: string
    }
}

export type SoftwareInventoryExtendedReportPayload = searchCriteria<
    SoftwareInventoryFilterCriteria,
    ReportType.SOFTWARE_INVENTORY
> & {
    criteria: {
        identity?: string
        statuses?: SoftwareStatus[]
        portable: boolean
    }
}

export type UsbInventoryExtendedReportPayload = searchCriteria<
    UsbInventoryFilterCriteria,
    ReportType.USB_INVENTORY
>
