import * as Styles from './_styles/nav-bar-link.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useRef, useState} from 'react'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {DropdownMenuWrap} from './_styles/location-dropdown.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {LocationDropDownButtonStyle} from './location-dropdown-button/location-dropdown-button.styled'
import {NavBarLink} from './nav-bar-link'
import {useLocation} from 'react-router-dom'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'
import {viewEngineStatusPageEvent} from '../../../../core/google-tag-manager/navigation/view-engine-status-page-event'
import {viewUserManagementEvent} from '../../../../core/google-tag-manager/navigation/view-user-management-event'
import {isUserAllowed} from '../../../../values/Role'
import {rolesSelector} from '../../../../store/state/roles/selectors'
import {viewSwaggerLinksPageEvent} from '../../../../core/google-tag-manager/navigation/view-swagger-links-page-event'
import {viewCustomerPackagesPageEvent} from '../../../../core/google-tag-manager/navigation/view-customer-packages-page-event'

interface SystemLinkProps {
    gtmLogger: ((trigger: string) => void) | null
}

export function SystemLink({gtmLogger = null}: SystemLinkProps): JSX.Element {
    const {width} = useDimensions()
    const [systemDropDown, setSystemDropDown] = useState(false)

    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const isUserRoleAllowed = isUserAllowed(userRoles, ['DEVELOPER', 'THREAT_ANALYST'])
    const isAllowed = isInternalUser && isUserRoleAllowed

    function menuClicked() {
        if (gtmLogger != null) {
            gtmLogger('mainNavigation')
        }
        setSystemDropDown(!systemDropDown)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clickOutside = useRef() as any
    useOnClickOutside(clickOutside, () => setSystemDropDown(false))

    const pathname = useLocation().pathname

    return (
        <Styles.NavigationLinkDiv
            id="navigation-system"
            width={width}
            activeLink={
                pathname.startsWith('/policy-management') ||
                pathname.startsWith('/vessel-management') ||
                pathname.startsWith('/user-management') ||
                pathname.startsWith('/engine-status') ||
                pathname.startsWith('/mona-engine-mode') ||
                pathname.startsWith('/self-monitoring-engine-status') ||
                pathname.startsWith('/email-recipients') ||
                pathname.startsWith('/swagger-links')
            }
            onClick={menuClicked}
            ref={clickOutside}
        >
            System
            <DropdownMenuWrap>
                <LocationDropDownButtonStyle id="navigation-system-toggle">
                    <Icon
                        glyph={systemDropDown ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </LocationDropDownButtonStyle>
                {systemDropDown && (
                    <Styles.DropdownContentStyle width={width} wide>
                        {isUserAllowed(userRoles, [
                            'DEVELOPER',
                            'THREAT_ANALYST',
                            'CUSTOMER_ADMIN',
                        ]) && (
                            <NavBarLink
                                id="navigation-policy-management"
                                navLinkText="Policy Management"
                                path="/policy-management"
                                gtmLogger={() => viewUserManagementEvent('mainNavigation')}
                            />
                        )}
                        {isUserAllowed(userRoles, [
                            'DEVELOPER',
                            'THREAT_ANALYST',
                            'CUSTOMER_ADMIN',
                        ]) && (
                            <NavBarLink
                                id="navigation-vessel-management"
                                navLinkText="Vessel Management"
                                path="/vessel-management"
                                gtmLogger={() => viewUserManagementEvent('mainNavigation')}
                            />
                        )}
                        <NavBarLink
                            id="navigation-user-management"
                            navLinkText="User Management"
                            path="/user-management"
                            gtmLogger={() => viewUserManagementEvent('mainNavigation')}
                        />
                        {isAllowed && (
                            <NavBarLink
                                id="navigation-engine-status"
                                path="/engine-status"
                                navLinkText="Engine Status"
                                gtmLogger={() => viewEngineStatusPageEvent('mainNavigation')}
                            />
                        )}
                        {isAllowed && (
                            <NavBarLink
                                id="navigation-mona-engine-mode"
                                path="/mona-engine-mode"
                                navLinkText="Mona Engine Mode"
                                gtmLogger={() => viewEngineStatusPageEvent('mainNavigation')}
                            />
                        )}
                        {isAllowed && (
                            <NavBarLink
                                id="navigation-self-monitoring-engine-status"
                                path="/self-monitoring-engine-status"
                                navLinkText="Self-monitioring Engine Status"
                                gtmLogger={() => viewEngineStatusPageEvent('mainNavigation')}
                            />
                        )}
                        {isAllowed && (
                            <NavBarLink
                                id="navigation-email-recipients-management"
                                path="/email-recipients"
                                navLinkText="Email Recipient Management"
                                gtmLogger={() => viewEngineStatusPageEvent('mainNavigation')}
                            />
                        )}
                        {isAllowed && (
                            <NavBarLink
                                id="navigation-swagger-links"
                                path="/swagger-links"
                                navLinkText="Swagger Links"
                                gtmLogger={() => viewSwaggerLinksPageEvent('mainNavigation')}
                            />
                        )}
                        {isAllowed && (
                            <NavBarLink
                                id="navigation-customer-packages"
                                path="/customer-packages"
                                navLinkText="Customer Packages"
                                gtmLogger={() => viewCustomerPackagesPageEvent('mainNavigation')}
                            />
                        )}
                    </Styles.DropdownContentStyle>
                )}
            </DropdownMenuWrap>
        </Styles.NavigationLinkDiv>
    )
}
