import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {AuthTokenDisplay} from './components/auth-token-display/auth-token-display'
import {useEffect} from 'react'
import {viewPageEvent} from '../../core/google-tag-manager/system-page/view-page-event'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'

export default function SwaggerLinksPage(): JSX.Element {
    const {width} = useDimensions()
    useEffect(() => {
        viewPageEvent()
    }, [])

    return (
        <FixedPageTemplate>
            <ContentContainerTop width={width}>
                <AuthTokenDisplay />
            </ContentContainerTop>
        </FixedPageTemplate>
    )
}
