import {IncidentModalOutputModel} from '../../../models/incident-modal.model'
import * as Styles from './_styled/incident-details.styled'
import {IncidentType} from './incident-type/incident-type'
import {IncidentStatusValue} from '../../shared/incident-status/incident-status'
import {ToggleProvider} from '../../../../../contexts/toggle/toggle-provider'
import {SeverityLevelSelectable} from '../../shared/severity-level/severity-level-selectable'
import {AssignedToCell} from '../../shared/assigned-to/assigned-to-cell'
import {CloseButton} from './close-button/close-button'

interface IncidentDetailsHeaderProps {
    model: IncidentModalOutputModel
}

export function IncidentDetailsHeader({model}: IncidentDetailsHeaderProps): JSX.Element {
    return (
        <Styles.IncidentDetailsHeader
            incidentStatus={model.status}
            id="incident-details-modal-header"
        >
            <Styles.TitleRow>
                <IncidentType incidentType={model.type} id={`type-value_${model.id}`} />
                <Styles.IncidentNumber id={model.id} gridArea="INCIDENT-NUMBER">
                    {model.number}
                </Styles.IncidentNumber>
                <Styles.VesselCell id={`vessel-value_${model.id}`} gridArea="VESSEL">
                    {model.vessel}
                </Styles.VesselCell>
                <CloseButton status={model.status} />
            </Styles.TitleRow>
            <Styles.DataRow>
                <Styles.DataCell gridArea="STATUS">
                    <ToggleProvider>
                        <IncidentStatusValue id={`${model.id}`} incidentStatus={model.status} />
                    </ToggleProvider>
                    <Styles.SubTitle>STATUS</Styles.SubTitle>
                </Styles.DataCell>
                <Styles.DataCell gridArea="SEVERITY">
                    <ToggleProvider>
                        <SeverityLevelSelectable id={model.id} incidentSeverity={model.severity} />
                    </ToggleProvider>
                    <Styles.SubTitle>SEVERITY</Styles.SubTitle>
                </Styles.DataCell>
                <Styles.DataCell gridArea="ASSIGNED" noBorder={true}>
                    <ToggleProvider>
                        <AssignedToCell
                            id={model.id}
                            assignedTo={model.assignedTo}
                            assignedToName={model.assignedToName}
                            guestAssignedToEmail={model.guestAssignedToEmail}
                            locationEmailAddress={model.vesselEmail}
                            isAssignedIncidentEmail={model.assignedToVesselEmail}
                            isAssignedToVesselEmail={model.assignedToVesselEmail}
                            isOnboardDashboard={model.vesselOnboardDashboard}
                        />
                    </ToggleProvider>
                    <Styles.SubTitle>ASSIGNED TO</Styles.SubTitle>
                </Styles.DataCell>
            </Styles.DataRow>
        </Styles.IncidentDetailsHeader>
    )
}
