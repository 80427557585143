import ActionType from './action-type'
import * as Actions from './actions'
import {ErrorOccurred} from '../session-data/action-creators'
import {AnyAction} from 'redux'
import {ThunkAction} from 'redux-thunk'
import {UserAgentApplication} from 'msal'

import {Api} from '../../../api/Api'
import {fetchNodes, registerNewAssetDiscoveredQuery} from '../nodes/action-creators'
import {fetchRolesForCurrentUser} from '../roles/action-creators'
import AppState from '../../types/app-state'
import {registerSFMQuery} from '../sfm-scores/action-creators'
import {fetchThreatMeasures} from '../threat-measures/action-creators'
import {fetchLocations, resetActiveLocation} from '../locations/action-creators'
import {registerLatestEventTimestampsQuery} from '../latest-event-timestamps/action-creators'
import {registerLatestLocationThreatScoresQuery} from '../latest-location-threat-scores/action-creators'
import {fetchWatchedNodes} from '../watched-nodes/action-creators'
import {fetchCurrentUser} from '../current-user/action-creators'
import {fetchIncidentTypeses} from '../incident-types/action-creators'
import {fetchWatchedIncidents} from '../watched-incidents/action-creators'
import {
    fetchEnforcementNetworksLicense,
    fetchEnforcementRemovablesLicense,
    fetchFleetConfigForIncidentReopenProcessing,
    fetchFleetConfigForNetworkAssets,
    fetchFleetConfigForNmeaInventory,
    fetchFleetConfigForReports,
    fetchFleetConfigForSoftwareInventory,
    fetchFleetConfigForUsbInventory,
    fetchIncidentLicense,
    fetchMetricsOnboardEngagementLicense,
} from '../config/action-creators'
import {fetchAllLocationTagsByLocation} from '../vessel-tags/action-creators'
import {fetchSavedFilters} from '../saved-filters/action-creators'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logout(msal?: UserAgentApplication): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any, _: any, api: Api): void => {
        dispatch(resetActiveLocation())
        api.logout()

        if (msal) {
            try {
                msal.logout()
                dispatch(clearMSAL())
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err)
            }
        }

        dispatch(clearAuthToken())
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorOccured(error: string): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any, _: any, api: Api): void => {
        dispatch(resetActiveLocation())
        dispatch(ErrorOccurred(error))
        api.logout()
    }
}

export function authTokenRetrieved(authToken: string): Actions.AuthTokenRetrievedAction {
    return {
        type: ActionType.RECEIVE_AUTH_TOKEN,
        payload: authToken,
    }
}

export function clearAuthToken(): Actions.ClearAuthTokenAction {
    return {
        type: ActionType.CLEAR_AUTH_TOKEN,
    }
}

export function fetchInitialData(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch) => {
        dispatch(registerLatestLocationThreatScoresQuery())
        dispatch(registerLatestEventTimestampsQuery())
        dispatch(registerNewAssetDiscoveredQuery())
        dispatch(registerSFMQuery())
        dispatch(fetchRolesForCurrentUser())
        dispatch(fetchNodes())
        dispatch(fetchLocations())
        dispatch(fetchCurrentUser())
        dispatch(fetchThreatMeasures())
        dispatch(fetchWatchedNodes())
        dispatch(fetchIncidentTypeses())
        dispatch(fetchWatchedIncidents())
        dispatch(fetchFleetConfigForReports())
        dispatch(fetchFleetConfigForNetworkAssets())
        dispatch(fetchFleetConfigForUsbInventory())
        dispatch(fetchFleetConfigForSoftwareInventory())
        dispatch(fetchFleetConfigForNmeaInventory())
        dispatch(fetchFleetConfigForIncidentReopenProcessing())
        dispatch(fetchAllLocationTagsByLocation())
        dispatch(fetchSavedFilters())
        dispatch(fetchIncidentLicense())
        dispatch(fetchMetricsOnboardEngagementLicense())
        dispatch(fetchEnforcementNetworksLicense())
        dispatch(fetchEnforcementRemovablesLicense())
    }
}

// Removed as we no longer preload this data, but it is left in to show how to pre-load additional data
/*export function fetchExtendedData(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch) => {
        dispatch(fetchEngineStatuses())
        dispatch(fetchSelfMonitoringEngineEvents())
        dispatch(fetchSelfMonitoringEngineRules())
    }
}*/

export function setMSAL(msal: UserAgentApplication): Actions.SetMSALAction {
    return {
        type: ActionType.SET_MSAL,
        payload: msal,
    }
}

export function clearMSAL(): Actions.ClearMSALAction {
    return {
        type: ActionType.CLEAR_MSAL,
    }
}
