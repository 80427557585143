import {PlaceholderType} from '../../../values/placeholder'
import {addEvent} from '../add-event'

export function expandAssetDetailsEvent(
    where: PlaceholderType,
    score: number | undefined,
    value: string,
): boolean {
    return addEvent('expandAssetDetails', {
        where: where,
        nodeScore: score,
        nodeValue: value,
    })
}
