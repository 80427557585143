import {useToggle} from '../../../../contexts/toggle/use-toggle'
import {showFilterEvent} from '../../../../core/google-tag-manager/reports/show-filter-event'
import {FilterBarButton} from '../../../../components/filter-button/filter-button'

export function ShowFilterButton(): JSX.Element {
    const {setValue: showFilter} = useToggle()
    return (
        <FilterBarButton
            onClick={() => {
                showFilterEvent()
                showFilter(true)
            }}
        />
    )
}
