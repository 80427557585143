import {StringUtils} from '../../../../../utils/Utils'
import {MetricsResponseForTarget} from '../../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {getMetricTypeIcon, findMetricCategory, getFormatedPercentNumber} from '../../data-helper'
import {CardDataCell} from './card-data-cell'
import * as Styled from './metrics-cards.styled'
import {MetricsDetailsExpanded} from './metrics-details-expanded/metrics-details-expanded'

interface MetricsCardProps {
    metrics: MetricsResponseForTarget
    onClickFn: () => void
}

export function MetricsCardTarget({metrics, onClickFn}: MetricsCardProps): JSX.Element {
    const cardAreaData = [
        {
            label: 'Framework:',
            identifier: `metrics-framework_type-${metrics.metric}`,
            value: StringUtils.capitaliseString(metrics.framework),
        },
        {
            label: 'Metric:',
            identifier: `metrics-metric_type-${metrics.metric}`,
            value: StringUtils.capitaliseString(metrics.metric.replace(/([A-Z])/g, ' $1')),
        },
        {
            label: 'Vessel performance:',
            identifier: `metrics-vessel-performance-${metrics.metric}`,
            value: getFormatedPercentNumber(metrics.performance),
        },
        {
            label: 'Assets to fix:',
            identifier: `metrics-assetsToFix-${metrics.metric}`,
            value: metrics.assetsToFix,
        },
    ]
    const {idForMetricDetailsModal} = usePagedMetricsBeta()

    const detailExtended =
        idForMetricDetailsModal?.metric === metrics.metric &&
        idForMetricDetailsModal?.locationId === metrics.location

    return (
        <Styled.MetricsDetails>
            <Styled.Card onClick={onClickFn}>
                <Styled.CardAreaTitle>
                    <Styled.CardCustomerTitleData gridArea="VESSEL-NAME">
                        <div id={`metrics-vessel_name-${metrics.location}`}>
                            {metrics.locationName}
                        </div>
                    </Styled.CardCustomerTitleData>
                    <Styled.CardProductTitleData gridArea="POLICY-NAME">
                        <div id={`metrics-policy_name-${metrics.policy}`}>{metrics.policyName}</div>
                    </Styled.CardProductTitleData>
                    <Styled.CardProductTitleData gridArea="ANALYSIS-NAME">
                        <div id={`metrics-vessel-target-${metrics.target}`}>
                            {getFormatedPercentNumber(metrics.target)}
                        </div>
                    </Styled.CardProductTitleData>
                </Styled.CardAreaTitle>
                <Styled.CardAreaContent>
                    {cardAreaData.map((data, index) => (
                        <CardDataCell
                            key={index}
                            gridArea={data.label}
                            label={data.label}
                            identifier={data.identifier}
                        >
                            {data.label === 'Framework:' && (
                                <img
                                    src={getMetricTypeIcon(findMetricCategory(metrics.framework))}
                                    style={{width: '35px', height: '35px', marginRight: '5px'}}
                                />
                            )}
                            {data.value}
                        </CardDataCell>
                    ))}
                </Styled.CardAreaContent>
            </Styled.Card>
            {detailExtended && <MetricsDetailsExpanded />}
        </Styled.MetricsDetails>
    )
}
