import {createSelector} from 'reselect'

import {locationsLoadingSelector} from '../../../store/state/locations/selectors'
import {fetchingLatestEventTimestampsSelector} from '../../../store/state/latest-event-timestamps/selectors'
import {isLoadingSelector} from '../../../store/state/watched-nodes/selectors'
import {fetchingStatusSelector} from '../../../store/state/config/selectors'
import {ConfigFetchingStatus} from '../../../store/state/config/state'
import {isTagLoadingSelector} from '../../../store/state/vessel-tags/selectors'

export const group2DataIsLoadedReselector = createSelector(
    locationsLoadingSelector,
    fetchingLatestEventTimestampsSelector,
    isLoadingSelector,
    fetchingStatusSelector,
    isTagLoadingSelector,
    (
        locationsLoading,
        latestEventTimestampsLoading,
        watchedNodesLoading,
        configFetchingStatus,
        isFetchingTags,
    ): boolean => {
        return locationsLoading ||
            latestEventTimestampsLoading ||
            watchedNodesLoading ||
            isFetchingTags ||
            configFetchingStatus !== ConfigFetchingStatus.INITIALISED
            ? false
            : true
    },
)
