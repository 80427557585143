import * as Styles from './_styles/nav-bar-link.styled'
import {LocationDropdown} from './location-dropdown'
import {useToggle} from '../../../../contexts/toggle/use-toggle'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {LocationDropDownButton} from './location-dropdown-button/location-dropdown-button'
import {useRef} from 'react'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {DropdownMenuWrap} from './_styles/location-dropdown.styled'

interface DashboardLinkProps {
    gtmLogger: ((trigger: string) => void) | null
}
export function DashboardLink({gtmLogger = null}: DashboardLinkProps): JSX.Element {
    const {value: dropdownOpen, setValue: setDropdownOpen} = useToggle()
    const {width} = useDimensions()

    function menuClicked() {
        if (gtmLogger != null) {
            gtmLogger('mainNavigation')
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clickOutside = useRef() as any
    useOnClickOutside(clickOutside, () => setDropdownOpen(false))
    return (
        <Styles.NavigationLink
            id="navigation-dashboard"
            width={width}
            to="/dashboard"
            activeClassName="activeLink"
            onClick={menuClicked}
            ref={clickOutside}
        >
            Dashboard
            <DropdownMenuWrap>
                <LocationDropDownButton dropdownOpen={dropdownOpen} />
                {dropdownOpen && <LocationDropdown />}
            </DropdownMenuWrap>
        </Styles.NavigationLink>
    )
}
