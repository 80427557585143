import {useEffect} from 'react'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {viewPageEvent} from '../../core/google-tag-manager/usb-devices/view-page-event'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import LoadingState from '../../values/loading-state-enum'
import {Content} from './components/content'
import {showFilterBarOnScreen} from './components/data-helpers'
import {CloseFilterButton} from './components/filter-bar/close-filter-button/close-filter-button'
import {FilterBar} from './components/filter-bar/filter-bar'
import {PagedUSBDevicesProvider} from './contexts/paged-usb-devices-provider'
import {usePagedUSBDevices} from './contexts/use-paged-usb-devices'
import * as Styled from './usb-devices.styled'
import useTypedSelector from '../../hooks/use-typed-selector'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../store/state/audit-log/state'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {useDispatch} from 'react-redux'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {
    fetchUsbInventoryFilter,
    resetFilter,
} from '../../store/state/usb-inventory-filter/action-creators'
import {usbInventoryFilterLoadingStateSelector} from '../../store/state/usb-inventory-filter/selectors'
import {StrictMode} from '../../store/state/users/state'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import {
    setCurrentAppliedSavedFilter,
    setSavedFilterPageType,
} from '../../store/state/saved-filters/action-creators'
import {savedUsbInventoryFiltersSelector} from '../../store/state/saved-filters/selectors'
import {ContactSocModal} from '../../components/contact-soc-modal/contact-soc-modal'
import {isModalOpenSelector} from '../../store/state/extended-data-request/selectors'
import {queryStringObjectSelector} from '../../store/routerSelectors'
import {GuidType} from '../../values/generic-type-defintions'
import {SetUsbPurposeModal} from './components/usb-device-details-modal/actions/set-purpose-modal/set-purpose-modal'
import {usersLoadingStateSelector} from '../../store/state/users/selectors'
import {fetchUsers} from '../../store/state/users/action-creators'
import {rolesSelector} from '../../store/state/roles/selectors'
import {isUserAllowed} from '../../values/Role'
import {Header} from '../../components/header/header'

export function UsbDevicesPage(): JSX.Element {
    const dispatch = useDispatch()
    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.USB_INVENTORY, AuditLogAuditType.PAGE_ACCESS))
        viewPageEvent()
        dispatch(setCurrentAppliedSavedFilter(undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (usersLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchUsers())
        }
    }, [dispatch, usersLoadingState])

    return (
        <PagedUSBDevicesProvider pageSize={10}>
            <USBDevices />
        </PagedUSBDevicesProvider>
    )
}

function USBDevices(): JSX.Element {
    const {showFilterBar, displayFilterBar, modalIdForUSBDetails, showSetPurposeModal} =
        usePagedUSBDevices()
    const loadingFilterState = useTypedSelector(usbInventoryFilterLoadingStateSelector)
    const {width} = useDimensions()
    const showFilterBarOn = showFilterBarOnScreen(width)
    const dispatch = useDispatch()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const savedFilters = useTypedSelector(savedUsbInventoryFiltersSelector)
    const isExpandedDataRequestModalOpen = useTypedSelector(isModalOpenSelector)
    const queryString = useTypedSelector(queryStringObjectSelector)
    const queryId = queryString?.usbDetails as GuidType
    const externalGuid = modalIdForUSBDetails == null && queryId != undefined
    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)
    const isDataLoaded =
        loadingFilterState === LoadingState.Loaded && usersLoadingState === LoadingState.Loaded
    const userRoles = useTypedSelector(rolesSelector)
    const isAllowedUserForSetPurpose = isUserAllowed(userRoles, [
        'DEVELOPER',
        'THREAT_ANALYST',
        'CUSTOMER_ADMIN',
    ])
    const isAllowedSetPurpose = isAllowedUserForSetPurpose && showSetPurposeModal
    const headerText = 'USB Device Inventory'

    useEffect(() => {
        dispatch(setPageType(PageType.USB_DEVICES))
        dispatch(setSavedFilterPageType(SavedFilterPageType.USB_INVENTORY))
        if (externalGuid) {
            dispatch(resetFilter())
        } else {
            dispatch(
                fetchUsbInventoryFilter(savedFilters, strictMode === StrictMode.DefaultVisibility),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    if (!showFilterBarOn && showFilterBar) {
        return (
            <ScrollablePageTemplate onClick={() => displayFilterBar(false)}>
                <Header text={headerText} width={width} />
                <Styled.FilterBarWrapper
                    width={width}
                    onClick={(e) => e.stopPropagation()}
                    id="filter-abr-wrapper"
                >
                    <FilterBar />
                    <Styled.BottomCloseFilterBar>
                        <CloseFilterButton text="Close Filter" />
                    </Styled.BottomCloseFilterBar>
                </Styled.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <FixedPageTemplate>
                <Header text={headerText} width={width} />
                <Styled.WideContentArea width={width} fullHeight={!showFilterBar}>
                    {showFilterBar && (
                        <Styled.LHFilterBar id="filter-abr-wrapper">
                            <FilterBar />
                        </Styled.LHFilterBar>
                    )}
                    {isDataLoaded && <Content />}
                </Styled.WideContentArea>
                {isAllowedSetPurpose && <SetUsbPurposeModal />}
                {isExpandedDataRequestModalOpen && <ContactSocModal />}
            </FixedPageTemplate>
        )
    }
}
