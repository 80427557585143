import ActionType from './action-type'
import * as Actions from './actions'
import {Location, LocationIdType, MetaData, Network, ReportConfiguration} from './state'
import {ThunkAction} from 'redux-thunk'

import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {GuidType} from '../../../values/generic-type-defintions'
import {VesselConfigModal} from '../../../pages/vessel-management/contexts/type/vessel-management-state'
import {
    getFormatedOTSourceData,
    OTSourceModalDetails,
} from '../../../pages/vessel-management/contexts/type/ot-sources-modal-details'

const LOCATIONS_URL = '/api/v1/nodes/locations'

const setLocations = (locations: Location[]): Actions.SetLocationsAction => ({
    type: ActionType.SET_LOCATIONS,
    payload: locations,
})

export const setActiveLocation = (activeLocation: Location): Actions.SetActiveLocationAction => ({
    type: ActionType.SET_ACTIVE_LOCATION,
    payload: activeLocation,
})

const requestLocations = (): Actions.RequestLocationsAction => ({
    type: ActionType.REQUEST_LOCATIONS,
})

export const resetActiveLocation = (): Actions.ResetActiveLocation => ({
    type: ActionType.RESET_ACTIVE_LOCATION,
})

export const fetchLocations = (
    needReset = true,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        needReset && dispatch(requestLocations())

        REST.get(LOCATIONS_URL).then((response) => {
            dispatch(setLocations(response.data))
        })
    }
}

export const updateVesselConfig = (
    locationId: LocationIdType,
    type: string,
    customerLocationCode: string,
    metaData: MetaData,
    reportConfiguration: ReportConfiguration,
): Actions.UpdateVesselConfigAction => ({
    type: ActionType.UPDATE_VESSEL_CONFIG,
    payload: {
        locationId,
        type,
        customerLocationCode,
        metaData,
        reportConfiguration,
    },
})

export function setChangeBusinessNetworks(
    value: string[],
): Actions.SetChangeBusinessNetworksAction {
    return {type: ActionType.CHANGE_BUSINESS_NETWORKS, payload: value}
}

export function setChangeOtNetworks(value: string[]): Actions.SetChangeOtNetworksAction {
    return {type: ActionType.CHANGE_OT_NETWORKS, payload: value}
}

export function setChangeCrewNetworks(value: string[]): Actions.SetChangeCrewNetworksAction {
    return {type: ActionType.CHANGE_CREW_NETWORKS, payload: value}
}

export function setChangeDashboardAgentIds(
    value: string,
): Actions.SetChangeDashboardAgentIdsAction {
    return {type: ActionType.CHANGE_DASHBOARD_AGENT_IDS, payload: value}
}

export function setChangeVesselEmail(
    value: string | null | undefined,
): Actions.SetChangeVesselEmailAction {
    return {
        type: ActionType.CHANGE_VESSEL_EMAIL,
        payload: value,
    }
}

export function updateVesselNetworkConfig(
    value: Network | undefined,
): Actions.UpdateVesselNetworkConfigAction {
    return {type: ActionType.UPDATE_VESSEL_NETWORK_CONFIG, payload: value}
}

export function updateVesselDashboardAgentIds(
    value: string[] | undefined,
): Actions.updateVesselDashboardAgentIdsAction {
    return {type: ActionType.UPDATE_VESSEL_DASHBOARD_AGENT_IDS, payload: value}
}

export function saveUpdatedVesselConfig(
    locationId: GuidType,
    updatedConfigValue: VesselConfigModal,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return async (dispatch, getState) => {
        const getLocationNetworkData =
            getState().locations.locationMap.get(locationId)?.metaData?.network

        if (updatedConfigValue.networkConfiguration) {
            await REST.patch(`${LOCATIONS_URL}/${locationId}/metadata?path=network`, {
                ...getLocationNetworkData,
                BusinessNetworks: getNetworkMetaData(
                    updatedConfigValue.networkConfiguration.BusinessNetworks,
                    getLocationNetworkData?.BusinessNetworks,
                ),
                OTNetworks: getNetworkMetaData(
                    updatedConfigValue.networkConfiguration.OTNetworks,
                    getLocationNetworkData?.OTNetworks,
                ),
                CrewNetworks: getNetworkMetaData(
                    updatedConfigValue.networkConfiguration.CrewNetworks,
                    getLocationNetworkData?.CrewNetworks,
                ),
            })
        }

        if (updatedConfigValue.vesselDashboardAgentIds) {
            const formattedVesselDashboardAgentIds =
                updatedConfigValue.vesselDashboardAgentIds.length == 0
                    ? null
                    : updatedConfigValue.vesselDashboardAgentIds
            await REST.patch(
                `${LOCATIONS_URL}/${locationId}/metadata?path=vesselDashboardAgentIds`,
                formattedVesselDashboardAgentIds,
            )
        }

        if (
            updatedConfigValue.vesselEmail !== undefined &&
            updatedConfigValue.vesselEmail !== null
        ) {
            const formattedEmail =
                updatedConfigValue.vesselEmail.length === 0 ? null : updatedConfigValue.vesselEmail

            formattedEmail === null
                ? await REST.patch(`${LOCATIONS_URL}/${locationId}/email`)
                : await REST.patch(`${LOCATIONS_URL}/${locationId}/email?email=${formattedEmail}`)
        }

        dispatch(fetchLocations(true))
    }
}

export function saveUpdatedOTSources(
    updatedOtSourcesValue: OTSourceModalDetails,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return async (dispatch, getState) => {
        const getLocationOtSourcesData = getState().locations.locationMap.get(
            updatedOtSourcesValue.locationId,
        )?.metaData?.otSources
        const oTSourceTypeToUpdate = updatedOtSourcesValue.sourceType

        await REST.patch(
            `${LOCATIONS_URL}/${updatedOtSourcesValue.locationId}/metadata?path=otSources`,
            {
                ...getLocationOtSourcesData,
                syslog:
                    oTSourceTypeToUpdate === 'syslog'
                        ? getFormatedOTSourceData(
                              updatedOtSourcesValue,
                              getLocationOtSourcesData?.syslog,
                          )
                        : getLocationOtSourcesData?.syslog,
                nmea:
                    oTSourceTypeToUpdate === 'nmea'
                        ? getFormatedOTSourceData(
                              updatedOtSourcesValue,
                              getLocationOtSourcesData?.nmea,
                          )
                        : getLocationOtSourcesData?.nmea,
            },
        )
        dispatch(fetchLocations(true))
    }
}

export const getNetworkMetaData = (
    formattedData: string[] | undefined,
    getLocationNetworkData: string[] | undefined,
): string[] | undefined => {
    return !formattedData
        ? getLocationNetworkData
        : formattedData.length === 0
          ? undefined
          : formattedData
}
