import {Button} from './close-filter-button.styled'
import {XCircle} from 'react-feather'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {showFilterEvent} from '../../../../../core/google-tag-manager/unknown-assets-beta/show-filter-event'
interface Props {
    text?: string
}
export function CloseFilterButton({text}: Props): JSX.Element {
    const {displayFilterBar} = usePagedUnknownAssetsBeta()

    return (
        <Button
            onClick={() => {
                displayFilterBar(false)
                showFilterEvent(false)
            }}
            id="close-filter-button"
        >
            <XCircle />
            {text}
        </Button>
    )
}
