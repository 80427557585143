import {addEvent} from '../add-event'

export function watchFilterIncidentEvent(watchedByCurrentUser: boolean | null): boolean {
    return addEvent('incidentsPageSelectWatchedIncidents', {
        watchedByCurrentUser: getValue(watchedByCurrentUser),
    })
}
export function getValue(watchedByCurrentUser: boolean | null): string {
    return watchedByCurrentUser == null || watchedByCurrentUser === false
        ? 'Watched incidents'
        : 'Show All incidents'
}
