import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {changeSoftwareInventorySortOrderEvent} from '../../../../../core/google-tag-manager/software-inventory/change-sort-order-event'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {getNewSortByDirection, SOFTWARE_INVENTORY_DETAILS} from '../../data-helpers'
import {IconStyled, TableHeaderCell, TableHeaderText} from './table-header-cell.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {useDispatch} from 'react-redux'
import {
    SoftwareInventorySortType,
    SortColumnType,
} from '../../../../../store/state/software-inventory-filter/state'
import {setSortColumn} from '../../../../../store/state/software-inventory-filter/action-creators'
import {
    softwareInventoryFilterLoadingSelector,
    softwareInventoryFilterSelector,
} from '../../../../../store/state/software-inventory-filter/selectors'

interface SortableColumnProps {
    gridColumn: number
    text: string
    colName: SoftwareInventorySortType
}

export function SortableColumn({gridColumn, text, colName}: SortableColumnProps): JSX.Element {
    const isLoadingFilter = useTypedSelector(softwareInventoryFilterLoadingSelector)
    const {sortColumn} = useTypedSelector(softwareInventoryFilterSelector)
    const dispatch = useDispatch()

    function setSortBy(selectedColumn: SoftwareInventorySortType) {
        const newSortColumn: SortColumnType = {
            orderAscending: getNewSortByDirection(selectedColumn, sortColumn),
            orderBy: selectedColumn,
        }
        dispatch(setSortColumn(newSortColumn))
        removeQueryParam(SOFTWARE_INVENTORY_DETAILS)
        changeSoftwareInventorySortOrderEvent(selectedColumn, sortColumn.orderAscending)
    }
    return (
        <TableHeaderCell id={`sortable-column_${colName}`} gridColumn={gridColumn} selectable>
            <TableHeaderText
                onClick={() => setSortBy(colName)}
                active={!isLoadingFilter && sortColumn.orderBy === colName}
                id={`text_${colName}`}
            >
                {text}
                <IconStyled arrowDec={sortColumn.orderBy === colName && !sortColumn.orderAscending}>
                    {!isLoadingFilter && <Icon width={12} height={17} glyph="Arrow" />}
                </IconStyled>
            </TableHeaderText>
        </TableHeaderCell>
    )
}
