import {ThreatValue} from '../../../helpers/threatHelper'
import {NodeValue} from '../../../values/nodes/NodeData'
import {addEvent} from '../add-event'
import {getThreatLevel} from '../helpers/threat-level-helper'

export function assetDetailsClickedEvent(value: NodeValue, threatValue: ThreatValue): boolean {
    return addEvent('assetDetailsClicked', {
        assetValue: value,
        threatLevel: getThreatLevel(threatValue),
    })
}
