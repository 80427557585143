import {Filter} from 'react-feather'
import {showFilterEvent} from '../../../../../core/google-tag-manager/incidents-beta/show-filter-event'
import {removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {activeVesselFilterSelector} from '../../../../../store/state/vessel-filter/selectors'

import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {INCIDENT_DETAILS} from '../../../data-helpers'
import * as Styles from './filter-bar-button.styled'
import {isActiveFilterSelector} from '../../../../../store/state/incidents-filter/selectors'

export function FilterBarButton(): JSX.Element {
    const {closeIncidentDetailsModal, displayFilterBar, showFilterBar} = usePagedIncidents()
    const activeVesselFilter = useTypedSelector(activeVesselFilterSelector)
    const activeFilter = useTypedSelector(isActiveFilterSelector)

    return (
        <Styles.Container
            onClick={(e) => {
                e.preventDefault()
                closeIncidentDetailsModal()
                removeQueryParam(INCIDENT_DETAILS)
                displayFilterBar(!showFilterBar)
                showFilterEvent(!showFilterBar)
            }}
            id="filter-button-incidents-page"
        >
            <Styles.SearchIcon active={activeFilter || activeVesselFilter}>
                <Filter height={16} width={16} />
            </Styles.SearchIcon>
            <Styles.TextWrapper active={activeFilter || activeVesselFilter}>
                <Styles.Text>Filter</Styles.Text>
            </Styles.TextWrapper>
        </Styles.Container>
    )
}
