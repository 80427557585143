import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {LicensesPageProvider} from './contexts/licenses-page-provider'
import * as Styled from './licenses-page.styled'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {Header} from './components/header'
import {LicenseListTable} from './license-table/license-list-table'

export function LicensesPage(): JSX.Element {
    return (
        <LicensesPageProvider>
            <LicensesPageContent />
        </LicensesPageProvider>
    )
}

function LicensesPageContent(): JSX.Element {
    const {width} = useDimensions()
    return (
        <FixedPageTemplate>
            <Styled.WideContentHeaderArea width={width}>
                <Header text="Customer packages" />
            </Styled.WideContentHeaderArea>
            <Styled.WideContentArea width={width} fullHeight={true}>
                <Styled.ScrollableContent flexGrow>
                    <LicenseListTable />
                </Styled.ScrollableContent>
            </Styled.WideContentArea>
        </FixedPageTemplate>
    )
}
