import {addEvent} from '../add-event'

export type ActiveLocationType = 'FLEET_VIEW' | 'INDIVIDUAL_VESSEL'
export type MenuType = 'mainNavigation' | 'hamburgerMenu'

export function selectActiveLocationEvent(
    newActiveLocation: ActiveLocationType,
    trigger: MenuType,
): boolean {
    return addEvent('selectActiveLocation', {newActiveLocation, from: trigger})
}
