import {useContext} from 'react'
import {useMetricsPolicyResult} from './use-metrics-policy-output'
import {MetricsPolicyContext} from './metrics-policy-context'
import * as ActionCreators from './state/action-creators'
import {warn} from '../../../../helpers/logging'
import {GuidType} from '../../../../values/generic-type-defintions'
import {REST} from '../../../..'
import {FormattedMetric} from './type/formatted-metric-policy'
import {ConfigTypes, OnboardEngagementConfigType, ValuesOutput} from './type/metrics-policy-schema'
import {usePolicyManagement} from '../policy-management/use-policy-management'
import {PolicyType} from '../policy-management/type/policy-management-state'
import {MetricsPolicy} from './type/metrics-policy'

const METRICS_POLICY_ENDPOINT = '/metrics/api/v1/policies'
const METRICS_SCHEMA_ENDPOINT = 'metrics/api/v1/metrics/schema'

export function useMetricsPolicy(): useMetricsPolicyResult {
    const {state, dispatch} = useContext(MetricsPolicyContext)
    const {requestDataToBeFecthed, showPolicyDetailModal} = usePolicyManagement()
    if (state == undefined || dispatch == undefined) {
        throw new Error('useMetricsPolicy must be used within a MetricsPolicyContext')
    }
    async function findMetricsPolicyDetails(identity: GuidType): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.requestMetricsPolicyData())
        try {
            const policyResponse = await REST.get(`${METRICS_POLICY_ENDPOINT}/${identity}`)
            const receivedPolicy = policyResponse.data as MetricsPolicy
            dispatch(ActionCreators.receivedRequestedMetricsPolicyData(identity, receivedPolicy))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }
    async function findMetricsSchema(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        try {
            const schemasResponse = await REST.get(`${METRICS_SCHEMA_ENDPOINT}`)
            const receivedSchemas = schemasResponse.data

            dispatch(ActionCreators.receivedMetricsSchema(receivedSchemas))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }
    function changePolicyName(value: string, isValueChanged: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangePolicyName(value, isValueChanged))
    }

    function changeTargetedAssets(
        metricConfigId: GuidType,
        targetAssets: number,
        isValueChanged: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(
            ActionCreators.setChangeTargetedAssetsValue(
                metricConfigId,
                targetAssets,
                isValueChanged,
            ),
        )
    }

    function changeMetricEnabledValue(
        metricConfigId: GuidType,
        enabled: boolean,
        isValueChanged: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeEnabledValue(metricConfigId, enabled, isValueChanged))
    }

    function changeSubMetricEnabledValue(
        metricConfigId: GuidType,
        configName: string,
        enabled: boolean,
        isValueChanged: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(
            ActionCreators.setChangeEnabledSubMetricValue(
                metricConfigId,
                configName,
                enabled,
                isValueChanged,
            ),
        )
    }

    function changeDropDownValue(
        metricConfigId: GuidType,
        configName: string,
        selectedValue: string,
        isValueChanged: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(
            ActionCreators.setChangeDropDownValue(
                metricConfigId,
                configName,
                selectedValue,
                isValueChanged,
            ),
        )
    }
    function changeOnboardValue(
        metricConfigId: GuidType,
        configName: string,
        selectedValue: OnboardEngagementConfigType,
        isValueChanged: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(
            ActionCreators.setChangeOnboardValue(
                metricConfigId,
                configName,
                selectedValue,
                isValueChanged,
            ),
        )
    }
    function changeNumericValue(
        metricConfigId: GuidType,
        configName: string,
        selectedValue: number,
        isValueChanged: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(
            ActionCreators.setChangeNumericValue(
                metricConfigId,
                configName,
                selectedValue,
                isValueChanged,
            ),
        )
    }
    function changeTextConfigValue(
        metricConfigId: GuidType,
        configName: string,
        selectedValue: string,
        isValueChanged: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(
            ActionCreators.setChangeTextValue(
                metricConfigId,
                configName,
                selectedValue,
                isValueChanged,
            ),
        )
    }
    function setDefaultMetricsPolicyData(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setDefaultPolicyData())
    }

    function requestDiscardChanges(discardChanges: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestDiscardChanges(discardChanges))
    }

    const formattedMetrics: FormattedMetric[] = state.metricsSchema.map((metricSchema) => {
        const whichPolicyToUse = state.policyToCreateOrUpdate
            ? state.policyToCreateOrUpdate
            : state.selectedPolicy ?? state.selectedPolicy
        const matchingSchema = whichPolicyToUse?.metricPolicies?.find(
            (metricPolicy) => metricSchema.identity === metricPolicy.metricConfigId,
        )

        if (matchingSchema != undefined) {
            return {
                metricConfigId: matchingSchema.metricConfigId,
                policySchemaId: whichPolicyToUse?.id,
                metricName: metricSchema.name,
                metricDisplayName: metricSchema.displayName,
                metricGroup: metricSchema.metricGroup,
                policySchema: populatePolicySchemaIfExist(
                    metricSchema.policySchema,
                    matchingSchema.settings,
                ),
                enabled: matchingSchema.enabled,
                target: matchingSchema.target,
            } as FormattedMetric
        } else {
            const defaultSchema: FormattedMetric = {
                metricConfigId: metricSchema.identity,
                metricName: metricSchema.name,
                metricDisplayName: metricSchema.displayName,
                metricGroup: metricSchema.metricGroup,
                policySchema: metricSchema.policySchema,
                enabled: true,
                target: 100,
            }
            return defaultSchema
        }
    })

    async function addNewMetricsPolicyDetails(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        try {
            const response = await REST.post(METRICS_POLICY_ENDPOINT, {
                isDefault: false,
                name: state.policyToCreateOrUpdate.name,
                metricPolicies: state.policyToCreateOrUpdate.metricPolicies,
            })
            requestDataToBeFecthed(true)
            showPolicyDetailModal(PolicyType.METRICS, response.data.id)
            dispatch(ActionCreators.requestDiscardChanges(false))
            findMetricsPolicyDetails(response.data.id)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    async function updateMetricsPolicyDetails(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        try {
            const policyIdentityToUpdate = state.policyToCreateOrUpdate.id
            const response = await REST.put(
                `${METRICS_POLICY_ENDPOINT}/${policyIdentityToUpdate}`,
                {
                    isDefault: state.policyToCreateOrUpdate.isDefault,
                    name: state.policyToCreateOrUpdate.name,
                    metricPolicies: state.policyToCreateOrUpdate.metricPolicies,
                },
            )
            dispatch(ActionCreators.requestDiscardChanges(false))
            requestDataToBeFecthed(true)
            dispatch(ActionCreators.setPolicyAfterUpdate(response.data))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }
    return {
        loadingRequestedMetricsDataState: state.loadingRequestedMetricsDataState,
        changePolicyName,
        selectedContentPage: state.selectedContentPage,
        findMetricsPolicyDetails,
        selectedMetricPolicyId: state.selectedPolicy?.id,
        formattedMetrics,
        policyName: state.selectedPolicy?.name ?? '',
        isDefaultPolicy: state.selectedPolicy?.isDefault ?? false,
        setDefaultMetricsPolicyData,
        findMetricsSchema,
        addNewMetricsPolicyDetails,
        changeTargetedAssets,
        changeMetricEnabledValue,
        changeDropDownValue,
        changeOnboardValue,
        changeNumericValue,
        updateMetricsPolicyDetails,
        isMetricsUpdated: state.isMetricsUpdated,
        discardChanges: state.discardChanges,
        requestDiscardChanges,
        changeSubMetricEnabledValue,
        changeTextConfigValue,
    }
}
function populatePolicySchemaIfExist(
    policySchema: ConfigTypes[],
    settings?: Record<string, ValuesOutput>,
): ConfigTypes[] {
    return policySchema.map((policyItem) => {
        // Check if the policyItem name exists in the settings object
        if (settings && policyItem.name in settings) {
            // Update the value property based on the settings object
            return {
                ...policyItem,
                value: settings[policyItem.name] as ValuesOutput,
            } as ConfigTypes
        } else {
            // If no match is found, return the original policyItem
            return policyItem
        }
    })
}
