import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    isLoadingSelector,
    loadingStateSelector,
} from '../../../../store/state/engine-status/selectors'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {EngineStatusesLoaded} from './engine-statuses-loaded'
import {Header} from '../shared/header/header'
import {useDispatch} from 'react-redux'
import {fetchEngineStatuses} from '../../../../store/state/engine-status/action-creators'
import {lastUpdatedSelector} from '../../../../store/state/engine-status/selectors'
import {configSelector} from '../../../../store/state/config/selectors'
import {refreshSystemPageEvent} from '../../../../core/google-tag-manager/system-page/refresh-system-page-event'
import {useEffect} from 'react'
import LoadingState from '../../../../values/loading-state-enum'

export function EngineStatuses(): JSX.Element {
    const config = useTypedSelector(configSelector)
    const title: string = config.tenantDisplayName ? config.tenantDisplayName : 'Medulla'

    const isLoading = useTypedSelector(isLoadingSelector)
    const loadingState = useTypedSelector(loadingStateSelector)
    const dispatch = useDispatch()
    const lastUpdate = useTypedSelector(lastUpdatedSelector)
    function refresh() {
        dispatch(fetchEngineStatuses())
        refreshSystemPageEvent('EngineData')
    }

    useEffect(() => {
        if (loadingState === LoadingState.NotPopulated) {
            dispatch(fetchEngineStatuses())
        }
    }, [dispatch, loadingState])

    return (
        <>
            <Header
                text={`Engine status for ${title}`}
                refreshFunction={refresh}
                lastUpdate={lastUpdate}
            />
            {isLoading ? <DataLoading /> : <EngineStatusesLoaded environment={title} />}
        </>
    )
}
