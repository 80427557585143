import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {newIncidentsSelector} from '../../../../../store/state/incident-overview/selectors'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'
interface Props {
    gridLayout: GridLayout
}
export function NewIncidents({gridLayout}: Props): JSX.Element {
    const newIncindents = useTypedSelector(newIncidentsSelector)

    return (
        <ValueForType
            type="New"
            value={newIncindents}
            gridLayout={gridLayout}
            id="incident-response-headline-values-new"
        />
    )
}
