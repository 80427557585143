/**
 * @fileoverview Contains all type declarations related to OTLocation model and
 * state.
 */

import {GuidType} from '../../../../values/generic-type-defintions'
import LoadingState from '../../../../values/loading-state-enum'
import {OTLocationsState} from './ot-locations-state'

/**
 * Enum for status field of OTLocation
 */
export enum OTLocationStatus {
    /**
     * Location is active in Basal Ganglia
     */
    ACTIVE = 'active',
    /**
     * Location is inactive in Basal Ganglia
     */
    INACTIVE = 'inactive',
    /**
     * Location is removed from Basal Ganglia
     */
    REMOVED = 'removed',
}

/**
 * Represents Location from OT inventory service
 */
export interface OTLocation {
    /**
     * Identifier for OTLocation
     */
    id: GuidType
    /**
     * IMO ID or code of Location
     */
    customerCode: string
    /**
     * Equivalent to description of Location
     */
    name: string
    /**
     * Equivalent to type of Location
     */
    type: string
    /**
     * Status of OTLocation
     */
    status: OTLocationStatus
    /**
     * Creation timestamp of OTLocation in OT inventory service
     */
    createdAt: Date
    /**
     * Update timestamp of OTLocation in OT inventory service
     */
    updatedAt: Date
    /**
     * Aggregated count of hardware asset associated with OTLocation
     */
    hardwareAssetCount: number
}

/**
 * Redux state value for OTLocations
 */
export interface OTLocationsReduxState {
    /**
     * Loading state for OTLocations
     */
    loadingState: LoadingState
    /**
     * Data for OTLocations
     */
    data: OTLocation[]
}

/**
 * Initial redux state value for OTLocation
 */
export const DEFAULT_OT_LOCATIONS_STATE: OTLocationsState = {
    loadingState: LoadingState.NotPopulated,
    data: [],
    filteredData: [],
    filter: '',
}
