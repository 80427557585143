import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {LocationIdType} from '../../../../../../store/state/locations/state'
import * as Styles from './vessel-details.styled'
import {vesselDetailsCachedReselector} from '../../../reselectors/vessel-details.cached-reselector'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {FullWidthDetailsPanel} from './full-width-details-panel'
import {ReducedWidthDetailsPanel} from './reduced-width-details-panel'
import {showReducedWidthVersion} from '../../../helpers'
import {useDispatch} from 'react-redux'
import {myVesselsZoomLevelSelector} from '../../../../../../store/state/my-vessels-filters/selectors'
import {setZoomLevel} from '../../../../../../store/state/my-vessels-filters/action-creators'
import {ZOOM_LEVEL_TYPE} from '../../../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {changeZoomLevelEvent} from '../../../../../../core/google-tag-manager/my-vessels/change-zoom-level-event'

interface Props {
    id: LocationIdType
    fixedWidthContent?: boolean
}

export function VesselDetails({id, fixedWidthContent = false}: Props): JSX.Element {
    const model = useTypedSelector((state) => vesselDetailsCachedReselector(state, id))
    const zoomLevel = parseInt(useTypedSelector(myVesselsZoomLevelSelector))

    const {width} = useDimensions()
    const dispatch = useDispatch()

    function incrementZoomLevel() {
        const newZoomLevel = (zoomLevel < 4 ? zoomLevel + 1 : 1).toString() as ZOOM_LEVEL_TYPE
        dispatch(setZoomLevel(newZoomLevel))
        changeZoomLevelEvent(newZoomLevel, 'double-clicking vessel')
    }

    return (
        <Styles.VesselDetails
            threatLevel={model.threatLevel}
            onDoubleClick={() => incrementZoomLevel()}
            id={`vessel-details-${id}`}
        >
            <Styles.VesselThreatScore
                id={`threat-score-${id}`}
                score={model.score}
                threatLevel={model.threatLevel}
            >
                {model.score}
            </Styles.VesselThreatScore>
            <Styles.Details>
                {showReducedWidthVersion(width) ? (
                    <ReducedWidthDetailsPanel model={model} />
                ) : (
                    <FullWidthDetailsPanel model={model} fixedWidthContent={fixedWidthContent} />
                )}
            </Styles.Details>
        </Styles.VesselDetails>
    )
}
