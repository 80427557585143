import {createContext, Dispatch} from 'react'
import type {Action as AssetListAction} from './state/asset-list/actions'
import type {Action as AssetFormAction} from './state/asset-form/actions'
import type {Action as AssetFormOptionsAction} from './state/asset-form-options/actions'
import type {Action as PageAction} from './state/page/actions'
import LoadingState from '../../../values/loading-state-enum'
import type {DropdownOption} from '../components/dropdown'

export interface AssetList {
    state: {
        // Probably not efficient to store it this way,
        // but i don't foresee user will expand so many
        // rows. Storing as object would be O(1) as opposed to O(n)
        // when trying to access it.
        expandedAssetId: string[]
    }
    dispatch: Dispatch<AssetListAction> | undefined
}

export interface AssetOption {
    id: string
    name: string
    created_at: string
    updated_at: string
}

export type FormStringField = keyof Pick<
    AssetForm['state']['formData'],
    'name' | 'vendor' | 'model' | 'description' | 'softwareVersion'
>

export type FormDropdownField = keyof Pick<
    AssetForm['state']['formData'],
    'assetTypeID' | 'systemID' | 'zoneID' | 'softwareID' | 'communicationProtocolID'
>

export type MultiselectField = 'physicalInterfaceIDs'

export interface AssetForm {
    state: {
        formData: {
            name: string
            assetTypeID: DropdownOption
            systemID: DropdownOption
            zoneID: DropdownOption
            vendor: string
            model: string
            description: string
            physicalInterfaceIDs: DropdownOption[]
            softwareID: DropdownOption
            softwareVersion: string
            communicationProtocolID: DropdownOption
            customFields: {name: string; value: string}[]
        }
        formFieldError: {
            [key: string]: string | {name: string; value: string}[]
            assetTypeID: string
            communicationProtocolID: string
            description: string
            model: string
            name: string
            physicalInterfaceIDs: string
            softwareID: string
            softwareVersion: string
            systemID: string
            vendor: string
            zoneID: string
            customFields: {name: string; value: string}[]
        }
        formSubmitLoadingState: LoadingState
        formSubmitError: string
        // Id of asset being edited
        editingAssetId: string | null
        editingAssetIdRevision: number | null
    }
    dispatch: Dispatch<AssetFormAction> | undefined
}

export interface AssetFormOptions {
    state: {
        formOptionsLoadingState: LoadingState
        formOptions: AssetOptions
        formFieldError: {
            name: string
        }
        formSubmitError: string
        formSubmitLoadingState: LoadingState
    }
    dispatch: Dispatch<AssetFormOptionsAction> | undefined
}

export interface Page {
    state: {
        isOpenAssetCreationForm: boolean
        isDisabledAddButton: boolean
    }
    dispatch: Dispatch<PageAction> | undefined
}

interface LocationInventoryPageContext {
    assetList: AssetList
    assetForm: AssetForm
    assetFormOptions: AssetFormOptions
    page: Page
}

export const defaultLocationInventoryContextState: LocationInventoryPageContext = {
    assetList: {
        state: {
            expandedAssetId: [],
        },
        dispatch: undefined,
    },
    assetForm: {
        state: {
            formData: {
                name: '',
                assetTypeID: {value: '', label: ''},
                systemID: {value: '', label: ''},
                zoneID: {value: '', label: ''},
                vendor: '',
                model: '',
                description: '',
                physicalInterfaceIDs: [],
                softwareID: {value: '', label: ''},
                softwareVersion: '',
                communicationProtocolID: {value: '', label: ''},
                customFields: [],
            },
            formFieldError: {
                assetTypeID: '',
                communicationProtocolID: '',
                description: '',
                model: '',
                name: '',
                physicalInterfaceIDs: '',
                softwareID: '',
                softwareVersion: '',
                systemID: '',
                vendor: '',
                zoneID: '',
                customFields: [],
            },
            formSubmitLoadingState: LoadingState.NotPopulated,
            formSubmitError: '',
            editingAssetId: null,
            editingAssetIdRevision: null,
        },
        dispatch: undefined,
    },

    assetFormOptions: {
        state: {
            formOptionsLoadingState: LoadingState.NotPopulated,
            formOptions: {
                softwares: [],
                systems: [],
                zones: [],
                physicalInterfaces: [],
                communicationProtocols: [],
                assetTypes: [],
            },
            formFieldError: {
                name: '',
            },
            formSubmitLoadingState: LoadingState.NotPopulated,
            formSubmitError: '',
        },
        dispatch: undefined,
    },

    page: {
        state: {
            isDisabledAddButton: false,
            isOpenAssetCreationForm: false,
        },
        dispatch: undefined,
    },
}

export const LocationInventoryPageContext = createContext<LocationInventoryPageContext>(
    defaultLocationInventoryContextState,
)

export interface CreateOTAssetOptionReq {
    name: string
}

export type AssetOptionKey =
    | 'softwares'
    | 'zones'
    | 'systems'
    | 'physicalInterfaces'
    | 'communicationProtocols'
    | 'assetTypes'

export type AssetOptions = Record<AssetOptionKey, AssetOption[]>
