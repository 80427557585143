import {SimpleButton} from '../../../../components/filter-button/simple-button'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'

export function RighHandButtonsArea(): JSX.Element {
    const {refreshData} = usePagedIncidentsReport()
    function handlePrint() {
        window.print()
    }

    return (
        <div style={{display: 'flex', padding: '0 10px', gap: '10px'}}>
            <SimpleButton onClick={handlePrint} label="Export as PDF" />
            <SimpleButton onClick={refreshData} label="Refresh stats" />
        </div>
    )
}
