/**
 * The types of action available for OT Locations Redux store
 */
enum ActionType {
    REQUEST_LOCATIONS = '[OT LOCATIONS] REQUEST',
    SET_LOCATIONS = '[OT LOCATIONS] SET',
    FAIL_FETCH_LOCATIONS = '[OT LOCATIONS] FETCH ERROR',
    SET_FILTER = '[OT LOCATIONS] SET FILTER',
}

export default ActionType
