import {CustomerReportsNotes} from '../../../../../../values/customer-reports'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {AddNote} from '../add-note/add-note'
import {Note} from './note'
import {Container} from './notes.styled'

interface NotesProps {
    identity: GuidType
    notes: CustomerReportsNotes[]
    card?: boolean
    reportType: string
    rating: number
}
export function Notes({identity, notes, card, reportType, rating}: NotesProps): JSX.Element {
    return (
        <Container>
            {notes && notes.map((note) => <Note key={note.identity} note={note} card={card} />)}
            <AddNote
                identity={identity}
                numberOfOtherNotes={notes.length}
                reportType={reportType}
                rating={rating}
            />
        </Container>
    )
}
