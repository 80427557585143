import {CustomerReportAttachmentData} from '../../../../../values/customer-reports'
import {DownloadIcon} from './download-icon'
import * as Styles from './attachment-button.styled'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {viewAttachmentEvent} from '../../../../../core/google-tag-manager/reports/view-attachment-event'
import {useDispatch} from 'react-redux'
import {logDownloadFile} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, DownloadFileType} from '../../../../../store/state/audit-log/state'

interface AttachmentButtonsProps {
    identity: GuidType
    attachments: CustomerReportAttachmentData[] | undefined
    rating: number
    reportType: string
}
export function AttachmentButtons({
    identity,
    attachments,
    rating,
    reportType,
}: AttachmentButtonsProps): JSX.Element | null {
    const dispatch = useDispatch()
    const {downloadCustomerReports, selectedPage} = usePagedReports()

    const onDownload = (identity: GuidType, attachment: CustomerReportAttachmentData): void => {
        const fileType = getContentType(attachment.contentType)
        downloadCustomerReports(identity, attachment)
        viewAttachmentEvent(attachment.contentType, (selectedPage || 0) + 1, rating, reportType)
        fileType && dispatch(logDownloadFile(AuditLogPageType.REPORTS, identity, fileType))
    }

    if (!attachments) {
        return null
    }

    return (
        <Styles.AttachmentButtonWrapper>
            {attachments &&
                attachments.map((attachment) => (
                    <Styles.AttachmentButton
                        key={attachment.identity}
                        id={`attachment-${attachment.identity}`}
                        disabled={attachment == null}
                        onClick={() => {
                            onDownload(identity, attachment)
                        }}
                    >
                        <DownloadIcon contentType={getContentType(attachment.contentType)} />
                    </Styles.AttachmentButton>
                ))}
        </Styles.AttachmentButtonWrapper>
    )
}

function getContentType(contentType: string): DownloadFileType | null {
    if (contentType == undefined || contentType == null) {
        return null
    }
    if (contentType.toLowerCase().startsWith('application/vnd')) {
        return DownloadFileType.EXCEL
    }
    if (contentType.toLowerCase() === 'application/csv') {
        return DownloadFileType.CSV
    }
    if (contentType.toLowerCase() === 'application/pdf') {
        return DownloadFileType.PDF
    }
    return DownloadFileType.MISC
}
