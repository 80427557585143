import {BasicPaging} from '../../../../../components/paging/basic/basic-paging'
import {
    getPagesToShow,
    getPagingButtonsToShow,
} from '../../../../../components/paging/basic/paging-helpers'
import {SpacingBlank} from '../../../../../components/paging/basic/styling/spacing-blank.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'

export function IncidentsPaging(): JSX.Element {
    const {selectedPage, totalNumberOfPages, selectPage, pageSize} = usePagedIncidents()
    const {width} = useDimensions()
    if (
        selectPage == null ||
        selectedPage == undefined ||
        totalNumberOfPages == undefined ||
        pageSize == undefined
    ) {
        return <SpacingBlank />
    }

    return (
        <BasicPaging
            gtmEventPrefix="incidents"
            selectedPage={selectedPage}
            totalNumberOfPages={totalNumberOfPages}
            selectPage={selectPage}
            pagesToShow={getPagesToShow(width)}
            pageSize={pageSize}
            showButtons={getPagingButtonsToShow(width, totalNumberOfPages)}
        />
    )
}
