import {addEvent} from '../add-event'

export function clickIndividualNetworkEvent(
    checked: boolean,
    numberOfOtherNetworksSelected: number,
): boolean {
    return addEvent('networkAssetsPageClickIndividualNetworks', {
        newState: checked ? 'Selected' : 'Not selected',
        numberOfOtherNetworksSelected,
    })
}
