import {Dispatch, useContext} from 'react'
import {REST} from '../../../..'
import {warn} from '../../../../helpers/logging'
import {PagedAlertsContext} from './paged-alerts-context'
import * as ActionCreators from './state/action-creators'
import {AllActions} from './state/actions'
import {UsePagedAlertsResult} from './use-paged-alerts-output'

const COMMON_EVENTS_URL = '/api/v1/commonEvents/find'

function getData(
    dispatch: Dispatch<AllActions>,
    assetId: string,
    offset: number,
    pageSize: number,
    fromDate: string | undefined,
    toDate: string | undefined,
): void {
    REST.post(COMMON_EVENTS_URL, {
        from: fromDate,
        to: toDate,
        pagination: {offset: offset, count: pageSize},
        node: assetId,
    })
        .then((response) => {
            dispatch(
                ActionCreators.receivedRequestedPageData(
                    response.data.data,
                    response.data.totalNumberOfItems || 0,
                ),
            )
        })
        .catch((error) => dispatch(ActionCreators.setError(error)))
}

export function usePagedAlerts(): UsePagedAlertsResult {
    const {state, dispatch} = useContext(PagedAlertsContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedAlerts must be used within a PagedAlertsContext')
    }

    function setPageSize(pageSize: number): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is not defined')
            return
        }

        dispatch(ActionCreators.changePageSize(pageSize))
        getData(dispatch, state.assetId, 0, pageSize, state.fromDate, state.toDate)
    }

    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is not defined')
            return
        }

        dispatch(ActionCreators.requestInitialPageData())
        getData(dispatch, state.assetId, 0, state.pageSize, state.fromDate, state.toDate)
    }

    function selectPage(requestedPage: number | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (requestedPage == undefined) {
            warn('requestedPage is not defined')
            return
        }

        if (state.dataPages && state.dataPages.has(requestedPage)) {
            dispatch(ActionCreators.switchToCachedPageData(requestedPage))
            return
        }

        if (!state.assetId) {
            warn('state.assetId is not defined')
            return
        }

        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))
        getData(dispatch, state.assetId, offset, state.pageSize, state.fromDate, state.toDate)
    }

    function clearDateFilter(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is not defined')
            return
        }

        dispatch(ActionCreators.clearDateFilter())
        getData(
            dispatch,
            state.assetId,
            (state.selectedPage || 0) * state.pageSize,
            state.pageSize,
            undefined,
            undefined,
        )
    }

    function setDateFilter(fromDate: string | undefined, toDate: string | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is not defined')
            return
        }

        dispatch(ActionCreators.setDateFilter(fromDate, toDate))
        getData(dispatch, state.assetId, 0, state.pageSize, fromDate, toDate)
    }

    function displayDateFilter(displayDateFilter: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayDateFilter(displayDateFilter))
    }

    const hasData = state.totalNumberOfEvents != undefined
    const dataPage =
        hasData && state.selectedPage != undefined
            ? state.dataPages?.get(state.selectedPage) ?? undefined
            : undefined

    return {
        assetId: state.assetId,
        loading: state.loading,
        pageSize: state.pageSize,
        dataPage: dataPage,
        totalNumberOfEvents: state.totalNumberOfEvents,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        fromDate: state.fromDate,
        toDate: state.toDate,
        setPageSize,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        clearDateFilter,
        setDateFilter,
        showDateFilter: state.showDateFilter,
        displayDateFilter,
        //...useMemo(() => buildActionCreators(dispatch, state.assetId), [dispatch, state.assetId]),
    }
}
