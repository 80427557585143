import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'

import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {IncidentType} from '../../../pages/incidents-v3/contexts/types/incident-type'

const TYPES_ENDPOINT = '/api/v1/incidentResponses/types'
const requestIncidentTypes = (): Actions.RequestIncidentTypesAction => ({
    type: ActionType.REQUEST_INCIDENT_TYPES,
})
const setIncidentTypes = (data: IncidentType[]): Actions.ReceivedIncidentTypesAction => ({
    type: ActionType.RECEIVED_INCIDENT_TYPES,
    payload: data,
})

export function fetchIncidentTypeses(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestIncidentTypes())

        REST.get(TYPES_ENDPOINT).then((response) => {
            dispatch(setIncidentTypes(response.data))
        })
    }
}
