import type {CreateOTAssetReq} from '../../../store/state/ot-assets/state'
import type {AssetForm} from '../contexts/location-inventory-page-context'
import {StringUtils} from '../../../utils/Utils'
import _ from 'lodash'

const FIELD_REQUIRED_MESSAGE = 'Field is required'

const FIELD_IS_BETWEEN_MESSAGE = (min: number, max: number) =>
    `Field must be between ${min} and ${max} characters`

const FIELD_SPECIAL_CHARACTERS_MESSAGE =
    'Field can only contain alphanumeric characters, commas, hyphens, and periods'

export function isAlphaNumericCommaHyphenPeriod(stringToCheck: string) {
    return /^[a-zA-Z0-9., -]*$/.test(stringToCheck)
}

export function validateOTAssetFormData(asset: CreateOTAssetReq): {
    success: boolean
    errors: AssetForm['state']['formFieldError']
} {
    const errors: AssetForm['state']['formFieldError'] = {
        // required
        assetTypeID: '',
        communicationProtocolID: '',
        description: '',
        model: '',
        // required
        name: '',
        physicalInterfaceIDs: '',
        softwareID: '',
        softwareVersion: '',
        // required
        systemID: '',
        vendor: '',
        // required
        zoneID: '',
        customFields: asset.customFields.map(() => ({name: '', value: ''})),
    }

    let success = true

    if (!StringUtils.validString(asset.name)) {
        errors.name = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (!StringUtils.validString(asset.assetTypeID)) {
        errors.assetTypeID = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (!StringUtils.validString(asset.systemID)) {
        errors.systemID = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (!StringUtils.validString(asset.zoneID)) {
        errors.zoneID = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.name) &&
        !StringUtils.isBetweenMinAndMaxInclusive(asset.name, 3, 30)
    ) {
        errors.name = FIELD_IS_BETWEEN_MESSAGE(3, 30)
        success = false
    }

    // If asset name contain invalid characters
    if (!isAlphaNumericCommaHyphenPeriod(asset.name)) {
        errors.name = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.vendor) &&
        !StringUtils.isBetweenMinAndMaxInclusive(asset.vendor, 3, 30)
    ) {
        errors.vendor = FIELD_IS_BETWEEN_MESSAGE(3, 30)
        success = false
    }

    if (!isAlphaNumericCommaHyphenPeriod(asset.vendor)) {
        errors.vendor = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.model) &&
        !StringUtils.isBetweenMinAndMaxInclusive(asset.model, 3, 30)
    ) {
        errors.model = FIELD_IS_BETWEEN_MESSAGE(3, 30)
        success = false
    }

    if (!isAlphaNumericCommaHyphenPeriod(asset.model)) {
        errors.model = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.description) &&
        !StringUtils.isBetweenMinAndMaxInclusive(asset.description, 3, 50)
    ) {
        errors.description = FIELD_IS_BETWEEN_MESSAGE(3, 50)
        success = false
    }

    if (!isAlphaNumericCommaHyphenPeriod(asset.description)) {
        errors.description = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    // Not really sure the requirement on this
    // Need to check if this is valid
    // Software version could be just 1 character
    if (
        StringUtils.validString(asset.softwareVersion) &&
        !StringUtils.isBetweenMinAndMaxInclusive(asset.softwareVersion, 1, 30)
    ) {
        errors.softwareVersion = FIELD_IS_BETWEEN_MESSAGE(1, 30)
        success = false
    }

    if (!isAlphaNumericCommaHyphenPeriod(asset.softwareVersion)) {
        errors.softwareVersion = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    // check custom fields

    const nameOfCustomFields = _.map(asset.customFields, 'name')

    asset.customFields.forEach((customField, index) => {
        if (!StringUtils.validString(customField.name)) {
            errors.customFields[index].name = FIELD_REQUIRED_MESSAGE
            success = false
        }

        if (!StringUtils.validString(customField.value)) {
            errors.customFields[index].value = FIELD_REQUIRED_MESSAGE
            success = false
        }

        if (
            StringUtils.validString(customField.name) &&
            !StringUtils.isBetweenMinAndMaxInclusive(customField.name, 3, 30)
        ) {
            errors.customFields[index].name = FIELD_IS_BETWEEN_MESSAGE(3, 30)
            success = false
        }

        if (!isAlphaNumericCommaHyphenPeriod(customField.name)) {
            errors.customFields[index].name = FIELD_SPECIAL_CHARACTERS_MESSAGE
            success = false
        }
        // Check that the name is unique
        const otherFieldWithSameName = nameOfCustomFields.find((value, nameIndex) => {
            if (value === customField.name && nameIndex !== index) {
                return true
            } else {
                return false
            }
        })

        if (otherFieldWithSameName) {
            errors.customFields[index].name =
                'Duplicate name found. Please enter a unique field name'
            success = false
        }

        if (
            StringUtils.validString(customField.value) &&
            !StringUtils.isBetweenMinAndMaxInclusive(customField.value, 3, 50)
        ) {
            errors.customFields[index].value = FIELD_IS_BETWEEN_MESSAGE(3, 50)
            success = false
        }

        if (!isAlphaNumericCommaHyphenPeriod(customField.value)) {
            errors.customFields[index].value = FIELD_SPECIAL_CHARACTERS_MESSAGE
            success = false
        }
    })

    return {success, errors}
}
