/* eslint-disable @typescript-eslint/no-explicit-any */
import {forwardRef, ReactNode, ReactNodeArray, useContext, useState} from 'react'
import {AssetDetails} from '../asset-details/asset-details'
import {BoundsContext, BoundsContextProps} from '../bounds-context/bounds-context'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import {GuidType} from '../../values/generic-type-defintions'

interface Props {
    id: GuidType
    children: ReactNode | ReactNodeArray
    openGTM: () => void
    directCloseGTM: () => void
    outsideCloseGTM: () => void
}

export function AssetPopover({
    id,
    children,
    openGTM,
    directCloseGTM,
    outsideCloseGTM,
}: Props): JSX.Element {
    const {ref: layoutRef} = useContext(BoundsContext) as BoundsContextProps

    const [visible, setVisible] = useState(false)

    function onClickWrapper() {
        if (!visible) {
            openGTM()
        } else {
            directCloseGTM()
        }

        setVisible(!visible)
    }

    function onClickOutside() {
        outsideCloseGTM()
        setVisible(false)
    }

    // eslint-disable-next-line react/display-name
    const ForwardRefThreatScore = forwardRef((_props, ref: any) => {
        return (
            <div ref={ref} onClick={onClickWrapper}>
                {children}
            </div>
        )
    })

    return (
        <Tippy
            visible={visible}
            onClickOutside={onClickOutside}
            zIndex={10}
            arrow={true}
            theme="light-border"
            interactive={true}
            popperOptions={{
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {boundary: layoutRef?.current},
                    },
                ],
            }}
            content={<AssetDetails id={id} />}
            placement="right-start"
        >
            <ForwardRefThreatScore />
        </Tippy>
    )
}
