import {GuidType} from '../../../../values/generic-type-defintions'
import {AssetVersion} from '../../../software-inventory/contexts/types/software-inventory-response'
import {IncidentLinkType} from './incident-link'

export interface NetworkAssetData {
    identity: GuidType
    name: string
    mainMacAddress: string
    mainMacAddressVendor: string
    mainIpAddress: string
    state: LinkedItemState
    lastSeen: string
}

export interface UsbItemData {
    identity: GuidType
    vendorName: string
    productName: string
    deviceId: string
    deviceStatus: LinkedItemState
    lastSeenTimestamp: string
}

export interface SoftwareItemData {
    identity: GuidType
    company: string
    product: string
    label: string
    status: LinkedItemState
    lastDetected: AssetVersion
}

export interface LinkedItemData {
    incidentLinkIdentity: GuidType
    identity: GuidType
    itemType: IncidentLinkType
    label: string
    linkUrl: string
    state: LinkedItemState
    lastSeen: string
    monitored: boolean
}

export enum LinkedItemState {
    UNKNOWN = 'unknown',
    TRUSTED = 'trusted',
    BLOCKED = 'blocked',
    MONITORED = 'monitored',
    UNWANTED = 'rejected',
}
