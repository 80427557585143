import {useState} from 'react'
import {useDispatch} from 'react-redux'
import CLOSE_GUEST_EMAIL from '../../../../../@assets/icons/close-guest-email.svg'
import {assignIncidentEvent} from '../../../../../core/google-tag-manager/incidents-beta/assign-incident'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentResponse} from '../../../contexts/types/incident-response'
import {GuestEmails} from '../../../contexts/types/type-of-interfaces'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import * as Styled from './assign-to-popover.styles'
interface Props {
    id: GuidType
    assignedTo: GuidType | undefined
    assignedToName: string | undefined
    setCurrentAssignedTo: (value: string | undefined) => void
    setOpenGuestEmail: (value: boolean) => void
}
export function OpenGuestEmailEntry({
    id,
    assignedTo,
    assignedToName,
    setCurrentAssignedTo,
    setOpenGuestEmail,
}: Props): JSX.Element {
    const [searchEmail, setSearchEmail] = useState('')
    const [validEmail, setValidEmail] = useState<RegExpMatchArray | null>(null)
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const buttonDisplayText = assignedToName != undefined ? 'Reassign' : 'Assign'
    const {assignIncidentResponseTo, guestEmails, findIncident} = usePagedIncidents()
    const dispatch = useDispatch()

    function validateEmail(email: string): void {
        setValidEmail(
            email
                .toString()
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                ),
        )
    }

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setSearchEmail(e.target.value)
        validateEmail(e.target.value)
    }
    function findRecommendationFromLocation(
        guestEmails: GuestEmails[],
        findIncident: IncidentResponse | undefined,
        searchEmail: string,
    ): string[] {
        if (!findIncident) {
            return []
        }
        const activeLocation = findIncident.location
        return guestEmails
            .filter((element) => element.guestEmail.includes(searchEmail))
            .sort((a, b) => {
                const aIsActive = a.locations.find((location) => location === activeLocation)
                const bIsActive = b.locations.find((location) => location === activeLocation)
                if (aIsActive !== bIsActive) {
                    return aIsActive ? 1 : -1
                } else {
                    return a.guestEmail > b.guestEmail ? 1 : -1
                }
            })
            .map((element) => element.guestEmail)
            .slice(0, 5)
    }

    const result = findRecommendationFromLocation(guestEmails, findIncident, searchEmail)
    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            setOpenGuestEmail(false)
            assignIncidentResponseTo(id, undefined, searchEmail, undefined, dispatch)
            assignIncidentEvent(searchEmail, id)
            setSearchEmail('')
            setValidEmail(null)
        }
    }
    return (
        <Styled.GuestUserWrapper>
            <Styled.GuestSectionTitle>Assign to guest</Styled.GuestSectionTitle>
            <div>
                <Styled.Container>
                    <Styled.SearchInput
                        id="guest-email-search"
                        type="text"
                        placeholder=" Enter email address "
                        autoComplete="off"
                        value={searchEmail}
                        onFocus={() => setRecommendationOpen(true)}
                        onChange={onChangeFn}
                        onKeyPress={onEnterClick}
                        validEmail={validEmail != null || searchEmail.length === 0}
                        validBackground={validEmail != null}
                    />
                    {searchEmail.length !== 0 && (
                        <Styled.CloseGuestEmail
                            src={CLOSE_GUEST_EMAIL}
                            onClick={() => {
                                setSearchEmail('')
                                setValidEmail(null)
                                setRecommendationOpen(false)
                            }}
                            id="close-guest-email-icon"
                        />
                    )}
                </Styled.Container>
                <Styled.EmailResultsWrapper>
                    {recommendationOpen &&
                        result.map((email, index) => (
                            <Styled.EmailResults
                                key={index}
                                onClick={() => {
                                    validateEmail(email)
                                    setSearchEmail(email)
                                    setRecommendationOpen(false)
                                }}
                                id={`recomened-email-value-${email}`}
                            >
                                {email}
                            </Styled.EmailResults>
                        ))}
                </Styled.EmailResultsWrapper>
            </div>

            <Styled.ButtonsWrapper>
                <Styled.CancelButton
                    onClick={() => {
                        setCurrentAssignedTo(assignedTo)
                        setOpenGuestEmail(false)
                        setSearchEmail('')
                        setValidEmail(null)
                    }}
                    id="cancel-button"
                >
                    Cancel
                </Styled.CancelButton>
                <Styled.AssignButton
                    disabled={validEmail == null || searchEmail === assignedToName}
                    onClick={() => {
                        setOpenGuestEmail(false)
                        assignIncidentResponseTo(id, undefined, searchEmail, undefined, dispatch)
                        assignIncidentEvent(searchEmail, id)
                        setSearchEmail('')
                        setValidEmail(null)
                    }}
                    id="assign-button"
                >
                    {buttonDisplayText}
                </Styled.AssignButton>
            </Styled.ButtonsWrapper>
        </Styled.GuestUserWrapper>
    )
}
