import {CustomerReports} from '../../../../../values/customer-reports'
import {TableDataCell} from '../_styled/table-row.styled'
import {AttachmentButtons} from '../../shared/attachment-button/attachment-buttons'
interface ReportsAttachmentsProps {
    gridRow: number
    report: CustomerReports
}
export function ReportsAttachmentsTable({gridRow, report}: ReportsAttachmentsProps): JSX.Element {
    return (
        <TableDataCell gridRow={gridRow} gridColumn={9} id={`reports-doc-type_${report.identity}`}>
            <AttachmentButtons
                identity={report.identity}
                attachments={report.attachments}
                rating={report.rating}
                reportType={report.reportType}
            />
        </TableDataCell>
    )
}
