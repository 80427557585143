import {useEffect, useState} from 'react'
import {NumberConfig} from '../../../../contexts/metrics-policy/type/metrics-policy-schema'
import * as Styled from './_styled/number-config.styled'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {isEqual} from 'lodash'

interface NumberConfigItemProps {
    configItem: NumberConfig
    metricConfigId: GuidType
    disabled?: boolean
}

export function NumberConfigItem({
    configItem,
    metricConfigId,
    disabled = false,
}: NumberConfigItemProps): JSX.Element {
    const initialNumberValue = configItem.value ?? configItem.default
    const [currentNumber, setCurrentNumber] = useState(initialNumberValue)
    const {changeNumericValue, discardChanges, requestDiscardChanges} = useMetricsPolicy()

    useEffect(() => {
        if (discardChanges && !isEqual(initialNumberValue, currentNumber)) {
            setCurrentNumber(initialNumberValue)
            requestDiscardChanges(false)
        }
    }, [currentNumber, discardChanges, initialNumberValue, requestDiscardChanges])

    function incrementNumber(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        if (disabled) {
            event.preventDefault()
            return
        }
        const maxNumber = configItem.max
        if (currentNumber < maxNumber) {
            const isItChanged = !isEqual(initialNumberValue, currentNumber + 1)
            setCurrentNumber(currentNumber + 1)
            changeNumericValue(metricConfigId, configItem.name, currentNumber + 1, isItChanged)
        }
    }

    function decrementNumber(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        if (disabled) {
            event.preventDefault()
            return
        }
        const minNumber = configItem.min
        if (currentNumber > minNumber) {
            const isItChanged = !isEqual(initialNumberValue, currentNumber - 1)
            setCurrentNumber(currentNumber - 1)
            changeNumericValue(metricConfigId, configItem.name, currentNumber - 1, isItChanged)
        }
    }

    const sortValue =
        currentNumber === 0
            ? 'disabled'
            : configItem.unit
              ? `${currentNumber} ${configItem.unit}`
              : `${currentNumber}`

    return (
        <Styled.Wrapper disabled={disabled}>
            <Styled.Label>{configItem.displayName}</Styled.Label>
            <Styled.SortOrderFakeInput>
                <Styled.SortValue disabled={currentNumber === 0}>{sortValue}</Styled.SortValue>

                <Styled.Arrows>
                    <Styled.UpArrow
                        onClick={incrementNumber}
                        disabled={currentNumber === configItem.max}
                    />
                    <Styled.DownArrow
                        onClick={decrementNumber}
                        disabled={currentNumber === configItem.min}
                    />
                </Styled.Arrows>
            </Styled.SortOrderFakeInput>
        </Styled.Wrapper>
    )
}
