import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    getAnalysisTypeDisplayName,
    getTypeDisplayName,
} from '../../../../pages/metrics-beta/components/data-helper'
import {allMainMetricsTypeSelector} from '../../../../store/state/metric-types/selectors'
import {AnalysisType, MetricsBetaFilter} from '../../../../store/state/metrics-filter-beta/state'
import {StringUtils} from '../../../../utils/Utils'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'

interface DetailFilterProps {
    criteria: MetricsBetaFilter
    tooltip?: boolean
}

export function DetailFilterMetricsBeta({criteria}: DetailFilterProps): JSX.Element {
    const allMainMetricsType = useTypedSelector(allMainMetricsTypeSelector)
    const formattedMetricType = criteria.metricTypes ?? allMainMetricsType

    return (
        <DetailsWrapper>
            {criteria.metricTypes && (
                <Details id="formattedMetricType">
                    • Metrics:
                    {StringUtils.capitaliseString(
                        formattedMetricType.join(',').replace(/([A-Z])/g, ' $1'),
                    )}
                </Details>
            )}
            <Details id="analysisTypes">
                • Analysis Type: {getAnalysisTypeDisplayName(criteria.analysisTypes)}
            </Details>
            <Details id="periodForTarget">
                • Period:{' '}
                {criteria.analysisTypes === AnalysisType.TRENDS ||
                (criteria.analysisTypes === AnalysisType.WORST_PERFORMING_VESSELS &&
                    criteria.selectedViewScreenType === 'Table')
                    ? getTypeDisplayName(criteria.periodForTrend)
                    : getTypeDisplayName(criteria.periodForCommon)}
            </Details>
            {criteria.selectedViewScreenType === 'Table' && (
                <Details id="orderBy">
                    • Order By: {criteria.orderBy.column} (
                    {criteria.orderBy.isAscending ? 'Ascending' : 'Descending'})
                </Details>
            )}
            {criteria.analysisTypes === AnalysisType.SCORECARD && (
                <Details id="onlyAssetsWithIssues">
                    •{criteria.onlyAssetsWithIssues ? 'Show assets with issues' : 'Show all assets'}
                </Details>
            )}
            {criteria.assetValues != undefined && (
                <Details id="assetValues-filter">
                    • Asset values:
                    {StringUtils.capitaliseString(
                        criteria.assetValues.join(',').replace(/([A-Z])/g, ' $1'),
                    )}
                </Details>
            )}
        </DetailsWrapper>
    )
}
