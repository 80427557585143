import {BasicPaging} from '../../../../../components/paging/basic/basic-paging'
import {
    getPagesToShow,
    getPagingButtonsToShow,
} from '../../../../../components/paging/basic/paging-helpers'
import {SpacingBlank} from '../../../../../components/paging/basic/styling/spacing-blank.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'

export function Pagination(): JSX.Element {
    const {width} = useDimensions()

    const {selectedPage, totalNumberOfPages, selectPage, pageSize, isInterlinking, showFilterBar} =
        usePagedUnknownAssetsBeta()

    if (
        selectPage == null ||
        selectedPage == undefined ||
        totalNumberOfPages == undefined ||
        pageSize == undefined ||
        isInterlinking
    ) {
        return <SpacingBlank />
    }

    return (
        <BasicPaging
            gtmEventPrefix="unknownAssets"
            selectedPage={Math.floor(selectedPage)}
            totalNumberOfPages={totalNumberOfPages}
            selectPage={selectPage}
            pagesToShow={getPagesToShow(width, showFilterBar)}
            pageSize={pageSize}
            showButtons={getPagingButtonsToShow(width, totalNumberOfPages)}
        />
    )
}
