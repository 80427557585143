import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {openIncidentsSelector} from '../../../../../store/state/incident-overview/selectors'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'

interface Props {
    gridLayout: GridLayout
}
export function OpenIncidents({gridLayout}: Props): JSX.Element {
    const openIncidents = useTypedSelector(openIncidentsSelector)
    return (
        <ValueForType
            type="Open"
            value={openIncidents}
            gridLayout={gridLayout}
            id="incident-response-headline-values-open"
        />
    )
}
