import {useState} from 'react'
import {LocationExpandedButton} from './location-expanded-button/location-expanded-button'
import * as Styled from './_styled/nav-bar-content.styled'
import {useToggle} from '../../../../contexts/toggle/use-toggle'
import {LocationExpanded} from './location-expanded'

interface DashboardLinkProps {
    gtmLogger: ((trigger: string) => void) | null
}
export function DashboardLink({gtmLogger = null}: DashboardLinkProps): JSX.Element {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => setDropdownOpen(!dropdownOpen)

    const {setValue: setMenuOpen} = useToggle()
    function menuClicked() {
        if (gtmLogger != null) {
            gtmLogger('hamburgerMenu')
        }
    }
    return (
        <Styled.DashboardLinkStyle onClick={menuClicked}>
            <Styled.NavigationLinkStyle
                to="/dashboard"
                activeClassName="activeLink"
                id="navigation-dashboard"
            >
                <LocationExpandedButton dropdownOpen={dropdownOpen} toggle={toggle} />
                <div onClick={() => setMenuOpen(false)}>Dashboard</div>
            </Styled.NavigationLinkStyle>
            {dropdownOpen && <LocationExpanded />}
        </Styled.DashboardLinkStyle>
    )
}
