import {useDispatch} from 'react-redux'
import {collapseIncidentEvent} from '../../../../../../core/google-tag-manager/incidents/collapse-incident-event'
import {removeQueryParam} from '../../../../../../helpers/navigation'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {displayModal} from '../../../../../../store/state/unsaved-incident-note-alert-modal/action-creators'
import {isNoteCreationSelector} from '../../../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {INCIDENT_DETAILS} from '../../../../data-helpers'
import CLOSE_ICON from '../../../../../../@assets/icons/close-panel.svg'
import {usePagedIncidents} from '../../../../contexts/use-paged-incidents'
import {IncidentStatus} from '../../../../contexts/types/incident-status'
import {setSearchIncidentNo} from '../../../../../../store/state/incidents-filter/action-creators'
import {CloseImage} from './button.styled'
interface CloseButtonProps {
    status?: IncidentStatus
}
export function CloseButton({status}: CloseButtonProps): JSX.Element {
    const noteCreationStatus = useTypedSelector(isNoteCreationSelector)
    const {closeIncidentDetailsModal} = usePagedIncidents()
    const dispatch = useDispatch()

    function onClickWrapper() {
        if (noteCreationStatus) {
            dispatch(displayModal())
        } else {
            status && collapseIncidentEvent(status, 'table')
            removeQueryParam(INCIDENT_DETAILS)
            closeIncidentDetailsModal()
            dispatch(setSearchIncidentNo(null))
        }
    }
    return <CloseImage src={CLOSE_ICON} onClick={onClickWrapper} id="close-details-icon" />
}
