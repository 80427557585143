import {useEffect} from 'react'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'
import {setCurrentAppliedSavedFilter} from '../../store/state/saved-filters/action-creators'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {PagedNmeaInventoryProvider} from './contexts/paged-nmea-inventory-provider'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ContentContainerBottom} from '../../templates/fixed-page/content-container-bottom.styled'
import {ContentWrapper, showCards, WideContentArea} from './nmea-page.styled'
import {DataActions} from './components/data-actions'
import {Footer} from '../../components/table-view-components/footer.styled'
import {NmeaInventoryPaging} from './components/paging/nmea-paging'
import {RecordSet} from './components/paging/record-set'
import {NMEAInventoryTable} from './components/table-view/nmea-table'
import {usePagedNmeaInventory} from './contexts/use-paged-nmea-inventory'
import {NMEAInventoryCards} from './components/cards-view/nmea-inventory-cards'
import {Header} from '../../components/header/header'

export function NMEAInventoryPage(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.NMEA_INVENTORY, AuditLogAuditType.PAGE_ACCESS))
        dispatch(setCurrentAppliedSavedFilter(undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PagedNmeaInventoryProvider pageSize={10}>
            <FixedPageTemplate>
                <WideContentArea>
                    <NMEAInventory />
                </WideContentArea>
            </FixedPageTemplate>
        </PagedNmeaInventoryProvider>
    )
}

function NMEAInventory(): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const {refreshData, modalIdForNmeaDetails, closeNmeaDetailsModal} = usePagedNmeaInventory()

    useEffect(() => {
        refreshData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    return (
        <ContentWrapper id="nmea_inventory-page_content-wrapper" cardsView={showCards(width)}>
            <Header text="NMEA Inventory" />
            <DataActions />
            {showCards(width) ? <NMEAInventoryCards /> : <NMEAInventoryTable />}
            <ContentContainerBottom width={width}>
                <Footer
                    width={width}
                    onClick={() => {
                        modalIdForNmeaDetails != null && closeNmeaDetailsModal()
                    }}
                >
                    <NmeaInventoryPaging />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </ContentWrapper>
    )
}
