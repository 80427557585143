import {viewAssetPopupEvent} from '../../../../../core/google-tag-manager/my-vessels/view-asset-popup-event'
import {closeAssetPopupEvent} from '../../../../../core/google-tag-manager/my-vessels/close-asset-popup-event'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {Square} from './asset-threat-score.styled'
import {AssetPopover} from '../../../../../components/asset-popover/asset-popover'
import {Border} from './border'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {assetThreatScoreCachedReselector} from '../../reselectors/asset-threat-score.cached-reselector'

interface Props {
    id: GuidType
    showScore?: boolean
}

export function AssetThreatScore({id, showScore = false}: Props): JSX.Element {
    const model = useTypedSelector((state) => assetThreatScoreCachedReselector(state, id))

    return (
        <AssetPopover
            id={id}
            openGTM={() => viewAssetPopupEvent(model.nodeValue, model.score)}
            directCloseGTM={() => closeAssetPopupEvent(model.nodeValue, model.score, 'direct')}
            outsideCloseGTM={() => closeAssetPopupEvent(model.nodeValue, model.score, 'outside')}
        >
            <Border threatLevel={model.threatLevel} nodeValue={model.nodeValue}>
                <Square
                    id={`asset-threat-score_${id}`}
                    nodeValue={model.nodeValue}
                    score={model.score}
                    threatLevel={model.threatLevel}
                >
                    {showScore ? model.score : ''}
                </Square>
            </Border>
        </AssetPopover>
    )
}
