import {ChangeEvent, MouseEvent, useState} from 'react'
import {addNoteEvent} from '../../../../../../core/google-tag-manager/reports/add-note-event'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../../../store/state/current-user/selectors'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {usePagedReports} from '../../../../contexts/use-paged-reports'
import * as Styles from './add-notes.styled'
import {CommentEntry} from './comment-entry'
import {NoteIcon} from './note-icon'
interface AddNoteProps {
    identity: GuidType
    numberOfOtherNotes: number
    reportType: string
    rating: number
}

export function AddNote({
    identity,
    reportType,
    rating,
    numberOfOtherNotes,
}: AddNoteProps): JSX.Element {
    const placeholderText = 'Add User Notes...'

    const [newNote, setNewNote] = useState<string>('')
    const [showNoteIcon, setShowNoteIcon] = useState<boolean>(true)
    const [notePlaceholder, setNotePlaceholder] = useState<string>(placeholderText)
    const {saveReportNotes, selectedPage} = usePagedReports()
    const {user} = useTypedSelector(currentUserSelector)

    const isSaveDisabled = !newNote.length

    const noteBlurHandler = () => {
        if (isSaveDisabled) {
            setNotePlaceholder(placeholderText)
        }

        setShowNoteIcon(true)
    }
    const noteClickHandler = () => {
        setNotePlaceholder('')
        setShowNoteIcon(false)
    }
    const noteChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setNewNote(e.target.value)
    }
    const onSaveHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        saveReportNotes(identity, newNote, user)
        setNewNote('')
        setNotePlaceholder(placeholderText)
        addNoteEvent(
            reportType,
            rating,
            numberOfOtherNotes,
            newNote.length,
            (selectedPage || 0) + 1,
        )
    }
    return (
        <>
            <Styles.AddNoteBorder>
                {showNoteIcon && <NoteIcon />}

                <CommentEntry
                    id={`note-entry_${identity}`}
                    value={newNote}
                    placeholder={notePlaceholder}
                    onClick={noteClickHandler}
                    onBlur={noteBlurHandler}
                    onChange={noteChangeHandler}
                />
            </Styles.AddNoteBorder>
            <Styles.ButtonWrapper>
                <Styles.SaveButton
                    disabled={isSaveDisabled}
                    id={`add-note_${identity}`}
                    value="Add"
                    onClick={(e) => onSaveHandler(e)}
                >
                    Save
                </Styles.SaveButton>
            </Styles.ButtonWrapper>
        </>
    )
}
