import {Selected} from './selected.styled'
import {ClickableLink} from './clickable-link.styled'
import {changeFleetTimelineGraphEvent} from '../../../../../../core/google-tag-manager/dashboard/change-fleet-timeline-graph-event'

interface LinkToGraphTypeProps {
    selected: boolean
    graphType: string
    text: string
}

export function LinkToGraphType({selected, graphType, text}: LinkToGraphTypeProps): JSX.Element {
    if (selected) {
        return <Selected id={`selected_risk_timeline_graph_period_${graphType}`}>{text}</Selected>
    }

    return (
        <ClickableLink
            to={`/dashboard?graph=${graphType}`}
            id={`selectable_risk_timeline_graph_period_${graphType}`}
            onClick={() => changeFleetTimelineGraphEvent(graphType)}
        >
            {text}
        </ClickableLink>
    )
}
