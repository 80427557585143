import {addEvent} from '../add-event'
import {getRatingStars} from '../helpers/compliance-rating-helper'
import {DisplayFormatType} from '../helpers/display-format-type'

export function expandReportNotesEvent(
    reportType: string,
    rating: number,
    pageOfReport: number,
    displayFormat: DisplayFormatType,
): boolean {
    return addEvent('reportsPageExpandReportNotes', {
        reportType,
        stars: getRatingStars(rating),
        pageOfReport,
        displayFormat,
    })
}
