import {XCircle} from 'react-feather'
import {LoadingSpinner} from '../../../../components/loading/loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import * as Styled from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {MetricsTypeFilter} from './metrics-type-filter/metrics-type-filter'
import {AnalysisSelectionFilter} from './analysis-selection-filter/analysis-selection-filter'
import {ResetFilterButton} from '../../../../components/page-filter/components/shared/reset-filter/reset-filter-button'
import {metricsBetaFilterLoadingSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {resetFilter} from '../../../../store/state/metrics-filter-beta/action-creators'
import {SavedSearch} from '../../../../components/saved-search/saved-search'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {AssetsFilter} from './assets-filter/assets-filter'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {
        showFilterBar,
        displayFilterBar,
        isInactive,
        displayHighlightedFilterValue,
        highlightedFilerValue,
    } = usePagedMetricsBeta()
    if (useTypedSelector(metricsBetaFilterLoadingSelector)) {
        return (
            <Styled.Loading>
                <LoadingSpinner />
            </Styled.Loading>
        )
    }

    return (
        <Styled.FilterBarWrapper width={width} id={`${PageType.METRICS}_filter-bar`}>
            <div
                id={`${PageType.METRICS}_filter-bar_close-icon`}
                style={{position: 'absolute', right: 0, cursor: 'pointer'}}
                onClick={() => {
                    displayFilterBar(!showFilterBar)
                    displayHighlightedFilterValue('None')
                }}
            >
                <XCircle color="white" fill="blue" />
            </div>
            <Styled.FilterBarStyled>
                <FilterBarHeader title="Metrics Filters" />
                <Styled.SavedFilterContent isInactive={isInactive}>
                    <SavedSearch savedFilterPageType={SavedFilterPageType.METRICS} />
                </Styled.SavedFilterContent>
                <Styled.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.METRICS}_filter-bar_section-content-1`}
                >
                    <MetricsTypeFilter />
                </Styled.SectionContent>
                <Styled.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.METRICS}_filter-bar_section-content-2`}
                >
                    <AnalysisSelectionFilter />
                </Styled.SectionContent>
                <Styled.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.METRICS}_filter-bar_section-content-3`}
                >
                    <AssetsFilter />
                </Styled.SectionContent>
                <VesselFilters
                    pageType={PageType.METRICS}
                    highlightedFilerValueForMetrics={highlightedFilerValue === 'Vessels'}
                />
                <ResetFilterButton resetFn={resetFilter} />
            </Styled.FilterBarStyled>
        </Styled.FilterBarWrapper>
    )
}
