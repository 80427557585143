import {collapseUserDetailsEvent} from '../../../../../../core/google-tag-manager/user-management/collapse-user-details-event'
import {useVesselManagement} from '../../../../contexts/use-vessel-management'
import {ButtonText} from './button-text.styled'
import {BottomContentWrapper, Button} from './button.styled'

export function CloseButton(): JSX.Element {
    const {closeVesselDetailModal} = useVesselManagement()

    function onClickWrapper() {
        collapseUserDetailsEvent('table')
        closeVesselDetailModal()
    }

    return (
        <BottomContentWrapper>
            <Button id="close-button" onClick={onClickWrapper}>
                <ButtonText>Close</ButtonText>
            </Button>
        </BottomContentWrapper>
    )
}
