import {useDispatch} from 'react-redux'

import * as Styles from './asset-filters.styled'
import {AssetValueRow} from './asset-value-row'
import {ViewAllRow} from './view-all-row'
import {determineViewAllAssetsState} from '../../data-helper'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {toggleAllAssetsValue} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {ExpandFilterDetailsButton} from '../shared/expand-filter-details-button/expand-filter-details-button'
import {FilterBarType} from '../../../../../store/state/metrics-filter-beta/state'
import {NodeValues} from '../../../../../values/nodes/NodeData'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {AppliedFilterIndicator} from '../../../../../components/page-filter/components/shared/applied-filter-indicator/applied-filter-indicator'
import {filterByEvent} from '../../../../../core/google-tag-manager/metrics-beta/filter-by-event'

export function AssetsFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {filterExpanded, assetValues} = useTypedSelector(metricsBetaFilterSelector)

    const viewAllAssetsValue = determineViewAllAssetsState(assetValues)

    function viewAllChanged(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleAllAssetsValue(false))
            filterByEvent('View all assets value', false)
        } else if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleAllAssetsValue(true))
            filterByEvent('View all assets value', true)
        }
    }

    const numOfAppliedFilter = !assetValues ? undefined : assetValues?.length

    const showFilteringIndicator =
        filterExpanded && !filterExpanded.assetsExpanded && numOfAppliedFilter != undefined
    const showClearButton = numOfAppliedFilter != 0

    return (
        <Styles.SectionWrapper id={`${PageType.METRICS}_filter-bar_asset-filters`}>
            <ExpandFilterDetailsButton headingRow={FilterBarType.AssetSelection} />
            {showFilteringIndicator && (
                <AppliedFilterIndicator numOfAppliedFilter={numOfAppliedFilter} />
            )}
            {filterExpanded && filterExpanded.assetsExpanded && (
                <>
                    <Styles.SubTitle>Asset value</Styles.SubTitle>
                    <ViewAllRow
                        state={viewAllAssetsValue}
                        viewAllChanged={viewAllChanged}
                        showClearButton={showClearButton}
                    />
                    <AssetValueRow nodeValue={NodeValues.HIGH} />
                    <AssetValueRow nodeValue={NodeValues.MEDIUM} />
                    <AssetValueRow nodeValue={NodeValues.LOW} />
                </>
            )}
        </Styles.SectionWrapper>
    )
}
