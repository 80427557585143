import {Dispatch, useContext} from 'react'
import {PagedIncidentsReportBetaContext} from './paged-incidents-report-context'
import {UsePagedIncidentsReportResult} from './use-paged-incidents-report-output'
import * as ActionCreators from './state/action-creators'
import {warn} from '../../../helpers/logging'
import {HighlightedFilerValueType} from './types/highlighted-filter-type'
import {LocationIdType} from '../../../store/state/locations/state'
import {filteredVesselIds} from './reselector/location-selection-number-reselector'
import {REST} from '../../..'
import {INCIDENTS_ENDPOINT} from '../../vessels-beta/contexts/types/vessels-beta-endpoints'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import {incidentsReportFilterSelector} from '../../../store/state/incidents-report-filter/selectors'
import {Location} from '../../../store/state/locations/state'
import {AllActions} from './state/actions'
import {transformTypesCountsToArray} from './data-helper'
import {
    IncidentAssignmentsCounts,
    IncidentAvgPerformanceType,
    IncidentCountsType,
    IncidentTrendsType,
    IncidentVesselsPerformanceType,
} from './types/incidents-report-output'
import {IncidentResponse} from '../../incidents-v3/contexts/types/incident-response'
import {GuidType} from '../../../values/generic-type-defintions'
import {IncidentAttachmentData} from '../../incidents-v3/contexts/types/incident-attachment-data'
import downloadFile from '../../../helpers/downloadFile'
import LoadingState from '../../../values/loading-state-enum'

export function usePagedIncidentsReport(): UsePagedIncidentsReportResult {
    const {state, dispatch} = useContext(PagedIncidentsReportBetaContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error(
            'usePagedIncidentsReport must be used within a PagedIncidentsReportBetaContext',
        )
    }
    const allLocations = useTypedSelector(locationsSelector)
    const currentFilter = useTypedSelector(incidentsReportFilterSelector)
    const currentVesselFilter = useTypedSelector(vesselFilterSelector)

    function refreshData(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestInitialPageData())

        getData(
            dispatch,
            currentFilter.fromDate,
            currentFilter.toDate,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            allLocations,
        )
    }

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    function displayHighlightedFilterValue(value: HighlightedFilerValueType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setHighlightedFilterValue(value))
    }
    function setFilter(
        createdFrom: string | null,
        createdTo: string | null,
        locations: Set<LocationIdType> | undefined,
        searchVesselTagTerm: string[],
        searchVesselNameTerm: string,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setFilter())
        getData(
            dispatch,
            createdFrom,
            createdTo,
            locations,
            searchVesselTagTerm,
            searchVesselNameTerm,
            allLocations,
        )
    }
    function displayIncidentDetailsExpanded(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayIncidentDetailsExpandedAction(identity))
    }
    function downloadIncidentResponse(
        identity: GuidType,
        attachment: IncidentAttachmentData,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getAttachmentIncidentResponse(dispatch, identity, attachment)
    }

    const formattedListOfIncidents: {incidentIdentity: GuidType; incidentCode: string}[] =
        state.filteredDataContent.map((incident) => {
            return {
                incidentIdentity: incident.identity,
                incidentCode: incident.incidentCode,
            }
        })
    const incidentDetailsToBeDisplayed = state.filteredDataContent.find(
        (incident) => incident.identity === state.incidentDetailExpandedId,
    )
    const loadedIncidentsDetails =
        state.loadingPopulatedDataState.incidentsDetails === LoadingState.Loaded
    const loadedIncidentsCounts =
        state.loadingPopulatedDataState.incidentsCounts === LoadingState.Loaded
    const loadedIncidentsAssignments =
        state.loadingPopulatedDataState.incidentsAssignments === LoadingState.Loaded
    const loadedIncidentsTrends =
        state.loadingPopulatedDataState.incidentsTrends === LoadingState.Loaded
    const loadedIncidentsByAvgPerformance =
        state.loadingPopulatedDataState.incidentsAvgPerformance === LoadingState.Loaded
    const loadedIncidentsByVesselPerformance =
        state.loadingPopulatedDataState.incidentsVesselsPerformance === LoadingState.Loaded
    return {
        loadedIncidentsDetails: loadedIncidentsDetails,
        loadedIncidentsCounts: loadedIncidentsCounts,
        loadedIncidentsAssignments: loadedIncidentsAssignments,
        loadedIncidentsTrends: loadedIncidentsTrends,
        loadedIncidentsByAvgPerformance: loadedIncidentsByAvgPerformance,
        loadedIncidentsByVesselPerformance: loadedIncidentsByVesselPerformance,
        refreshData,
        showFilterBar: state.showFilterBar,
        displayFilterBar,
        isInactive: state.isInactive,
        displayHighlightedFilterValue,
        highlightedFilerValue: state.highlightedFilerValue,
        setFilter,
        totalNumberOfItemsByStatus: state.filteredDataCounts?.countsByStatus,
        totalNumberOfItemsByTypes: transformTypesCountsToArray(
            state.filteredDataCounts?.countsByType,
        ),
        listOfIncidentsCodes: formattedListOfIncidents,
        totalNumberOfItemsBySeverity: state.filteredDataCounts?.countsBySeverity,
        incidentDetailsToBeDisplayed,
        incidentDetailExpandedId: incidentDetailsToBeDisplayed?.identity,
        displayIncidentDetailsExpanded,
        downloadIncidentResponse,
        assignementsCounts: state.filteredAssignementsCounts,
        incidentsTrendsBySeverity: state.incidentsTrends,
        incidentsByAvgPerformance: state.incidentsAvgPerformance,
        incidentsByVesselPerformance: state.incidentsVesselsPerformance,
    }
}
function getData(
    dispatch: Dispatch<AllActions>,
    createdFrom: string | null,
    createdTo: string | null,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    allLocations: Location[],
): void {
    const filteredVessels = filteredVesselIds(
        allLocations,
        locations,
        searchVesselTagTerm ?? [],
        searchVesselNameTerm ?? '',
    )

    const incidentsCountsPromise = REST.post(`${INCIDENTS_ENDPOINT}/getCounts`, {
        createdFrom: createdFrom,
        createdTo: createdTo,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
    })

    const incidentsDetailsPromise = REST.post(`${INCIDENTS_ENDPOINT}/find`, {
        createdFrom: createdFrom,
        createdTo: createdTo,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        statuses: ['NEW', 'OPEN'],
    })

    const incidentsAssignmentsPromise = REST.post(
        `${INCIDENTS_ENDPOINT}/find/assignmentStatistics`,
        {
            createdFrom: createdFrom,
            createdTo: createdTo,
            locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        },
    )

    const incidentsTrendsPromise = REST.post(`${INCIDENTS_ENDPOINT}/getIncidentTrends`, {
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
    })

    const incidentsAvgPerformancePromise = REST.post(`${INCIDENTS_ENDPOINT}/performance/summary`, {
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
    })

    const incidentsVesselsPerformancePromise = REST.post(
        `${INCIDENTS_ENDPOINT}/vesselPerformance/summary`,
        {
            createdFrom: createdFrom,
            createdTo: createdTo,
            locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        },
    )

    incidentsCountsPromise
        .then((incidentsCounts) =>
            dispatch(
                ActionCreators.receivedRequestedCountsData(
                    incidentsCounts.data as IncidentCountsType,
                ),
            ),
        )
        .catch(() => {
            dispatch(ActionCreators.receivedRequestedCountsData({} as IncidentCountsType))
        })

    incidentsDetailsPromise
        .then((incidentsDetails) =>
            dispatch(
                ActionCreators.receivedRequestedDetailsData(
                    incidentsDetails.data.data as IncidentResponse[],
                ),
            ),
        )
        .catch(() => {
            dispatch(ActionCreators.receivedRequestedDetailsData([] as IncidentResponse[]))
        })

    incidentsAssignmentsPromise
        .then((incidentsAssignments) =>
            dispatch(
                ActionCreators.receivedRequestedAssignmentsData(
                    incidentsAssignments.data as IncidentAssignmentsCounts,
                ),
            ),
        )
        .catch(() => {
            dispatch(
                ActionCreators.receivedRequestedAssignmentsData({} as IncidentAssignmentsCounts),
            )
        })

    incidentsTrendsPromise
        .then((incidentsTrends) =>
            dispatch(
                ActionCreators.receivedRequestedTrendsData(
                    incidentsTrends.data as IncidentTrendsType[],
                ),
            ),
        )
        .catch(() => {
            dispatch(ActionCreators.receivedRequestedTrendsData([] as IncidentTrendsType[]))
        })

    incidentsAvgPerformancePromise
        .then((incidentsAvgPerformance) =>
            dispatch(
                ActionCreators.receivedRequestedAvgPerformanceData(
                    incidentsAvgPerformance.data as IncidentAvgPerformanceType[],
                ),
            ),
        )
        .catch(() => {
            dispatch(
                ActionCreators.receivedRequestedAvgPerformanceData(
                    [] as IncidentAvgPerformanceType[],
                ),
            )
        })

    incidentsVesselsPerformancePromise
        .then((incidentsVesselsPerformance) =>
            dispatch(
                ActionCreators.receivedRequestedVesselsPerformanceData(
                    incidentsVesselsPerformance.data as IncidentVesselsPerformanceType,
                ),
            ),
        )
        .catch(() => {
            dispatch(
                ActionCreators.receivedRequestedVesselsPerformanceData(
                    {} as IncidentVesselsPerformanceType,
                ),
            )
        })
}

function getFormattedFilteredVessels(
    allLocations: Location[],
    filteredVessels: string[],
): string[] | undefined {
    return filteredVessels.length === allLocations.length ? undefined : filteredVessels
}

function getAttachmentIncidentResponse(
    _dispatch: Dispatch<AllActions>,
    identity: GuidType,
    attachment: IncidentAttachmentData,
): void {
    REST.get(`${INCIDENTS_ENDPOINT}/${identity}/download`, {
        responseType: 'blob',
    })
        .then((resp) => resp.data.arrayBuffer())
        .then((responseArray) => {
            if (responseArray) {
                const blob = new Blob([responseArray], {type: attachment.contentType})
                downloadFile(blob, attachment.name)
            }
        })
}
