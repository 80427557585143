import {ThreatValue} from '../../../helpers/threatHelper'
import {NodeValue} from '../../../values/nodes/NodeData'
import {addEvent} from '../add-event'
import {getThreatLevel} from '../helpers/threat-level-helper'

export function assetFilterClickedEvent(
    checked: boolean,
    value: NodeValue,
    threatValue: ThreatValue,
): boolean {
    return addEvent('assetFilterClicked', {
        newState: checked ? 'Selected' : 'Not selected',
        assetValue: value,
        threatLevel: getThreatLevel(threatValue),
    })
}
