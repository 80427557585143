import {GuidType} from '../../../../../../values/generic-type-defintions'
import {FormattedMetric} from '../../../../contexts/metrics-policy/type/formatted-metric-policy'
import {ConfigTypes} from '../../../../contexts/metrics-policy/type/metrics-policy-schema'
import {DropDownConfig} from './drop-down-config'
import {NumberConfigItem} from './number-config'
import {OnBoardEngagementConfigItem} from './on-board-engagement-config'
import {OnOffSubMetricToggleConfig} from './on-off-sub-metric-toggle-config'
import {OnOffToggleConfig} from './on-off-toggle-config'
import {TargetedAssetsVolume} from './targeted-assets-volume'
import {TextConfigItem} from './text-config'

interface MetricElementProps {
    metricSchema: FormattedMetric
    defaultPolicy?: boolean
}

export function MetricElement({metricSchema}: MetricElementProps): JSX.Element {
    return (
        <>
            <OnOffToggleConfig
                label={metricSchema?.metricDisplayName}
                metricConfigId={metricSchema.metricConfigId}
                enabled={metricSchema.enabled}
                categoryLevel
            />
            {metricSchema.policySchema.map((configItem, index) => (
                <GetConfigItemDisplay
                    key={index}
                    configItem={configItem}
                    metricConfigId={metricSchema.metricConfigId}
                    disabled={!metricSchema.enabled}
                />
            ))}
            <TargetedAssetsVolume
                metricConfigId={metricSchema.metricConfigId}
                targetAssets={metricSchema.target}
                disabled={!metricSchema.enabled}
            />
        </>
    )
}

interface GetConfigItemProps {
    configItem: ConfigTypes
    metricConfigId: GuidType
    disabled?: boolean
}

function GetConfigItemDisplay({
    configItem,
    metricConfigId,
    disabled,
}: GetConfigItemProps): JSX.Element {
    switch (configItem.type) {
        case 'dropDown':
            return (
                <DropDownConfig
                    metricConfigId={metricConfigId}
                    configItem={configItem}
                    disabled={disabled}
                />
            )
        case 'number':
            return (
                <NumberConfigItem
                    metricConfigId={metricConfigId}
                    configItem={configItem}
                    disabled={disabled}
                />
            )
        case 'onOff':
            return (
                <OnOffSubMetricToggleConfig
                    metricConfigId={metricConfigId}
                    configItem={configItem}
                    disabled={disabled}
                />
            )
        case 'onboard':
            return (
                <OnBoardEngagementConfigItem
                    metricConfigId={metricConfigId}
                    configItem={configItem}
                    disabled={disabled}
                />
            )
        case 'text':
            return (
                <TextConfigItem
                    metricConfigId={metricConfigId}
                    configItem={configItem}
                    disabled={disabled}
                />
            )
        default:
            return <div />
    }
}
