import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(4)} 3px ${spacing(6)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
export const WrapperOption = styled.div`
    ${mediumSmallFont()}
    text-align-last: center;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
`
export const ValueOptions = styled.div`
    position: absolute;
    background-color: ${(props) => props.theme.incidents.dataRow.background};
    padding: 3px ${spacing(1)};
    width: 200px;
    max-height: 173px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    font-weight: ${(props) => props.theme.font.weight.normal};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
export const ValueOption = styled.div`
    ${mediumSmallFont()}
    text-align-last: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
`
