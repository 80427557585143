import produce from 'immer'
import {Action} from './actions'
import ActionType from './action-type'
import {OTLocationsState} from '../../types/ot-locations-state'
import LoadingState from '../../../../../values/loading-state-enum'
import {StringUtils} from '../../../../../utils/Utils'
import Fuse from 'fuse.js'
import {OTLocation} from '../../types/ot-inventory-response'

/**
 * Reducer for OTLocationsReduxState. Has the following effects depending on the
 * actions:
 *
 * 1. REQUEST_LOCATIONS: Set data and filteredData to empty array and loading state to
 * RequestData
 * 2. SET_LOCATIONS: Set data and filteredData using data from payload and loading state to
 * Loaded
 * 3. FAIL_FETCH_LOCATIONS: Set data and filteredData to empty array and loading state to Errored
 * 4. SET_FILTER: Set filter to payload provided and filteredData. Filter is
 * performed if the filter is valid (empty filter is considered invalid), else
 * set filteredData as data
 */
export const OTLocationsReducer = produce((draft: OTLocationsState, action: Action) => {
    switch (action.type) {
        case ActionType.REQUEST_LOCATIONS:
            draft.loadingState = LoadingState.RequestingData
            draft.data = []
            draft.filteredData = []
            return draft
        case ActionType.SET_LOCATIONS:
            draft.loadingState = LoadingState.Loaded
            draft.data = action.payload.data
            draft.filteredData = action.payload.data
            draft.fuse = createFuse(action.payload.data)
            return draft
        case ActionType.FAIL_FETCH_LOCATIONS:
            draft.loadingState = LoadingState.Errored
            draft.data = []
            draft.filteredData = []
            return draft
        case ActionType.SET_FILTER:
            draft.filter = action.payload.filter
            if (StringUtils.validString(draft.filter) && draft.fuse) {
                draft.filteredData = searchWithFuse(draft.filter, draft.fuse)
            } else {
                draft.filteredData = draft.data
            }
            return draft
        default:
            return draft
    }
})

/**
 * Returns a list of OTLocation after applying fuzzy search on the filter using
 * the provided Fuse instance.
 *
 * @param filter text to be used in filter
 * @param fuse an instance of Fuse with data and generated index
 */
export function searchWithFuse(filter: string, fuse: Fuse<OTLocation>): OTLocation[] {
    return fuse.search(filter).map((res) => res.item)
}

/**
 * Returns an instance of Fuse using the provided data and fuseOptions
 *
 * @param data a list of OTLocation to be used in filter
 */
export function createFuse(data: OTLocation[]): Fuse<OTLocation> {
    const fuseOptions = {
        isCaseSensitive: false,
        shouldSort: true,
        threshold: 0.3,
        keys: ['name', 'customerCode', 'type'],
    }
    return new Fuse(data, fuseOptions)
}
