import {createSelector} from 'reselect'

import {extendedDataIsLoadedReselector} from './extended-data-is-loaded-reselector'
import {group1DataIsLoadedReselector} from './group1-data-is-loaded-reselector'
import {group2DataIsLoadedReselector} from './group2-data-is-loaded-reselector'

export const allDataIsLoadedReselector = createSelector(
    extendedDataIsLoadedReselector,
    group1DataIsLoadedReselector,
    group2DataIsLoadedReselector,
    (extendedDataIsLoaded, group1DataIsLoaded, group2DataIsLoaded): boolean => {
        return extendedDataIsLoaded && group1DataIsLoaded && group2DataIsLoaded
    },
)
