import useTypedSelector from '../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {MetricType} from '../../contexts/types/metrics-response'
import {MetricsFrameworkCard} from './metrics-framework-card'
import {MetricsScorecardCard} from './metrics-scorecard-card'

interface Props {
    metricType: MetricType
}
export function GetMetricTypeContent({metricType}: Props): JSX.Element {
    const {selectedViewScreenType} = useTypedSelector(metricsBetaFilterSelector)

    switch (selectedViewScreenType) {
        case 'framework':
            return <MetricsFrameworkCard key={metricType} metricType={metricType} />

        case 'metrics':
            return <MetricsScorecardCard key={metricType} metricType={metricType} />

        default:
            return <div></div>
    }
}
