import {useDispatch} from 'react-redux'
import ARROW_BACK from '../../../../@assets/icons/arrow-back.svg'
import {
    filterMainFrameworkCard,
    setSelectedViewScreen,
} from '../../../../store/state/metrics-filter-beta/action-creators'
import {ViewScreenLevelType} from '../../../../store/state/metrics-filter-beta/state'
import * as Styled from './back-arrow-div.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
interface BackArrowDivProps {
    viewScreenLevel: ViewScreenLevelType
}
export function BackArrowDiv({viewScreenLevel}: BackArrowDivProps): JSX.Element {
    const dispatch = useDispatch()
    const {changePreviousPage, previousMetricTypesSelection} = usePagedMetricsBeta()
    const {selectedViewScreenType, metricTypes} = useTypedSelector(metricsBetaFilterSelector)

    function onClickWrapper(): void {
        changePreviousPage(selectedViewScreenType, metricTypes)
        dispatch(setSelectedViewScreen(viewScreenLevel))
        dispatch(filterMainFrameworkCard(previousMetricTypesSelection))
    }
    return (
        <Styled.StyledWrapper onClick={onClickWrapper} id="back-arrow-button">
            <Styled.StyledImage src={ARROW_BACK} id="back-arrow-image" />
            Back
        </Styled.StyledWrapper>
    )
}
