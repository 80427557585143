import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'

import {MonaEngineMode} from '../../../values/mona_engine_mode'

import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'

const MONA_ENGINE_MODES_URL = '/api/v1/mona/anomalyProcessingModes'

const requestMonaEngineModes = (): Actions.RequestMonaEngineModesAction => ({
    type: ActionType.REQUEST_MONA_ENGINE_MODES,
})
const setMonaEngineModes = (data: MonaEngineMode[]): Actions.ReceivedMonaEngineModesAction => ({
    type: ActionType.RECEIVED_MONA_ENGINE_MODES,
    payload: data,
})

export function fetchMonaEngineModes(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestMonaEngineModes())

        REST.get(MONA_ENGINE_MODES_URL).then((response) => {
            dispatch(setMonaEngineModes(response.data))
        })
    }
}
