import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {DisplayFormatType} from '../../../../../core/google-tag-manager/helpers/display-format-type'
import {collapseReportNotesEvent} from '../../../../../core/google-tag-manager/reports/collapse-report-notes-event'
import {expandReportNotesEvent} from '../../../../../core/google-tag-manager/reports/expand-report-notes-event'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import * as Styles from './toggle-button.styled'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface ToggleReportDetailsButtonProps {
    id: string
    rating: number
    reportType: string
    displayFormat: DisplayFormatType
}

export function ToggleReportDetailsButton({
    id,
    reportType,
    rating,
    displayFormat,
}: ToggleReportDetailsButtonProps): JSX.Element {
    const {notesExpanded, toggleNotesExpanded, selectedPage} = usePagedReports()
    const isExpanded = notesExpanded.get(id) === true
    const dispatch = useDispatch()

    function onClickToggle() {
        if (isExpanded) {
            collapseReportNotesEvent(reportType, rating, (selectedPage || 0) + 1, displayFormat)
        } else {
            expandReportNotesEvent(reportType, rating, (selectedPage || 0) + 1, displayFormat)
            dispatch(
                logDetailsOpen(AuditLogPageType.REPORTS, AuditLogAuditType.DETAIL_PANEL_ACCESS, id),
            )
        }
        toggleNotesExpanded(id)
    }
    return (
        <Styles.Button onClick={onClickToggle} id={`expand-notes-button_${id}`}>
            <Styles.IconWrapper>
                <Icon glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            </Styles.IconWrapper>
            <Styles.ButtonText>Report Details</Styles.ButtonText>
        </Styles.Button>
    )
}
