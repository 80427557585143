import {useDispatch} from 'react-redux'
import {useModalEditAsset} from '../../../../contexts/edit-asset/use-modal-edit-asset'
import * as Styled from './submit-area.styled'
import {logChangeAssetValues} from '../../../../../../store/state/audit-log/action-creators'

export function AddButton(): JSX.Element | null {
    const {
        saveEditAssetChanges,
        newHostname,
        currentHostname,
        currentAssetValue,
        newAssetValue,
        assetId,
    } = useModalEditAsset()
    const dispatch = useDispatch()

    const blocked =
        (currentHostname?.length === 0 && newHostname.length === 0) ||
        (newAssetValue === currentAssetValue && currentHostname === newHostname)
    function onClick() {
        saveEditAssetChanges(dispatch)
        let auditAssetValue = undefined
        if (newAssetValue !== currentAssetValue) {
            auditAssetValue = newAssetValue
        }
        let auditAssetHostname = null
        if (currentHostname !== newHostname) {
            auditAssetHostname = newHostname
        }
        dispatch(logChangeAssetValues(auditAssetValue, auditAssetHostname, assetId))
    }

    return (
        <Styled.ButtonStyled disabled={blocked} id="add-button" onClick={onClick}>
            Save
        </Styled.ButtonStyled>
    )
}
