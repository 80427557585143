import {useDispatch} from 'react-redux'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {StatusRowWrapper} from './usb-device-status-filter.styled'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {DeviceStatus} from '../../../../../store/state/usb-inventory-filter/state'
import {usbInventoryFilterSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {allUsbDeviceStatus} from '../../data-helpers'
import {toggleUsbDeviceStatus} from '../../../../../store/state/usb-inventory-filter/action-creators'
import {StringUtils} from '../../../../../utils/Utils'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

interface Props {
    statusLabel: DeviceStatus
}
export function UsbDeviceStatusRow({statusLabel}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {selectedUsbDeviceStatus} = useTypedSelector(usbInventoryFilterSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    function isChecked(givenValue: DeviceStatus): boolean {
        try {
            return !selectedUsbDeviceStatus ? true : selectedUsbDeviceStatus.includes(givenValue)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error})
            return true
        }
    }

    function setChecked(currentSelectedUsbDeviceStatus: DeviceStatus, newValue: boolean): void {
        dispatch(
            toggleUsbDeviceStatus(allUsbDeviceStatus, currentSelectedUsbDeviceStatus, newValue),
        )
    }

    const label =
        statusLabel === DeviceStatus.rejected
            ? 'Unwanted'
            : StringUtils.capitaliseString(statusLabel)

    return (
        <StatusRowWrapper>
            <Checkbox
                id={`${PageType.USB_DEVICES}_filter-bar_status-filter_${statusLabel}`}
                label={label}
                checked={isChecked(statusLabel)}
                onChange={(newValue) => setChecked(statusLabel, newValue)}
                isInactive={isInactive ?? false}
            />
        </StatusRowWrapper>
    )
}
