import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {ReactNode} from 'react'
import {useDispatch} from 'react-redux'
import {changeNetworkAssetsSortOrderEvent} from '../../../../../core/google-tag-manager/unknown-assets-beta/change-sort-order-event'
import {removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setSortColumn} from '../../../../../store/state/network-assets-filter/action-creators'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import LoadingState from '../../../../../values/loading-state-enum'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {NETWORK_ASSET_DETAILS} from '../../helpers/data-helpers'
import {TableHeaderCell, TableHeaderContent, IconStyled} from './_styled/header-column.styled'
import {
    NetworkAssetsSortType,
    SortColumnType,
} from '../../../../../store/state/network-assets-filter/state'

const getNewSortByDirection = (
    newlySelectedColumn: string,
    currentSortColumn: SortColumnType,
): boolean => {
    if (newlySelectedColumn !== currentSortColumn.column) {
        return false
    }
    switch (currentSortColumn.isAscending) {
        case true:
            return false
        case false:
            return true
        default:
            return false
    }
}
interface SortableColumnProps {
    gridColumn: number
    text: string
    colName: NetworkAssetsSortType
    children?: ReactNode
}
export function SortableColumn({
    gridColumn,
    text,
    colName,
    children,
}: SortableColumnProps): JSX.Element {
    const dispatch = useDispatch()
    const {loadingState, orderBy} = useTypedSelector(networkAssetsFilterSelector)
    const {closeNetworkAssetDetailsModal} = usePagedUnknownAssetsBeta()
    const isLoading =
        loadingState === LoadingState.NotPopulated || loadingState === LoadingState.RequestingData
    function setSortBy(selectedColumn: NetworkAssetsSortType) {
        const newSortColumn: SortColumnType = {
            isAscending: getNewSortByDirection(selectedColumn, orderBy),
            column: selectedColumn,
        }
        dispatch(setSortColumn(newSortColumn))
        changeNetworkAssetsSortOrderEvent(selectedColumn, orderBy.isAscending)
        removeQueryParam(NETWORK_ASSET_DETAILS)
        closeNetworkAssetDetailsModal()
    }

    return (
        <TableHeaderCell id={`sortable-column_${colName}`} gridColumn={gridColumn} selectable>
            <TableHeaderContent
                onClick={() => setSortBy(colName)}
                active={!isLoading && orderBy.column === colName}
                id={`text_${colName}`}
            >
                {text}
                <IconStyled
                    arrowDec={orderBy.column === colName && !orderBy.isAscending}
                    id={`arrow-icon_${colName}`}
                >
                    {!isLoading && <Icon width={12} height={17} glyph="Arrow" />}
                </IconStyled>
            </TableHeaderContent>
            {children}
        </TableHeaderCell>
    )
}
