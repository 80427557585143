import moment from 'moment'
import {useDispatch} from 'react-redux'
import {getTimeRangeLabel} from '../../../../../core/google-tag-manager/helpers/time-range-label-helper'
import {selectToTimePeriodEvent} from '../../../../../core/google-tag-manager/reports/select-to-time-period-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setSelectedToTimeRange} from '../../../../../store/state/reports-filter/action-creators'
import {reportsFilterSelector} from '../../../../../store/state/reports-filter/selectors'
import {FilterTimeRangeSelectOptionProps} from '../../../contexts/types/filter-time-range'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import {getReportMonths} from '../../data-helpers'

import * as Styles from './time-period.styled'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function TimePeriodTo(): JSX.Element {
    const dispatch = useDispatch()
    const {selectedToTimeRange} = useTypedSelector(reportsFilterSelector)
    const {earliestPeriodEndDate, latestPeriodEndDate} = usePagedReports()
    const startDate = earliestPeriodEndDate ? moment(earliestPeriodEndDate) : moment()
    const endDate = latestPeriodEndDate ? moment(latestPeriodEndDate) : moment()

    const defaultSelectedToTimeRange: FilterTimeRangeSelectOptionProps[] = getReportMonths(
        startDate,
        endDate,
    )

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        selectToTimePeriodEvent(
            getTimeRangeLabel(defaultSelectedToTimeRange, selectedToTimeRange),
            getTimeRangeLabel(defaultSelectedToTimeRange, event.target.value),
        )
        dispatch(setSelectedToTimeRange(event.target.value))
    }

    return (
        <Styles.TimePeriodContent id={`${PageType.REPORTS}_filter-bar_TimePeriodTo-filter`}>
            <Styles.Label>Time Period To:</Styles.Label>
            <Styles.Input>
                <Styles.Select
                    onChange={onChangeWrapper}
                    value={selectedToTimeRange}
                    id={`${PageType.REPORTS}_filter-bar_selectedToTimeRange-value`}
                >
                    <option
                        key="default-option"
                        value=""
                        id={`${PageType.REPORTS}_filter-bar_selectedToTimeRange_default-option`}
                    >
                        - All -
                    </option>
                    {defaultSelectedToTimeRange.map(({value, label}) => (
                        <option
                            key={value}
                            value={value}
                            id={`${PageType.REPORTS}_filter-bar_selectedToTimeRange_${value}-option`}
                        >
                            {label}
                        </option>
                    ))}
                </Styles.Select>
            </Styles.Input>
        </Styles.TimePeriodContent>
    )
}
