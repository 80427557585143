import {useEffect} from 'react'
import {batch, useDispatch} from 'react-redux'
import {useLocation} from 'react-router'
import {Switch, Route, Redirect} from 'react-router-dom'
import useTypedSelector from '../hooks/use-typed-selector'
import {LoginPage} from '../pages/login-v2/login-page'
import {SystemReinitialising} from '../pages/system-reinitialising/system-reinitialising'
import {
    hasErrorSelector,
    logInStatusSelector,
    userLogInFlowSelector,
} from '../store/state/session-data/selectors'
import {loggedOut, loggingIn} from '../store/state/session-data/action-creators'
import {hasAuthTokenSelector} from '../store/state/auth/selectors'
import {LoginStatus} from '../store/state/session-data/state'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {oauthClientIdsSelector} from '../store/state/config/selectors'

export function UnauthenticatedRoutes(): JSX.Element | null {
    const dispatch = useDispatch()
    const location = useLocation()

    const loginStatus = useTypedSelector(logInStatusSelector)
    const hasError = useTypedSelector(hasErrorSelector)
    const hasAuthToken = useTypedSelector(hasAuthTokenSelector)
    const loginFlow = useTypedSelector(userLogInFlowSelector)
    const oauthClientIds = useTypedSelector(oauthClientIdsSelector)
    const rebuildingRedux =
        loginFlow === false && loginStatus != LoginStatus.LOGGING_OUT && !hasError

    useEffect((): void => {
        if (loginStatus === LoginStatus.UNKNOWN) {
            if (hasAuthToken) {
                dispatch(loggingIn())
            } else {
                batch(() => {
                    dispatch(loggedOut())
                })
            }
        }
    }, [dispatch, hasAuthToken, loginStatus])

    if (loginStatus === LoginStatus.UNKNOWN) {
        return null
    }

    if (rebuildingRedux) {
        return <SystemReinitialising />
    }

    return (
        <Switch>
            <Route path="/login" render={() => <LoginPage admin={false} />} />
            <Route
                path="/adminLogin"
                render={() => (
                    <GoogleOAuthProvider clientId={oauthClientIds?.google ?? ''}>
                        <LoginPage admin={true} />
                    </GoogleOAuthProvider>
                )}
            />
            <Route
                path="/"
                render={() => <Redirect to={{pathname: '/login', state: {from: location}}} />}
            />
        </Switch>
    )
}
