import * as Styles from './watched-incidents.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {incidentsFilterWatchIncidentsToSelector} from '../../../../../store/state/incidents-filter/selectors'
import {useDispatch} from 'react-redux'
import {watchIncidentsOfCurrentUser} from '../../../../../store/state/incidents-filter/action-creators'
import {watchFilterIncidentEvent} from '../../../../../core/google-tag-manager/incidents-beta/watch-filter-incident-event'
export function WatchedIncidents(): JSX.Element {
    const watchedByCurrentUser = useTypedSelector(incidentsFilterWatchIncidentsToSelector)
    const dispatch = useDispatch()
    function onClickWrapper() {
        if (watchedByCurrentUser == null || watchedByCurrentUser === false) {
            dispatch(watchIncidentsOfCurrentUser(true))
        } else {
            dispatch(watchIncidentsOfCurrentUser(false))
        }
        watchFilterIncidentEvent(watchedByCurrentUser)
    }
    const action = watchedByCurrentUser ? 'Unwatch' : 'Watch'

    return (
        <Styles.Wrapper id="watchedIncidents-filter-wrapper">
            <Styles.Label>My watched incidents:</Styles.Label>
            <Styles.Button id="watch-incidents" onClick={onClickWrapper}>
                <Icon glyph={action} height={16} width={16} />
                {action === 'Watch' ? (
                    <Styles.Text id="watchedIncidents-Watched-filter">
                        Watched incidents
                    </Styles.Text>
                ) : (
                    <Styles.Text id="watchedIncidents-All-filter">Show All incidents</Styles.Text>
                )}
            </Styles.Button>
        </Styles.Wrapper>
    )
}
