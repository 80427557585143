import {useDispatch} from 'react-redux'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {filterByDeviceTypeEvent} from '../../../../../core/google-tag-manager/usb-devices/filter-by-device-type-event'
import * as Styles from './usb-type-filter.styled'
import {setSearchedUSBType} from '../../../../../store/state/usb-inventory-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usbInventoryFilterSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {USBDeviceType} from '../../../../../store/state/usb-inventory-filter/state'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function USBTypeFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {searchedType} = useTypedSelector(usbInventoryFilterSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    function isChecked(givenValue: USBDeviceType): boolean {
        try {
            return searchedType.includes(givenValue)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error})
            return true
        }
    }

    function setChecked(givenValue: USBDeviceType, checked: boolean): void {
        if (checked) {
            dispatch(setSearchedUSBType([...searchedType, givenValue]))
            filterByDeviceTypeEvent([...searchedType, givenValue])
        } else {
            const newSearchType = searchedType?.filter((element) => element !== givenValue)
            dispatch(setSearchedUSBType(newSearchType))
            filterByDeviceTypeEvent(newSearchType)
        }
    }
    return (
        <Styles.Wrapper id={`${PageType.USB_DEVICES}_filter-bar_usb-type-filter`}>
            <Styles.Label>Type:</Styles.Label>
            <Styles.Selections>
                <Checkbox
                    id={`${PageType.USB_DEVICES}_filter-bar_searched-usb-type-storage`}
                    label="Storage"
                    checked={isChecked('storage')}
                    onChange={(newvalue) => {
                        setChecked('storage', newvalue)
                    }}
                    isInactive={isInactive}
                />
                <Checkbox
                    id={`${PageType.USB_DEVICES}_filter-bar_searched-usb-type-other`}
                    label="Other"
                    checked={isChecked('other')}
                    onChange={(newvalue) => {
                        setChecked('other', newvalue)
                    }}
                    isInactive={isInactive}
                />
            </Styles.Selections>
        </Styles.Wrapper>
    )
}
