import {useEffect} from 'react'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {viewPageEvent} from '../../core/google-tag-manager/software-inventory/view-page-event'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import LoadingState from '../../values/loading-state-enum'
import {Content} from './components/content'
import {showFilterBarOnScreen} from './components/data-helpers'
import {CloseFilterButton} from './components/filter-bar/close-filter-button/close-filter-button'
import {FilterBar} from './components/filter-bar/filter-bar'
import {PagedSoftwareInventoryProvider} from './contexts/paged-software-inventory-provider'
import {usePagedSoftwareInventory} from './contexts/use-paged-software-inventory'
import * as Styled from './software-inventory.styled'
import useTypedSelector from '../../hooks/use-typed-selector'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../store/state/audit-log/state'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {useDispatch} from 'react-redux'
import {softwareInventoryFilterLoadingStateSelector} from '../../store/state/software-inventory-filter/selectors'
import {
    fetchSoftwareInventoryFilter,
    resetFilter,
} from '../../store/state/software-inventory-filter/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {StrictMode} from '../../store/state/users/state'
import {savedSoftwareInventoryFiltersSelector} from '../../store/state/saved-filters/selectors'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import {
    setCurrentAppliedSavedFilter,
    setSavedFilterPageType,
} from '../../store/state/saved-filters/action-creators'
import {SoftwareChangeStatusModal} from './components/software-details-modal/trust-software-modal'
import {ContactSocModal} from '../../components/contact-soc-modal/contact-soc-modal'
import {isModalOpenSelector} from '../../store/state/extended-data-request/selectors'
import {queryStringObjectSelector} from '../../store/routerSelectors'
import {GuidType} from '../../values/generic-type-defintions'
import {Header} from '../../components/header/header'

export function SoftwareInventoryPage(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.SOFTWARE_INVENTORY, AuditLogAuditType.PAGE_ACCESS))
        viewPageEvent()
        dispatch(setCurrentAppliedSavedFilter(undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PagedSoftwareInventoryProvider pageSize={10}>
            <SoftwareInventory />
        </PagedSoftwareInventoryProvider>
    )
}

function SoftwareInventory(): JSX.Element {
    const {
        showFilterBar,
        displayFilterBar,
        showChangeSoftwareStatusModalDisplay,
        modalIdForSoftwareDetails,
    } = usePagedSoftwareInventory()
    const loadingFilterState = useTypedSelector(softwareInventoryFilterLoadingStateSelector)
    const {width} = useDimensions()
    const showFilterBarOn = showFilterBarOnScreen(width)
    const dispatch = useDispatch()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const savedFilters = useTypedSelector(savedSoftwareInventoryFiltersSelector)
    const isExpandedDataRequestModalOpen = useTypedSelector(isModalOpenSelector)

    const queryString = useTypedSelector(queryStringObjectSelector)
    const queryId = queryString?.softwareDetails as GuidType
    const externalGuid = modalIdForSoftwareDetails == null && queryId != undefined
    const headerText = 'Software Inventory'

    useEffect(() => {
        dispatch(setPageType(PageType.SOFTWARE_INVENTORY))
        dispatch(setSavedFilterPageType(SavedFilterPageType.SOFTWARE_INVENTORY))
        if (externalGuid) {
            dispatch(resetFilter())
        } else {
            dispatch(
                fetchSoftwareInventoryFilter(
                    savedFilters,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode, externalGuid])

    if (!showFilterBarOn && showFilterBar) {
        return (
            <ScrollablePageTemplate onClick={() => displayFilterBar(false)}>
                <Header width={width} text={headerText} />
                <Styled.FilterBarWrapper
                    width={width}
                    onClick={(e) => e.stopPropagation()}
                    id="filter-bar_wrapper"
                >
                    <FilterBar />
                    <Styled.BottomCloseFilterBar>
                        <CloseFilterButton text="Close Filter" />
                    </Styled.BottomCloseFilterBar>
                </Styled.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <FixedPageTemplate>
                <Header width={width} text={headerText} />
                <Styled.WideContentArea width={width} fullHeight={!showFilterBar}>
                    {showFilterBar && (
                        <Styled.LHFilterBar id="filter-bar_wrapper">
                            <FilterBar />
                        </Styled.LHFilterBar>
                    )}
                    {loadingFilterState === LoadingState.Loaded && <Content />}
                </Styled.WideContentArea>
                {showChangeSoftwareStatusModalDisplay && <SoftwareChangeStatusModal />}
                {isExpandedDataRequestModalOpen && <ContactSocModal />}
            </FixedPageTemplate>
        )
    }
}
