import {addEvent} from '../add-event'
type UserType = 'Medulla User' | 'Guest email'
export function selectAssignedToEvent(userType: UserType, assignedTo: string | undefined): boolean {
    return addEvent('incidentPageSelectAssignedTo', {
        filterByAssignedTo: {
            userType: userType,
            assignedTo: assignedTo ? assignedTo : 'All',
        },
    })
}
