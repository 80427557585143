import {UserData} from '../../../store/state/current-user/state'
import {GuidType} from '../../../values/generic-type-defintions'
import {hex_sha256} from './sha-256-helper'

function getUserIdPrefix(userEmail: string): string {
    if (!userEmail || userEmail.length === 0) {
        return 'ex'
    }

    const emailAddressToCheck = userEmail.toLowerCase()
    return emailAddressToCheck.includes('cyberowl.io') || emailAddressToCheck.includes('cyberowluk')
        ? 'in'
        : 'ex'
}

export function getGtmUserIdentifier(
    currentUser: UserData,
    unauthenticatedGTMUserIdentifier: GuidType,
    gfcFlag: boolean,
): string {
    if (!currentUser || currentUser.email.length === 0) {
        return unauthenticatedGTMUserIdentifier
    }
    return gfcFlag ? '' : `${getUserIdPrefix(currentUser.email)}_${hex_sha256(currentUser.email)}`
}
