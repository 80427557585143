import {Card} from './_styled/card.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {RowActions} from './_styled/row-actions.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {TitleRow} from './_styled/title-row.styled'
import {IncidentDetails} from './_styled/incident-details.styled'
import {Event} from './event/event'
import {IncidentNumber, IncidentTitleCell, SubTitle} from './_styled/incident-card.styled'
import {DataRow} from './_styled/data-row.styled'
import {DataCell} from './_styled/data-cell.styled'
import {SeverityLevelSelectable} from '../shared/severity-level/severity-level-selectable'
import {IncidentStatusValue} from '../shared/incident-status/incident-status'
import {IncidentType} from './incident-type/incident-type'
import {ToggleDetailsButton} from './details/toggle-details-button'
import {narrowLayoutFn} from './grid-helper'
import {ToggleProvider} from '../../../../contexts/toggle/toggle-provider'
import {IncidentDetailsExpanded} from './incident-details-expanded/incident-details-expanded'
import {GuidType} from '../../../../values/generic-type-defintions'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {IncidentResponse} from '../../contexts/types/incident-response'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {rolesSelector} from '../../../../store/state/roles/selectors'
import {usersSelector} from '../../../../store/state/users/selectors'
import {IncidentOutputModel} from '../../models/incident.model'
import {AssignedToCell} from '../shared/assigned-to/assigned-to-cell'
import {WatchListButton} from '../shared/row-actions/watchlist-button/watchlist-button'
import {vesselTagsForLocationsSelector} from '../../../../store/state/vessel-tags/selectors'
import {VesselDataCardCell} from './vessel-data-card-cell'

interface IncidentCardProps {
    incident: IncidentResponse
}

export function IncidentCard({incident}: IncidentCardProps): JSX.Element {
    const {width} = useDimensions()
    const locationMap = useTypedSelector(locationMapSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const users = useTypedSelector(usersSelector)
    const vesselTags = useTypedSelector(vesselTagsForLocationsSelector)
    const model = new IncidentOutputModel(incident, locationMap, userRoles, users, vesselTags)
    const fixedWidthReducedText = width > 850
    const narrowLayout = narrowLayoutFn(width)

    const queryString = useTypedSelector(queryStringObjectSelector)
    const incidentModalId = queryString?.incidentDetails as GuidType

    const detailsExpanded = incidentModalId === model.id

    return (
        <Card id={`card_${model.id}`} incidentStatus={model.status}>
            <IncidentDetails incidentStatus={model.status}>
                <TitleRow width={width}>
                    {narrowLayout && (
                        <DataCell gridArea="INCIDENT-TYPE" noBorder={true}>
                            <IncidentType id={`type-value_${model.id}`} incidentType={model.type} />
                        </DataCell>
                    )}
                    <IncidentNumber
                        id={`incident-number-value_${model.id}`}
                        gridArea="INCIDENT-NUMBER"
                        width={width}
                    >
                        {model.number}
                    </IncidentNumber>
                    <IncidentTitleCell id={`title-value_${model.id}`} gridArea="TITLE">
                        {model.title}
                    </IncidentTitleCell>
                    <VesselDataCardCell model={model} />
                </TitleRow>
                <DataRow width={width}>
                    {!narrowLayout && (
                        <DataCell gridArea="INCIDENT-TYPE">
                            <SubTitle>Incident type:</SubTitle>
                            <IncidentType id={`type-value_${model.id}`} incidentType={model.type} />
                        </DataCell>
                    )}
                    <DataCell gridArea="STATUS">
                        <SubTitle>Status:</SubTitle>
                        <ToggleProvider>
                            <IncidentStatusValue id={model.id} incidentStatus={model.status} />
                        </ToggleProvider>
                    </DataCell>
                    <DataCell gridArea="SEVERITY">
                        <SubTitle>Severity:</SubTitle>
                        <ToggleProvider>
                            <SeverityLevelSelectable
                                id={model.id}
                                incidentSeverity={model.severity}
                            />
                        </ToggleProvider>
                    </DataCell>
                    <DataCell gridArea="ASSIGNED" noBorder={narrowLayout}>
                        <SubTitle>Assigned to:</SubTitle>
                        <ToggleProvider>
                            <AssignedToCell
                                id={model.id}
                                assignedTo={model.assignedTo}
                                assignedToName={model.assignedToName}
                                guestAssignedToEmail={model.guestAssignedToEmail}
                                locationEmailAddress={model.vesselEmail}
                                isAssignedIncidentEmail={model.assignedToVesselEmail}
                                isAssignedToVesselEmail={model.assignedToVesselEmail}
                                isOnboardDashboard={true}
                            />
                        </ToggleProvider>
                    </DataCell>
                    <Event
                        gridArea="RAISED"
                        id={`raised-value_${model.id}`}
                        title="Raised:"
                        data={model.raised}
                        titleOnSameLine={fixedWidthReducedText}
                        lastElement={narrowLayout}
                    />
                    <Event
                        gridArea="UPDATED"
                        id={`updated-value_${model.id}`}
                        title="Updated:"
                        data={model.updated}
                        titleOnSameLine={fixedWidthReducedText}
                        lastElement={true}
                    />
                </DataRow>
                <RowActions>
                    <ToggleDetailsButton
                        id={model.id}
                        status={model.status}
                        numberOfNewItems={model.numberOfNewItems}
                    />
                    <WatchListButton incidentId={model.id} />
                </RowActions>
            </IncidentDetails>
            {detailsExpanded && <IncidentDetailsExpanded incident={incident} />}
        </Card>
    )
}
