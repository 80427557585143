import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {changeIncidentStateEvent} from '../../../../../core/google-tag-manager/incidents/change-incident-state-event'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {addAnnotation, displayStatus} from '../../../data-helpers'
import {Square, StatusAnnotation} from './incident-status.styled'
import {DropdownContentStyle, Label, Option, Options} from './status-update-popup.styles'

interface Props {
    id: GuidType
    currentStatus: IncidentStatus
    avaliableStatuses: IncidentStatus[]
}

export function StatusUpdatePopover({id, currentStatus, avaliableStatuses}: Props): JSX.Element {
    const {
        changeIncidentResponseStatus,
        reopenClosedMonitorIncident,
        showCloseMonitorIncidentModal,
        findIncident,
    } = usePagedIncidents()
    const {width} = useDimensions()
    const dispatch = useDispatch()

    const noItems = findIncident?.links?.length === 0
    const formattedAvailableStatuses = noItems
        ? avaliableStatuses.filter((status) => status !== IncidentStatus.CLOSED_MONITOR)
        : avaliableStatuses

    const links = findIncident?.links.map((link) => link.identity)

    function updateIncidentStatus(id: GuidType, status: IncidentStatus): void {
        if (currentStatus === IncidentStatus.CLOSED_MONITOR && links !== undefined) {
            reopenClosedMonitorIncident(id, links, dispatch)
        } else if (status === IncidentStatus.CLOSED_MONITOR) {
            showCloseMonitorIncidentModal(true)
        } else {
            changeIncidentStateEvent(currentStatus, status)
            changeIncidentResponseStatus(id, status, dispatch)
        }
    }

    return (
        <DropdownContentStyle width={width}>
            <Options width={width}>
                {formattedAvailableStatuses.map((status) => (
                    <Option key={status}>
                        <Label
                            htmlFor={`update-button_${status}`}
                            id={`update-label_${status}-${id}`}
                        >
                            <Square
                                incidentStatus={status}
                                id={`update-square_${status}-${id}`}
                                onClick={() => updateIncidentStatus(id, status)}
                            >
                                {displayStatus(status)} <br />
                                <StatusAnnotation id={`update-annotation_${status}-${id}`}>
                                    {addAnnotation(status)}
                                </StatusAnnotation>
                            </Square>
                        </Label>
                    </Option>
                ))}
            </Options>
        </DropdownContentStyle>
    )
}
