import {useEffect, useState} from 'react'
import {useSpring} from 'react-spring'
import {removeMultipleQueryParam} from '../../helpers/navigation'
import {queryStringObjectSelector} from '../../store/routerSelectors'
import Overlay from '../shared-components/overlay'
import Header from './components/header/header'
import {ModalDialog} from '../shared-components/modal-dialog.styled'
import {ModalContent} from './components/modal-content'
import {GuidType} from '../../values/generic-type-defintions'
import {viewPageEvent} from '../../core/google-tag-manager/asset-modal/view-page-event'
import useTypedSelector from '../../hooks/use-typed-selector'

function AssetDetailsModal(): JSX.Element {
    const [closing, setClosing] = useState(false)
    const queryString = useTypedSelector(queryStringObjectSelector)
    const assetId = queryString?.assetDetails as GuidType

    useEffect(() => {
        viewPageEvent()
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const opacityAnimation: any = useSpring({
        opacity: closing ? 0 : 1,
        from: {opacity: closing ? 1 : 0.7},
        onRest: () => {
            if (closing) {
                removeMultipleQueryParam(['assetDetails', 'category', 'fromDate', 'toDate'])
            }
        },
    })

    return (
        <Overlay
            closing={closing}
            onClick={() => {
                setClosing(true)
            }}
            z={900}
        >
            <ModalDialog style={opacityAnimation} onClick={(e) => e.stopPropagation()}>
                <Header assetId={assetId} />
                <ModalContent assetId={assetId} />
            </ModalDialog>
        </Overlay>
    )
}

export default AssetDetailsModal
