import {LocationIdType} from '../../../../../store/state/locations/state'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentResponsePolicy} from '../../incidents-policy/type/incident-response-policy'
import {MetricsPolicy} from '../../metrics-policy/type/metrics-policy'
import {PolicyType} from '../type/policy-management-state'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {EnforcementPolicy} from '../../enforcement-policy/type/enforcement-policy'

export function requestAllPolicyData(): Actions.RequestAllPolicyDataAction {
    return {type: ActionType.REQUEST_ALL_POLICY_DATA_ACTION}
}

export function receivedAllPolicyData(payload: {
    type: PolicyType
    data: IncidentResponsePolicy[] | MetricsPolicy[] | EnforcementPolicy[]
}): Actions.ReceivedAllPolicyDataAction {
    return {
        type: ActionType.RECEIVE_ALL_POLICY_DATA_ACTION,
        payload,
    }
}

export function showPolicyDetailModal(
    type: PolicyType,
    policyId: GuidType,
): Actions.ShowPolicyDetailModalAction {
    return {
        type: ActionType.SHOW_POLICY_DETAIL_MODAL,
        payload: {type, policyId},
    }
}

export function closePolicyDetailModal(): Actions.ClosePolicyDetailModalAction {
    return {
        type: ActionType.CLOSE_POLICY_DETAIL_MODAL,
    }
}

export function addNewPolicy(policyType: PolicyType): Actions.AddNewPolicyAction {
    return {type: ActionType.ADD_NEW_POLICY_DETAIL, payload: {policyType}}
}

export function requestDataToBeFetched(requestFetch: boolean): Actions.SetDataToBeChangedAction {
    return {type: ActionType.CHANGE_DATA_TO_BE_FETCHED, payload: requestFetch}
}

export function showAssignPolicyDialog(
    policyId: GuidType,
    currentAssignedLocations: GuidType[] | undefined,
): Actions.ShowAssignPolicyDialogAction {
    return {
        type: ActionType.SHOW_ASSIGN_POLICY_DIALOG,
        payload: {policyId, currentAssignedLocations},
    }
}

export function closeAssignPolicyDialog(): Actions.CloseAssignPolicyDialogAction {
    return {
        type: ActionType.CLOSE_ASSIGN_POLICY_DIALOG,
    }
}

export function toggleVessel(
    allLocations: LocationIdType[],
    location: LocationIdType,
    newValue: boolean,
): Actions.ToggleVesselAction {
    return {type: ActionType.TOGGLE_VESSEL, payload: {allLocations, location, newValue}}
}

export function setSearchVesselTagTerm(
    searchVesselTagTerm: string[],
): Actions.SetSearchVesselTagTermAction {
    return {
        type: ActionType.SET_SEARCH_VESSEL_TAG_TERM,
        payload: searchVesselTagTerm,
    }
}

export function setSearchVesselNameTerm(
    searchVesselNameTerm: string,
): Actions.SetSearchVesselNameTermAction {
    return {
        type: ActionType.SET_SEARCH_VESSEL_NAME_TERM,
        payload: searchVesselNameTerm,
    }
}

export function setSearchVesselTerm(searchTerm: string): Actions.SetSearchVesselTermAction {
    return {
        type: ActionType.SET_SEARCH_VESSEL_TERM,
        payload: searchTerm,
    }
}

export function setShowXButton(value: boolean): Actions.SetShowXButtonAction {
    return {
        type: ActionType.SET_SHOW_X_BUTTON,
        payload: value,
    }
}
