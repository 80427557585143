import useTypedSelector from '../../../../../hooks/use-typed-selector'
import * as Styled from './analysis-selection.styled'
import {ExpandFilterDetailsButton} from '../shared/expand-filter-details-button/expand-filter-details-button'
import {FilterBarType} from '../../../../../store/state/metrics-filter-beta/state'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {PeriodSelectionFilter} from './period-selection-filter'
import {AnalysisTypeFilter} from './analysis-type-filter'

export function AnalysisSelectionFilter(): JSX.Element {
    const {filterExpanded} = useTypedSelector(metricsBetaFilterSelector)

    return (
        <Styled.AnalysisPeriod id="AnalysisSelectionFilter">
            <ExpandFilterDetailsButton headingRow={FilterBarType.AnalysisSelection} />
            {filterExpanded && filterExpanded.periodExpanded && (
                <>
                    <PeriodSelectionFilter />
                    <AnalysisTypeFilter />
                </>
            )}
        </Styled.AnalysisPeriod>
    )
}
