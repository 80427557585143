import {DropdownOption} from './dropdown'
import type {AssetOption} from '../contexts/location-inventory-page-context'
import LoadingState from '../../../values/loading-state-enum'

export function getDropdownOptions(data: AssetOption[], state: LoadingState): DropdownOption[] {
    if (state === LoadingState.RequestingData) {
        return [
            {
                value: '',
                label: 'Loading...',
            },
        ]
    }

    if (state === LoadingState.Errored) {
        return [
            {
                value: '',
                label: 'Error loading data',
            },
        ]
    }

    return data.map((item) => ({value: item.id, label: item.name}))
}
