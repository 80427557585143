import {useEffect} from 'react'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {usePagedMetricsBeta} from './contexts/use-paged-metrics-beta'
import {PagedMetricsBetaProvider} from './contexts/paged-metrics-beta-provider'
import {FilterBar} from './components/filter-bar/filter-bar'
import * as Styled from './metrics-page.styled'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../hooks/use-typed-selector'
import {savedMetricsBetaFiltersSelector} from '../../store/state/saved-filters/selectors'
import {StrictMode} from '../../store/state/users/state'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {Content} from './components/content'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import {
    setCurrentAppliedSavedFilter,
    setSavedFilterPageType,
} from '../../store/state/saved-filters/action-creators'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../store/state/audit-log/state'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {SHOW_FILTER_BAR_ON_BREAK_POINT} from './metrics-page.styled'
import {fetchMetricsBetaFilter} from '../../store/state/metrics-filter-beta/action-creators'
import {fetchMetricTypes} from '../../store/state/metric-types/action-creators'
import {metricsBetaFilterLoadingStateSelector} from '../../store/state/metrics-filter-beta/selectors'
import LoadingState from '../../values/loading-state-enum'
import {DataLoading} from '../../components/data-loading/data-loading'

export function MetricsBetaPage(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.METRICS, AuditLogAuditType.PAGE_ACCESS))
        dispatch(setCurrentAppliedSavedFilter(undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PagedMetricsBetaProvider pageSize={10}>
            <FixedPageTemplate>
                <MetricsBeta />
            </FixedPageTemplate>
        </PagedMetricsBetaProvider>
    )
}

function MetricsBeta(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, displayFilterBar} = usePagedMetricsBeta()
    const dispatch = useDispatch()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const savedFilters = useTypedSelector(savedMetricsBetaFiltersSelector)
    const filterLoading = useTypedSelector(metricsBetaFilterLoadingStateSelector)
    const showFilterBarOn = showFilterBarOnScreen(width)

    useEffect(() => {
        dispatch(setPageType(PageType.METRICS))
        dispatch(fetchMetricTypes())
        dispatch(setSavedFilterPageType(SavedFilterPageType.METRICS))
        dispatch(fetchMetricsBetaFilter(savedFilters, strictMode === StrictMode.DefaultVisibility))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    if (!showFilterBarOn && showFilterBar) {
        return (
            <ScrollablePageTemplate onClick={() => displayFilterBar(false)}>
                <Styled.FilterBarWrapper
                    width={width}
                    onClick={(e) => e.stopPropagation()}
                    id="filter-abr-wrapper"
                >
                    <FilterBar />
                </Styled.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <Styled.WideContentArea>
                {showFilterBar && (
                    <Styled.LHFilterBar id="filter-abr-wrapper">
                        <FilterBar />
                    </Styled.LHFilterBar>
                )}
                {filterLoading === LoadingState.Loaded ? <Content /> : <DataLoading />}
            </Styled.WideContentArea>
        )
    }
}

function showFilterBarOnScreen(width: number): boolean {
    return width > SHOW_FILTER_BAR_ON_BREAK_POINT
}
