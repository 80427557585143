import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {DownloadReportButton} from '../../shared/download/download-report-button'
import {IncidentAssetsList} from '../../table/incident-details-modal/assets-list'
import {IncidentRecommendations} from '../../shared/incident-recommendations/incident-recommendations'
import {IncidentHistory} from '../../shared/incident-history/incident-history'
import * as Styles from './incident-details-expanded.styled'
import {IncidentResponse} from '../../../contexts/types/incident-response'
import {id} from 'date-fns/locale'
import {latestTimestampSelector} from '../../../../../store/state/latest-event-timestamps/selectors'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {rolesSelector} from '../../../../../store/state/roles/selectors'
import {sfmScoresSelector} from '../../../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../../../store/state/threat-measures/selectors'
import {usersSelector} from '../../../../../store/state/users/selectors'
import {IncidentModalOutputModel} from '../../../models/incident-modal.model'
import {LinkedIncidentItemsList} from '../../shared/item-list/linked-items-list'
import {CardDataCell} from './card-data-cell'
import {useEffect} from 'react'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {WatchedIncidentItemsList} from '../../shared/item-list/watched-items-list'
import {ItemListWrapper} from './incident-details-expanded.styled'
import {CardItemsListCell} from './card-items-list-cell'
import {IncidentDetailsReopenDurationMessage} from '../../table/incident-details-modal/incident-details-reopen-duration-message'
import {fleetConfigSelector} from '../../../../../store/state/config/selectors'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface IncidentDetailsExpandedProps {
    incident: IncidentResponse
}
export function IncidentDetailsExpanded({
    incident,
}: IncidentDetailsExpandedProps): JSX.Element | null {
    const locationMap = useTypedSelector(locationMapSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const users = useTypedSelector(usersSelector)
    const nodes = useTypedSelector(nodesSelector)
    const threatMeasures = useTypedSelector(threatMeasuresSelector)
    const sfmScores = useTypedSelector(sfmScoresSelector)
    const latestTimestamps = useTypedSelector(latestTimestampSelector)
    const {findIncident, getLinkedItemsData, initialiseLinkedItemsData, downloadIncidentResponse} =
        usePagedIncidents()
    const {incidentReopenDuration} = useTypedSelector(fleetConfigSelector)

    useEffect(() => {
        initialiseLinkedItemsData()
        if (findIncident && findIncident.links?.length > 0) {
            getLinkedItemsData(findIncident.links)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findIncident?.identity, findIncident?.currentStatus])

    const model = new IncidentModalOutputModel(
        incident,
        locationMap,
        userRoles,
        users,
        sfmScores,
        nodes,
        latestTimestamps,
        threatMeasures,
    )

    if (!model) {
        return null
    }
    const showReopenDurationMessage =
        model.status === IncidentStatus.CLOSED_MONITOR && incidentReopenDuration !== undefined

    return (
        <Styles.NotesSection id={`incident-details-card-${id}`}>
            {showReopenDurationMessage && (
                <IncidentDetailsReopenDurationMessage
                    incidentReopenDuration={incidentReopenDuration}
                />
            )}
            <Styles.ModalTitle>INCIDENT DETAILS</Styles.ModalTitle>

            <Styles.DataRow>
                <Styles.DataCell gridArea="DETAILS">
                    <Styles.WarningMessage id={`warning-message-${id}`}>
                        {model.warning}
                    </Styles.WarningMessage>
                    <Styles.DescriptionMessage id={`description-message-${id}`}>
                        {model.description}
                    </Styles.DescriptionMessage>
                </Styles.DataCell>
                <Styles.DataCell gridArea="DOWNLOAD">
                    <DownloadReportButton
                        id={model.id}
                        attachment={model.attachment}
                        status={model.status}
                        displayFormat="card"
                        page={AuditLogPageType.INCIDENTS}
                        downloadIncidentResponse={downloadIncidentResponse}
                    />
                </Styles.DataCell>
            </Styles.DataRow>
            <Styles.CardDataRow>
                <CardDataCell gridArea="ASSETS" label="Assets affected:">
                    <IncidentAssetsList
                        incidentStatus={model.status}
                        assets={model.assetsInvolved}
                        displayFormat="card"
                    />
                </CardDataCell>
                <ItemListWrapper gridArea="ITEMS">
                    {model.hasWatchedItems && (
                        <CardItemsListCell label="Watched items:">
                            <WatchedIncidentItemsList />
                        </CardItemsListCell>
                    )}
                    <CardItemsListCell label="Linked items:">
                        <LinkedIncidentItemsList />
                    </CardItemsListCell>
                </ItemListWrapper>
            </Styles.CardDataRow>

            <IncidentHistory
                history={model.history}
                incidentIdentity={model.id}
                displayFormat="card"
            />
            {model.recommendations.length !== 0 && (
                <IncidentRecommendations recommendations={model.recommendations} />
            )}
        </Styles.NotesSection>
    )
}
