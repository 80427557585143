import {addEvent} from '../add-event'

export function changeSoftwareInventorySortOrderEvent(
    column: string,
    orderAscending: boolean,
): boolean {
    return addEvent('changeSoftwareInventorySortOrder', {
        column: column,
        orderAscending: orderAscending,
    })
}
