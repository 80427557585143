import {Filter} from 'react-feather'
import {showFilterEvent} from '../../../../../core/google-tag-manager/software-inventory/show-filter-event'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {SOFTWARE_INVENTORY_DETAILS} from '../../data-helpers'
import * as Styles from './filter-bar-button.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {isActiveFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import {activeVesselFilterSelector} from '../../../../../store/state/vessel-filter/selectors'

export function FilterBarButton(): JSX.Element {
    const {displayFilterBar, showFilterBar, closeSoftwareDetailsModal} = usePagedSoftwareInventory()
    const activeFilter = useTypedSelector(isActiveFilterSelector)
    const activeVesselFilter = useTypedSelector(activeVesselFilterSelector)

    return (
        <Styles.Container
            onClick={(e) => {
                e.preventDefault()
                displayFilterBar(!showFilterBar)
                removeQueryParam(SOFTWARE_INVENTORY_DETAILS)
                closeSoftwareDetailsModal()
                showFilterEvent(!showFilterBar)
            }}
            id="filter-bar_button"
        >
            <Styles.SearchIcon active={activeFilter || activeVesselFilter}>
                <Filter height={16} width={16} />
            </Styles.SearchIcon>
            <Styles.TextWrapper active={activeFilter || activeVesselFilter}>
                <Styles.Text>Filter</Styles.Text>
            </Styles.TextWrapper>
        </Styles.Container>
    )
}
