import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import {TableDataRow} from './_styled/table-row.styled'
import {getImageForNetworkAssetState} from '../../context/helpers'
import {TableDataCell} from './_styled/table-data-cell.styled'
import {TrustDeviceCell} from './trust-device-cell'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {useDispatch} from 'react-redux'
import {expandNetworkAssetEvent} from '../../../../core/google-tag-manager/unknown-assets-beta/expand-network-asset-event'
import {addQueryParam} from '../../../../helpers/navigation'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../store/state/audit-log/state'
import {NETWORK_ASSET_DETAILS} from '../helpers/data-helpers'
import {TrustDeviceCellNoAc} from './trust-device-cell-no-ac'
import {acEnabledSelector} from '../../../../store/state/config/selectors'

interface TableRowProps {
    anomaly: FormattedAnomaly
    index: number
}

export function TableRow({anomaly, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const {networkAssetModalId, displayNetworkAssetDetailsModal, displayFilterBar} =
        usePagedUnknownAssetsBeta()
    const activeNetworkAsset = anomaly.identity === networkAssetModalId
    const dispatch = useDispatch()
    const acEnabled = useTypedSelector(acEnabledSelector)

    function onClickWrapper(e?: React.MouseEvent<HTMLDivElement>) {
        e?.stopPropagation()
        expandNetworkAssetEvent(anomaly.state, 'table')
        addQueryParam(NETWORK_ASSET_DETAILS, anomaly.identity)
        displayNetworkAssetDetailsModal(anomaly.identity)
        displayFilterBar(false)
        dispatch(
            logDetailsOpen(
                AuditLogPageType.NETWORK_INVENTORY,
                AuditLogAuditType.DETAIL_PANEL_ACCESS,
                anomaly.identity,
            ),
        )
    }

    return (
        <>
            <TableDataRow
                gridRow={gridRow}
                id={`data-row_${anomaly.identity}`}
                activeNetworkAsset={activeNetworkAsset}
                onClick={onClickWrapper}
            />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                onClick={onClickWrapper}
                id={`network-assets-status_${anomaly.identity}`}
            >
                <img src={getImageForNetworkAssetState(anomaly.state)} />
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                onClick={onClickWrapper}
                id={`network-assets-first-detected_${anomaly.identity}`}
            >
                {anomaly.occurred}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                onClick={onClickWrapper}
                id={`network-assets-vessel_${anomaly.identity}`}
            >
                {anomaly.location}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                onClick={onClickWrapper}
                id={`network-assets-network_${anomaly.identity}`}
            >
                {anomaly.network}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                onClick={onClickWrapper}
                id={`network-assets-mac-description_${anomaly.identity}`}
            >
                {anomaly.macDescription}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                onClick={onClickWrapper}
                id={`network-assets-ip-address_${anomaly.identity}`}
            >
                {anomaly.ipAddress}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={8}
                onClick={onClickWrapper}
                id={`network-assets-hostname_${anomaly.identity}`}
            >
                {anomaly.hostname}
            </TableDataCell>
            {acEnabled ? (
                <TrustDeviceCell anomaly={anomaly} index={index} onClickFn={onClickWrapper} />
            ) : (
                <TrustDeviceCellNoAc anomaly={anomaly} index={index} onClickFn={onClickWrapper} />
            )}
        </>
    )
}
