import React from 'react'
import {FilterableColumn} from './filterable-column'
import {filterByValueEvent} from '../../../../../core/google-tag-manager/unknown-assets/filter-by-value-event'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {setSearchNicVendor} from '../../../../../store/state/network-assets-filter/action-creators'
import {NetworkAssetsSortType} from '../../../../../store/state/network-assets-filter/state'

interface ColumnProps {
    gridColumn: number
}

export function NICVendorColumn({gridColumn}: ColumnProps): JSX.Element {
    const dispatch = useDispatch()
    const {searchedMacDescription} = useTypedSelector(networkAssetsFilterSelector)
    const colName = NetworkAssetsSortType.MAC_DESCRIPTION
    const search = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        if (event.target.value) {
            filterByValueEvent(colName, event.target.value.length)
        }
        dispatch(setSearchNicVendor(event.target.value))
    }

    return (
        <FilterableColumn
            text="Vendor"
            colName={colName}
            gridColumn={gridColumn}
            searchFunc={search}
            searchValue={searchedMacDescription}
        />
    )
}
