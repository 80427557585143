import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './expand-panel-button.styled'
import {DetailExpandedType, MetricsDetailType} from '../../../../contexts/types/modal-types'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

interface ExpandPanelButtonProps {
    headingRow: MetricsDetailType
}

export function ExpandPanelButton({headingRow}: ExpandPanelButtonProps): JSX.Element {
    const {modalDetailsExpanded, toggleModalDetailsExpanded} = usePagedMetricsBeta()
    const isExpanded = getCurrentDetailExpanded(headingRow, modalDetailsExpanded)
    function setNewDetailExpanded() {
        const newDetailExpanded: DetailExpandedType = getNewDetailExpanded(
            headingRow,
            modalDetailsExpanded,
        )
        toggleModalDetailsExpanded(newDetailExpanded)
    }

    return (
        <Styles.HeadingWrapper>
            <Styles.DetailHeadingRow id={`${PageType.METRICS}_expand-heading-row-${headingRow}`}>
                {headingRow}
            </Styles.DetailHeadingRow>
            <Styles.Button
                onClick={() => setNewDetailExpanded()}
                id={`${PageType.METRICS}_expand-button-${headingRow}`}
            >
                <Styles.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styles.IconWrapper>
            </Styles.Button>
        </Styles.HeadingWrapper>
    )
}

const getNewDetailExpanded = (
    toggledDetail: MetricsDetailType,
    currentDetailExpanded: DetailExpandedType,
): DetailExpandedType => {
    switch (toggledDetail) {
        case MetricsDetailType.Details:
            return {
                ...currentDetailExpanded,
                detailsExpanded: !currentDetailExpanded.detailsExpanded,
            }
        case MetricsDetailType.Insights:
            return {
                ...currentDetailExpanded,
                insightsExpanded: !currentDetailExpanded.insightsExpanded,
            }

        default:
            return currentDetailExpanded
    }
}

const getCurrentDetailExpanded = (
    toggledDetail: MetricsDetailType,
    currentDetailExpanded: DetailExpandedType,
): boolean => {
    switch (toggledDetail) {
        case MetricsDetailType.Details:
            return currentDetailExpanded.detailsExpanded
        case MetricsDetailType.Insights:
            return currentDetailExpanded.insightsExpanded

        default:
            return false
    }
}
