import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {changeUSBDevicesSortOrderEvent} from '../../../../../core/google-tag-manager/usb-devices/change-sort-order-event'
import {USB_DEVICES_DETAILS, getNewSortByDirection} from '../../data-helpers'
import {IconStyled, TableHeaderCell, TableHeaderText} from './table-header-cell.styled'
import {useDispatch} from 'react-redux'
import {setSortColumn} from '../../../../../store/state/usb-inventory-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    usbInventoryFilterLoadingSelector,
    usbInventoryFilterSelector,
} from '../../../../../store/state/usb-inventory-filter/selectors'
import {
    SortColumnType,
    USBDevicesSortType,
} from '../../../../../store/state/usb-inventory-filter/state'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'

interface SortableColumnProps {
    gridColumn: number
    text: string
    colName: USBDevicesSortType
}

export function SortableColumn({gridColumn, text, colName}: SortableColumnProps): JSX.Element {
    const isLoadingFilter = useTypedSelector(usbInventoryFilterLoadingSelector)
    const {sortColumn} = useTypedSelector(usbInventoryFilterSelector)
    const dispatch = useDispatch()
    const {setIdForUSBDetailsModal} = usePagedUSBDevices()

    function setSortBy(selectedColumn: USBDevicesSortType) {
        const newSortColumn: SortColumnType = {
            orderAscending: getNewSortByDirection(selectedColumn, sortColumn),
            orderBy: selectedColumn,
        }
        dispatch(setSortColumn(newSortColumn))
        removeQueryParam(USB_DEVICES_DETAILS)
        setIdForUSBDetailsModal(null)
        changeUSBDevicesSortOrderEvent(selectedColumn, sortColumn.orderAscending)
    }
    return (
        <TableHeaderCell id={`sortable-column_${colName}`} gridColumn={gridColumn} selectable>
            <TableHeaderText
                onClick={() => setSortBy(colName)}
                active={!isLoadingFilter && sortColumn.orderBy === colName}
                id={`text_${colName}`}
            >
                {text}
                <IconStyled arrowDec={sortColumn.orderBy === colName && !sortColumn.orderAscending}>
                    {!isLoadingFilter && <Icon width={12} height={17} glyph="Arrow" />}
                </IconStyled>
            </TableHeaderText>
        </TableHeaderCell>
    )
}
