import {DEFAULT_ACTIVE_LOCATION_STATE, Location} from '../../../../store/state/locations/state'
import useLocationActions from '../../../../store/state/locations/use-actions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {activeLocationSelector} from '../../../../store/state/locations/selectors'
import {DropdownItemStyle} from './_styles/location-dropdown.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {selectActiveLocationEvent} from '../../../../core/google-tag-manager/navigation/select-active-location-event'
import * as RadioStyled from '../shared/radio-button.styled'

interface DropDownItemProps {
    location: Location
}
export function DropDownItem({location}: DropDownItemProps): JSX.Element {
    const {setActiveLocation} = useLocationActions()
    const activeLocation = useTypedSelector(activeLocationSelector)
    const {width} = useDimensions()

    const onClickWrapper = (location: Location) => {
        setActiveLocation(location)
        selectActiveLocationEvent(
            location.description === DEFAULT_ACTIVE_LOCATION_STATE.description
                ? 'FLEET_VIEW'
                : 'INDIVIDUAL_VESSEL',
            'mainNavigation',
        )
    }

    return (
        <DropdownItemStyle
            width={width}
            onClick={() => {
                onClickWrapper(location)
            }}
        >
            <RadioStyled.Container>
                <RadioStyled.Input
                    type="radio"
                    id={`${location.location}-radio`}
                    value={location.location}
                    checked={activeLocation && activeLocation.location === location.location}
                    onChange={() => setActiveLocation(location)}
                    disabled={false}
                />
                <RadioStyled.Label htmlFor={`${location.location}-radio`}>
                    {location?.description || 'Unknown'}
                </RadioStyled.Label>
            </RadioStyled.Container>
        </DropdownItemStyle>
    )
}
