import {DataLoading} from '../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'
import {IncidentsResponsePerformanceWidget} from './incidents-response-performance-widget'

import * as Styled from './incidents-response-performance.styled'
import {VesselPerformanceWidget} from './vessel-performance-widget'

export function IncidentsResponsePerformance(): JSX.Element {
    const {width} = useDimensions()
    const {loadedIncidentsByAvgPerformance, showFilterBar, loadedIncidentsByVesselPerformance} =
        usePagedIncidentsReport()
    const narrowLayoutForOpenFilter = showFilterBar === true

    return (
        <Styled.Section>
            <Styled.SectionText>Incident response performance</Styled.SectionText>

            <Styled.SectionContent
                width={width}
                narrowLayoutForOpenFilter={narrowLayoutForOpenFilter}
            >
                <Styled.StatusFilterContainer
                    width={width}
                    id="incidents-report-page-incidents-performance-container"
                >
                    <Styled.SectionText>INCIDENT RESPONSE PERFORMANCE</Styled.SectionText>
                    {loadedIncidentsByAvgPerformance ? (
                        <IncidentsResponsePerformanceWidget />
                    ) : (
                        <DataLoading />
                    )}
                </Styled.StatusFilterContainer>
                <Styled.StatusFilterContainer
                    width={width}
                    id="incidents-report-page-vessel-performance-container"
                >
                    <Styled.SectionText>INCIDENT RESPONSE - VESSELS PERFORMANCE</Styled.SectionText>
                    {loadedIncidentsByVesselPerformance ? (
                        <VesselPerformanceWidget />
                    ) : (
                        <DataLoading />
                    )}
                </Styled.StatusFilterContainer>
            </Styled.SectionContent>
        </Styled.Section>
    )
}
