import {
    findMetricCategory,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {FrameworkSummaryTrendResponse} from '../../../contexts/types/framework-summary-trends-api'
import * as Styled from './framework-summary-trend.styled'
import POSITIVE_VECTOR from './_styled/positive-vector-trend.svg'
import NEGATIVE_VECTOR from './_styled/negative-vector-trend.svg'

interface FrameworkSummaryTrendProps {
    findTrendSummaryData: FrameworkSummaryTrendResponse
}
export function FrameworkSummaryTrend({
    findTrendSummaryData,
}: FrameworkSummaryTrendProps): JSX.Element {
    function getTrendsPerformanceComparisionImg(selectedPerformance: number): string {
        return selectedPerformance >= 0 ? POSITIVE_VECTOR : NEGATIVE_VECTOR
    }
    return (
        <Styled.ContentWrapper id={`framework-summary-trend-${findTrendSummaryData.locationId}`}>
            {findTrendSummaryData.protectionTrend !== undefined ? (
                <Styled.ScoreSection>
                    <img
                        src={getMetricTypeIcon(findMetricCategory('protection'))}
                        style={{width: '30px', height: '30px'}}
                        id={`metricTypeIcon-protection-${findTrendSummaryData.locationId}-${findTrendSummaryData.protectionTrend}`}
                    />
                    <Styled.TrendScore
                        metricType="protection"
                        id={`trendScore-protection-${findTrendSummaryData.locationId}-${findTrendSummaryData.protectionTrend}`}
                    >
                        <div
                            style={{display: 'flex'}}
                            id={`trendSummaryData-protection-${findTrendSummaryData.locationId}-${findTrendSummaryData.protectionTrend}`}
                        >
                            {getFormatedPercentNumber(findTrendSummaryData.protectionTrend)}
                            <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                        </div>
                    </Styled.TrendScore>

                    <Styled.ImgComparison
                        src={getTrendsPerformanceComparisionImg(
                            findTrendSummaryData.protectionTrend,
                        )}
                        metricType="protection"
                        id={`trendsPerformanceComparisionImg-protection-${findTrendSummaryData.locationId}-${findTrendSummaryData.protectionTrend}`}
                    />
                </Styled.ScoreSection>
            ) : null}
            {findTrendSummaryData.maintenanceTrend !== undefined ? (
                <Styled.ScoreSection>
                    <img
                        src={getMetricTypeIcon(findMetricCategory('maintenance'))}
                        style={{width: '30px', height: '30px'}}
                        id={`metricTypeIcon-maintenance-${findTrendSummaryData.locationId}-${findTrendSummaryData.maintenanceTrend}`}
                    />
                    <Styled.TrendScore
                        metricType="maintenance"
                        id={`trendScore-maintenance-${findTrendSummaryData.locationId}-${findTrendSummaryData.maintenanceTrend}`}
                    >
                        <div
                            style={{display: 'flex'}}
                            id={`trendSummaryData-maintenance-${findTrendSummaryData.locationId}-${findTrendSummaryData.maintenanceTrend}`}
                        >
                            {getFormatedPercentNumber(findTrendSummaryData.maintenanceTrend)}
                            <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                        </div>
                    </Styled.TrendScore>
                    <Styled.ImgComparison
                        src={getTrendsPerformanceComparisionImg(
                            findTrendSummaryData.maintenanceTrend,
                        )}
                        metricType="maintenance"
                        id={`trendsPerformanceComparisionImg-maintenance-${findTrendSummaryData.locationId}-${findTrendSummaryData.maintenanceTrend}`}
                    />
                </Styled.ScoreSection>
            ) : null}
            {findTrendSummaryData.behaviourTrend !== undefined ? (
                <Styled.ScoreSection>
                    <img
                        src={getMetricTypeIcon(findMetricCategory('behaviour'))}
                        style={{width: '30px', height: '30px'}}
                        id={`metricTypeIcon-behaviour-${findTrendSummaryData.locationId}-${findTrendSummaryData.behaviourTrend}`}
                    />
                    <Styled.TrendScore
                        metricType="behaviour"
                        id={`trendScore-behaviour-${findTrendSummaryData.locationId}-${findTrendSummaryData.behaviourTrend}`}
                    >
                        <div
                            style={{display: 'flex'}}
                            id={`trendSummaryData-behaviour-${findTrendSummaryData.locationId}-${findTrendSummaryData.behaviourTrend}`}
                        >
                            {getFormatedPercentNumber(findTrendSummaryData.behaviourTrend)}
                            <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                        </div>
                    </Styled.TrendScore>
                    <Styled.ImgComparison
                        src={getTrendsPerformanceComparisionImg(
                            findTrendSummaryData.behaviourTrend,
                        )}
                        metricType="behaviour"
                        id={`trendsPerformanceComparisionImg-behaviour-${findTrendSummaryData.locationId}-${findTrendSummaryData.behaviourTrend}`}
                    />
                </Styled.ScoreSection>
            ) : null}
        </Styled.ContentWrapper>
    )
}
function getFormatedPercentNumber(value: number): string {
    const formattedValue = Number.isInteger(value) ? value : value?.toFixed(2)
    const prefix = value > 0 ? '+' : ''
    return `${prefix}${formattedValue}`
}
