import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import * as Styled from './analysis-selection.styled'
import {getTypeDisplayName} from '../../data-helper'
import {
    AnalysisType,
    TimestampFilterType,
} from '../../../../../store/state/metrics-filter-beta/state'
import {allPeriodTypeOther, allPeriodTypeTarget} from '../../../contexts/types/metrics-response'
import {
    setPeriodCommon,
    setPeriodTarget,
} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {filterByEvent} from '../../../../../core/google-tag-manager/metrics-beta/filter-by-event'

export function PeriodSelectionFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {periodForCommon, periodForTrend, analysisTypes, selectedViewScreenType} =
        useTypedSelector(metricsBetaFilterSelector)
    const {highlightedFilerValue} = usePagedMetricsBeta()
    function onChangeWrapperCommon(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(setPeriodCommon(event.target.value as TimestampFilterType))
        filterByEvent('Analysis period', event.target.value)
    }
    function onChangeWrapperTarget(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(setPeriodTarget(event.target.value as TimestampFilterType))
        filterByEvent('Analysis period', event.target.value)
    }
    if (
        analysisTypes === AnalysisType.TRENDS ||
        (analysisTypes === AnalysisType.WORST_PERFORMING_VESSELS &&
            selectedViewScreenType === 'Table')
    ) {
        return (
            <Styled.Wrapper
                highlightedFilerValue={highlightedFilerValue === 'Analysis period'}
                id={`${PageType.METRICS}_filter-bar_period-type-filter`}
            >
                <Styled.Label>Analysis Period:</Styled.Label>
                <Styled.Input>
                    <Styled.Select
                        onChange={onChangeWrapperTarget}
                        value={periodForTrend}
                        id={`${PageType.METRICS}_filter-bar_searched-period-type-value`}
                    >
                        <option
                            key="latest"
                            value={TimestampFilterType.LAST_2_H}
                            id={`${PageType.METRICS}_filter-bar_period-type_latest-option`}
                        >
                            {getTypeDisplayName(TimestampFilterType.LAST_2_H)}
                        </option>
                        <option
                            key="default-option"
                            value={TimestampFilterType.ONE_FORTNIGHT}
                            id={`${PageType.METRICS}_filter-bar_period-type_default-option`}
                        >
                            {getTypeDisplayName(TimestampFilterType.ONE_FORTNIGHT)}
                        </option>
                        {allPeriodTypeTarget?.map((value) => (
                            <option
                                key={value}
                                value={value}
                                id={`${PageType.METRICS}_filter-bar_period-type_${value}-option`}
                            >
                                {getTypeDisplayName(value)}
                            </option>
                        ))}
                    </Styled.Select>
                </Styled.Input>
            </Styled.Wrapper>
        )
    }
    return (
        <Styled.Wrapper
            highlightedFilerValue={highlightedFilerValue === 'Analysis period'}
            id={`${PageType.METRICS}_filter-bar_period-type-filter`}
        >
            <Styled.Label>Analysis Period:</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapperCommon}
                    value={periodForCommon}
                    id={`${PageType.METRICS}_filter-bar_searched-period-type-value`}
                >
                    <option
                        key="latest"
                        value={TimestampFilterType.LATEST}
                        id={`${PageType.METRICS}_filter-bar_period-type_latest-option`}
                    >
                        {getTypeDisplayName(TimestampFilterType.LATEST)}
                    </option>
                    {allPeriodTypeOther?.map((value) => (
                        <option
                            key={value}
                            value={value}
                            id={`${PageType.METRICS}_filter-bar_period-type_${value}-option`}
                        >
                            {getTypeDisplayName(value)}
                        </option>
                    ))}
                    <option
                        key="default-option"
                        value={TimestampFilterType.LAST_30_DAYS}
                        id={`${PageType.METRICS}_filter-bar_period-type_default-option`}
                    >
                        {getTypeDisplayName(TimestampFilterType.LAST_30_DAYS)}
                    </option>
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
