import {ThreatMeasure, ThreatMeasureMap} from '../../ThreatMeasure'
import {getNodeLatestEventOrNA, NodeData, NodeValue} from '../NodeData'
import {Location, FLEET_VIEW_IDENTIFIER, LocationIdType} from '../../../store/state/locations/state'
import {SFMScoreMap} from '../../../store/state/sfm-scores/state'
import {LatestEventTimestampMap} from '../../../store/state/latest-event-timestamps/state'
import NodeRecord from './types/node-record'

export const filterActiveLocation = (location: LocationIdType, activeLocation: Location): boolean =>
    activeLocation.location === FLEET_VIEW_IDENTIFIER || activeLocation.location === location

export function getThreatLevel(
    score: number | undefined,
    threatMeasures: Map<NodeValue, ThreatMeasure>,
    value: NodeValue,
): string {
    const threatMeasure = threatMeasures.get(value)
    if (!threatMeasure || score == undefined) {
        return 'unknown'
    }

    if (score >= threatMeasure.upperThreshold) {
        return 'high'
    } else if (score >= threatMeasure.lowerThreshold) {
        return 'medium'
    } else {
        return 'low'
    }
}

export function getScoreForNodes(node: NodeData, sfmScores: SFMScoreMap): number {
    if (!node) {
        return 0
    }

    return Math.ceil(sfmScores?.get(node.node) || 0)
}

export function populateNodeRecord(
    node: NodeData,
    sfmScores: SFMScoreMap,
    latestEventTimestamps: LatestEventTimestampMap,
    threatMeasures: ThreatMeasureMap,
): NodeRecord {
    const score = getScoreForNodes(node, sfmScores)

    return {
        assetId: node.node,
        ipAddress: node.ipAddress,
        lastEvent: getNodeLatestEventOrNA(node.node, latestEventTimestamps),
        assetName: node.alias,
        assetScore: score,
        threatLevel: getThreatLevel(score, threatMeasures, node.value),
        assetValueLevel: node.value?.toLowerCase() || 'Unknown',
    }
}
