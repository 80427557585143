import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'
import {mediumSmallFont} from '../../../theme/font-styles'

export const TitleRow = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: ${spacing(1)};
`

export const Title = styled.div`
    ${mediumSmallFont()}
`

export const CloseImage = styled.img`
    height: 17px;
    width: 17px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(1)};
    top: ${spacing(1)};
`

export const AssignPolicyDialogWrapperCard = styled.div`
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    position: relative;
    top: 70px;
    right: 310px;
    z-index: 999;
`

export const AssignPolicyDialogCard = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 350px;
    top: -20px;
    max-height: 500px;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    padding: ${spacing(1)};
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    gap: ${spacing(1)};
    margin-top: -${spacing(2)};
`
