import {MouseEvent, useState} from 'react'
import {ButtonText} from './button-text.styled'
import {Button} from './button.styled'
import {GenerateReportPopup} from '../generate-report-popup/generate-report-popup'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {DisableButtonRecommendation} from '../disable-button-recom/disable-button-recom'

interface GenerateReportButtonProps {
    createReport: () => void
    reportUrl: string | null
    showGenerateReportPopup: (show: boolean) => void
    reportDialogShown: boolean
    pageType: string
    isDisabled: boolean
}

export function GenerateReportButton({
    createReport,
    reportUrl,
    showGenerateReportPopup,
    reportDialogShown,
    pageType,
    isDisabled = false,
}: GenerateReportButtonProps): JSX.Element {
    const [viewRecommendation, setViewRecommendation] = useState(false)

    function refreshFunction(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        createReport()
    }

    return (
        <div
            onMouseEnter={() => {
                if (isDisabled) {
                    setViewRecommendation(true)
                }
            }}
            onMouseLeave={() => {
                if (isDisabled) {
                    setViewRecommendation(false)
                }
            }}
            style={{position: 'relative'}}
        >
            <Button
                id={`${pageType}_generate-report-button`}
                onClick={(e) => refreshFunction(e)}
                disabled={isDisabled}
            >
                <Icon glyph="Excel" height={19} width={19} />
                <ButtonText id={`${pageType}_generate-report-button_text`}>
                    Create Report
                </ButtonText>
            </Button>
            {reportDialogShown && (
                <GenerateReportPopup
                    showGenerateReportPopup={showGenerateReportPopup}
                    reportUrl={reportUrl}
                    pageType={pageType}
                />
            )}
            {viewRecommendation && <DisableButtonRecommendation />}
        </div>
    )
}
