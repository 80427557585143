import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './metrics-framework-card.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {findMetricCategory, getMainMetricsDisplayName, getMetricTypeIcon} from '../data-helper'
import {MetricsFooter} from './metrics-footer'
import {MetricType} from '../../contexts/types/metrics-response'
import {useDispatch} from 'react-redux'
import {
    filterMainFrameworkCard,
    setSelectedViewScreen,
} from '../../../../store/state/metrics-filter-beta/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    behaviourMetricTypesSelector,
    maintenanceMetricTypesSelector,
    protectionMetricTypesSelector,
} from '../../../../store/state/metric-types/selectors'
import {useCallback} from 'react'
import LoadingState from '../../../../values/loading-state-enum'
import {useSpring} from 'react-spring'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'

interface MetricsFrameworkCardProps {
    metricType: MetricType
}
export function MetricsFrameworkCard({metricType}: MetricsFrameworkCardProps): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {showFilterBar, loadingFilteredDataState, changePreviousPage} = usePagedMetricsBeta()
    const dataLoaded = loadingFilteredDataState === LoadingState.Loaded
    const {metricTypes} = useTypedSelector(metricsBetaFilterSelector)
    const availableProtectionSubMetrics = useTypedSelector(protectionMetricTypesSelector)
    const availableMaintenanceSubMetrics = useTypedSelector(maintenanceMetricTypesSelector)
    const availableBehaviourSubMetrics = useTypedSelector(behaviourMetricTypesSelector)

    // Function to find available sub-metrics based on the metric type
    const findAvailableSubMetrics = useCallback(
        (metricType: MetricType): MetricType[] => {
            switch (metricType) {
                case MetricType.PROTECTION:
                    return availableProtectionSubMetrics
                case MetricType.MAINTENANCE:
                    return availableMaintenanceSubMetrics
                case MetricType.BEHAVIOUR:
                    return availableBehaviourSubMetrics
                default:
                    return new Array<MetricType>()
            }
        },
        [
            availableProtectionSubMetrics,
            availableMaintenanceSubMetrics,
            availableBehaviourSubMetrics,
        ],
    )

    // Function to handle framework card click
    const onFrameworkClick = useCallback(
        (metricType: MetricType) => {
            const availableSubMetrics = findAvailableSubMetrics(metricType)
            changePreviousPage('framework', metricTypes)
            dispatch(setSelectedViewScreen('metrics'))
            dispatch(filterMainFrameworkCard(availableSubMetrics))
        },
        [changePreviousPage, dispatch, findAvailableSubMetrics],
    )
    const fadeStyles = useSpring({
        opacity: dataLoaded ? 1 : 0,
        from: {opacity: dataLoaded ? 0 : 1},
    })
    return (
        <Styled.AnimatedDiv style={fadeStyles} id={`framework-card-${metricType}-wrapper`}>
            <Styled.HeaderSection
                width={width}
                showFilterBar={showFilterBar}
                onClick={() => onFrameworkClick(metricType)}
                id={`framework-card-${metricType}-header`}
            >
                <Styled.Image
                    src={getMetricTypeIcon(findMetricCategory(metricType))}
                    id={`framework-card-${metricType}-image`}
                />
                <Styled.Title id={`framework-card-${metricType}-title`}>
                    {getMainMetricsDisplayName(metricType)}
                </Styled.Title>
                <Styled.Message id={`framework-card-${metricType}-message`}>
                    <MetricsFooter metricType={metricType} />
                </Styled.Message>
            </Styled.HeaderSection>
        </Styled.AnimatedDiv>
    )
}
