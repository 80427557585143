import {Dispatch, useContext} from 'react'
import {PagedUSBDevicesContext} from './paged-usb-devices-context'
import {UsePagedUSBDevicesResult} from './use-paged-usb-devices-output'
import * as ActionCreators from './state/action-creators'
import {setReportCreate} from './state/action-creators'
import {AllActions} from './state/actions'
import {warn} from '../../../helpers/logging'
import {REST} from '../../..'
import {Location, LocationIdType} from '../../../store/state/locations/state'
import {GuidType} from '../../../values/generic-type-defintions'
import {formatTimestampCriteria} from './context-helper'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {filteredVesselIds} from '../../unknown-assets-v2/context/reselector/filtered-vessel-ids.reselector'
import {
    DeviceStatus,
    SortColumnType,
    TimestampFilter,
    USBDeviceType,
    UsbInventoryFilterReduxState,
} from '../../../store/state/usb-inventory-filter/state'
import {usbInventoryFilterSelector} from '../../../store/state/usb-inventory-filter/selectors'
import {USBDevicesResponse} from './types/usb-devices-response'
import {getFormattedUSBDeviceModalData} from '../components/data-helpers'
import {nodesSelector} from '../../../store/state/nodes/selectors'
import {isInternalUserSelector} from '../../../store/state/current-user/selectors'
import {usersSelector} from '../../../store/state/users/selectors'
import {
    ReportType,
    UsbInventoryExtendedReportPayload,
} from '../../../values/extended-report/report-payload'

const USB_DEVICES_ENDPOINT = 'usb-inventory/api/v1/usb_devices'
const REPORTS_ENDPOINT = '/api/v1/customerReports'

export function usePagedUSBDevices(): UsePagedUSBDevicesResult {
    const {state, dispatch} = useContext(PagedUSBDevicesContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedUSBDevices must be used within a PagedUSBDevicesContext')
    }

    const allLocations = useTypedSelector(locationsSelector)
    const currentFilter = useTypedSelector(usbInventoryFilterSelector)
    const nodes = useTypedSelector(nodesSelector)
    const users = useTypedSelector(usersSelector)
    const isInternalUser = useTypedSelector(isInternalUserSelector)

    function createReport(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const filteredVessels = filteredVesselIds(
            allLocations,
            currentFilter.locations,
            currentFilter.searchVesselTagTerm,
            currentFilter.searchVesselNameTerm,
        )
        const locationsToUse = getFormattedFilteredVessels(allLocations, filteredVessels)
        const find: UsbInventoryExtendedReportPayload = {
            criteria: {
                fromRelativeFirstSeen: formatTimestampCriteria(currentFilter.searchedFirstDetected),
                fromRelativeLastSeen: formatTimestampCriteria(currentFilter.searchedLastActive),
                locations: locationsToUse,
                orderBy: {
                    column: currentFilter.sortColumn.orderBy,
                    isAscending: currentFilter.sortColumn.orderAscending,
                },
                pagination: {pageSize: state.pageSize, pageOffset: state.selectedPage * 10},
                type: ReportType.USB_INVENTORY,
                productName:
                    currentFilter.searchedProductName?.length === 0
                        ? null
                        : currentFilter.searchedProductName ?? null,
                deviceStatuses: currentFilter.selectedUsbDeviceStatus,
                deviceTypes: currentFilter.searchedType,
                hasPurpose: currentFilter.hasPurpose,
                vendorName:
                    currentFilter.searchedVendor?.length === 0
                        ? null
                        : currentFilter.searchedVendor ?? null,
            },
        }
        REST.post(`${REPORTS_ENDPOINT}/extendedInventory`, find)
            .then((response) => {
                const {identity} = response.data
                const reportUrl = `/reports?reportDetails=${identity}`
                dispatch(setReportCreate(reportUrl))
                dispatch(ActionCreators.showGenerateReportPopup(true))
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.requestInitialPageData())
        getData(
            dispatch,
            0,
            state.pageSize,
            currentFilter.selectedUsbDeviceStatus,
            currentFilter.searchedFirstDetected,
            currentFilter.searchedLastActive,
            currentFilter.searchedVendor,
            currentFilter.searchedProductName,
            currentFilter.searchedType,
            currentFilter.hasPurpose,
            currentFilter.sortColumn,
            allLocations,
            currentFilter.locations,
            currentFilter.searchVesselTagTerm,
            currentFilter.searchVesselNameTerm,
        )
    }

    async function setDeviceStatus(
        device: USBDevicesResponse,
        status: DeviceStatus,
    ): Promise<void> {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        await REST.put(`${USB_DEVICES_ENDPOINT}/${device.identity}/status/${status}`)
        dispatch(ActionCreators.usbDeviceStatusSet(device, status))
        updateOpenUsbDetails(dispatch, state.pageSize, device.identity)
    }

    function selectPage(requestedPage: number | undefined): void {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        if (requestedPage == undefined) {
            warn('requestedPage is undefined')
            return
        }

        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))
        const {searchedVessels, searchedVesselTagTerm, searchedVesselNameTerm} = state.vesselFilter
        getData(
            dispatch,
            offset,
            state.pageSize,
            currentFilter.selectedUsbDeviceStatus,
            currentFilter.searchedFirstDetected,
            currentFilter.searchedLastActive,
            currentFilter.searchedVendor,
            currentFilter.searchedProductName,
            currentFilter.searchedType,
            currentFilter.hasPurpose,
            currentFilter.sortColumn,
            allLocations,
            searchedVessels,
            searchedVesselTagTerm,
            searchedVesselNameTerm,
        )
    }

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    function setFilter(
        selectedUsbDeviceStatus: DeviceStatus[] | undefined,
        searchedFirstDetected: TimestampFilter,
        searchedLastActive: TimestampFilter,
        searchedVendor: string | undefined,
        searchedProductName: string | undefined,
        searchedType: USBDeviceType[],
        hasPurpose: boolean | undefined,
        sortColumn: SortColumnType,
        searchedVessels: Set<LocationIdType> | undefined,
        searchedVesselTagTerm: string[],
        searchedVesselNameTerm: string,
        externalGuid: boolean,
        usbDetailsModalId: GuidType,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        if (externalGuid) {
            dispatch(ActionCreators.displayUsbDetailsModal(usbDetailsModalId))
            getUsbDetailsModalId(
                dispatch,
                0,
                state.pageSize,
                sortColumn,
                usbDetailsModalId,
                allLocations,
                currentFilter,
            )
            return
        }
        dispatch(
            ActionCreators.setFilter({
                searchedVessels,
                searchedVesselTagTerm,
                searchedVesselNameTerm,
            }),
        )
        getData(
            dispatch,
            0,
            state.pageSize,
            selectedUsbDeviceStatus,
            searchedFirstDetected ? searchedFirstDetected : currentFilter.searchedFirstDetected,
            searchedLastActive ? searchedLastActive : currentFilter.searchedLastActive,
            searchedVendor ?? currentFilter.searchedVendor,
            searchedProductName ?? currentFilter.searchedProductName,
            searchedType ?? currentFilter.searchedType,
            hasPurpose,
            sortColumn ?? currentFilter.sortColumn,
            allLocations,
            searchedVessels,
            searchedVesselTagTerm,
            searchedVesselNameTerm,
        )
    }

    function setIdForUSBDetailsModal(modalIdForUSBDetails: GuidType | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setIdUSBDetailsModal(modalIdForUSBDetails))
    }

    function displayUsbModal(identity: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getUsbDetails(identity)
        dispatch(ActionCreators.displayUsbDetailsModal(identity))
    }

    function getUsbDetails(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        REST.get(`${USB_DEVICES_ENDPOINT}/${identity}`)
            .then((response) => {
                dispatch(ActionCreators.setFindUsbDetails(response.data))
                dispatch(
                    ActionCreators.setUsbDetailsOutput(
                        getFormattedUSBDeviceModalData(
                            response.data as USBDevicesResponse,
                            nodes,
                            users,
                            isInternalUser,
                        ),
                    ),
                )
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function displaySetPurposeModal(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displaySetPurposeModal(value))
    }

    function showGenerateReportPopup(show: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showGenerateReportPopup(show))
    }

    async function getUsbDetailsModalId(
        dispatch: Dispatch<AllActions>,
        offset: number,
        count: number,
        sortColumn: SortColumnType,
        usbDetailsModalId: GuidType,
        allLocations: Location[],
        currentFilter: UsbInventoryFilterReduxState,
    ): Promise<void> {
        const filteredVessels = filteredVesselIds(
            allLocations,
            currentFilter.locations,
            currentFilter.searchVesselTagTerm,
            currentFilter.searchVesselNameTerm,
        )
        const locationsQuery = getFormattedFilteredVessels(allLocations, filteredVessels)
        const response = await REST.post(`${USB_DEVICES_ENDPOINT}/${usbDetailsModalId}/find`, {
            orderBy: {column: sortColumn.orderBy, isAscending: sortColumn.orderAscending},
            pagination: {pageSize: count, pageOffset: offset},
            fromRelativeFirstSeen: formatTimestampCriteria(currentFilter.searchedFirstDetected),
            fromRelativeLastSeen: formatTimestampCriteria(currentFilter.searchedLastActive),
            vendorName: currentFilter.searchedVendor ?? null,
            productName: currentFilter.searchedProductName ?? null,
            deviceTypes: currentFilter.searchedType ?? null,
            hasPurpose: currentFilter.hasPurpose,
            locations: locationsQuery,
        })

        const requestedPage =
            response.data.criteria.pagination.pageOffset /
            response.data.criteria.pagination.pageSize

        dispatch(ActionCreators.requestPageData(requestedPage))
        dispatch(
            ActionCreators.receivedRequestedPageData(
                response.data.data,
                response.data.totalNumberOfItems ?? 0,
                response.data.totalNumberOfPages,
            ),
        )

        const findUsbDetails = response.data.data.find(
            (data: USBDevicesResponse) => data.identity === usbDetailsModalId,
        )

        if (!findUsbDetails) {
            updateOpenUsbDetails(dispatch, count, usbDetailsModalId)
        } else {
            dispatch(ActionCreators.setFindUsbDetails(findUsbDetails))
            dispatch(
                ActionCreators.setUsbDetailsOutput(
                    getFormattedUSBDeviceModalData(
                        findUsbDetails as USBDevicesResponse,
                        nodes,
                        users,
                        isInternalUser,
                    ),
                ),
            )
        }
    }

    function updateOpenUsbDetails(
        dispatch: Dispatch<AllActions>,
        pageSize: number,
        usbDetailsModalId: GuidType,
    ): void {
        REST.post(`${USB_DEVICES_ENDPOINT}/${usbDetailsModalId}/find`, {
            pagination: {pageSize: pageSize},
        }).then((response) => {
            const findUsbDetails: USBDevicesResponse = response.data.data.find(
                (networkAsset: USBDevicesResponse) => networkAsset.identity === usbDetailsModalId,
            )

            dispatch(ActionCreators.setFindUsbDetails(findUsbDetails))
            dispatch(
                ActionCreators.setUsbDetailsOutput(
                    getFormattedUSBDeviceModalData(
                        findUsbDetails as USBDevicesResponse,
                        nodes,
                        users,
                        isInternalUser,
                    ),
                ),
            )
        })
    }

    async function submitUsbPurpose(identity: GuidType, purpose: string): Promise<void> {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        await REST.put(`${USB_DEVICES_ENDPOINT}/${identity}`, {
            purpose: purpose,
        })
        updateOpenUsbDetails(dispatch, state.pageSize, identity)
    }

    const hasData = state.totalNumberOfUSBDevices != undefined
    const dataUSBDevices = hasData
        ? state.dataUSBDevicesMap?.get(state.selectedPage || 0) ?? undefined
        : undefined

    return {
        loadingDataState: state.loadingDataState,
        pageSize: state.pageSize,
        totalNumberOfFilteredUSBDevices: state.totalNumberOfUSBDevices,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        dataUSBDevices: dataUSBDevices,
        showFilterBar: state.showFilterBar,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        displayFilterBar,
        setFilter,
        modalIdForUSBDetails: state.modalIdForUSBDetails,
        setIdForUSBDetailsModal,
        findUSBDeviceForModal: state.findUsbDetailForModal,
        displayUsbModal,
        setDeviceStatus,
        displaySetPurposeModal,
        showSetPurposeModal: state.showSetPurposeModal,
        usbDetailsOutput: state.usbDetailsOutput,
        submitUsbPurpose,
        createReport,
        reportDialogShown: state.showGenerateReportPopup,
        showGenerateReportPopup,
        reportUrl: state.reportUrl,
    }
}

function getFormattedFilteredVessels(
    allLocations: Location[],
    filteredVessels: string[],
): string[] | undefined {
    return filteredVessels.length === allLocations.length ? undefined : filteredVessels
}

function getData(
    dispatch: Dispatch<AllActions>,
    offset: number,
    count: number,
    selectedUsbDeviceStatus: DeviceStatus[] | undefined,
    searchedFirstDetected: TimestampFilter,
    searchedLastActive: TimestampFilter,
    searchedVendor: string | undefined,
    searchedProductName: string | undefined,
    searchedType: USBDeviceType[],
    hasPurpose: boolean | undefined,
    sortColumn: SortColumnType,
    allLocations: Location[],
    searchedVessels: Set<LocationIdType> | undefined,
    searchedVesselTagTerm: string[],
    searchedVesselNameTerm: string,
): void {
    const filteredVessels = filteredVesselIds(
        allLocations,
        searchedVessels,
        searchedVesselTagTerm,
        searchedVesselNameTerm,
    )
    REST.post(`${USB_DEVICES_ENDPOINT}/find`, {
        pagination: {pageSize: count, pageOffset: offset},
        deviceStatuses: selectedUsbDeviceStatus,
        fromRelativeFirstSeen: formatTimestampCriteria(searchedFirstDetected),
        fromRelativeLastSeen: formatTimestampCriteria(searchedLastActive),
        vendorName: searchedVendor ? searchedVendor : null,
        productName: searchedProductName ? searchedProductName : null,
        deviceTypes: searchedType,
        hasPurpose: hasPurpose,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        orderBy: {column: sortColumn.orderBy, isAscending: sortColumn.orderAscending},
    }).then((response) => {
        dispatch(
            ActionCreators.receivedRequestedPageData(
                response.data.data,
                response.data.totalNumberOfItems ?? 0,
                response.data.totalNumberOfPages,
            ),
        )
    })
}
