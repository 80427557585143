import {addEvent} from '../add-event'
import {DisplayFormatType} from '../helpers/display-format-type'

export function downloadIncidentAttachmentEvent(
    contentType: string,
    status: string,
    displayFormat: DisplayFormatType,
): boolean {
    return addEvent('downloadIncidentAttachment', {
        contentType,
        incidentStatus: status,
        displayFormat,
    })
}
