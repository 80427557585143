import * as Styles from './software-details-expanded.styled'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {closeSoftwareDetailsModalEvent} from '../../../../../core/google-tag-manager/software-inventory/close-software-details-modal'
import {SOFTWARE_INVENTORY_DETAILS} from '../../data-helpers'
import {ActivityContents} from '../../software-details-modal/activity/activity-contents'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {ActionsContents} from '../../software-details-modal/actions/actions-contents'

export function SoftwareDetailsExpanded(): JSX.Element {
    const {closeSoftwareDetailsModal, findSoftwareDetailsForModal} = usePagedSoftwareInventory()

    return (
        <Styles.DetailsSection
            id={`software-details-card-extended-${findSoftwareDetailsForModal?.identity}`}
        >
            <Styles.DetailsContent>
                <Styles.ModalTitle>Activity</Styles.ModalTitle>
                <ActivityContents cardView />
            </Styles.DetailsContent>

            <Styles.DetailsContent>
                <Styles.ModalTitle>Actions</Styles.ModalTitle>
                <ActionsContents />
            </Styles.DetailsContent>

            <Styles.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closeSoftwareDetailsModal()
                    removeQueryParam(SOFTWARE_INVENTORY_DETAILS)
                    closeSoftwareDetailsModalEvent()
                }}
                id="close-details-icon"
            />
        </Styles.DetailsSection>
    )
}
