import {useContext} from 'react'
import {REST} from '../../../..'
import {warn} from '../../../../helpers/logging'
import {ModalEditAssetContext} from './modal-edit-asset-context'
import * as ActionCreators from './state/action-creators'
import {UsePagedUniqueFlowsResult} from './use-modal-edit-asset-output'
import {NodeValue} from '../../../../values/nodes/NodeData'
import {ProcessingStatus} from '../../../../values/processing-save-state-enum'
import {fetchNodes} from '../../../../store/state/nodes/action-creators'
import {ThunkDispatch} from 'redux-thunk'
import AppState from '../../../../store/types/app-state'
import {Api} from '../../../../api/Api'
import {Action} from '../../../../store/state/nodes/actions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {currentAssetValueReselector} from '../../components/edit-asset-modal/reselector/current-asset-value-reselector'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {removeQueryParam} from '../../../../helpers/navigation'
const UNKNOWN_ASSETS_ENDPOINT = 'aimee/api/v1/assets'

export function useModalEditAsset(): UsePagedUniqueFlowsResult {
    const {state, dispatch} = useContext(ModalEditAssetContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useModalEditAsset must be used within a ModalEditAssetContext')
    }
    const queryString = useTypedSelector(queryStringObjectSelector)
    const editAssetId = queryString ? (queryString.editAsset as string) : null

    const currentAsset = useTypedSelector((state) =>
        currentAssetValueReselector(state, editAssetId),
    )

    function setNewAssetValue(value: NodeValue): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeAssetValue(value))
    }

    function setNewHostname(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeHostname(value))
    }
    async function saveEditAssetChanges(
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.PROCESSING))
        if (editAssetId) {
            const requestBody: {
                hostname?: string
                alias?: string
                value?: string
            } = {}

            if (state.newHostname.length !== 0) {
                requestBody.hostname = state.newHostname
                requestBody.alias = state.newHostname
            }

            if (state.newAssetValue) {
                requestBody.value = state.newAssetValue
            }

            await REST.patch(`${UNKNOWN_ASSETS_ENDPOINT}/${editAssetId}`, requestBody)
                .then(() => {
                    dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.PROCESSED))

                    setTimeout(() => {
                        dispatch(
                            ActionCreators.setSaveProcessingStatus(ProcessingStatus.NOT_PROCESSING),
                        )
                        removeQueryParam('editAsset')
                        thunkDispatch(fetchNodes())
                    }, 2000)
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(error)
                    dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.FAILED))
                })
        }
    }
    return {
        assetId: editAssetId,
        isProcessingSave: state.isProcessingSave,
        newHostname: state.newHostname,
        newAssetValue: state.newAssetValue,
        currentAssetValue: currentAsset.nodeValue,
        currentHostname: currentAsset.nodeHostname,
        setNewHostname,
        setNewAssetValue,
        saveEditAssetChanges,
    }
}
