import moment from 'moment'
import * as Styled from './trends.styled'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import {MetricTrendsArrayForUIDisplay} from '../../contexts/types/metrics-summary'
export interface CurrentScoreTooltipProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    active?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label?: any
    data: MetricTrendsArrayForUIDisplay[]
}

export function CurrentScoreTooltip({
    active,
    payload,
    data,
    label,
}: CurrentScoreTooltipProps): JSX.Element | null {
    if (active && payload && payload.length) {
        const dataPoint = data.find((element) => element.timestamp === label)
        return (
            <Styled.ToolTipContainer id={`ToolTipContainer-${dataPoint?.totalAssetsOkScore}`}>
                <Styled.ToolTipTitle>
                    <div>Assets OK: {Math.round(dataPoint?.totalAssetsOkScore ?? 0)}%</div>
                </Styled.ToolTipTitle>
                <Styled.ToolTipSubTitle>
                    {formatDateWithoutTime(moment(label))}
                </Styled.ToolTipSubTitle>
            </Styled.ToolTipContainer>
        )
    }

    return null
}
