import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../../store/state/locations/selectors'
import {getLocationDescription} from '../../../../../../store/state/locations/state'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import * as Styled from './current-applied-vessels.style'
import {SectionContent, SectionTitle} from '../assign-policy-dialog.styled'

interface AppliedVesselsProps {
    locations: GuidType[]
}

export function CurrentAppliedVessels({locations}: AppliedVesselsProps): JSX.Element {
    const allLocations = useTypedSelector(locationMapSelector)

    return (
        <SectionContent>
            <SectionTitle> Currently assigned vessels:</SectionTitle>
            <Styled.CurrentAppliedVessels>
                {locations
                    .filter((location) => allLocations.has(location))
                    .map((location, index) => (
                        <Styled.CurrentVessel key={index}>
                            • {getLocationDescription(allLocations, location)}
                        </Styled.CurrentVessel>
                    ))}
            </Styled.CurrentAppliedVessels>
        </SectionContent>
    )
}
