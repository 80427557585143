import {useRef, DragEvent, ChangeEvent, Dispatch, SetStateAction, useState} from 'react'
import * as Styled from './drag-and-drop-file.styled'
import UPLOAD_DOC from '../pdf-icon-upload.svg'
import {UploadButton} from './upload-button'
import {ProcessingStatus} from '../../../values/processing-save-state-enum'

interface NativeDragAndDropProps {
    setFile: Dispatch<SetStateAction<File | null>>
    isSelectFileDisabled: boolean
    setIsProcessingSave: Dispatch<SetStateAction<ProcessingStatus>>
}
export function NativeDragAndDrop({
    setFile,
    isSelectFileDisabled,
    setIsProcessingSave,
}: NativeDragAndDropProps): JSX.Element {
    const [isDragOver, setIsDragOver] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(false)
        setIsProcessingSave(ProcessingStatus.PROCESSING)
        const droppedFiles = Array.from(event.dataTransfer.files)
        // Simulate file selection (remove this part when integrating with API)
        setTimeout(() => {
            if (droppedFiles.length > 0) {
                setFile(droppedFiles[0])
                setIsProcessingSave(ProcessingStatus.PROCESSED)
            }
        }, 2000)
    }

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
    }

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(true)
    }

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(false)
    }

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        setIsProcessingSave(ProcessingStatus.PROCESSING)
        const selectedFiles = Array.from(event.target.files || [])
        // Simulate file selection (remove this part when integrating with API)
        setTimeout(() => {
            if (selectedFiles.length > 0) {
                setFile(selectedFiles[0])
                setIsProcessingSave(ProcessingStatus.PROCESSED)
            }
        }, 2000)
    }

    const openFileBrowser = () => {
        if (!isSelectFileDisabled) {
            fileInputRef.current?.click()
        }
    }

    return (
        <Styled.DragAndDropWrapper
            isDragOver={isDragOver}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            aria-disabled={isSelectFileDisabled}
        >
            <Styled.ImageHeader src={UPLOAD_DOC} id="drag-and-drop-header_title-icon" />

            <Styled.DragAndDropBox onClick={openFileBrowser}>
                <Styled.SectionTitle>Drag and drop PDF file to upload</Styled.SectionTitle>
                <input
                    type="file"
                    onChange={handleFileSelect}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                />
            </Styled.DragAndDropBox>
            <UploadButton onClick={openFileBrowser} disabled={isSelectFileDisabled} />
        </Styled.DragAndDropWrapper>
    )
}
