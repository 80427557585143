import {RefreshButton} from '../common/refresh-button/refresh-button'
import * as styled from '../common/data-actions.styled'
import {usePagedAlerts} from '../../../contexts/alerts/use-paged-alerts'

export function DataActions(): JSX.Element {
    const {loading, refreshData, totalNumberOfEvents} = usePagedAlerts()

    return (
        <styled.DataActions>
            <RefreshButton
                id="alerts_refresh"
                loading={loading}
                totalNumberOfRecords={totalNumberOfEvents}
                refreshData={refreshData}
                state="alerts"
            />
        </styled.DataActions>
    )
}
