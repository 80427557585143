import {PopulatedData} from './populated-data'
import {NoData} from './no-data'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {Level} from '../../../../../values/location-level-enum'
import {latestIncidentResponseSelector} from '../../../../../store/state/incident-overview/selectors'

export function DataLoaded(): JSX.Element {
    const latestIncident = useTypedSelector(latestIncidentResponseSelector)
    return latestIncident == null ? <NoData level={Level.UNKNOWN} /> : <PopulatedData />
}
