import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Area,
    AreaChart,
    ReferenceArea,
} from 'recharts'
import {CyberOwlTheme} from '../../../../theme/theme'
import * as Styled from './trends.styled'
import {useTheme} from 'styled-components'
import {MetricType} from '../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {CurrentScoreTooltip} from './current-score-tooltip'
import {
    MetricTrendsArray,
    MetricTrendsArrayForUIDisplay,
} from '../../contexts/types/metrics-summary'
import {dataLine} from './data-line'
import TRENDS from '../../../../@assets/icons/trends-metrics.svg'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {NoData} from '../no-data/no-data'
import LoadingState from '../../../../values/loading-state-enum'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {getColor} from './score-bar.styled'
import {TimestampFilterType} from '../../../../store/state/metrics-filter-beta/state'

function yAxis(theme: CyberOwlTheme, id: string): JSX.Element {
    const ticks = [0, 25, 50, 75, 100]
    return (
        <YAxis
            type="number"
            domain={[0, 100]}
            ticks={ticks}
            tickLine={true}
            fontSize="14px"
            color={theme.colors.text.default}
            fontWeight={theme.font.weight.semibold}
            id={`y-axis-${id}`}
        />
    )
}
interface TrendsProps {
    metricType: MetricType
}
export function Trends({metricType}: TrendsProps): JSX.Element {
    const theme = useTheme()
    const {width} = useDimensions()

    const {dataSummariesMetricsMap, showFilterBar, loadingFilteredDataState} = usePagedMetricsBeta()
    const {selectedViewScreenType, periodForTrend} = useTypedSelector(metricsBetaFilterSelector)
    const trendsArray = dataSummariesMetricsMap?.get(metricType)?.trends

    if (loadingFilteredDataState === LoadingState.RequestingData) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-trends-section-${metricType}`}
            >
                <TitleWrapper
                    icon={TRENDS}
                    title="Trends"
                    scorecardView={selectedViewScreenType === 'metrics'}
                    id={metricType}
                />
                <DataLoading />
            </Styled.Section>
        )
    }
    if (!trendsArray) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-trends-section-${metricType}`}
            >
                <TitleWrapper
                    icon={TRENDS}
                    title="Trends"
                    scorecardView={selectedViewScreenType === 'metrics'}
                    id={metricType}
                />
                <NoData text="No Assets are found" />
            </Styled.Section>
        )
    }
    // Determine start and end date based on the analysis period
    const endDate = new Date()
    const startDate = new Date(endDate)
    switch (periodForTrend) {
        case '365d':
            startDate.setFullYear(endDate.getFullYear() - 1)
            break
        case '71d':
            startDate.setDate(endDate.getDate() - 71)
            break
        case '14d':
            startDate.setDate(endDate.getDate() - 14)
            break
        case '2h':
            startDate.setHours(endDate.getHours() - 2)
            break
        default:
            startDate.setFullYear(endDate.getFullYear() - 1)
    }

    function getTrendsArrayForUIDisplay(
        trendsArray: MetricTrendsArray[],
    ): MetricTrendsArrayForUIDisplay[] {
        if (!trendsArray) {
            return new Array<MetricTrendsArrayForUIDisplay>()
        }
        return trendsArray.map((trendItem) => ({
            timestamp: new Date(trendItem.timestamp).getTime(),
            totalAssetsOkScore: isNaN(
                trendItem.totalAssetsOk /
                    (trendItem.totalAssetsOk + trendItem.totalAssetsWithIssue),
            )
                ? 0
                : Math.round(
                      (trendItem.totalAssetsOk /
                          (trendItem.totalAssetsOk + trendItem.totalAssetsWithIssue)) *
                          100,
                  ),
        }))
    }
    const formattedData = getTrendsArrayForUIDisplay(trendsArray)
    // Adjust x2 if it's the same as x1
    const singleDataPoint =
        formattedData[0].timestamp === formattedData[formattedData.length - 1].timestamp
    const x2 = singleDataPoint
        ? findX2PositionFromPeriod(formattedData[0].timestamp, periodForTrend)
        : formattedData[formattedData.length - 1].timestamp
    const yValue = formattedData[0].totalAssetsOkScore
    return (
        <Styled.Section
            showFilterBar={showFilterBar}
            width={width}
            id={`footer-trends-section-${metricType}`}
        >
            <TitleWrapper
                icon={TRENDS}
                title="Trends"
                scorecardView={selectedViewScreenType === 'metrics'}
                id={metricType}
            />
            <Styled.Title>Assets with status OK</Styled.Title>
            <ResponsiveContainer height={180}>
                <AreaChart margin={{top: 15, right: 20, bottom: -10}} data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" fill="#f0f0f0" id="CartesianGrid" />
                    <XAxis
                        dataKey="timestamp"
                        type="number"
                        tick={false}
                        domain={[startDate.getTime(), endDate.getTime()]}
                        id={`x-axis-${metricType}`}
                    />

                    {yAxis(theme, metricType)}
                    <Tooltip content={<CurrentScoreTooltip data={formattedData} />} />
                    <ReferenceArea
                        x1={formattedData[0].timestamp}
                        x2={x2}
                        fill="#ffffff"
                        fillOpacity={1}
                        id="area-background"
                        strokeWidth={5}
                    />
                    {!singleDataPoint ? (
                        <Area
                            id="area-chart"
                            type="monotone"
                            dataKey="totalAssetsOkScore"
                            fill={getColor(metricType)}
                            stroke={theme.colors.graph.primary}
                            dot={{
                                stroke: theme.colors.graph.primary,
                                strokeWidth: 2,
                                fill: '#ffffff',
                            }}
                            fillOpacity={0.5}
                            isAnimationActive={true}
                        />
                    ) : (
                        <>
                            <ReferenceArea
                                x1={formattedData[0].timestamp}
                                x2={x2}
                                y1={yValue}
                                fill="#ffffff"
                                fillOpacity={1}
                                id="one-point-background-white"
                                strokeWidth={5}
                            />
                            <ReferenceArea
                                x1={formattedData[0].timestamp}
                                x2={x2}
                                y1={yValue}
                                y2={0}
                                fill={getColor(metricType)}
                                fillOpacity={1}
                                id="one-point-background-colour"
                            />
                            <Area
                                id="area-chart"
                                type="monotone"
                                dataKey="totalAssetsOkScore"
                                fill={getColor(metricType)}
                                stroke={theme.colors.graph.primary}
                                dot={{
                                    stroke: theme.colors.graph.primary,
                                    strokeWidth: 1,
                                    fill: '#ffffff',
                                }}
                                fillOpacity={0.5}
                                connectNulls={true}
                            />
                        </>
                    )}
                    {dataLine(theme, formattedData)}
                </AreaChart>
            </ResponsiveContainer>
        </Styled.Section>
    )
}
interface TitleWrapperProps {
    icon: string
    title: string
    scorecardView: boolean
    id: string
}

function TitleWrapper({icon, title, scorecardView, id}: TitleWrapperProps): JSX.Element {
    return (
        <Styled.TitleWrapper scorecardView={scorecardView} id={`footer-trends-title-${id}`}>
            <img src={icon} id={`footer-trends-icon-${id}`} />
            {title}
        </Styled.TitleWrapper>
    )
}

function findX2PositionFromPeriod(timestamp: number, periodForTrend: TimestampFilterType): number {
    switch (periodForTrend) {
        case '365d':
            return timestamp + 86400000 //add 1 day
        case '71d':
            return timestamp + 15400000
        case '14d':
            return timestamp + 7200000 //add 2h
        case '2h':
            return timestamp + 60000
        default:
            return timestamp + 60000
    }
}
