import {useDispatch} from 'react-redux'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import * as Styles from './metrics-type-filter.styled'
import {ViewAllMetricTypeRow} from './view-all-metrics-type-row'
import {ExpandFilterDetailsButton} from '../shared/expand-filter-details-button/expand-filter-details-button'
import {AppliedFilterIndicator} from '../../../../../components/page-filter/components/shared/applied-filter-indicator/applied-filter-indicator'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {toggleAllMetricType} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {FilterBarType} from '../../../../../store/state/metrics-filter-beta/state'
import {determineViewAllState} from '../../data-helper'
import {ViewAllProtectionMetrics} from './view-all-protection-metrics'
import {ViewAllMaintenanceMetrics} from './view-all-maintenance-metrics'
import {ViewAllBehaviourMetrics} from './view-all-behaviour-metrics'
import {
    isProtectionMetricTypesSelector,
    isMaintenanceMetricTypesSelector,
    isBehaviourMetricTypesSelector,
    allMainMetricsTypeSelector,
} from '../../../../../store/state/metric-types/selectors'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {filterByEvent} from '../../../../../core/google-tag-manager/metrics-beta/filter-by-event'

export function MetricsTypeFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {filterExpanded, metricTypes} = useTypedSelector(metricsBetaFilterSelector)
    const allMainMetricsType = useTypedSelector(allMainMetricsTypeSelector)
    const {highlightedFilerValue} = usePagedMetricsBeta()

    const protectionMetricTypes = useTypedSelector(isProtectionMetricTypesSelector)
    const maintenanceMetricTypes = useTypedSelector(isMaintenanceMetricTypesSelector)
    const behaviourMetricTypes = useTypedSelector(isBehaviourMetricTypesSelector)
    const numOfAppliedFilter = !metricTypes ? undefined : metricTypes?.length

    const viewAll = determineViewAllState(allMainMetricsType, metricTypes)

    function setViewAllState(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleAllMetricType(true))
            filterByEvent('View all metrics', true)
        } else if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleAllMetricType(false))
            filterByEvent('View all metrics', false)
        }
    }

    const showFilteringIndicator =
        filterExpanded && !filterExpanded.metricsExpanded && numOfAppliedFilter != undefined

    const showClearButton = numOfAppliedFilter != 0
    return (
        <Styles.AssetStatus id={`${PageType.METRICS}_filter-bar_metrics-filter`}>
            <ExpandFilterDetailsButton headingRow={FilterBarType.MetricsType} />
            {showFilteringIndicator && (
                <AppliedFilterIndicator numOfAppliedFilter={numOfAppliedFilter} />
            )}
            {filterExpanded && filterExpanded.metricsExpanded && (
                <Styles.Wrapper
                    highlightedFilerValue={highlightedFilerValue === 'Framework'}
                    id={`${PageType.METRICS}_filter-bar_metrics-filter_wrapper`}
                >
                    <ViewAllMetricTypeRow
                        state={viewAll}
                        setState={setViewAllState}
                        showClearButton={showClearButton}
                        label="View All"
                    />
                    <Styles.MainMetricsWrapper>
                        {protectionMetricTypes && <ViewAllProtectionMetrics />}
                        {maintenanceMetricTypes && <ViewAllMaintenanceMetrics />}
                        {behaviourMetricTypes && <ViewAllBehaviourMetrics />}
                    </Styles.MainMetricsWrapper>
                </Styles.Wrapper>
            )}
        </Styles.AssetStatus>
    )
}
