import {useDispatch} from 'react-redux'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {clickIndividualNetworkEvent} from '../../../../../core/google-tag-manager/unknown-assets-beta/click-individual-network-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {toggleNetwork} from '../../../../../store/state/network-assets-filter/action-creators'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {NetworkType} from '../../../context/types/network-type'
import {StatusRowWrapper} from '../network-asset-state-filter/network-asset-state-filter.styled'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

function numberOfOtherNetworksSelected(
    selectedNetwork: NetworkType,
    networksSelected: NetworkType[] | undefined,
): number {
    if (!networksSelected || networksSelected.length === 0) {
        return 0
    }
    return networksSelected.includes(selectedNetwork)
        ? networksSelected.length - 1
        : networksSelected.length
}

interface Props {
    networkLabel: NetworkType
}
export function NetworkRow({networkLabel}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {networks} = useTypedSelector(networkAssetsFilterSelector)
    const isInactive = useTypedSelector(isInactiveSelector)
    const allNetworks = new Array<NetworkType>(
        NetworkType.NetworkTypeBusiness,
        NetworkType.NetworkTypeCrew,
        NetworkType.NetworkTypeOT,
        NetworkType.NetworkTypeOther,
    )

    function isChecked(givenValue: NetworkType): boolean {
        return !networks ? true : networks.includes(givenValue)
    }

    function setChecked(currentSelectedNetwork: NetworkType, newValue: boolean): void {
        dispatch(toggleNetwork(allNetworks, currentSelectedNetwork, newValue))
        clickIndividualNetworkEvent(
            newValue,
            numberOfOtherNetworksSelected(currentSelectedNetwork, networks),
        )
    }

    const formmattedLabel =
        networkLabel === NetworkType.NetworkTypeOther ? networkLabel : `${networkLabel} Network`

    return (
        <StatusRowWrapper>
            <Checkbox
                id={`${PageType.NETWORK_ASSETS}_filter-bar_network-filter_${networkLabel}`}
                label={formmattedLabel}
                checked={isChecked(networkLabel)}
                onChange={(newValue) => setChecked(networkLabel, newValue)}
                isInactive={isInactive ?? false}
            />
        </StatusRowWrapper>
    )
}
