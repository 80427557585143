import {addEvent} from '../add-event'

export function clickIndividualNetworkAssetStateEvent(
    checked: boolean,
    numberOfOtherNetworkAssetStateSelected: number,
): boolean {
    return addEvent('networkAssetsPageFilterClickIndividualNetworkAssetState', {
        newState: checked ? 'Selected' : 'Not selected',
        numberOfOtherNetworkAssetStateSelected,
    })
}
