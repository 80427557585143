import {LocationIdType} from '../../../../store/state/locations/state'

export interface TotalNumberOfItemsBySeverity {
    LOW: number
    MEDIUM: number
    HIGH: number
    CRITICAL: number
}
export interface TotalNumberOfItemsByStatus {
    DRAFT: number
    NEW: number
    OPEN: number
    CLOSED: number
    CLOSED_FALSE_POSITIVE: number
    CLOSED_MONITOR: number
}
export interface TotalNumberOfItemsByTypes {
    DEFAULT: number
    NETWORK: number
    PUP: number
    MISCONFIGURATION: number
    UNKNOWN_ASSETS: number
    MALWARE: number
    POTENTIALLY_MALICIOUS_FILE: number
    ANOMALOUS_BEHAVIOUR: number
    COMPLIANCE: number
    REMOTE_ACCESS: number
    DATA_TRANSFER: number
    ACCOUNT_ACTIVITY: number
    INFORMATIONAL: number
}

export enum IncidentResponseSortType {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    LOCATION_NAME = 'LOCATION_NAME',
    STATUS = 'STATUS',
    SEVERITY = 'SEVERITY',
    TYPE = 'TYPE',
    CODE = 'CODE',
    TITLE = 'TITLE',
    ASSIGNED_TO = 'ASSIGNED_TO',
}
export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}

export interface GuestEmails {
    guestEmail: string
    locations: LocationIdType[]
}
export interface AssignedTo {
    id: string | undefined
    name: string
}
