import LoadingState from '../../../values/loading-state-enum'
import {IncidentsOverview} from './types/incidents-overview'

export interface IncidentsOverviewReduxState {
    loading: LoadingState
    overview: IncidentsOverview
}

export function defaultIncidentsOverview(): IncidentsOverview {
    return {
        latestIncident: undefined,
        newIncidentsCount: 0,
        openIncidentsCount: 0,
        averageIncidentAge: 0,
    } as IncidentsOverview
}

export const DEFAULT_INCIDENTS_OVERVIEW: IncidentsOverviewReduxState = {
    loading: LoadingState.NotPopulated,
    overview: defaultIncidentsOverview(),
}
