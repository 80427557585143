import {useDispatch} from 'react-redux'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'

import {watchListSelector} from '../../../store/state/watched-nodes/selectors'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {
    addNodeToWatchList,
    deleteNodeFromWatchList,
} from '../../../store/state/watched-nodes/action-creators'
import * as Styled from './watchlist-button.styled'
import {GuidType} from '../../../values/generic-type-defintions'
import {Dispatch} from 'react'
import {watchAssetEvent} from '../../../core/google-tag-manager/watch-asset-event'
import {unwatchAssetEvent} from '../../../core/google-tag-manager/unwatch-asset-event'
import {nodesSelector} from '../../../store/state/nodes/selectors'
import {NodeData} from '../../../values/nodes/NodeData'
import {sfmScoresSelector} from '../../../store/state/sfm-scores/selectors'

interface ClickableButtonProps {
    assetId: GuidType
}

export function watchAsset(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    node: NodeData | undefined,
    score: number | undefined,
): void {
    if (node) {
        watchAssetEvent(node.value, score)
        dispatch(addNodeToWatchList(node.node))
    }
}

export function unwatchAsset(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    node: NodeData | undefined,
    score: number | undefined,
): void {
    if (node) {
        unwatchAssetEvent(node.value, score)
        dispatch(deleteNodeFromWatchList(node.node))
    }
}

export function ClickableButton({assetId}: ClickableButtonProps): JSX.Element {
    const dispatch = useDispatch()
    const isIncludedInWatchList = useTypedSelector(watchListSelector)?.includes(assetId)
    const action = isIncludedInWatchList ? 'Unwatch' : 'Watch'
    const asset = useTypedSelector(nodesSelector)?.get(assetId)
    const scores = useTypedSelector(sfmScoresSelector)
    const score = (scores && scores?.get(assetId)) ?? undefined
    return (
        <Styled.Button
            id={`watch-asset_${assetId}`}
            onClick={() => {
                isIncludedInWatchList
                    ? unwatchAsset(dispatch, asset, score)
                    : watchAsset(dispatch, asset, score)
            }}
        >
            <Icon glyph={action} height={16} width={16} />
            <Styled.Text>{action}</Styled.Text>
        </Styled.Button>
    )
}
