import {OTLocation} from '../../types/ot-inventory-response'
import ActionType from './action-type'
import * as Actions from './actions'

/**
 * Return ReduxAction for request of OTLocations
 */
export function requestOTLocations(): Actions.RequestOTLocations {
    return {
        type: ActionType.REQUEST_LOCATIONS,
    }
}

/**
 * Return ReduxAction for setting OTLocations
 *
 * @param data Value to be set
 */
export function setOTLocations(data: OTLocation[]): Actions.SetOTLocations {
    return {
        type: ActionType.SET_LOCATIONS,
        payload: {
            data,
        },
    }
}

/**
 * Return ReduxAction for failed fetching OTLocations request
 */
export function failFetchOTLocations(): Actions.FailFetchOTLocations {
    return {
        type: ActionType.FAIL_FETCH_LOCATIONS,
    }
}

/**
 * Perform SetOTLOcationsFilter using provided filter
 *
 * @param filter text to be used in filter
 */
export function setOTLocationsFilter(filter: string): Actions.SetOTLocationsFilter {
    return {
        type: ActionType.SET_FILTER,
        payload: {
            filter,
        },
    }
}
