import {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
    createOrAssignLocationTag,
    setTagToCreateOrAssign,
    setTagToUpdate,
    updateLocationTag,
    deleteLocationTag,
    setSearchTerm,
} from '../../../../../../../store/state/vessel-tags/action-creators'
import {
    errorMessageSelector,
    isTheMessageErrorSelector,
    searchTermSelector,
    tagToCreateOrAssignSelector,
    tagToUpdateSelector,
    vesselTagsSelector,
} from '../../../../../../../store/state/vessel-tags/selectors'
import {VesselTags} from '../../../../../../../store/state/vessel-tags/state'
import {GuidType} from '../../../../../../../values/generic-type-defintions'
import * as Styled from './vessel-tagging.styled'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {updateLocationTagEvent} from '../../../../../../../core/google-tag-manager/my-vessels/update-vessel-tag-event'
import {createOrAssignLocationTagEvent} from '../../../../../../../core/google-tag-manager/my-vessels/create-or-assign-vessel-tag-event'
import {findRecommendationFromEntryTag} from '../../../../../../../components/vessel-filters/data-helper'
import {useOnClickOutside} from '../../../../../../../hooks/useOnClickOutside'
import {deleteOrUnassignLocationTagEvent} from '../../../../../../../core/google-tag-manager/my-vessels/delete-or-unassign-vessel-tag-event'
import {VesselTag} from '../../../../../../../components/vessel-tag/vessel-tag'

interface Props {
    vesselTags: VesselTags[]
    locationIdentity: GuidType
}
export function VesselTagging({vesselTags, locationIdentity}: Props): JSX.Element {
    const [recommendationOpen, setRecommendationOpen] = useState(false)

    const existingTags = useTypedSelector(vesselTagsSelector)
    const errorMessage = useTypedSelector(errorMessageSelector)
    const tagToCreateOrAssign = useTypedSelector(tagToCreateOrAssignSelector)
    const tagToUpdate = useTypedSelector(tagToUpdateSelector)
    const searchTag = useTypedSelector(searchTermSelector)

    const isTheMessageError = useTypedSelector(isTheMessageErrorSelector)
    const dispatch = useDispatch()

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        dispatch(setSearchTerm(e.target.value))
        if (tagToUpdate) {
            dispatch(setTagToUpdate({...tagToUpdate, name: e.target.value}))
        } else {
            setRecommendationOpen(true)
            dispatch(
                setTagToCreateOrAssign({
                    locationIdentity: locationIdentity,
                    tagDataDefinition: {name: e.target.value},
                }),
            )
        }
    }

    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            setRecommendationOpen(false)
            if (searchTag.trim().length !== 0) {
                if (tagToUpdate) {
                    dispatch(updateLocationTag(tagToUpdate))
                    updateLocationTagEvent(tagToUpdate)
                } else {
                    tagToCreateOrAssign && dispatch(createOrAssignLocationTag(tagToCreateOrAssign))
                    createOrAssignLocationTagEvent(tagToCreateOrAssign)
                }
            }
        }
    }

    const tagToDisplay = tagToUpdate?.name ? tagToUpdate.name : searchTag

    const result = findRecommendationFromEntryTag(existingTags, searchTag)
    const showRecomandations = recommendationOpen && result?.length !== 0

    const displayEditOptions = tagToUpdate?.name != null

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setRecommendationOpen(false))

    return (
        <Styled.SectionContent>
            <div style={{position: 'relative'}}>
                <Styled.SearchInput
                    id="vessel-tag-search"
                    type="text"
                    placeholder=" Enter tag "
                    value={tagToDisplay}
                    onChange={onChangeFn}
                    onKeyPress={onEnterClick}
                    autoComplete="off"
                />
                {displayEditOptions && (
                    <Styled.DoneOption
                        onClick={() => {
                            tagToUpdate && dispatch(updateLocationTag(tagToUpdate))
                            updateLocationTagEvent(tagToUpdate)
                        }}
                        disabledOption={searchTag !== tagToDisplay}
                    >
                        Done
                    </Styled.DoneOption>
                )}
            </div>
            <div style={{display: 'flex'}}>
                {displayEditOptions && (
                    <>
                        <Styled.EditOption
                            onClick={() => {
                                if (tagToUpdate) {
                                    dispatch(
                                        deleteLocationTag(locationIdentity, tagToUpdate?.identity),
                                    )
                                    deleteOrUnassignLocationTagEvent(
                                        locationIdentity,
                                        tagToUpdate?.identity,
                                    )
                                }
                            }}
                        >
                            Delete tag
                        </Styled.EditOption>
                        <Styled.EditOption
                            onClick={() => {
                                dispatch(setTagToUpdate(null))
                            }}
                        >
                            Cancel
                        </Styled.EditOption>
                    </>
                )}
                <Styled.CharsCount reachedLimit={tagToDisplay.trim().length > 15}>{`${
                    tagToDisplay.trim().length
                }/15`}</Styled.CharsCount>
            </div>
            <Styled.TagResultsWrapper ref={clickOutside}>
                {showRecomandations && (
                    <Styled.TagResultsPopUp>
                        {result?.map((tag, index) => (
                            <VesselTag
                                key={index}
                                tagName={tag.name}
                                onClickFn={() => {
                                    dispatch(
                                        createOrAssignLocationTag({
                                            locationIdentity: locationIdentity,
                                            locationTagIdentity: tag.identity,
                                        }),
                                    )
                                    createOrAssignLocationTagEvent({
                                        locationIdentity: locationIdentity,
                                        locationTagIdentity: tag.identity,
                                    })
                                    setRecommendationOpen(false)
                                }}
                            />
                        ))}
                    </Styled.TagResultsPopUp>
                )}
                {isTheMessageError && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
                {vesselTags?.map((tag, index) => (
                    <VesselTag
                        key={index}
                        tagName={tag.name}
                        onClickFn={() => {
                            dispatch(setTagToUpdate({identity: tag.identity, name: tag.name}))
                        }}
                    />
                ))}
            </Styled.TagResultsWrapper>
        </Styled.SectionContent>
    )
}
