import * as Styled from './upload-processing.styled'
import PDF_UPLOAD from '../pdf-icon-upload.svg'
import {Loader, Trash2} from 'react-feather'
interface UploadProps {
    fileName: string
    fileSize: string
}
export function UploadInProgress({fileName, fileSize}: UploadProps): JSX.Element {
    return (
        <>
            <Styled.FileBox>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Styled.FileImage src={PDF_UPLOAD} />
                    <Styled.SubTitle>{`${fileName} (${fileSize})`}</Styled.SubTitle>
                    <Trash2 color="blue" width={19} height={19} />
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Styled.SubTitle>Uploading</Styled.SubTitle>
                    <Loader color="blue" width={19} height={19} />
                </div>
            </Styled.FileBox>
        </>
    )
}
