import {createSelector} from 'reselect'
import {activeLocationSelector} from '../../../store/state/locations/selectors'
import {nodesSelector} from '../../../store/state/nodes/selectors'
import {Location} from '../../../store/state/locations/state'
import {NodeDataMap} from '../NodeData'
import {ThreatMeasureMap} from '../../ThreatMeasure'
import {fetchingSelector2} from '../../../store/common-selectors/fetching-selector'
import {sfmScoresSelector} from '../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../store/state/threat-measures/selectors'
import {watchListSelector} from '../../../store/state/watched-nodes/selectors'
import {latestTimestampSelector} from '../../../store/state/latest-event-timestamps/selectors'
import NodeRecord from './types/node-record'
import {populateNodeRecord, filterActiveLocation} from './helpers'

const sortOrder = ['high', 'medium', 'low']

export const watchedNodesReselector = createSelector(
    fetchingSelector2,
    nodesSelector,
    watchListSelector,
    threatMeasuresSelector,
    sfmScoresSelector,
    latestTimestampSelector,
    activeLocationSelector,
    (
        loading: boolean,
        nodes: NodeDataMap,
        watchList,
        threatMeasures: ThreatMeasureMap,
        sfmScores,
        latestEventTimestamps,
        activeLocation: Location,
    ): NodeRecord[] => {
        if (loading) {
            return []
        }

        const mappedData: NodeRecord[] = []
        for (const node of nodes.values()) {
            if (
                !filterActiveLocation(node.location, activeLocation) ||
                !watchList.includes(node.node)
            ) {
                continue
            }

            mappedData.push(
                populateNodeRecord(node, sfmScores, latestEventTimestamps, threatMeasures),
            )
        }

        return mappedData.sort(
            (a: NodeRecord, b: NodeRecord) =>
                sortOrder.indexOf(a.assetValueLevel) - sortOrder.indexOf(b.assetValueLevel) ||
                b.assetScore - a.assetScore,
        )
    },
)
