import {Dispatch, useContext} from 'react'
import {PagedIncidentsContext} from './paged-incidents-context'
import {UsePagedIncidentsResult} from './use-paged-incidents-output'
import * as ActionCreators from './state/action-creators'
import {AllActions} from './state/actions'
import {warn} from '../../../helpers/logging'
import {REST} from '../../..'
import {IncidentStatus} from './types/incident-status'
import {IncidentSeverityValue} from './types/incident-severity-types'
import {IncidentAttachmentData} from './types/incident-attachment-data'
import {GuidType} from '../../../values/generic-type-defintions'
import downloadFile from '../../../helpers/downloadFile'
import {
    SaveEditNoteAction,
    SaveNewNoteAction,
} from '../../../store/state/unsaved-incident-note-alert-modal/actions'
import {SaveMessage} from './types/save-note-result'
import {IncidentResponseSortType, ProcessingStatus} from './types/type-of-interfaces'
import {Location, LocationIdType} from '../../../store/state/locations/state'
import {ThunkDispatch} from 'redux-thunk'
import {Action} from '../../../store/state/watched-incidents/actions'
import {Api} from '../../../api/Api'
import AppState from '../../../store/types/app-state'
import {IncidentResponse} from './types/incident-response'
import {PagedIncidentsState} from './types/paged-incidents-state'
import {filteredVesselIds} from './reselector/filtered-vessel-ids.reselector'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {TimeRange} from '../../../values/TimeRange'
import {getRelativeTimeRange} from '../../../utils/Utils'
import {IncidentsFilterCriteria} from '../../../values/user-preferences/incidents-filter'
import {IncidentLink} from './types/incident-link'
import {vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import {incidentsFilterSelector} from '../../../store/state/incidents-filter/selectors'
import {getSortOrderValue} from '../data-helpers'
import {IncidentsFilterReduxState} from '../../../store/state/incidents-filter/state'
import {VesselFilterReduxState} from '../../../store/state/vessel-filter/types/vessel-filter-state'

const INCIDENTS_ENDPOINT = '/api/v1/incidentResponses'

export function usePagedIncidents(): UsePagedIncidentsResult {
    const {state, dispatch} = useContext(PagedIncidentsContext)

    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedIncidents must be used within a PagedIncidentsContext')
    }

    const allLocations = useTypedSelector(locationsSelector)
    const currentFilter = useTypedSelector(incidentsFilterSelector)
    const currentVesselFilter = useTypedSelector(vesselFilterSelector)

    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.requestInitialPageData())
        getData(
            dispatch,
            0,
            state.pageSize,
            currentFilter.selectedCreatedFromTimeRange,
            currentFilter.selectedCreatedToTimeRange,
            currentFilter.createdFromRelative,
            currentFilter.createdToRelative,
            currentFilter.updatedToRelative,
            currentFilter.updatedFromRelative,
            currentFilter.searchIncidentNo,
            currentFilter.orderIncidentsBy.column,
            getSortOrderValue(currentFilter.orderIncidentsBy.direction),
            currentFilter.filterBySeverities,
            currentFilter.filterByStatus,
            currentFilter.selectedIncidentType,
            currentFilter.selectedUpdatedFromTimeRange,
            currentFilter.selectedUpdatedToTimeRange,
            currentFilter.assignedTo,
            currentFilter.guestAssignedToEmail,
            currentFilter.watchedByCurrentUser,
            currentFilter.unseenIncidentResponses,
            currentFilter.assignedToVesselEmail,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            allLocations,
        )
    }

    function selectPage(requestedPage: number | undefined): void {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        if (requestedPage == undefined) {
            warn('requestedPage is undefined')
            return
        }

        const offset = requestedPage * state.pageSize

        dispatch(ActionCreators.requestPageData(requestedPage))
        getData(
            dispatch,
            offset,
            state.pageSize,
            currentFilter.selectedCreatedFromTimeRange,
            currentFilter.selectedCreatedToTimeRange,
            currentFilter.createdFromRelative,
            currentFilter.createdToRelative,
            currentFilter.updatedToRelative,
            currentFilter.updatedFromRelative,
            currentFilter.searchIncidentNo,
            currentFilter.orderIncidentsBy.column,
            getSortOrderValue(currentFilter.orderIncidentsBy.direction),
            currentFilter.filterBySeverities,
            currentFilter.filterByStatus,
            currentFilter.selectedIncidentType,
            currentFilter.selectedUpdatedFromTimeRange,
            currentFilter.selectedUpdatedToTimeRange,
            currentFilter.assignedTo,
            currentFilter.guestAssignedToEmail,
            currentFilter.watchedByCurrentUser,
            currentFilter.unseenIncidentResponses,
            currentFilter.assignedToVesselEmail,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            allLocations,
        )
    }

    function downloadIncidentResponse(
        identity: GuidType,
        attachment: IncidentAttachmentData,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getAttachmentIncidentResponse(dispatch, identity, attachment)
    }

    function getAssignedGuestEmailsFn(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getAssignedGuestEmails(dispatch)
    }

    function saveNotesToIncident(
        identity: GuidType,
        note: string,
        reduxDispatch: Dispatch<SaveNewNoteAction>,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        ActionCreators.saveNotes(
            dispatch,
            reduxDispatch,
            identity,
            note,
            () => updateOpenIncident(dispatch, identity),
            thunkDispatch,
        )
    }

    function updateNoteOfIncident(
        incidentIdentity: GuidType,
        noteIdentity: GuidType,
        newNote: string,
        reduxDispatch: Dispatch<SaveEditNoteAction>,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.updateNote(
            dispatch,
            reduxDispatch,
            incidentIdentity,
            noteIdentity,
            newNote,
            () => updateOpenIncident(dispatch, incidentIdentity),
            thunkDispatch,
        )
    }

    function changeIncidentResponseStatus(
        identity: GuidType,
        newStatus: string,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.changeIncidentResponseStatus(
            dispatch,
            identity,
            newStatus,
            () => requestDataFunction(),
            thunkDispatch,
        )
    }

    function reopenClosedMonitorIncident(
        identity: GuidType,
        monitoredItems: GuidType[],
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.reopenClosedMonitorIncident(
            dispatch,
            identity,
            monitoredItems,
            () => requestDataFunction(),
            () => updateOpenIncident(dispatch, identity),
            thunkDispatch,
        )
    }

    function changeIncidentResponseSeverity(
        identity: GuidType,
        newSeverity: string,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.changeIncidentResponseSeverity(
            dispatch,
            identity,
            newSeverity,
            () => requestDataFunction(),
            thunkDispatch,
        )
    }

    function assignIncidentResponseTo(
        identity: GuidType,
        assignedToWho: string | undefined,
        guestAssignedToEmail: string | undefined,
        assignedToVesselEmail: boolean | undefined,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.assignIncidentResponseTo(
            dispatch,
            identity,
            assignedToWho,
            guestAssignedToEmail,
            () => requestDataFunction(),
            () => updateOpenIncident(dispatch, identity),
            assignedToVesselEmail,
            thunkDispatch,
        )
    }

    function deassignIncidentResponseFrom(
        identity: GuidType,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.deassignIncidentResponseFrom(
            dispatch,
            identity,
            () => requestDataFunction(),
            () => updateOpenIncident(dispatch, identity),
            thunkDispatch,
        )
    }

    function updateLastViewed(identity: GuidType, user: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const offset = state.selectedPage * state.pageSize
        ActionCreators.updateLastViewed(dispatch, identity, user, () =>
            updateLastViewedFunction(
                dispatch,
                identity,
                state,
                currentFilter,
                currentVesselFilter,
                offset,
                allLocations,
            ),
        )
    }

    function toggleNoteEdit(id: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setEditableComment(id))
    }

    function setSaveMessage(
        message: SaveMessage | undefined,
        noteIdentity?: GuidType | undefined,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSaveMessage(message, noteIdentity))
    }

    function setFilter(
        filterByStatus: IncidentStatus[] | undefined,
        filterBySeverity: IncidentSeverityValue[] | undefined,
        incidentNo: string | null,
        orderIncidentsBy: {column: IncidentResponseSortType; ascending: boolean},
        selectedIncidentType: string | undefined,
        createdFromRelative: TimeRange | null,
        createdToRelative: TimeRange | null,
        updatedFromRelative: TimeRange | null,
        updatedToRelative: TimeRange | null,
        createdTimeRangeFrom: string | null,
        createdTimeRangeTo: string | null,
        updatedTimeRangeFrom: string | null,
        updatedTimeRangeTo: string | null,
        locations: Set<LocationIdType> | undefined,
        searchVesselTagTerm: string[],
        searchVesselNameTerm: string,
        assignedTo: string | undefined,
        guestAssignedToEmail: string | undefined,
        watchedByCurrentUser: boolean | null,
        unseenIncidentResponses: boolean | null,
        externalGuid: boolean,
        incidentModalId: GuidType,
        assignedToVesselEmail: boolean | null,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (externalGuid) {
            dispatch(ActionCreators.displayIncidentDetailsModal(incidentModalId))
            getData(
                dispatch,
                0,
                state.pageSize,
                null,
                null,
                null,
                null,
                null,
                null,
                incidentNo,
                IncidentResponseSortType.STATUS,
                false,
                undefined,
                undefined,
                undefined,
                null,
                null,
                undefined,
                undefined,
                null,
                null,
                null,
                undefined,
                [],
                '',
                allLocations,
            )
            getIncidentModalId(
                dispatch,
                state.pageSize,
                null,
                null,
                incidentNo,
                undefined,
                [],
                '',
                IncidentResponseSortType.STATUS,
                false,
                undefined,
                undefined,
                undefined,
                null,
                null,
                undefined,
                undefined,
                null,
                null,
                incidentModalId,
                allLocations,
            )
        } else {
            dispatch(
                ActionCreators.setFilter(
                    filterByStatus,
                    filterBySeverity,
                    incidentNo,
                    orderIncidentsBy,
                    selectedIncidentType,
                    createdTimeRangeFrom,
                    createdTimeRangeTo,
                    updatedTimeRangeFrom,
                    updatedTimeRangeTo,
                    createdFromRelative,
                    createdToRelative,
                    updatedToRelative,
                    updatedFromRelative,
                    locations,
                    searchVesselTagTerm,
                    searchVesselNameTerm,
                    assignedTo,
                    guestAssignedToEmail,
                    watchedByCurrentUser,
                    unseenIncidentResponses,
                    assignedToVesselEmail,
                ),
            )

            getData(
                dispatch,
                0,
                state.pageSize,
                createdTimeRangeFrom ?? null,
                createdTimeRangeTo ?? null,
                createdFromRelative ? createdFromRelative : null,
                createdToRelative ? createdToRelative : null,
                updatedToRelative ? updatedToRelative : null,
                updatedFromRelative ? updatedFromRelative : null,
                incidentNo ?? null,
                orderIncidentsBy != undefined
                    ? orderIncidentsBy.column
                    : IncidentResponseSortType.STATUS,
                orderIncidentsBy != undefined ? orderIncidentsBy.ascending : false,
                filterBySeverity ? filterBySeverity : undefined,
                filterByStatus ? filterByStatus : undefined,
                selectedIncidentType ? selectedIncidentType : undefined,
                updatedTimeRangeFrom ? updatedTimeRangeFrom : null,
                updatedTimeRangeTo ? updatedTimeRangeTo : null,
                assignedTo ? assignedTo : undefined,
                guestAssignedToEmail ? guestAssignedToEmail : undefined,
                watchedByCurrentUser ? watchedByCurrentUser : null,
                unseenIncidentResponses ? unseenIncidentResponses : null,
                assignedToVesselEmail ? assignedToVesselEmail : null,
                locations,
                searchVesselTagTerm,
                searchVesselNameTerm,
                allLocations,
            )
        }
    }

    function displayIncidentDetailsModal(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayIncidentDetailsModal(identity))
        dispatch(
            ActionCreators.setFindIncident(
                state.dataIncidentsMap
                    ?.get(state.selectedPage)
                    ?.find((incident) => incident.identity === identity),
            ),
        )
        dispatch(ActionCreators.setSaveMessage(SaveMessage.SUCCESFULLY, identity))
        dispatch(ActionCreators.getIncidentModalCashData())
    }

    function closeIncidentDetailsModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeIncidentDetailsModal())
        dispatch(ActionCreators.setSaveMessage(undefined))
    }

    function requestDataFunction(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const offset = state.selectedPage * state.pageSize
        getData(
            dispatch,
            offset,
            state.pageSize,
            currentFilter.selectedCreatedFromTimeRange,
            currentFilter.selectedCreatedToTimeRange,
            currentFilter.createdFromRelative,
            currentFilter.createdToRelative,
            currentFilter.updatedToRelative,
            currentFilter.updatedFromRelative,
            currentFilter.searchIncidentNo,
            currentFilter.orderIncidentsBy.column,
            getSortOrderValue(currentFilter.orderIncidentsBy.direction),
            currentFilter.filterBySeverities,
            currentFilter.filterByStatus,
            currentFilter.selectedIncidentType,
            currentFilter.selectedUpdatedFromTimeRange,
            currentFilter.selectedUpdatedToTimeRange,
            currentFilter.assignedTo,
            currentFilter.guestAssignedToEmail,
            currentFilter.watchedByCurrentUser,
            currentFilter.unseenIncidentResponses,
            currentFilter.assignedToVesselEmail,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            allLocations,
        )
    }

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    function getLinkedItemsData(linkedItems: IncidentLink[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.getLinkedItemsData(dispatch, linkedItems)
    }

    function initialiseLinkedItemsData(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.initialiseLinkedItemsData())
    }

    function showCloseMonitorIncidentModal(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayCloseMonitorIncidentModal(value))
        dispatch(
            ActionCreators.setSaveCloseMonitorProcessingStatus(ProcessingStatus.NOT_PROCESSING),
        )
    }

    function setMonitoredItem(identity: GuidType, value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setMonitoredItem({identity, value}))
    }

    function saveMonitoredItems(monitoredItems: GuidType[], incidentModalId: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSaveCloseMonitorProcessingStatus(ProcessingStatus.PROCESSING))
        REST.put(`${INCIDENTS_ENDPOINT}/${incidentModalId}/stateTransition?status=CLOSED_MONITOR`, [
            ...monitoredItems,
        ])
            .then(() => {
                dispatch(
                    ActionCreators.setSaveCloseMonitorProcessingStatus(ProcessingStatus.PROCESSED),
                )
                updateOpenIncident(dispatch, incidentModalId)
                requestDataFunction()
            })
            .catch(() =>
                dispatch(
                    ActionCreators.setSaveCloseMonitorProcessingStatus(ProcessingStatus.FAILED),
                ),
            )
    }

    function showIncidentManagementModal(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayIncidentManagementModal(value))
    }

    const hasData = state.totalNumberOfIncidents != undefined

    const dataIncidents = hasData
        ? state.dataIncidentsMap?.get(state.selectedPage || 0) ?? undefined
        : undefined

    const closedIncidents = state.totalNumberOfItemsByStatus
        ? state.totalNumberOfItemsByStatus.CLOSED +
          state.totalNumberOfItemsByStatus.CLOSED_FALSE_POSITIVE +
          state.totalNumberOfItemsByStatus.CLOSED_MONITOR
        : 0

    const totalNumberOfIncidents = state.totalNumberOfItemsBySeverity
        ? state.totalNumberOfItemsBySeverity?.LOW +
          state.totalNumberOfItemsBySeverity?.MEDIUM +
          state.totalNumberOfItemsBySeverity?.HIGH +
          state.totalNumberOfItemsBySeverity?.CRITICAL
        : undefined

    return {
        loading: state.loading,
        pageSize: state.pageSize,
        totalNumberOfIncidents: totalNumberOfIncidents,
        totalNumberOfFilteredIncidents: state.totalNumberOfIncidents,
        totalNumberOfItemsBySeverity: state.totalNumberOfItemsBySeverity,
        totalNumberOfItemsByStatus: state.totalNumberOfItemsByStatus,
        newIncindents: state.totalNumberOfItemsByStatus ? state.totalNumberOfItemsByStatus?.NEW : 0,
        openIncidents: state.totalNumberOfItemsByStatus
            ? state.totalNumberOfItemsByStatus?.OPEN
            : 0,
        closedIncidents: closedIncidents,
        lowIncidents: state.totalNumberOfItemsBySeverity
            ? state.totalNumberOfItemsBySeverity?.LOW
            : 0,
        mediumIncidents: state.totalNumberOfItemsBySeverity
            ? state.totalNumberOfItemsBySeverity?.MEDIUM
            : 0,
        highIncidents: state.totalNumberOfItemsBySeverity
            ? state.totalNumberOfItemsBySeverity?.HIGH
            : 0,
        criticalIncidents: state.totalNumberOfItemsBySeverity
            ? state.totalNumberOfItemsBySeverity?.CRITICAL
            : 0,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        dataIncidents: dataIncidents,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        setFilter,
        downloadIncidentResponse,
        saveNotesToIncident,
        saveResultMessage: state.saveResultMessage,
        changeIncidentResponseStatus,
        reopenClosedMonitorIncident,
        changeIncidentResponseSeverity,
        assignIncidentResponseTo,
        deassignIncidentResponseFrom,
        updateLastViewed,
        updateNoteOfIncident,
        toggleNoteEdit,
        setSaveMessage,
        noteIdentityToEdit: state.noteIdentityToEdit,
        editableComments: state.editableComments,
        incidentModalId: state.incidentModalId,
        displayIncidentDetailsModal,
        isProcessingModal: state.isProcessingIncidentModal === ProcessingStatus.PROCESSING,
        closeIncidentDetailsModal,
        findIncident: state.findIncident,
        getAssignedGuestEmailsFn,
        guestEmails: state.guestEmails,
        displayFilterBar,
        showFilterBar: state.displayFilterBar,
        getLinkedItemsData,
        linkedItemsData: state.linkedItemsData,
        isFetchingItems: state.isFetchingItems,
        initialiseLinkedItemsData,
        showCloseMonitoredIncidentModal: state.showCloseMonitoredIncidentModal,
        showCloseMonitorIncidentModal,
        monitorLinkedItems: state.monitorLinkedItems,
        setMonitoredItem,
        processingSaveCloseMonitor: state.processingSaveCloseMonitor,
        saveMonitoredItems,
        incidentManagementModalDisplayed: state.showIncidentManagementModal,
        showIncidentManagementModal,
    }
}

function getFormattedFilteredVessels(
    allLocations: Location[],
    filteredVessels: string[],
): string[] | undefined {
    return filteredVessels.length === allLocations.length ? undefined : filteredVessels
}

function getData(
    dispatch: Dispatch<AllActions>,
    offset: number,
    count: number,
    createdFrom: string | null,
    createdTo: string | null,
    createdFromRelative: TimeRange | null,
    createdToRelative: TimeRange | null,
    updatedToRelative: TimeRange | null,
    updatedFromRelative: TimeRange | null,
    incidentNo: string | null,
    sortByColumn: IncidentResponseSortType | undefined,
    ascending: boolean,
    filterBySeverities: IncidentSeverityValue[] | undefined,
    filterByStatus: IncidentStatus[] | undefined,
    type: string | undefined,
    updatedFrom: string | null,
    updatedTo: string | null,
    assignedTo: string | undefined,
    guestAssignedToEmail: string | undefined,
    watchedByCurrentUser: boolean | null,
    unseenIncidentResponses: boolean | null,
    assignedToVesselEmail: boolean | null,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    allLocations: Location[],
): void {
    const filteredVessels = filteredVesselIds(
        allLocations,
        locations,
        searchVesselTagTerm ?? [],
        searchVesselNameTerm ?? '',
    )

    REST.post(`${INCIDENTS_ENDPOINT}/find`, {
        pagination: {count: count, offset: offset},
        orderBy: {column: sortByColumn, ascending: ascending},
        updatedFrom: updatedFrom,
        updatedTo: updatedTo,
        createdFrom: createdFrom,
        createdTo: createdTo,
        createdFromRelative: getRelativeTimeRange(createdFromRelative),
        createdToRelative: getRelativeTimeRange(createdToRelative),
        updatedToRelative: getRelativeTimeRange(updatedToRelative),
        updatedFromRelative: getRelativeTimeRange(updatedFromRelative),
        text: incidentNo,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        severities: filterBySeverities,
        statuses: filterByStatus,
        type: type === '' ? null : type,
        assignedTo: assignedTo ? assignedTo : undefined,
        guestAssignedToEmail: guestAssignedToEmail ? guestAssignedToEmail : undefined,
        watchedByCurrentUser: watchedByCurrentUser,
        unseenIncidentResponses: unseenIncidentResponses ?? null,
        assignedToVesselEmail: assignedToVesselEmail,
    } as IncidentsFilterCriteria).then((response) => {
        dispatch(
            ActionCreators.receivedRequestedPageData(
                response.data.data,
                response.data.totalNumberOfItems || 0,
                response.data.totalNumberOfPages,
                response.data.totalNumberOfItemsBySeverity,
                response.data.totalNumberOfItemsByStatus,
            ),
        )
    })
}

function getAttachmentIncidentResponse(
    _dispatch: Dispatch<AllActions>,
    identity: GuidType,
    attachment: IncidentAttachmentData,
): void {
    REST.get(`${INCIDENTS_ENDPOINT}/${identity}/download`, {
        responseType: 'blob',
    })
        .then((resp) => resp.data.arrayBuffer())
        .then((responseArray) => {
            if (responseArray) {
                const blob = new Blob([responseArray], {type: attachment.contentType})
                downloadFile(blob, attachment.name)
            }
        })
}

function getAssignedGuestEmails(dispatch: Dispatch<AllActions>): void {
    REST.get(`${INCIDENTS_ENDPOINT}/assigned/guests`).then((response) => {
        dispatch(ActionCreators.getGuestEmails(response.data))
    })
}

function getIncidentModalId(
    dispatch: Dispatch<AllActions>,
    count: number,
    createdFrom: string | null,
    createdTo: string | null,
    incidentNo: string | null,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    sortByColumn: IncidentResponseSortType | undefined,
    ascending: boolean,
    filterBySeverity: IncidentSeverityValue | undefined,
    filterByStatus: IncidentStatus[] | undefined,
    type: string | undefined,
    updatedFrom: string | null,
    updatedTo: string | null,
    assignedTo: string | undefined,
    guestAssignedToEmail: string | undefined,
    watchedByCurrentUser: boolean | null,
    unseenIncidentResponses: boolean | null,
    incidentModalId: GuidType,
    allLocations: Location[],
): void {
    const filteredVessels = filteredVesselIds(
        allLocations,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
    )

    REST.post(`${INCIDENTS_ENDPOINT}/${incidentModalId}/find`, {
        pagination: {count: count},
        createdFrom: createdFrom,
        createdTo: createdTo,
        text: incidentNo,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        orderBy: {column: sortByColumn, ascending: ascending},
        severity: filterBySeverity,
        statuses: filterByStatus,
        type: type ? type : undefined,
        updatedFrom: updatedFrom,
        updatedTo: updatedTo,
        assignedTo: assignedTo ? assignedTo : undefined,
        guestAssignedToEmail: guestAssignedToEmail ? guestAssignedToEmail : undefined,
        watchedByCurrentUser: watchedByCurrentUser,
        unseenIncidentResponses: unseenIncidentResponses,
    })
        .then((response) => {
            const requestedPage =
                response.data.criteria.pagination.offset / response.data.criteria.pagination.count
            dispatch(ActionCreators.setSaveMessage(SaveMessage.SUCCESFULLY))
            dispatch(ActionCreators.requestPageData(requestedPage))
            dispatch(
                ActionCreators.receivedRequestedPageData(
                    response.data.data,
                    response.data.totalNumberOfItems || 0,
                    response.data.totalNumberOfPages,
                    response.data.totalNumberOfItemsBySeverity,
                    response.data.totalNumberOfItemsByStatus,
                ),
            )
            const findIncident: IncidentResponse = response.data.data.find(
                (incident: IncidentResponse) => incident.identity === incidentModalId,
            )
            if (!findIncident) {
                updateOpenIncident(dispatch, incidentModalId)
            } else {
                dispatch(ActionCreators.setFindIncident(findIncident))
            }
        })
        .catch(() => {
            dispatch(ActionCreators.setSaveMessage(SaveMessage.FAILED))
        })
}

function updateOpenIncident(dispatch: Dispatch<AllActions>, incidentModalId: GuidType): void {
    REST.get(`${INCIDENTS_ENDPOINT}/${incidentModalId}`)
        .then((response) => {
            dispatch(ActionCreators.setFindIncident(response.data))
        })
        .catch(() => dispatch(ActionCreators.setSaveMessage(SaveMessage.FAILED)))
}

function updateLastViewedFunction(
    dispatch: Dispatch<AllActions>,
    identity: GuidType,
    state: PagedIncidentsState,
    currentFilter: IncidentsFilterReduxState,
    currentVesselFilter: VesselFilterReduxState,
    offset: number,
    allLocations: Location[],
): void {
    const filteredVessels = filteredVesselIds(
        allLocations,
        currentVesselFilter.locations,
        currentVesselFilter.searchVesselTagTerm,
        currentVesselFilter.searchVesselNameTerm,
    )

    REST.post(`${INCIDENTS_ENDPOINT}/find`, {
        pagination: {count: state.pageSize, offset: offset},
        createdFrom: currentFilter.selectedCreatedFromTimeRange,
        createdTo: currentFilter.selectedCreatedToTimeRange,
        text: currentFilter.searchIncidentNo,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        orderBy: {
            column: currentFilter.orderIncidentsBy.column,
            ascending: getSortOrderValue(currentFilter.orderIncidentsBy.direction),
        },
        severities: currentFilter.filterBySeverities,
        statuses: currentFilter.filterByStatus,
        type: currentFilter.selectedIncidentType,
        updatedFrom: currentFilter.selectedUpdatedFromTimeRange,
        updatedTo: currentFilter.selectedUpdatedToTimeRange,
        assignedTo: currentFilter.assignedTo,
        guestAssignedToEmail: currentFilter.guestAssignedToEmail,
        watchedByCurrentUser: currentFilter.watchedByCurrentUser,
        unseenIncidentResponses: currentFilter.unseenIncidentResponses,
    }).then((response) => {
        const findIncident: IncidentResponse = response.data.data.find(
            (incident: IncidentResponse) => incident.identity === identity,
        )
        if (findIncident) {
            dispatch(
                ActionCreators.receivedRequestedPageData(
                    response.data.data,
                    response.data.totalNumberOfItems || 0,
                    response.data.totalNumberOfPages,
                    response.data.totalNumberOfItemsBySeverity,
                    response.data.totalNumberOfItemsByStatus,
                ),
            )
            dispatch(ActionCreators.setFindIncident(findIncident))
        } else {
            updateOpenIncident(dispatch, identity)
        }
    })
}
