import {Dispatch, useContext} from 'react'
import * as ActionCreators from '../state/vessels/action-creators'
import {Action} from '../state/vessels/actions'
import {REST} from '../../../..'
import {UseOTLocationsResult} from './use-ot-locations-result'
import {VesselListContext} from '../vessel-list-context'

const OT_LOCATIONS_ENDPOINT = 'ot-inventory/api/v1/locations'

export function useOTLocations(): UseOTLocationsResult {
    const {state, dispatch} = useContext(VesselListContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useOTLocations must be used within a OTLocationsContext')
    }

    return {
        dispatch,
        refreshData: () => getData(dispatch),
        data: state.data,
        filteredData: state.filteredData,
        filter: state.filter,
        loadingState: state.loadingState,
    }
}

function getData(dispatch: Dispatch<Action>): void {
    dispatch(ActionCreators.requestOTLocations())
    REST.get(`${OT_LOCATIONS_ENDPOINT}`, {retry: 3, retryDelay: 1000})
        .then((response) => {
            dispatch(ActionCreators.setOTLocations(response.data))
        })
        .catch(() => {
            dispatch(ActionCreators.failFetchOTLocations())
        })
}
