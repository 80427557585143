import {TimestampFilter} from '../../../store/state/software-inventory-filter/state'
import {addEvent} from '../add-event'

export function searchedFirstDetectedValueEvent(
    currentTimePeriod: TimestampFilter,
    newTimePeriod: TimestampFilter,
): boolean {
    return addEvent('softwareInventoryPageSearchedFirstDetectedValue', {
        currentTimePeriod,
        newTimePeriod,
    })
}
