import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {formatDate} from '../../../../helpers/formatting'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {AuditLogPageType} from '../../../../store/state/audit-log/state'
import {latestTimestampSelector} from '../../../../store/state/latest-event-timestamps/selectors'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {nodesSelector} from '../../../../store/state/nodes/selectors'
import {rolesSelector} from '../../../../store/state/roles/selectors'
import {sfmScoresSelector} from '../../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../../store/state/threat-measures/selectors'
import {usersSelector} from '../../../../store/state/users/selectors'
import {User} from '../../../../store/state/users/state'
import {DownloadReportButton} from '../../../incidents-v3/components/shared/download/download-report-button'
import {IncidentAssetsList} from '../../../incidents-v3/components/table/incident-details-modal/assets-list'
import {IncidentHistoryOutputModel} from '../../../incidents-v3/models/history-record.model'
import {IncidentModalOutputModel} from '../../../incidents-v3/models/incident-modal.model'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'
import {DataCell} from './data-cell/data-cell'
import {IncidentStatusValue} from './incident-status'
import * as Styled from './new-open-incidents-details.styled'
import {SeverityLevelValue} from './severity-level'
import {TimelineCircle} from './timeline-circle'
import VESSEL_ASSIGN from '../../../../@assets/icons/new-vessel-icon.svg'
import PERSON_ASSIGN from '../../../../@assets/icons/assigned-to-person.svg'
import {LatestNotes} from './latest-notes'

export function IncidentsDetailsExpanded(): JSX.Element | null {
    const {width} = useDimensions()
    const {incidentDetailsToBeDisplayed, downloadIncidentResponse} = usePagedIncidentsReport()
    const locationMap = useTypedSelector(locationMapSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const users = useTypedSelector(usersSelector)
    const nodes = useTypedSelector(nodesSelector)
    const threatMeasures = useTypedSelector(threatMeasuresSelector)
    const sfmScores = useTypedSelector(sfmScoresSelector)
    const latestTimestamps = useTypedSelector(latestTimestampSelector)
    const allUsers = useTypedSelector(usersSelector)

    if (!incidentDetailsToBeDisplayed) {
        return null
    }
    const model = new IncidentModalOutputModel(
        incidentDetailsToBeDisplayed,
        locationMap,
        userRoles,
        users,
        sfmScores,
        nodes,
        latestTimestamps,
        threatMeasures,
    )

    if (!model) {
        return null
    }
    const filteredHistoryForTimelime = sortInicidentsHistory(
        model.history?.filter((record) => {
            // Keep all records except noteRecord with isAddedOnVessel false
            if (record.noteRecord != undefined && !record.noteRecord.isAddedOnVessel) {
                return false
            }
            return true
        }),
        false,
    )

    return (
        <Styled.IncidentDetailsContainer
            width={width}
            id="incidents-report-page-IncidentsDetails-section-container"
        >
            <Styled.SectionHeader>
                <Styled.SectionText>{model.number}</Styled.SectionText>
                <DownloadReportButton
                    id={model.id}
                    attachment={model.attachment}
                    status={model.status}
                    displayFormat="modal"
                    page={AuditLogPageType.INCIDENTS_REPORT}
                    downloadIncidentResponse={downloadIncidentResponse}
                />
            </Styled.SectionHeader>
            <Styled.WrapperSectionContent width={width}>
                <Styled.SectionContent width={width}>
                    <Styled.SectionText>Summary</Styled.SectionText>
                    <Styled.SummaryGrid>
                        <DataCell label="Status">
                            <IncidentStatusValue incidentStatus={model.status} id={model.id} />
                        </DataCell>
                        <DataCell label="Severity">
                            <SeverityLevelValue id={model.id} incidentSeverity={model.severity} />
                        </DataCell>
                        <DataCell label="Title">{model.title}</DataCell>
                        <DataCell label="Vessel">{model.vessel}</DataCell>
                        <DataCell label="Assets">
                            <IncidentAssetsList
                                incidentStatus={model.status}
                                assets={model.assetsInvolved}
                                displayFormat="modal"
                            />
                        </DataCell>
                    </Styled.SummaryGrid>
                </Styled.SectionContent>
                <Styled.SectionContent width={width}>
                    <Styled.SectionText>Timeline</Styled.SectionText>
                    {!filteredHistoryForTimelime.length ? (
                        <Styled.NoEvents>No events to display</Styled.NoEvents>
                    ) : (
                        <Styled.TimelineWrapper>
                            <Styled.CircleBox>
                                {filteredHistoryForTimelime?.map((historyItem, index) => (
                                    <GetTheUpperValueTimeline
                                        key={`upper-value-${index}`}
                                        historyItem={historyItem}
                                        index={index}
                                        allUsers={allUsers}
                                    />
                                ))}
                                {filteredHistoryForTimelime?.map((historyItem, index) => (
                                    <TimelineCircle
                                        key={`timeline-circle-${historyItem.id}`}
                                        index={index}
                                        lastItem={filteredHistoryForTimelime.length}
                                    />
                                ))}
                            </Styled.CircleBox>
                            {filteredHistoryForTimelime?.map((historyItem, index) => (
                                <Styled.SubTitle key={`timestamp-${index}`} index={index}>
                                    {formatDate(historyItem.when) ?? 'Unknown'}
                                </Styled.SubTitle>
                            ))}
                        </Styled.TimelineWrapper>
                    )}
                </Styled.SectionContent>
            </Styled.WrapperSectionContent>

            <Styled.WrapperSectionContent width={width}>
                <Styled.SectionContent width={width}>
                    <Styled.SectionText>Details</Styled.SectionText>
                    <Styled.Text> {model.description}</Styled.Text>
                </Styled.SectionContent>
                <Styled.SectionContent width={width}>
                    <LatestNotes incidentHistory={model.history} incidentId={model.id} />
                </Styled.SectionContent>
            </Styled.WrapperSectionContent>
        </Styled.IncidentDetailsContainer>
    )
}
interface GetTheUpperValueTimelineProps {
    historyItem: IncidentHistoryOutputModel
    index: number
    allUsers: User[]
}
function GetTheUpperValueTimeline({
    historyItem,
    index,
    allUsers,
}: GetTheUpperValueTimelineProps): JSX.Element {
    const newAssigned = historyItem.assignmentChangeRecord?.newAssignedToVesselEmail
        ? 'Vessel'
        : allUsers.find(
              (user) => user.user === historyItem.assignmentChangeRecord?.newAssignedToUser,
          )?.username ??
          historyItem.assignmentChangeRecord?.newGuestAssignedToEmail ??
          'Unassigned'
    function getTheIconType(newAssigned: string): string | undefined {
        if (newAssigned === 'Vessel') {
            return VESSEL_ASSIGN
        } else {
            return PERSON_ASSIGN
        }
    }
    return (
        <div style={{position: 'absolute', top: '5px', left: `${index * 120}px`}}>
            {historyItem.statusChangeRecord != undefined && (
                <IncidentStatusValue
                    incidentStatus={historyItem.statusChangeRecord.newStatus}
                    id={historyItem.statusChangeRecord.id}
                />
            )}
            {historyItem.severityChangeRecord != undefined && (
                <SeverityLevelValue
                    id={historyItem.severityChangeRecord.id}
                    incidentSeverity={historyItem.severityChangeRecord.newSeverity}
                />
            )}
            {historyItem.assignmentChangeRecord != undefined && (
                <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                    <img src={getTheIconType(newAssigned)} width={20} height={20} />
                    <Styled.AssignedToCell>
                        Assigned to{' '}
                        <span style={{fontSize: '10px', fontStyle: 'italic'}}>{newAssigned}</span>
                    </Styled.AssignedToCell>
                </div>
            )}
            {historyItem.noteRecord != undefined && (
                <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                    <img src={VESSEL_ASSIGN} width={20} height={20} />
                    <Styled.AssignedToCell>Master first viewed</Styled.AssignedToCell>
                </div>
            )}
        </div>
    )
}

function sortInicidentsHistory(
    history: IncidentHistoryOutputModel[],
    descending: boolean,
): IncidentHistoryOutputModel[] {
    if (!history.length) {
        return []
    }
    const noteRecords = history.filter((record) => record.noteRecord != undefined)
    const otherRecords = history.filter((record) => !record.noteRecord)

    const sortedNoteRecords = noteRecords.sort(
        (a: IncidentHistoryOutputModel, b: IncidentHistoryOutputModel) => {
            return dateComparison(a.when, b.when, descending)
        },
    )[0]

    const sortedOtherRecords = otherRecords.sort(
        (a: IncidentHistoryOutputModel, b: IncidentHistoryOutputModel) => {
            return dateComparison(a.when, b.when, descending)
        },
    )
    if (!sortedNoteRecords) {
        return sortedOtherRecords
    }

    return otherRecords
        .concat(sortedNoteRecords)
        .sort((a: IncidentHistoryOutputModel, b: IncidentHistoryOutputModel) => {
            return dateComparison(a.when, b.when, descending)
        })
}

function dateComparison(aValue: string, bValue: string, descending: boolean): number {
    if (aValue < bValue) {
        return descending ? 1 : -1
    }

    if (aValue > bValue) {
        return descending ? -1 : 1
    }

    return 0
}
