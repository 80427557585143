import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {X} from 'react-feather'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import * as Styles from './tag-search.styled'
import {useDispatch} from 'react-redux'
import {
    setSearchVesselNameTerm,
    setSearchVesselTerm,
    setShowXButton,
} from '../../../../store/state/vessel-filter/action-creators'
import {
    pageTypeSelector,
    vesselFilterSelector,
} from '../../../../store/state/vessel-filter/selectors'
import {setSearchIncidentNo} from '../../../../store/state/incidents-filter/action-creators'

export function VesselOnlySearch(): JSX.Element {
    const dispatch = useDispatch()
    const {showXButton, searchVesselTerm} = useTypedSelector(vesselFilterSelector)
    const pageType = useTypedSelector(pageTypeSelector)

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        dispatch(setSearchVesselTerm(e.currentTarget.value))
        if (e.currentTarget.value.length === 0) {
            dispatch(setShowXButton(false))
            dispatch(setSearchVesselNameTerm(e.currentTarget.value, pageType))
        }
    }

    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            dispatch(setSearchVesselNameTerm(searchVesselTerm, pageType))
            dispatch(setSearchIncidentNo(''))
            dispatch(setShowXButton(true))
        }
    }

    function onSearchClick() {
        dispatch(setSearchVesselNameTerm(searchVesselTerm, pageType))
        dispatch(setSearchIncidentNo(''))
        dispatch(setShowXButton(true))
    }

    function onXClick() {
        dispatch(setSearchVesselTerm(''))
        dispatch(setSearchVesselNameTerm('', pageType))
        dispatch(setShowXButton(false))
    }
    return (
        <Styles.Container>
            <Styles.SearchInput
                id="vessel-search"
                placeholder="Filter by vessel name"
                autoComplete="off"
                value={searchVesselTerm}
                onChange={onChangeFn}
                onKeyPress={onEnterClick}
            />
            {showXButton && (
                <Styles.XIcon onClick={() => onXClick()} id="x-icon">
                    <X onClick={() => onXClick()} height={20} width={20} />
                </Styles.XIcon>
            )}
            <Styles.SearchIcon onClick={() => onSearchClick()} id="search-icon">
                <Icon glyph="Search" height={20} width={20} />
            </Styles.SearchIcon>
        </Styles.Container>
    )
}
