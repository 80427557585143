import {useContext} from 'react'
import * as ActionCreators from './state/action-creators'
import {UsePolicyManagementResult} from './use-policy-management-output'
import {PolicyManagementContext} from './policy-management-context'
import {PolicyType} from './type/policy-management-state'
import {warn} from '../../../../helpers/logging'
import {REST} from '../../../..'
import {GuidType} from '../../../../values/generic-type-defintions'
import {LocationIdType} from '../../../../store/state/locations/state'

const INCIDENT_POLICY_ENDPOINT = '/api/v1/incidentResponses/policies'
const METRICS_POLICY_ENDPOINT = '/metrics/api/v1/policies'
const ENFORCEMENT_POLICY_ENDPOINT = '/active-control/api/v1/policies'
export function usePolicyManagement(): UsePolicyManagementResult {
    const {state, dispatch} = useContext(PolicyManagementContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('usePolicyManagement must be used within a PolicyManagementContext')
    }

    async function fetchPolicyData(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestAllPolicyData())

        const fetchData = async (endpoint: string, policyType: PolicyType) => {
            try {
                const response = await REST.get(endpoint)
                dispatch(
                    ActionCreators.receivedAllPolicyData({
                        type: policyType,
                        data: response.data,
                    }),
                )
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }

        await Promise.all([
            fetchData(INCIDENT_POLICY_ENDPOINT, PolicyType.INCIDENT),
            fetchData(METRICS_POLICY_ENDPOINT, PolicyType.METRICS),
            fetchData(ENFORCEMENT_POLICY_ENDPOINT, PolicyType.ENFORCEMENT),
        ])
    }

    function showPolicyDetailModal(policyType: PolicyType, policyId: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showPolicyDetailModal(policyType, policyId))
    }

    function requestDataToBeFecthed(requestFetch: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestDataToBeFetched(requestFetch))
    }

    async function closePolicyDetailModal(fetchForRefresh = false): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closePolicyDetailModal())
        if (state.dataToBeFetched) {
            await fetchPolicyData()
        } else if (fetchForRefresh) {
            await fetchPolicyData()
        }
    }

    function showAddNewPolicyModalFn(policyType: PolicyType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.addNewPolicy(policyType))
    }

    function showAssignPolicyDialog(
        policyId: GuidType,
        currentAssignedLocations: GuidType[] | undefined,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showAssignPolicyDialog(policyId, currentAssignedLocations))
    }

    function closeAssignPolicyDialog(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeAssignPolicyDialog())
    }

    function toggleVessel(
        allLocations: LocationIdType[],
        location: LocationIdType,
        newValue: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleVessel(allLocations, location, newValue))
    }

    function setSearchVesselTagTerm(vesselTagTerm: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSearchVesselTagTerm(vesselTagTerm))
    }

    function setSearchVesselNameTerm(vesselNameTerm: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSearchVesselNameTerm(vesselNameTerm))
    }

    function setSearchVesselTerm(vesselTerm: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSearchVesselTerm(vesselTerm))
    }

    function setShowXButton(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setShowXButton(value))
    }

    async function assignVesselsToMetricsPolicy(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        try {
            const policyIdentityToUpdate = state.assignPolicyDialogState.selectedPolicyId
            if (!policyIdentityToUpdate) {
                return
            }
            await REST.put(
                `${METRICS_POLICY_ENDPOINT}/${policyIdentityToUpdate}/locations`,
                Array.from(state.assignPolicyDialogState.newLocations ?? []),
            )
            requestDataToBeFecthed(true)
            closeAssignPolicyDialog()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    async function assignOneVesselToMetricsPolicy(
        locationId: LocationIdType,
        metricsPolicyId: GuidType | undefined,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const formattedLocationId = metricsPolicyId ? locationId : null
        try {
            await REST.put(
                `${METRICS_POLICY_ENDPOINT}/${metricsPolicyId}/locations/${formattedLocationId}`,
            )
            fetchPolicyData()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    async function unassignOneVesselFromMetricsPolicy(
        policyIdentityToUpdate: GuidType,
        remainLocations: LocationIdType[] | null | undefined,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        try {
            if (!policyIdentityToUpdate) {
                return
            }
            await REST.put(
                `${METRICS_POLICY_ENDPOINT}/${policyIdentityToUpdate}/locations`,
                remainLocations,
            ),
                requestDataToBeFecthed(true)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    async function assignVesselToEnforcementPolicy(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        try {
            const policyIdentityToUpdate = state.assignPolicyDialogState.selectedPolicyId
            if (!policyIdentityToUpdate) {
                return
            }
            await REST.put(
                `${ENFORCEMENT_POLICY_ENDPOINT}/${policyIdentityToUpdate}/locations`,
                Array.from(state.assignPolicyDialogState.newLocations ?? []),
            ),
                requestDataToBeFecthed(true)
            closeAssignPolicyDialog()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    async function assignOneVesselToEnforcementPolicy(
        locationId: LocationIdType,
        enforcementPolicyId: GuidType | undefined,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const formattedLocationId = enforcementPolicyId ? locationId : null
        try {
            await REST.put(
                `${ENFORCEMENT_POLICY_ENDPOINT}/${enforcementPolicyId}/locations/${formattedLocationId}`,
            )
            fetchPolicyData()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    async function unassignOneVesselFromEnforcementPolicy(
        policyIdentityToUpdate: GuidType,
        remainLocations: LocationIdType[] | null | undefined,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        try {
            if (!policyIdentityToUpdate) {
                return
            }
            await REST.put(
                `${ENFORCEMENT_POLICY_ENDPOINT}/${policyIdentityToUpdate}/locations`,
                remainLocations,
            ),
                requestDataToBeFecthed(true)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    const hasData = state.allPolicyData?.length !== 0

    return {
        loadingDataState: state.loadingDataState,
        fetchPolicyData,
        hasData,
        showPolicyDetailModal,
        closePolicyDetailModal,
        selectedPolicyId: state.selectedPolicyId,
        selectedPolicyType: state.selectedPolicyType,
        showAddNewPolicyModalFn,
        showPolicyModal: state.showPolicyModal,
        allPolicyData: state.allPolicyData,
        requestDataToBeFecthed,
        showAssignPolicyDialog,
        closeAssignPolicyDialog,
        toggleVessel,
        setSearchVesselTagTerm,
        setSearchVesselNameTerm,
        setSearchVesselTerm,
        setShowXButton,
        assignPolicyDialogState: state.assignPolicyDialogState,
        assignVesselsToMetricsPolicy,
        assignOneVesselToMetricsPolicy,
        unassignOneVesselFromMetricsPolicy,
        assignOneVesselToEnforcementPolicy,
        unassignOneVesselFromEnforcementPolicy,
        assignVesselToEnforcementPolicy,
        locationsAssignedToMetricsPolicies: state.locationsAssignedToMetricsPolicies,
        locationsAssignedToIncidentPolicies: state.locationsAssignedToIncidentPolicies,
        locationsAssignedToEnforcementPolicies: state.locationsAssignedToEnforcementPolicies,
    }
}
