import {useDispatch} from 'react-redux'
import * as Styled from './purpose-filter.styled'
import {setSearchedUSBHasPurpose} from '../../../../../store/state/usb-inventory-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usbInventoryFilterSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function PurposeFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {hasPurpose} = useTypedSelector(usbInventoryFilterSelector)

    const purposeOptions = ['Given purpose', 'No given purpose']

    const getPayload = (value: string) => {
        switch (value) {
            case 'Given purpose':
                return true
            case 'No given purpose':
                return false
            default:
                return undefined
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const payload = getPayload(event.currentTarget.value as string)
        dispatch(setSearchedUSBHasPurpose(payload))
    }

    const selectValue =
        hasPurpose === undefined ? 'All' : hasPurpose ? 'Given purpose' : 'No given purpose'

    return (
        <Styled.Wrapper id={`${PageType.USB_DEVICES}_filter-bar_purpose-filter`}>
            <Styled.Label>Purpose:</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChange}
                    value={selectValue}
                    id={`${PageType.USB_DEVICES}_filter-bar_searched-purposed-value`}
                >
                    <option
                        key="default-option"
                        value="All"
                        id={`${PageType.USB_DEVICES}_filter-bar_purpose_default-option`}
                    >
                        - All -
                    </option>
                    {purposeOptions.map((option) => (
                        <option
                            key={option}
                            value={option}
                            id={`${PageType.USB_DEVICES}_filter-bar_purpose_${option}-option`}
                        >
                            {option}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
