import {TimestampFilter} from '../../../store/state/software-inventory-filter/state'
import {addEvent} from '../add-event'

export function searchedLastActiveValueEvent(
    currentTimePeriod: TimestampFilter,
    newTimePeriod: TimestampFilter,
): boolean {
    return addEvent('softwareInventoryPageSearchedLastActiveValue', {
        currentTimePeriod,
        newTimePeriod,
    })
}
