import {addMultipleQueryParam} from '../../../../../../helpers/navigation'
import {formatDate} from '../../../../../../helpers/formatting'
import usedTypeSelector from '../../../../../../hooks/use-typed-selector'
import {indicatorsSelector} from '../../../../../../store/state/indicators/selectors'
import {TableDataCell} from '../../common/table/table-data-cell.styled'
import {TableDataRow} from '../../common/table/table-data-row.styled'
import {DetailsButton} from './styled/details-button.styled'
import {IndicatorOutputModel} from '../indicator.model'
import {Indicator} from '../../../../contexts/indicators/types/indicator'
import {clickIndicatorDetailsEvent} from '../../../../../../core/google-tag-manager/asset-modal/click-details-on-indicator-event'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {gfcFlagSelector} from '../../../../../../store/state/config/selectors'

interface TableRowProps {
    event: Indicator
    index: number
}

function TableRow({event, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const gfcFlag = useTypedSelector(gfcFlagSelector)

    const model = new IndicatorOutputModel(
        event,
        usedTypeSelector(indicatorsSelector).get(event.indicator),
        gfcFlag,
    )

    return (
        <>
            <TableDataRow gridRow={gridRow} />
            <TableDataCell gridRow={gridRow} id={`indicators_${model.id}_timestamp`} gridColumn={2}>
                {formatDate(event.timestamp)}
            </TableDataCell>
            <TableDataCell
                id={`indicators_${model.id}_title`}
                gridRow={gridRow}
                gridColumn={3}
                extrabold
            >
                {model.title}
            </TableDataCell>
            <TableDataCell id={`indicators_${model.id}_tatic`} gridRow={gridRow} gridColumn={4}>
                {model.tatic}
            </TableDataCell>
            <TableDataCell id={`indicators_${model.id}_technique`} gridRow={gridRow} gridColumn={5}>
                {model.technique}
            </TableDataCell>
            <TableDataCell gridRow={gridRow} gridColumn={6}>
                <DetailsButton
                    id={`indicators_${model.id}_details`}
                    value={model.id}
                    onClick={() => {
                        addMultipleQueryParam(
                            'eventDetails',
                            model.id,
                            'eventTimestamp',
                            model.timeStamp,
                        )
                        clickIndicatorDetailsEvent()
                    }}
                >
                    Details
                </DetailsButton>
            </TableDataCell>
        </>
    )
}

export default TableRow
