import * as Styled from './benchmark-summary.styled'
import FLEET_COMPARISON_POSITIVE from '../../../../../@assets/icons/fleet-comparision-positive.svg'
import FLEET_COMPARISON_NEGATIVE from '../../../../../@assets/icons/fleet-comparision-negative.svg'
import {FrameworkSummaryBenchmarkResponse} from '../../../contexts/types/framework-summary-benchmark-api'
import {
    findMetricCategory,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
interface BenchmarkSummaryProps {
    findbehaviourSummaryData: FrameworkSummaryBenchmarkResponse
}
export function BenchmarkSummary({findbehaviourSummaryData}: BenchmarkSummaryProps): JSX.Element {
    function getFleetComparisionImg(selectedPerformance: number): string {
        return selectedPerformance >= 0 ? FLEET_COMPARISON_POSITIVE : FLEET_COMPARISON_NEGATIVE
    }
    return (
        <Styled.BenchmarkWrapper id={`benchmark-wrapper-${findbehaviourSummaryData.locationId}`}>
            {findbehaviourSummaryData.protectionBenchmark !== undefined ? (
                <Styled.ScoreSection>
                    <img
                        src={getMetricTypeIcon(findMetricCategory('protection'))}
                        style={{width: '30px', height: '30px'}}
                        id={`metricTypeIcon-${findbehaviourSummaryData.protectionBenchmark}`}
                    />
                    <Styled.BenchmarkScore
                        metricType="protection"
                        id={`benchmarkScore-${findbehaviourSummaryData.protectionBenchmark}`}
                    >
                        <div
                            style={{display: 'flex'}}
                            id={`protectionBenchmark-${findbehaviourSummaryData.protectionBenchmark}`}
                        >
                            {Math.round(findbehaviourSummaryData.protectionBenchmark)}
                            <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                        </div>
                    </Styled.BenchmarkScore>

                    <img
                        src={getFleetComparisionImg(findbehaviourSummaryData.protectionPerformance)}
                        style={{width: '19px', height: '26px'}}
                        id={`fleetComparisionImg-${findbehaviourSummaryData.protectionBenchmark}`}
                    />
                </Styled.ScoreSection>
            ) : null}
            {findbehaviourSummaryData.maintenanceBenchmark !== undefined ? (
                <Styled.ScoreSection>
                    <img
                        src={getMetricTypeIcon(findMetricCategory('maintenance'))}
                        style={{width: '30px', height: '30px'}}
                        id={`metricTypeIcon-${findbehaviourSummaryData.maintenanceBenchmark}`}
                    />
                    <Styled.BenchmarkScore
                        metricType="maintenance"
                        id={`benchmarkScore-${findbehaviourSummaryData.maintenanceBenchmark}`}
                    >
                        <div
                            style={{display: 'flex'}}
                            id={`maintenanceBenchmark-${findbehaviourSummaryData.maintenanceBenchmark}`}
                        >
                            {Math.round(findbehaviourSummaryData.maintenanceBenchmark)}
                            <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                        </div>
                    </Styled.BenchmarkScore>
                    <img
                        src={getFleetComparisionImg(
                            findbehaviourSummaryData.maintenancePerformance,
                        )}
                        style={{width: '19px', height: '26px'}}
                        id={`fleetComparisionImg-${findbehaviourSummaryData.maintenanceBenchmark}`}
                    />
                </Styled.ScoreSection>
            ) : null}
            {findbehaviourSummaryData.behaviourBenchmark !== undefined ? (
                <Styled.ScoreSection>
                    <img
                        src={getMetricTypeIcon(findMetricCategory('behaviour'))}
                        style={{width: '30px', height: '30px'}}
                        id={`metricTypeIcon-${findbehaviourSummaryData.behaviourBenchmark}`}
                    />
                    <Styled.BenchmarkScore
                        metricType="behaviour"
                        id={`benchmarkScore-${findbehaviourSummaryData.behaviourBenchmark}`}
                    >
                        <div
                            style={{display: 'flex'}}
                            id={`behaviourBenchmark-${findbehaviourSummaryData.behaviourBenchmark}`}
                        >
                            {Math.round(findbehaviourSummaryData.behaviourBenchmark)}
                            <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                        </div>
                    </Styled.BenchmarkScore>
                    <img
                        src={getFleetComparisionImg(findbehaviourSummaryData.behaviourPerformance)}
                        style={{width: '19px', height: '26px'}}
                        id={`fleetComparisionImg-${findbehaviourSummaryData.behaviourBenchmark}`}
                    />
                </Styled.ScoreSection>
            ) : null}
        </Styled.BenchmarkWrapper>
    )
}
