import {useEffect, useRef, useState} from 'react'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useToggle} from '../../../../../contexts/toggle/use-toggle'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usersSelector} from '../../../../../store/state/users/selectors'
import {GuidType} from '../../../../../values/generic-type-defintions'
import * as Styled from './assign-to-popover.styles'

import {
    AssignedToGuestIcon,
    AssignedToIcon,
    AssignedToWrapper,
    ButtonText,
} from './assigned-to-cell.styled'
import CLOSE_ICON from '../../../../../@assets/icons/close-assign-to-icon.svg'
import ASSIGNED_TO_ICON from '../../../../../@assets/icons/assigned-to-blue.svg'
import ASSIGNED_TO_GUEST from '../../../../../@assets/icons/assigned-to-guest.svg'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {assignIncidenEvent} from '../../../../../core/google-tag-manager/incidents/assign-incident-event'
import {OpenGuestEmailEntry} from './open-guest-email-entry'
import {Checkbox} from './checkbox'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {useDispatch} from 'react-redux'

interface Props {
    id: GuidType
    assignedTo: GuidType | undefined
    assignedToName: string | undefined
    guestAssignedToEmail: string | undefined
    locationEmailAddress: string | null
    isAssignedIncidentEmail: boolean
    isAssignedToVesselEmail: boolean
    isOnboardDashboard: boolean
}

export function AssignedToCell({
    id,
    assignedTo,
    assignedToName,
    guestAssignedToEmail,
    locationEmailAddress,
    isAssignedIncidentEmail,
    isAssignedToVesselEmail,
    isOnboardDashboard,
}: Props): JSX.Element {
    const {assignIncidentResponseTo, deassignIncidentResponseFrom, getAssignedGuestEmailsFn} =
        usePagedIncidents()
    const dispatch = useDispatch()

    const {width} = useDimensions()

    const {value: dropdownOpen, setValue: setDropdownOpen} = useToggle()

    const users = useTypedSelector(usersSelector).filter(
        (user) => !user.internalUser && user.active && !user.deleted,
    )

    const [currentUserAssignedTo, setCurrentUserAssignedTo] = useState(assignedTo)
    const [currentEmailAssignedTo, setCurrentEmailAssignedTo] = useState(guestAssignedToEmail)
    const [currentVesselAssignedTo, setCurrentVesselAssignedTo] = useState(isAssignedIncidentEmail)

    let assignedToUsername = assignedToName != undefined ? assignedToName : 'Unassigned'
    if (isAssignedIncidentEmail && locationEmailAddress) {
        assignedToUsername = locationEmailAddress
    }
    if (isAssignedToVesselEmail) {
        assignedToUsername = 'Vessel'
    }
    const buttonDisplayText =
        assignedToName !== currentUserAssignedTo || assignedToName !== currentEmailAssignedTo
            ? 'Reassign'
            : 'Assign'

    const [openGuestEmail, setOpenGuestEmail] = useState(false)

    useEffect(() => {
        setCurrentUserAssignedTo(assignedTo)
        setCurrentEmailAssignedTo(guestAssignedToEmail)
        setCurrentVesselAssignedTo(isAssignedIncidentEmail)
    }, [assignedTo, guestAssignedToEmail, id, setDropdownOpen, isAssignedIncidentEmail])

    function setChecked(user: string | undefined): void {
        if (user === 'unassigned' || user == undefined) {
            setCurrentUserAssignedTo(undefined)
            setCurrentEmailAssignedTo(undefined)
            setCurrentVesselAssignedTo(false)
        } else {
            setCurrentUserAssignedTo(user)
        }
    }

    function assignAndClose(id: GuidType, user: string | undefined): void {
        setDropdownOpen(false)
        setCurrentVesselAssignedTo(false)
        if (user != undefined) {
            assignIncidenEvent(id, user)
            assignIncidentResponseTo(id, user, undefined, undefined, dispatch)
            setDropdownOpen(false)
            setOpenGuestEmail(false)
        } else {
            assignIncidenEvent(id, 'Unassigned')
            deassignIncidentResponseFrom(id, dispatch)
            setDropdownOpen(false)
            setOpenGuestEmail(false)
        }
    }
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setCurrentUserAssignedTo(assignedTo)
        setDropdownOpen(false)
        setOpenGuestEmail(false)
    })

    const showCurrentAssignToVessel = currentVesselAssignedTo && locationEmailAddress !== null
    const showAssignToVesselButton = isOnboardDashboard || locationEmailAddress !== null

    return (
        <AssignedToWrapper ref={clickOutside}>
            <ButtonText
                onClick={() => {
                    setDropdownOpen(!dropdownOpen)
                }}
                id={`expand-details-button_${assignedToUsername}_${id}`}
            >
                {assignedToUsername}
            </ButtonText>
            <AssignedToIcon src={ASSIGNED_TO_ICON} />
            {dropdownOpen && (
                <Styled.DropdownContentStyle width={width}>
                    <Styled.CloseImage
                        src={CLOSE_ICON}
                        onClick={() => {
                            setDropdownOpen(!dropdownOpen)
                        }}
                        id={`close-details-icon${id}`}
                    />
                    {guestAssignedToEmail && (
                        <Styled.GuestUserRow>
                            <div>
                                {!currentUserAssignedTo && currentEmailAssignedTo && (
                                    <AssignedToGuestIcon src={ASSIGNED_TO_GUEST} />
                                )}
                                Assigned to guest:
                            </div>
                            <Styled.GuestUserLink id="guestAssignedToEmail">
                                {guestAssignedToEmail}
                            </Styled.GuestUserLink>
                        </Styled.GuestUserRow>
                    )}
                    <Styled.SearchInputWrapper>
                        <Styled.UserSearchInput
                            id="user-search"
                            onClick={() => {
                                getAssignedGuestEmailsFn()
                                setDropdownOpen(false)
                                setOpenGuestEmail(true)
                            }}
                        >
                            Enter email address
                        </Styled.UserSearchInput>
                    </Styled.SearchInputWrapper>

                    {showCurrentAssignToVessel && (
                        <Styled.GuestUserRow>
                            <div>
                                {!currentUserAssignedTo && currentVesselAssignedTo && (
                                    <AssignedToGuestIcon src={ASSIGNED_TO_GUEST} />
                                )}
                                Assign to vessel:
                            </div>
                            <Styled.GuestUserLink id="locationEmailAddress">
                                {locationEmailAddress}
                            </Styled.GuestUserLink>
                        </Styled.GuestUserRow>
                    )}
                    {!isAssignedToVesselEmail && showAssignToVesselButton && (
                        <Styled.SearchInputWrapper>
                            <Styled.UserSearchInput
                                id="assign-vessel"
                                onClick={() => {
                                    setDropdownOpen(false)
                                    assignIncidentResponseTo(
                                        id,
                                        undefined,
                                        undefined,
                                        true,
                                        dispatch,
                                    )
                                }}
                            >
                                Assign to vessel
                            </Styled.UserSearchInput>
                        </Styled.SearchInputWrapper>
                    )}
                    <Styled.Options width={width}>
                        {users.map((user) => (
                            <Styled.Option key={user.user}>
                                <Checkbox
                                    id={user.user}
                                    checked={user.user === currentUserAssignedTo}
                                    onChange={() => {
                                        setChecked(user.user)
                                    }}
                                    label={user.username}
                                />
                            </Styled.Option>
                        ))}
                        <Styled.Option key="unassigned">
                            <Checkbox
                                id="unassigned"
                                checked={
                                    !currentUserAssignedTo &&
                                    !currentEmailAssignedTo &&
                                    !currentVesselAssignedTo
                                }
                                onChange={() => {
                                    setChecked('unassigned')
                                }}
                                label="Unassigned"
                            />
                        </Styled.Option>
                    </Styled.Options>
                    <Styled.ButtonsWrapper>
                        <Styled.CancelButton
                            onClick={() => {
                                setCurrentUserAssignedTo(assignedTo)
                                setDropdownOpen(false)
                            }}
                        >
                            Cancel
                        </Styled.CancelButton>
                        <Styled.AssignButton
                            disabled={
                                currentUserAssignedTo === assignedTo &&
                                currentEmailAssignedTo === guestAssignedToEmail &&
                                currentVesselAssignedTo === isAssignedIncidentEmail
                            }
                            onClick={() => {
                                assignAndClose(id, currentUserAssignedTo)
                            }}
                            id="assign-button"
                        >
                            {buttonDisplayText}
                        </Styled.AssignButton>
                    </Styled.ButtonsWrapper>
                </Styled.DropdownContentStyle>
            )}
            {openGuestEmail && (
                <OpenGuestEmailEntry
                    id={id}
                    assignedTo={assignedTo}
                    assignedToName={assignedToName}
                    setCurrentAssignedTo={setCurrentUserAssignedTo}
                    setOpenGuestEmail={setOpenGuestEmail}
                />
            )}
        </AssignedToWrapper>
    )
}
