import * as Styled from '../user-management.styled'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {RefreshButton} from './refresh-button/refresh-button'
import {fetchUsers} from '../../../store/state/users/action-creators'
import {refreshUserManagementPageEvent} from '../../../core/google-tag-manager/user-management/refresh-user-management-page-event'
import {useDispatch} from 'react-redux'
import {useUserManagement} from '../context/use-user-management'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {
    currentUserSelector,
    isInternalUserSelector,
} from '../../../store/state/current-user/selectors'
import {AddNewUserArea} from './add-new-user-area'
import {isUserAllowed} from '../../../values/Role'
import {SwitchToggle} from '../../../components/switch-toggle/switch-toggle'
import {fetchAllLocationTagsByLocation} from '../../../store/state/vessel-tags/action-creators'

interface HeaderProp {
    text: string
}
export function Header({text}: HeaderProp): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {setViewAsExternal, externalView} = useUserManagement()
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const currentUser = useTypedSelector(currentUserSelector)
    const isAdminRole = isUserAllowed(currentUser.roles, [
        'CUSTOMER_ADMIN',
        'DEVELOPER',
        'THREAT_ANALYST',
    ])

    function refresh() {
        dispatch(fetchUsers())
        dispatch(fetchAllLocationTagsByLocation())
        refreshUserManagementPageEvent()
    }

    return (
        <Styled.Header width={width}>
            <Styled.TitleWrapper>
                <Styled.PageTitle width={width}> {text} </Styled.PageTitle>
                {isAdminRole && <AddNewUserArea />}
            </Styled.TitleWrapper>

            <Styled.HeaderAreaWrapper width={width}>
                <Styled.ToggleButton>
                    {isInternalUser && (
                        <>
                            View as external
                            <SwitchToggle
                                onValue={externalView}
                                setToggleLeftValue={() => {
                                    setViewAsExternal(false)
                                }}
                                setToggleRightValue={() => {
                                    setViewAsExternal(true)
                                }}
                                id="view-as-external"
                            />
                        </>
                    )}
                </Styled.ToggleButton>
                <Styled.UpdatedTimeArea width={width}>
                    <RefreshButton refreshFunction={refresh} />
                </Styled.UpdatedTimeArea>
            </Styled.HeaderAreaWrapper>
        </Styled.Header>
    )
}
