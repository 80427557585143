import {addEvent} from '../add-event'
import {getReportType} from '../helpers/report-type-helper'

export function selectIncidentTypeEvent(
    currentIncidentType: string | undefined,
    newIncidentType: string,
): boolean {
    return addEvent('incidentPageSelectIncidentType', {
        currentIncidentType: getReportType(currentIncidentType),
        newIncidentType: getReportType(newIncidentType),
    })
}
