import {addEvent} from '../add-event'
import {getRatingStars} from '../helpers/compliance-rating-helper'

export function addNoteEvent(
    reportType: string,
    rating: number,
    numberOfOtherNotes: number,
    lengthOfNote: number,
    pageOfReport: number,
): boolean {
    return addEvent('reportsPageAddNote', {
        reportType,
        stars: getRatingStars(rating),
        numberOfOtherNotes,
        lengthOfNote,
        pageOfReport,
    })
}
