import {
    AnalysisType,
    MetricsSortType,
    TimestampFilterType,
} from '../../../../store/state/metrics-filter-beta/state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {MetricTrendsArray} from './metrics-summary'

export type PagedDataMetricsTableMap = Map<
    number,
    | MetricsResponseForAssetStatus[]
    | MetricsResponseForBenchmark[]
    | MetricsResponseForTarget[]
    | MetricsResponseForTrends[]
>
export type ApiMetricsTableMap = Map<AnalysisType, MetricsResponseData>

export interface MetricsResponseData {
    data: MetricsResponse
    totalNumberOfItems: number
    totalNumberOfPages: number
}
type commonProps = {
    location: GuidType
    locationName: string
    policy: GuidType
    policyName: string
    framework: string
    metric: MetricType
}
export interface MetricsResponseForAssetStatus extends commonProps {
    asset: GuidType
    assetName: string
    timeWithControlIssue: number
}
export interface MetricsResponseForBenchmark extends commonProps {
    benchmark: number
    performance: number
    assetsToFix: number
}
export interface MetricsResponseForTrends extends commonProps {
    window: number
    performance: number
}

export interface MetricsResponseForTarget extends commonProps {
    target: number
    performance: number
    assetsToFix: number
}

export type MetricsResponse =
    | MetricsResponseForAssetStatus[]
    | MetricsResponseForBenchmark[]
    | MetricsResponseForTrends[]
    | MetricsResponseForTarget[]

export interface MetricTableHeaderForUI {
    columnName: MetricsSortType
    columnText: string
    columnType: 'sortable' | 'fixed'
}
export enum MetricType {
    MALWARE_DETECTION = 'malwareDetection',
    SUPPORTED_OS = 'supportedOs',
    PRIVILEGED_ACCOUNTS = 'priviligedAccounts',
    REMOVABLE_CONTROLS = 'removableControls',
    SECURITY_TOOLS = 'securityTools',
    MALWARE_DEFINITION = 'malwareDefinition',
    OS_SECURITY_PATCHES = 'osSecurityPatches',
    ASSET_ONLINE = 'assetOnline',
    USB_STORAGE = 'usbStorage',
    MOBILE_DEVICES = 'mobileDevices',
    HOTSPOT_MODE = 'hotspotMode',
    WIRELESS_CONNECTIVITY = 'wirelessConnection',
    ADMIN_LOGON = 'adminLogon',
    PROTECTION = 'protection',
    MAINTENANCE = 'maintenance',
    BEHAVIOUR = 'behaviour',
}
export const allProtectionMetrics: MetricType[] = [
    MetricType.MALWARE_DETECTION,
    MetricType.SUPPORTED_OS,
    MetricType.PRIVILEGED_ACCOUNTS,
    MetricType.REMOVABLE_CONTROLS,
    MetricType.SECURITY_TOOLS,
]
export const allMaintenanceMetrics: MetricType[] = [
    MetricType.MALWARE_DEFINITION,
    MetricType.OS_SECURITY_PATCHES,
    MetricType.ASSET_ONLINE,
]

export const allBehaviourMetrics: MetricType[] = [
    MetricType.USB_STORAGE,
    MetricType.MOBILE_DEVICES,
    MetricType.HOTSPOT_MODE,
    MetricType.WIRELESS_CONNECTIVITY,
    MetricType.ADMIN_LOGON,
]
export const allMainMetricsType: MetricType[] = [
    ...allProtectionMetrics,
    ...allMaintenanceMetrics,
    ...allBehaviourMetrics,
]

export const allPeriodTypeOther = [TimestampFilterType.LAST_24_H, TimestampFilterType.LAST_7_DAYS]
export const allPeriodTypeTarget = [TimestampFilterType.ONE_QUARTER, TimestampFilterType.ONE_YEAR]
export const allAnalysisTypes = [
    AnalysisType.BENCHMARK,
    AnalysisType.TRENDS,
    AnalysisType.WORST_PERFORMING_VESSELS,
    AnalysisType.TARGET,
]
export interface MetricModalDetails {
    metricName: string
    metricValue: MetricType | string
    locationName: string
    numberOfAssets: number
    assetsWithIssues: number
    scoreOfOkAssets: number
    appliedPolicyName: string
    vesselWithinTarget: string
    changeOverTrendPeriod: string
    targetForThisMetric: string
    assetsToFix: number
    trends: MetricTrendsArray[]
}
export const UNDEFINED_METRIC_MODAL_DETAILS: MetricModalDetails = {
    metricName: 'Unknown',
    metricValue: 'Unknown',
    locationName: 'Unknown',
    numberOfAssets: 0,
    assetsWithIssues: 0,
    scoreOfOkAssets: 0,
    appliedPolicyName: 'Unknown',
    vesselWithinTarget: 'Unknown',
    changeOverTrendPeriod: 'Unknown',
    targetForThisMetric: 'Unknown',
    assetsToFix: 0,
    trends: [],
}
