import {FilterTimeRangeSelectOptionProps} from '../../../pages/reports-v2/contexts/types/filter-time-range'

export function getTimeRangeLabel(
    timeRangeArray: FilterTimeRangeSelectOptionProps[],
    timeRangeValue: string | undefined,
): string {
    if (!timeRangeValue || timeRangeValue === '') {
        return 'All'
    }
    const value = timeRangeArray.find((e) => e.value === timeRangeValue)
    return value ? value.label : 'Unknown'
}
