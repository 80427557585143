import styled, {css, keyframes} from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const AnalysisPeriod = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
const blinkAnimation = keyframes`
  0%, 100% {
    background-color: rgba(31, 136, 229, 0.1);
  }
  50% {
    background-color: inherit;
  }
`

interface WrapperProps {
    highlightedFilerValue: boolean
}
export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${spacing(4)};
    padding: 2px 4px;
    animation: ${(props) =>
        props.highlightedFilerValue
            ? css`
                  ${blinkAnimation} 1s infinite
              `
            : 'none'};
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 190px;
    background-color: inherit;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
