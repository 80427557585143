import {NoteOutputModel} from '../../../models/note.model'
import COMMENT_ICON from '../../../../../@assets/images/comment-icon.png'
import EXPAND_ICON from '../../../../../@assets/icons/expnd-icon.svg'
import * as Styled from './incident-note.styled'
import {useState} from 'react'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {wordCount, truncateNotes, getLines, isUserAllowToEdit} from '../../../data-helpers'
import {expandNoteEvent} from '../../../../../core/google-tag-manager/incidents/expand-note-event'
import {EditButton} from '../edit-message/edit-button'
import {EditableComment} from '../edit-message/editable-comment'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {useTheme} from 'styled-components'
import {DisplayFormatType} from '../../../../../core/google-tag-manager/helpers/display-format-type'
import {formatDate} from '../../../../../helpers/formatting'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {SaveMessage} from '../../../contexts/types/save-note-result'
import {
    noteToEditSelector,
    noteToEditStatusSelector,
} from '../../../../../store/state/unsaved-incident-note-alert-modal/selectors'
interface NoteProps {
    note: NoteOutputModel
    incidentidentity: GuidType
    displayFormat: DisplayFormatType
}

export function IncidentNoteRecord({
    note,
    incidentidentity,
    displayFormat,
}: NoteProps): JSX.Element | null {
    const expandedNote = wordCount(note.text) > 40
    const {saveResultMessage, editableComments} = usePagedIncidents()
    const [isExpanded, setIsExpanded] = useState(expandedNote)
    const [noteToDisplay, setNoteToDisplay] = useState(note.text)
    const noteToEditReview = useTypedSelector(noteToEditStatusSelector)
    const noteToEditMap = useTypedSelector(noteToEditSelector)
    const noteToEdit = noteToEditMap.has(note.id)
    const noteIsAdded =
        saveResultMessage?.message === SaveMessage.NOTE_ADDED &&
        saveResultMessage?.identity === note.id

    const noteIsUpdated = note.edited === true

    const noteIsReviewed = noteToEditReview && noteToEdit

    const truncatedNote = truncateNotes(note.text)

    const userAllowToEdit = isUserAllowToEdit(note.userId, useTypedSelector(currentUserSelector))

    const [hoverOn, setHoverOn] = useState<boolean>(false)

    const {user} = useTypedSelector(currentUserSelector)

    function mouseOverRow(): void {
        if (userAllowToEdit) {
            setHoverOn(true)
        }
    }

    function mouseLeaveRow(): void {
        setHoverOn(false)
    }
    const isEditableComments = editableComments.get(note.id) === true
    const showEditButton = hoverOn && !isEditableComments

    function getFormattedComment(): string {
        if (isExpanded) {
            return `${truncatedNote}...`
        }
        return noteToDisplay
    }
    const formattedComment = getFormattedComment()
    const incativeEditableComment = noteToEditMap.get(note.id) === note.text

    function getBackgroundColor(theme: CyberOwlTheme): string {
        let result = ''
        if (noteIsAdded) {
            return (result = theme.incidents.dataRow.saveNoteBackground)
        }
        if (hoverOn) {
            if (isEditableComments) {
                return (result = theme.incidents.dataRow.editNoteBackground)
            }
            return (result = theme.incidents.dataRow.hoverNoteBackground)
        }
        if (noteIsReviewed) {
            if (incativeEditableComment) {
                return (result = '')
            }
            return (result = theme.incidents.dataRow.editNoteBackground)
        }
        return result
    }
    const theme = useTheme()
    const {width} = useDimensions()

    if (note.text.length === 0) {
        return null
    }
    let who = note?.createdBy ?? 'Unknown'
    if (note.isAddedOnVessel) {
        who = 'Note added on vessel'
    }
    const serviceAccount = who === 'Basal Ganglia Service Account User'

    return (
        <Styled.Note
            result={getBackgroundColor(theme)}
            onMouseEnter={() => mouseOverRow()}
            onMouseLeave={() => mouseLeaveRow()}
            id={`incident-note-record-${note.id}-wrapper`}
        >
            <Styled.NoteDetail id={`incident-note-detail-${note.id}`}>
                <img src={COMMENT_ICON} height={12} width={12} />
                <Styled.When id={`incident-note-detail-when-${note.id}`}>
                    {formatDate(note?.updatedWhen) ?? 'Unknown'}
                </Styled.When>
                <Styled.Who id={`incident-note-detail-who-${note.id}`}>
                    {serviceAccount ? 'Cyberowl Threat Analyst' : who}
                </Styled.Who>
                {!note.isViewed && note.userId != user && <Styled.NewSquare>NEW</Styled.NewSquare>}
            </Styled.NoteDetail>
            {showEditButton && <EditButton noteIdentityToEdit={note.id} />}
            {isEditableComments ? (
                <EditableComment
                    comment={note?.text}
                    setNoteToDisplay={setNoteToDisplay}
                    noteIdentity={note.id}
                    incidentidentity={incidentidentity}
                    displayFormat={displayFormat}
                />
            ) : (
                <Styled.Text id={`incident-note-detail-text-${note.id}`}>
                    {getLines(formattedComment).map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                    <Styled.BottomLine>
                        {isExpanded && (
                            <Styled.ExpandImage
                                src={EXPAND_ICON}
                                onClick={() => {
                                    setIsExpanded(!expandedNote)
                                    setNoteToDisplay(note.text)
                                    expandNoteEvent(note.id, displayFormat)
                                }}
                                id={`incident-note-expandedNote-${note.id}`}
                            />
                        )}
                        {noteIsUpdated && <Styled.Edited width={width}>(edited)</Styled.Edited>}
                    </Styled.BottomLine>
                </Styled.Text>
            )}
        </Styled.Note>
    )
}
