import PersistableAppState from './persistable-app-state'
import {RouterState} from 'connected-react-router'
import {ConfigReduxState} from '../state/config/state'
import {IndicatorsReduxState} from '../state/indicators/state'
import {LocationsReduxState} from '../state/locations/state'
import {NodesReduxState} from '../state/nodes/state'
import {RolesReduxState} from '../state/roles/state'
import {ThreatMeasuresReduxState} from '../state/threat-measures/state'
import {LatestEventTimestampsReduxState} from '../state/latest-event-timestamps/state'
import {UsersReduxState} from '../state/users/state'
import {EtsValueReduxState} from '../state/ets-value/state'
import {EtsTrendReduxState} from '../state/ets-trend/state'
import {SFMReduxState} from '../state/sfm-scores/state'
import {WatchReduxState} from '../state/watched-nodes/state'
import {LatestLocationThreatScoresReduxState} from '../state/latest-location-threat-scores/state'
import {NetworkAnomalyMatrixState} from '../state/network-anomaly-matrix/state'
import {EngineStatusReduxState} from '../state/engine-status/state'
import {SelfMonitoringEngineEventsReduxState} from '../state/self-monitoring-events/state'
import {SelfMonitoringEngineRulesReduxState} from '../state/self-monitoring-rules/state'
import {SessionDataReduxState} from '../state/session-data/state'
import {LocationState} from 'history'
import {CurrentUserReduxState} from '../state/current-user/state'
import {VesselFiltersState} from '../state/my-vessels-filters/types/my-vessel-filter-state'
import {IncidentsFilterReduxState} from '../state/incidents-filter/state'
import {LatestUnknownDataReduxState} from '../state/latest-unknown-asset/state'
import {MonaEngineModesReduxState} from '../state/mona-engine-modes/state'
import {IncidentTypesReduxState} from '../state/incident-types/state'
import {UnsavedIncidentNoteAlertModalReduxState} from '../state/unsaved-incident-note-alert-modal/state'
import {IncidentsOverviewReduxState} from '../state/incident-overview/state'
import {WatchIncidentsReduxState} from '../state/watched-incidents/state'
import {CurrentEmailRecipientsReduxState} from '../state/email-recipient-management/state'
import {VesselTagReduxState} from '../state/vessel-tags/state'
import {LocationTagsSummaryReduxState} from '../state/location-tags-summary/state'
import {VesselFilterReduxState} from '../state/vessel-filter/types/vessel-filter-state'
import {ReportsFilterReduxState} from '../state/reports-filter/state'
import {NetworkAssetsFilterReduxState} from '../state/network-assets-filter/state'
import {AuditLogReduxState} from '../state/audit-log/state'
import {LocationTagThreatScoreReduxState} from '../state/location-tag-score/state'
import {UsbInventoryFilterReduxState} from '../state/usb-inventory-filter/state'
import {SoftwareInventoryFilterReduxState} from '../state/software-inventory-filter/state'
import {SavedFiltersReduxState} from '../state/saved-filters/state'
import {ExtendedDataRequestReduxState} from '../state/extended-data-request/state'
import {MetricsBetaFilterReduxState} from '../state/metrics-filter-beta/state'
import {MetricTypesReduxState} from '../state/metric-types/state'
import {VesselsBetaFilterReduxState} from '../state/vessels-beta-filter/state'
import {VesselManagementFilterReduxState} from '../state/vessel-management-filter/state'
import {OTAssetStateReduxState} from '../state/ot-assets/state'
import {IncidentsReportFilterReduxState} from '../state/incidents-report-filter/state'

interface AppState extends PersistableAppState {
    router?: RouterState<LocationState>
    etsValue: EtsValueReduxState
    etsTrend: EtsTrendReduxState
    indicators: IndicatorsReduxState
    roles: RolesReduxState
    nodes: NodesReduxState
    threatMeasures: ThreatMeasuresReduxState
    locations: LocationsReduxState
    latestEventTimestamps: LatestEventTimestampsReduxState
    users: UsersReduxState
    sfmScores: SFMReduxState
    watchedNodes: WatchReduxState
    latestLocationThreatScores: LatestLocationThreatScoresReduxState
    networkAnomalyMatrix: NetworkAnomalyMatrixState
    engineStatus: EngineStatusReduxState
    selfMonitoringEngineEvents: SelfMonitoringEngineEventsReduxState
    selfMonitoringEngineRules: SelfMonitoringEngineRulesReduxState
    sessionData: SessionDataReduxState
    config: ConfigReduxState
    currentUser: CurrentUserReduxState
    myVesselsFilter: VesselFiltersState
    incidentsFilter: IncidentsFilterReduxState
    latestUnknownAsset: LatestUnknownDataReduxState
    monaEngineModes: MonaEngineModesReduxState
    incidentTypes: IncidentTypesReduxState
    unsavedIncidentNoteAlertModal: UnsavedIncidentNoteAlertModalReduxState
    incidentsOverview: IncidentsOverviewReduxState
    watchIncidents: WatchIncidentsReduxState
    emailRecipientData: CurrentEmailRecipientsReduxState
    vesselTags: VesselTagReduxState
    locationTagsSummary: LocationTagsSummaryReduxState
    vesselFilter: VesselFilterReduxState
    reportsFilter: ReportsFilterReduxState
    networkAssetsFilter: NetworkAssetsFilterReduxState
    usbInventoryFilter: UsbInventoryFilterReduxState
    softwareInventoryFilter: SoftwareInventoryFilterReduxState
    auditLog: AuditLogReduxState
    locationTagThreatScore: LocationTagThreatScoreReduxState
    savedFilters: SavedFiltersReduxState
    extendedDataRequest: ExtendedDataRequestReduxState
    metricsBetaFilter: MetricsBetaFilterReduxState
    metricTypes: MetricTypesReduxState
    vesselsBetaFilter: VesselsBetaFilterReduxState
    vesselManagementFilter: VesselManagementFilterReduxState
    OTAssets: OTAssetStateReduxState
    incidentsReportFilter: IncidentsReportFilterReduxState
}

export function toPersistableAppState(state: AppState): PersistableAppState {
    return {
        auth: state.auth,
    }
}

export default AppState
