import {createSelector} from 'reselect'

import {nodesFetchingSelector} from '../../../store/state/nodes/selectors'
import {fetchingSfmScoresSelector} from '../../../store/state/sfm-scores/selectors'
import {threatMeasuresFetchingSelector} from '../../../store/state/threat-measures/selectors'

export const group1DataIsLoadedReselector = createSelector(
    nodesFetchingSelector,
    fetchingSfmScoresSelector,
    threatMeasuresFetchingSelector,
    (nodesLoading, sfmScoresLoading, threatMeasuresLoading): boolean => {
        return nodesLoading || sfmScoresLoading || threatMeasuresLoading ? false : true
    },
)
