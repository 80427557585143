import {IncidentResponse} from '../../../pages/incidents-v3/contexts/types/incident-response'
import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'

export const newIncidentsSelector = (state: AppState): number => {
    return state.incidentsOverview.overview.newIncidentsCount
}

export const openIncidentsSelector = (state: AppState): number => {
    return state.incidentsOverview.overview.openIncidentsCount
}

export const latestIncidentResponseSelector = (state: AppState): IncidentResponse | undefined => {
    return state.incidentsOverview.overview.latestIncident
}

export const averageIncdientsAgeSelector = (state: AppState): number => {
    return state.incidentsOverview.overview.averageIncidentAge
}

export const hasNewIncidentsSelector = (state: AppState): boolean => {
    return state.incidentsOverview.overview.newIncidentsCount !== 0
}

export const isIncidentsOverviewLoadingSelector = (state: AppState): boolean => {
    return state.incidentsOverview.loading === LoadingState.RequestingData
}
