import {IncidentSeverityValues} from '../../../contexts/types/incident-severity-types'
import * as Styled from './incident-severity-filter.styled'
import {useDispatch} from 'react-redux'
import {filterBySeverityEvent} from '../../../../../core/google-tag-manager/incidents-beta/filter-by-severity-event'
import {setFilteredIncidentSeverity} from '../../../../../store/state/incidents-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {difference, remove} from 'lodash'

export const severities = [
    [IncidentSeverityValues.LOW],
    [IncidentSeverityValues.MEDIUM],
    [IncidentSeverityValues.HIGH],
    [IncidentSeverityValues.CRITICAL],
]
export function IncidentSeverityFilter(): JSX.Element {
    const dispatch = useDispatch()
    let {filterBySeverities} = useTypedSelector(incidentsFilterSelector)
    const {width} = useDimensions()

    return (
        <Styled.ContentWrapper width={width} id="incidentSeverityFilter-wrapper">
            <Styled.Label>Severity:</Styled.Label>
            <Styled.SeverityWrapper>
                {severities.map((severity) => (
                    <Styled.SeverityLevelSquare
                        severityLevel={severity[0]}
                        key={severity[0]}
                        activeFilter={
                            difference(severity, filterBySeverities ?? []).length === 0 ||
                            filterBySeverities === undefined ||
                            filterBySeverities?.length === 0
                        }
                        onClick={() => {
                            if (!filterBySeverities) {
                                filterBySeverities = []
                            }
                            filterBySeverities = [...filterBySeverities]
                            if (filterBySeverities.length === severities.flat().length) {
                                remove(filterBySeverities, (s) => !severity.includes(s))
                            } else if (!filterBySeverities.find((s) => severity.includes(s))) {
                                filterBySeverities.push(...severity)
                            } else {
                                remove(filterBySeverities, (s) => severity.includes(s))
                            }
                            dispatch(setFilteredIncidentSeverity(filterBySeverities))
                            filterBySeverityEvent(filterBySeverities)
                        }}
                        id={`incidentSeverityFilter-${severity[0]}`}
                    >
                        {severity}
                    </Styled.SeverityLevelSquare>
                ))}
            </Styled.SeverityWrapper>
        </Styled.ContentWrapper>
    )
}
