import {useDispatch} from 'react-redux'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {determineViewAllState, getMainMetricsDisplayName} from '../../data-helper'
import {MetricsTypeRow} from './metrics-type-row'
import {ViewAllMetricTypeRow} from './view-all-metrics-type-row'
import {toggleMainMetricsType} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {useEffect, useState} from 'react'
import EXPAND_METRICS from './expand-metrics.svg'
import COLLAPSE_METRICS from './collapse-metrics.svg'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    allMainMetricsTypeSelector,
    behaviourMetricTypesSelector,
    behaviourMetricsForUISelector,
} from '../../../../../store/state/metric-types/selectors'
import {MetricType} from '../../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {filterByEvent} from '../../../../../core/google-tag-manager/metrics-beta/filter-by-event'

export function ViewAllBehaviourMetrics(): JSX.Element {
    const [subMetricsVisible, setSubMetricsVisible] = useState(false)

    const dispatch = useDispatch()
    const {metricTypes} = useTypedSelector(metricsBetaFilterSelector)
    const availableBehaviourSubMetrics = useTypedSelector(behaviourMetricTypesSelector)
    const allMainMetricsType = useTypedSelector(allMainMetricsTypeSelector)
    const behaviourMetricsForUI = useTypedSelector(behaviourMetricsForUISelector)
    const viewAllBehaviour = determineViewAllState(availableBehaviourSubMetrics, metricTypes)
    const {highlightedFilerValue} = usePagedMetricsBeta()

    useEffect(() => {
        if (highlightedFilerValue === 'Metrics selection') {
            setSubMetricsVisible(true)
        }
    }, [highlightedFilerValue])

    function setViewMainMetricsState(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleMainMetricsType(allMainMetricsType, availableBehaviourSubMetrics, true))
            filterByEvent('View all Behaviour metrics', true)
        } else if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleMainMetricsType(allMainMetricsType, availableBehaviourSubMetrics, false))
            filterByEvent('View all Behaviour metrics', false)
        }
    }
    function getIconState(subMetricsVisible: boolean): string {
        return subMetricsVisible ? COLLAPSE_METRICS : EXPAND_METRICS
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: '5px',
                }}
            >
                <ViewAllMetricTypeRow
                    state={viewAllBehaviour}
                    setState={setViewMainMetricsState}
                    showClearButton={false}
                    label={getMainMetricsDisplayName(MetricType.BEHAVIOUR)}
                />
                <img
                    src={getIconState(subMetricsVisible)}
                    onClick={() => setSubMetricsVisible(!subMetricsVisible)}
                    style={{width: '14px', height: '14px'}}
                    id={`icon-collapse-${MetricType.BEHAVIOUR}`}
                />
            </div>
            {subMetricsVisible && (
                <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    id="all-behaviour-metrics-wrapper"
                >
                    {behaviourMetricsForUI?.map((subMetric) => (
                        <MetricsTypeRow subMetric={subMetric} key={subMetric.name} />
                    ))}
                </div>
            )}
        </>
    )
}
