import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {
    AnalysisType,
    DEFAULT_METRICS_BETA_FILTER,
    MetricsBetaFilterReduxState,
    TimestampFilterType,
} from './state'

export const metricsBetaFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.metricsBetaFilter.loadingState

export const metricsBetaFilterLoadingSelector = (state: AppState): boolean =>
    state.metricsBetaFilter.loadingState === LoadingState.RequestingData ||
    state.metricsBetaFilter.loadingState === LoadingState.NotPopulated

export const metricsBetaFilterSelector = (state: AppState): MetricsBetaFilterReduxState =>
    state.metricsBetaFilter

export const selectedPeriodSelector = (state: AppState): number => {
    if (state.metricsBetaFilter.analysisTypes === AnalysisType.TRENDS) {
        switch (state.metricsBetaFilter.periodForTrend) {
            case TimestampFilterType.ONE_FORTNIGHT:
                return 14
            case TimestampFilterType.ONE_QUARTER:
                return 71
            case TimestampFilterType.ONE_YEAR:
                return 365
            case TimestampFilterType.LAST_2_H:
                return 2
            default:
                return 0
        }
    } else {
        switch (state.metricsBetaFilter.periodForCommon) {
            case TimestampFilterType.LAST_24_H:
                return 1
            case TimestampFilterType.LAST_7_DAYS:
                return 7
            case TimestampFilterType.LAST_30_DAYS:
                return 30
            case TimestampFilterType.LATEST:
                return 0
            default:
                return 0
        }
    }
}
export const selectedFilterNameSelector = (state: AppState): string =>
    state.metricsBetaFilter.selectedFilterName

export const activeFilterSelector = (state: AppState): boolean =>
    state.metricsBetaFilter.metricTypes !== undefined ||
    state.metricsBetaFilter.periodForCommon !== DEFAULT_METRICS_BETA_FILTER.periodForCommon ||
    state.metricsBetaFilter.periodForTrend !== DEFAULT_METRICS_BETA_FILTER.periodForTrend ||
    state.metricsBetaFilter.onlyAssetsWithIssues === false
