import {createSelector} from 'reselect'
import {locationsSelector} from '../../../../store/state/locations/selectors'
import {Location, LocationIdType} from '../../../../store/state/locations/state'
import {
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../../store/state/vessel-filter/selectors'
import {VesselFilterReduxState} from '../../../../store/state/vessel-filter/types/vessel-filter-state'
import LoadingState from '../../../../values/loading-state-enum'
import {VesselTags} from '../../../../store/state/vessel-tags/state'
import {UsbInventoryFilterReduxState} from '../../usb-inventory-filter/state'
import {NetworkAssetsFilterReduxState} from '../../network-assets-filter/state'
import AppState from '../../../types/app-state'
import {SoftwareInventoryFilterReduxState} from '../../software-inventory-filter/state'

export function createLocationSelectionNumberReselector<
    T extends
        | UsbInventoryFilterReduxState
        | NetworkAssetsFilterReduxState
        | SoftwareInventoryFilterReduxState,
>(filterSelector: (state: AppState) => T) {
    return createSelector(
        locationsSelector,
        vesselFilterSelector,
        vesselsFilterLoadingStateSelector,
        filterSelector,
        (
            allLocations: Location[],
            vesselFilter: VesselFilterReduxState,
            vesselFilterLoading: LoadingState,
            filterSelectorResult: T,
        ): number => {
            const vesselFilterLocations = vesselFilter.locations
            const vesselFilterTagTerm = vesselFilter.searchVesselTagTerm
            const vesselFilterNameTerm = vesselFilter.searchVesselNameTerm

            const isNotPopulated = vesselFilterLoading === LoadingState.NotPopulated
            const {locations, searchVesselTagTerm, searchVesselNameTerm} = filterSelectorResult as
                | UsbInventoryFilterReduxState
                | NetworkAssetsFilterReduxState
                | SoftwareInventoryFilterReduxState

            const filterLocations = isNotPopulated ? locations : vesselFilterLocations
            const filterTagTerm = isNotPopulated ? searchVesselTagTerm : vesselFilterTagTerm
            const filterNameTerm = isNotPopulated ? searchVesselNameTerm : vesselFilterNameTerm

            const filteredVessels = filteredVesselIds(
                allLocations,
                filterLocations,
                filterTagTerm ?? [],
                filterNameTerm ?? '',
            )
            return filteredVessels.length ?? 0
        },
    )
}

function filteredVesselIds(
    AllLocations: Location[],
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
): string[] {
    return AllLocations.filter((location) => filterVessel(location, locations))
        .filter((location) => filterVesselTags(location.tags, searchVesselTagTerm))
        .filter((location) => filterVesselName(location.description, searchVesselNameTerm))
        .sort(compareRecord)
        .map((location) => location.location)
}
function filterVessel(location: Location, locations: Set<LocationIdType> | undefined): boolean {
    return !locations ? true : locations.has(location.location)
}

function filterVesselTags(vesselTag: VesselTags[] | undefined, searchTerm: string[]): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return searchTerm.every((searchTag) =>
        vesselTag?.map((element) => element.name).includes(searchTag),
    )
}

function filterVesselName(vesselName: string, searchTerm: string): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return vesselName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
}

function compareRecord(a: Location, b: Location): number {
    return a.description.localeCompare(b.description)
}
