import {SoftwareStatus} from '../../../../../store/state/software-inventory-filter/state'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {allSoftwareStatuses} from '../../data-helpers'
import {toggleSoftwareStatuses} from '../../../../../store/state/software-inventory-filter/action-creators'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {StatusRowWrapper} from './software-inventory-status-filter.styled'
import {StringUtils} from '../../../../../utils/Utils'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

type Props = {
    statusLabel: SoftwareStatus
}

export function SoftwareStatusRow({statusLabel}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {filteredSoftwareStatus} = useTypedSelector(softwareInventoryFilterSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    function isChecked(givenValue: SoftwareStatus): boolean {
        return !filteredSoftwareStatus ? true : filteredSoftwareStatus.includes(givenValue)
    }

    function setChecked(currentSelectedOsftwareStatuses: SoftwareStatus, newValue: boolean): void {
        dispatch(
            toggleSoftwareStatuses(allSoftwareStatuses, currentSelectedOsftwareStatuses, newValue),
        )
    }
    return (
        <StatusRowWrapper>
            <Checkbox
                id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_status-filter_${statusLabel}`}
                label={StringUtils.capitaliseString(statusLabel)}
                checked={isChecked(statusLabel)}
                onChange={(newValue) => setChecked(statusLabel, newValue)}
                isInactive={isInactive ?? false}
            />
        </StatusRowWrapper>
    )
}
