import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {toggleFilteredVessels} from '../../../store/state/vessel-filter/action-creators'
import {filteredByTagsVesselIds} from '../../../store/state/vessel-filter/reselector/filtered-by-tags-vessel-ids.reselector'
import {filteredVesselIds} from '../../../store/state/vessel-filter/reselector/filtered-vessel-ids.reselector'
import {SelectButton} from './select-button/select-button'
import * as Styled from './_styled/select-vessels-action-row.styled'
import {pageTypeSelector, vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'

interface Props {
    showAllButton: boolean
    showNoneButton: boolean
}

export function SelectVesselsActionRow({showAllButton, showNoneButton}: Props): JSX.Element {
    const dispatch = useDispatch()
    const locations = useTypedSelector(locationsSelector)
    const filterState = useTypedSelector(vesselFilterSelector)
    const vessels = locations.map((location) => location.location)
    const filteredVessels = filteredByTagsVesselIds(locations, filterState)
    const ids = filteredVesselIds(locations, filterState)
    const pageType = useTypedSelector(pageTypeSelector)
    const filterSelectedMessage =
        ids.length <= 1
            ? `${ids.length} vessel selected`
            : vessels.length === ids.length
              ? 'All vessels selected'
              : `${ids.length} vessels selected`

    return (
        <Styled.ActionRowWrapper>
            <Styled.ButtonWrapper id="selected-vessels-row">
                <SelectButton
                    clearFn={() => dispatch(toggleFilteredVessels(filteredVessels, true, pageType))}
                    label="All"
                    disabled={!showAllButton}
                />
                <SelectButton
                    clearFn={() =>
                        dispatch(toggleFilteredVessels(filteredVessels, false, pageType))
                    }
                    label="None"
                    disabled={!showNoneButton}
                />
            </Styled.ButtonWrapper>
            <Styled.NumOfSelectedVesselMessage id="number-of-selected-vessels">
                {filterSelectedMessage}
            </Styled.NumOfSelectedVesselMessage>
        </Styled.ActionRowWrapper>
    )
}
