import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {FormattedMetricData, MetricTypeResponseMap} from './state'
import {MetricType} from '../../../pages/metrics-beta/contexts/types/metrics-response'

export const loadingStateSelector = (state: AppState): LoadingState => state.metricTypes.loading

export const metricTypesSelector = (state: AppState): MetricTypeResponseMap | undefined =>
    state.metricTypes.dataMetricResponseMap

export const isProtectionMetricTypesSelector = (state: AppState): boolean =>
    state.metricTypes.dataMetricResponseMap
        ? state.metricTypes.dataMetricResponseMap.has(MetricType.PROTECTION)
        : false

export const protectionMetricTypesSelector = (state: AppState): MetricType[] =>
    state.metricTypes.dataMetricResponseMap != undefined
        ? state.metricTypes.dataMetricResponseMap?.get(MetricType.PROTECTION) ??
          new Array<MetricType>()
        : new Array<MetricType>()

export const isMaintenanceMetricTypesSelector = (state: AppState): boolean =>
    state.metricTypes.dataMetricResponseMap
        ? state.metricTypes.dataMetricResponseMap.has(MetricType.MAINTENANCE)
        : false

export const maintenanceMetricTypesSelector = (state: AppState): MetricType[] =>
    state.metricTypes.dataMetricResponseMap != undefined
        ? state.metricTypes.dataMetricResponseMap?.get(MetricType.MAINTENANCE) ??
          new Array<MetricType>()
        : new Array<MetricType>()

export const isBehaviourMetricTypesSelector = (state: AppState): boolean =>
    state.metricTypes.dataMetricResponseMap
        ? state.metricTypes.dataMetricResponseMap.has(MetricType.BEHAVIOUR)
        : false

export const behaviourMetricTypesSelector = (state: AppState): MetricType[] =>
    state.metricTypes.dataMetricResponseMap != undefined
        ? state.metricTypes.dataMetricResponseMap?.get(MetricType.BEHAVIOUR) ??
          new Array<MetricType>()
        : new Array<MetricType>()
export const allMainMetricsTypeSelector = (state: AppState): MetricType[] =>
    state.metricTypes.dataMetricResponseMap != undefined
        ? [
              ...(state.metricTypes.dataMetricResponseMap?.get(MetricType.PROTECTION) ??
                  new Array<MetricType>()),
              ...(state.metricTypes.dataMetricResponseMap?.get(MetricType.MAINTENANCE) ??
                  new Array<MetricType>()),
              ...(state.metricTypes.dataMetricResponseMap?.get(MetricType.BEHAVIOUR) ??
                  new Array<MetricType>()),
          ]
        : new Array<MetricType>()

export const totalNumberOfFrameworkFiltersSelector = (state: AppState): number => {
    const protectionMetricTypes = state.metricTypes.dataMetricResponseMap
        ? state.metricTypes.dataMetricResponseMap.has(MetricType.PROTECTION)
        : false
    const maintenanceMetricTypes = state.metricTypes.dataMetricResponseMap
        ? state.metricTypes.dataMetricResponseMap.has(MetricType.MAINTENANCE)
        : false
    const behaviourMetricTypes = state.metricTypes.dataMetricResponseMap
        ? state.metricTypes.dataMetricResponseMap.has(MetricType.BEHAVIOUR)
        : false
    const totalNumberOfFrameworkFilters =
        [protectionMetricTypes, maintenanceMetricTypes, behaviourMetricTypes].filter(
            (mainCategory) => mainCategory === true,
        ).length ?? 0
    return totalNumberOfFrameworkFilters
}
export const maintenanceMetricsForUISelector = (state: AppState): FormattedMetricData[] => {
    if (!state.metricTypes.dataMetricResponseMap) {
        return new Array<FormattedMetricData>()
    }

    const metricType = state.metricTypes.dataMetricResponseMap.get(MetricType.MAINTENANCE)

    if (!metricType) {
        return new Array<FormattedMetricData>()
    }

    return filterFormattedMetricData(state.metricTypes.formattedMetricData, metricType)
}

export const behaviourMetricsForUISelector = (state: AppState): FormattedMetricData[] => {
    if (!state.metricTypes.dataMetricResponseMap) {
        return new Array<FormattedMetricData>()
    }

    const metricType = state.metricTypes.dataMetricResponseMap.get(MetricType.BEHAVIOUR)

    if (!metricType) {
        return new Array<FormattedMetricData>()
    }

    return filterFormattedMetricData(state.metricTypes.formattedMetricData, metricType)
}

export const protectionMetricsForUISelector = (state: AppState): FormattedMetricData[] => {
    if (!state.metricTypes.dataMetricResponseMap) {
        return new Array<FormattedMetricData>()
    }

    const metricType = state.metricTypes.dataMetricResponseMap.get(MetricType.PROTECTION)

    if (!metricType) {
        return new Array<FormattedMetricData>()
    }

    return filterFormattedMetricData(state.metricTypes.formattedMetricData, metricType)
}

const filterFormattedMetricData = (
    formattedMetricData: FormattedMetricData[],
    metricType: MetricType[],
): FormattedMetricData[] => {
    return formattedMetricData.filter((filterMaintenanceMetrics) =>
        metricType.includes(filterMaintenanceMetrics.name),
    )
}
export const allFormattedMetricsForUISelector = (state: AppState): FormattedMetricData[] => {
    if (!state.metricTypes.dataMetricResponseMap) {
        return new Array<FormattedMetricData>()
    }
    if (!state.metricTypes.formattedMetricData) {
        return new Array<FormattedMetricData>()
    }

    return state.metricTypes.formattedMetricData
}
