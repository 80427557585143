import {IsolationOperation} from '../../../pages/unknown-assets-v2/context/types/isolated-assets-type'
import {addEvent} from '../add-event'

export function changeIsolationOperationEvent(
    assetIdentity: string,
    operation: IsolationOperation,
): boolean {
    return addEvent('changeIsolationOperation', {
        assetIdentity: assetIdentity,
        operation: operation,
    })
}
