import {useDispatch} from 'react-redux'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../store/state/audit-log/state'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import * as Styled from './policy-management.styled'
import {useEffect, useRef} from 'react'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {DataPopulated} from './components/data-populated'
import {Header} from './components/header'
import {DataLoading} from '../../components/data-loading/data-loading'
import {NoData} from './components/shared/no-data/no-data'
import LoadingState from '../../values/loading-state-enum'
import useTypedSelector from '../../hooks/use-typed-selector'
import {usersLoadingStateSelector} from '../../store/state/users/selectors'
import {fetchUsers} from '../../store/state/users/action-creators'
import {PolicyManagementProvider} from './contexts/policy-management/policy-management-provider'
import {usePolicyManagement} from './contexts/policy-management/use-policy-management'
import {IncidentsPolicyProvider} from './contexts/incidents-policy/incidents-policy-provider'
import {MetricsPolicyProvider} from './contexts/metrics-policy/metrics-policy-provider'
import {EnforcementPolicyProvider} from './contexts/enforcement-policy/enforcement-policy-provider'
import {useMetricsPolicy} from './contexts/metrics-policy/use-metrics-policy'
import {BoundsContext} from '../../components/bounds-context/bounds-context'

export default function PolicyManagement(): JSX.Element {
    return (
        <PolicyManagementProvider>
            <IncidentsPolicyProvider>
                <MetricsPolicyProvider>
                    <EnforcementPolicyProvider>
                        <PolicyManagementPage />
                    </EnforcementPolicyProvider>
                </MetricsPolicyProvider>
            </IncidentsPolicyProvider>
        </PolicyManagementProvider>
    )
}

function PolicyManagementPage(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {fetchPolicyData, hasData, loadingDataState} = usePolicyManagement()
    const {findMetricsSchema} = useMetricsPolicy()
    const isLoading = loadingDataState !== LoadingState.Loaded
    const layoutRef = useRef(null)
    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.POLICY_MANAGEMENT, AuditLogAuditType.PAGE_ACCESS))
        fetchPolicyData()
        findMetricsSchema()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (usersLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchUsers())
        }
    }, [dispatch, usersLoadingState])

    return (
        <FixedPageTemplate>
            <Styled.WideContentHeaderArea width={width}>
                <Header text="Policy Management" />
            </Styled.WideContentHeaderArea>
            <Styled.WideContentArea width={width} fullHeight={true}>
                <Styled.ScrollableContent ref={layoutRef} flexGrow>
                    {isLoading ? (
                        <DataLoading />
                    ) : hasData ? (
                        <BoundsContext.Provider value={{ref: layoutRef}}>
                            <DataPopulated />
                        </BoundsContext.Provider>
                    ) : (
                        <NoData message="There are no policy found" />
                    )}
                </Styled.ScrollableContent>
            </Styled.WideContentArea>
        </FixedPageTemplate>
    )
}
