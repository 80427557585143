import TagManager from 'react-gtm-module'

interface ConfigProps {
    gfc: boolean
}

function getGfcFlag(): Promise<boolean> {
    return fetch(
        `${process.env.PUBLIC_URL}/config/${
            process.env.REACT_APP_ALT_BACKEND || 'medulla-ui'
        }.json`,
    )
        .then((result) => result.json())
        .then((result) => (result as ConfigProps).gfc)
}

export function addEvent<T>(eventName: string, data: T): boolean {
    getGfcFlag().then((flag) => {
        return !flag ? tagManager(eventName, data) : false
    })
    return true
}

function tagManager<T>(eventName: string, data: T): boolean {
    try {
        TagManager.dataLayer({
            dataLayer: {
                event: 'CyberOwlCustomEvent',
                cyberOwlEventName: eventName,
                cyberOwlEventParams: data,
            },
        })
        return true
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, eventName, data)
        return false
    }
}
