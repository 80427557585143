import {XCircle} from 'react-feather'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './filter-bar.styled'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {ResetFilterButton} from '../../../../components/page-filter/components/shared/reset-filter/reset-filter-button'
import {VesselOverview} from './vessel-overview/vessel-overview'
import {ViewControlsFilter} from './view-controls-filter/view-controls-filter'
import {AnalysisSelectionFilter} from './analysis-selection-filter/analysis-selection-filter'
import {usePagedVesselsBeta} from '../../contexts/use-paged-vessels-beta'
import {resetFilter} from '../../../../store/state/vessels-beta-filter/action-creators'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, displayFilterBar} = usePagedVesselsBeta()
    return (
        <Styled.FilterBarWrapper width={width}>
            <div style={{position: 'absolute', right: 0, cursor: 'pointer'}}>
                <XCircle
                    color="white"
                    fill="blue"
                    onClick={() => displayFilterBar(!showFilterBar)}
                />
            </div>
            <Styled.FilterBarStyled>
                <Styled.SectionHeader>
                    <Styled.SectionTitle>Fleet Overview</Styled.SectionTitle>
                </Styled.SectionHeader>
                <Styled.SectionContent>
                    <VesselOverview />
                </Styled.SectionContent>
                <Styled.SectionContent>
                    <AnalysisSelectionFilter />
                </Styled.SectionContent>
                <Styled.SectionContent>
                    <ViewControlsFilter />
                </Styled.SectionContent>
                <VesselFilters pageType={PageType.VESSELS_BETA} />
                <ResetFilterButton resetFn={resetFilter} />
            </Styled.FilterBarStyled>
        </Styled.FilterBarWrapper>
    )
}
