export enum ActionType {
    REQUEST_ALL_POLICY_DATA_ACTION = '[POLICY MANAGEMENT CONTEXT] REQUEST ALL POLICY DATA ACTION',
    RECEIVE_ALL_POLICY_DATA_ACTION = '[POLICY MANAGEMENT CONTEXT] RECEIVE ALL POLICY DATA ACTION',
    SHOW_POLICY_DETAIL_MODAL = '[POLICY MANAGEMENT CONTEXT] SHOW POLICY DETAIL MODAL',
    CLOSE_POLICY_DETAIL_MODAL = '[POLICY MANAGEMENT CONTEXT] CLOSE POLICY DETAIL MODAL',
    ADD_NEW_POLICY_DETAIL = '[POLICY MANAGEMENT CONTEXT] ADD NEW POLICY DETAIL',
    CHANGE_DATA_TO_BE_FETCHED = '[POLICY MANAGEMENT CONTEXT] CHANGE DATA TO BE FETCHED',
    SHOW_ASSIGN_POLICY_DIALOG = '[POLICY MANAGEMENT CONTEXT] SHOW ADDIGN POLICY DIALOG',
    CLOSE_ASSIGN_POLICY_DIALOG = '[POLICY MANAGEMENT CONTEXT] CLOSE ADDIGN POLICY DIALOG',
    TOGGLE_VESSEL = '[POLICY MANAGEMENT CONTEXT] TOGGLE VESSEL',
    TOGGLE_FILTERED_VESSELS = '[VESSEL FILTER] TOGGLE FILTERED VESSELS',
    SET_SEARCH_VESSEL_TAG_TERM = '[VESSEL FILTER] SET SEARCH VESSEL TAG TERM',
    SET_SEARCH_VESSEL_NAME_TERM = '[VESSEL FILTER] SET SEARCH VESSEL NAME TERM',
    SET_SEARCH_VESSEL_TERM = '[VESSEL FILTER] SET SEARCH VESSEL TERM',
    SET_SHOW_X_BUTTON = '[VESSEL FILTER] SET SHOW X BUTTON',
}
