import {Button} from './close-filter-button.styled'
import {XCircle} from 'react-feather'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {showFilterEvent} from '../../../../../core/google-tag-manager/usb-devices/show-filter-event'
interface Props {
    text?: string
}
export function CloseFilterButton({text}: Props): JSX.Element {
    const {displayFilterBar} = usePagedUSBDevices()

    return (
        <Button
            onClick={() => {
                displayFilterBar(false)
                showFilterEvent(false)
            }}
            id="close-filter-button"
        >
            <XCircle />
            {text}
        </Button>
    )
}
