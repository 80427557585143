import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import ASSIGNED_TO_GUEST from '../../../../@assets/icons/assigned-to-guest.svg'
import {deleteUserFromEmailRecipientsEvent} from '../../../../core/google-tag-manager/system-page/delete-user-from-email-recipient-event'
import {getUserDescription} from '../../../../helpers/getUserDescription'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {deleteEmailRecipient} from '../../../../store/state/email-recipient-management/action-creators'
import {EmailRecipientData} from '../../../../store/state/email-recipient-management/types/email-recipient-data'
import {usersSelector} from '../../../../store/state/users/selectors'
import * as Styled from './_styled/email-management-table-data.styled'
interface TableRowProps {
    emailRecipient: EmailRecipientData
    index: number
}

export function TableRow({emailRecipient, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const users = useTypedSelector(usersSelector)
    const userName = getUserDescription(users, emailRecipient.user, emailRecipient.email, true)
    const dispatch = useDispatch()

    return (
        <>
            <Styled.TableDataRow
                gridRow={gridRow}
                id={`data-email-row_${emailRecipient.identity}`}
            />
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`email-value_${emailRecipient.identity}`}
            >
                {userName}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`email-checked-${emailRecipient.identity}`}
                centered
            >
                <img src={ASSIGNED_TO_GUEST} style={{width: '15px'}} />
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`email-value_${emailRecipient.identity}`}
                centered
            >
                <div
                    style={{color: 'red', cursor: 'pointer'}}
                    onClick={() => {
                        dispatch(deleteEmailRecipient(emailRecipient.identity))
                        deleteUserFromEmailRecipientsEvent(
                            emailRecipient.user || emailRecipient.email,
                        )
                    }}
                >
                    <Icon glyph="Delete" width={15} />
                </div>
            </Styled.TableDataCell>
        </>
    )
}
