import {NodeValue} from '../../../values/nodes/NodeData'
import {addEvent} from '../add-event'

export function closeAssetPopupEvent(
    value: NodeValue,
    score: number | undefined,
    clickType: 'direct' | 'outside',
): boolean {
    return addEvent('closeMyVesselsAssetPopup', {
        nodeValue: value,
        nodeScore: score,
        clickType,
    })
}
