import {useState} from 'react'
import {useDispatch} from 'react-redux'
import CLOSE_GUEST_EMAIL from '../../../../../@assets/icons/close-guest-email.svg'
import {addUserToEmailRecipientsEvent} from '../../../../../core/google-tag-manager/system-page/add-user-to-email-recipient-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {addNewEmailRecipient} from '../../../../../store/state/email-recipient-management/action-creators'
import {listOfExistingRecipientSelector} from '../../../../../store/state/email-recipient-management/selectors'

import * as Styled from './add-email-entry.styled'
interface Props {
    setNewEmailEntry: (value: boolean) => void
}
export function AddEmailEntry({setNewEmailEntry}: Props): JSX.Element {
    const [searchEmail, setSearchEmail] = useState('')
    const [existingEmail, setExistingEmail] = useState(false)
    const [validEmail, setValidEmail] = useState<RegExpMatchArray | null>(null)
    const listOfExistingEmails = useTypedSelector(listOfExistingRecipientSelector)
    const dispatch = useDispatch()
    function validateEmail(email: string): void {
        if (listOfExistingEmails.includes(email)) {
            setValidEmail(null)
            setExistingEmail(true)
        } else {
            setValidEmail(
                email
                    .toString()
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    ),
            )
            setExistingEmail(false)
        }
    }

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        validateEmail(e.target.value)
        setSearchEmail(e.target.value)
    }

    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            setNewEmailEntry(false)
            dispatch(addNewEmailRecipient(searchEmail, undefined))
            addUserToEmailRecipientsEvent(searchEmail)
            setSearchEmail('')
            setValidEmail(null)
        }
    }
    return (
        <Styled.EmailSectionWrapper>
            <Styled.EmailSectionTitle>Add email address</Styled.EmailSectionTitle>
            <div>
                <Styled.Container>
                    <Styled.SearchInput
                        id="guest-email-search"
                        type="text"
                        placeholder=" Enter email address "
                        autoComplete="off"
                        value={searchEmail}
                        onChange={onChangeFn}
                        onKeyPress={onEnterClick}
                        validEmail={validEmail != null || searchEmail.length === 0}
                        validBackground={validEmail != null}
                    />
                    {searchEmail.length !== 0 && (
                        <Styled.CloseEmail
                            src={CLOSE_GUEST_EMAIL}
                            onClick={() => {
                                setSearchEmail('')
                                setValidEmail(null)
                                setExistingEmail(false)
                            }}
                        />
                    )}
                </Styled.Container>
            </div>
            {existingEmail && (
                <Styled.ErrorMessage>This email address already exist!</Styled.ErrorMessage>
            )}

            <Styled.ButtonsWrapper>
                <Styled.CancelButton
                    onClick={() => {
                        setNewEmailEntry(false)
                        setSearchEmail('')
                        setValidEmail(null)
                        setExistingEmail(false)
                    }}
                >
                    Cancel
                </Styled.CancelButton>
                <Styled.AddButton
                    disabled={validEmail == null}
                    onClick={() => {
                        setNewEmailEntry(false)
                        dispatch(addNewEmailRecipient(searchEmail, undefined))
                        addUserToEmailRecipientsEvent(searchEmail)
                        setSearchEmail('')
                        setValidEmail(null)
                    }}
                >
                    Add
                </Styled.AddButton>
            </Styled.ButtonsWrapper>
        </Styled.EmailSectionWrapper>
    )
}
