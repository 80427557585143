enum ActionType {
    REQUEST_CONFIG = '[CORE/CONFIG] GET CONFIG',
    RECEIVE_CONFIG = '[CORE/CONFIG] SET CONFIG',
    CONFIG_ERROR = '[CORE/CONFIG] CONFIG ERROR',
    SET_GFC_FLAG = '[CORE/CONFIG] SET GFC FLAG',
    SET_CUSTOM_LOGO_FLAG = '[CORE/CONFIG] SET CUSTOM LOGO FLAG',
    SET_ENABLE_REPORTS_PAGE = '[CORE/CONFIG] SET ENABLE REPORTS PAGE',
    SET_ENABLE_NETWORK_ASSETS_PAGE = '[CORE/CONFIG] SET ENABLE NETWORK ASSETS PAGE',
    SET_ENABLE_USB_INVENTORY_PAGE = '[CORE/CONFIG] SET ENABLE USB INVENTORY PAGE',
    SET_ENABLE_SOFTWARE_INVENTORY_PAGE = '[CORE/CONFIG] SET ENABLE SOFTWARE INVENTORY PAGE',
    SET_ENABLE_NMEA_INVENTORY_PAGE = '[CORE/CONFIG] SET ENABLE NMEA INVENTORY PAGE',
    SET_INCIDENT_REOPEN_DURATION = '[CORE/CONFIG] SET INCIDENT REOPEN DURATION',
    SET_LICENSE_METRICS_ONBOARD_ENGAGEMENT = '[CORE/CONFIG] SET METRICS ONBOARD ENGAGEMENT',
    SET_LICENSE_INCIDENTS = '[CORE/CONFIG] SET LICENSE INCIDENTS ',
    SET_LICENSE_ENFORCEMENT_REMOVABLES = '[CORE/CONFIG] SET LICENSE ENFORCEMENT REMOVABLES',
    SET_LICENSE_ENFORCEMENT_NETWORKS = '[CORE/CONFIG] SET LICENSE ENFORCEMENT NETWORKS',
}

export default ActionType
