import {INCIDENT_NOTES_SORT_ORDER} from '../../../pages/incidents-v3/data-helpers'
import {addEvent} from '../add-event'
import {DisplayFormatType} from '../helpers/display-format-type'

export function selectIncidentHistoryEvent(
    value: INCIDENT_NOTES_SORT_ORDER,
    displayFormat: DisplayFormatType,
): boolean {
    return addEvent('selectIncidentHistory', {incidentSortOrder: value, displayFormat})
}
