import {ResponsiveContainer, CartesianGrid, LineChart, Tooltip, XAxis, YAxis} from 'recharts'
import {useTheme} from 'styled-components'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {WidgetOutputModel, WidgetOutputTagScoreModel} from '../../widget.model'
import {CurrentScoreTooltip} from '../tooltip/current-score/current-score-tooltip'
import {dataLine} from './data-line'
import {redRiskBackground} from './red-risk-background'
import {amberRiskBackground} from './amber-risk-background'
import {clickOnTheGraphEvent} from '../../../../../../core/google-tag-manager/dashboard/click-on-the-graph-event'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {threatMeasuresSelector} from '../../../../../../store/state/threat-measures/selectors'
import {NodeValues} from '../../../../../../values/nodes/NodeData'
import {secondDataLine} from './second-data-line'
import {useFleetScoreOverTime} from '../../contexts/use-fleet-score-over-time'

function yAxis(theme: CyberOwlTheme): JSX.Element {
    return (
        <YAxis
            type="number"
            domain={[0, 100]}
            ticks={[0, 25, 50, 75, 100]}
            tickCount={5}
            tickLine={false}
            tickSize={12}
            fontSize="14px"
            color={theme.colors.text.default}
            fontWeight={theme.font.weight.semibold}
            id="y-axis"
        />
    )
}

interface GraphProps {
    model: WidgetOutputModel
    secondModel: WidgetOutputTagScoreModel
}

export function Graph({model, secondModel}: GraphProps): JSX.Element {
    const theme = useTheme()
    const threatMeasure = useTypedSelector(threatMeasuresSelector).get(NodeValues.HIGH)
    const {searchedVesselTagTerm} = useFleetScoreOverTime()

    return (
        <ResponsiveContainer height="100%">
            <LineChart
                style={{marginTop: '-15px', marginBottom: '-15px'}}
                margin={{top: 15, right: 30, bottom: -30}}
                // data={model.data}
                onClick={() => clickOnTheGraphEvent(model.timeRange)}
                id="LineChart"
            >
                <CartesianGrid
                    strokeDasharray="3 0"
                    color={theme.colors.text.default}
                    id="CartesianGrid"
                />
                <XAxis
                    dataKey="time"
                    type="number"
                    tick={false}
                    domain={[model.startTime, model.endTime]}
                    id="x-axis"
                />
                {yAxis(theme)}
                <Tooltip
                    content={
                        <CurrentScoreTooltip data={model.data} secondData={secondModel.data} />
                    }
                />
                {amberRiskBackground(
                    theme,
                    threatMeasure?.lowerThreshold || 0,
                    threatMeasure?.upperThreshold || 0,
                )}
                {redRiskBackground(theme, threatMeasure?.upperThreshold || 0)}
                {searchedVesselTagTerm && secondDataLine(theme, secondModel.data)}
                {dataLine(theme, model.data)}
            </LineChart>
        </ResponsiveContainer>
    )
}
