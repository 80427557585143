import * as Styles from './_styles/nav-bar-links.styled'
// import RoleChecker from '../../../RoleChecker'
// import {Role} from '../../../../values/Role'
import {NavBarLink} from './nav-bar-link'
import {DashboardLink} from './dashboard-link'
import {viewIncidentsEvent} from '../../../../core/google-tag-manager/navigation/view-incidents-event'
import {viewVesselsEvent} from '../../../../core/google-tag-manager/navigation/view-vessels-event'
import {viewReportsEvent} from '../../../../core/google-tag-manager/navigation/view-reports-event'
import {viewDashboardEvent} from '../../../../core/google-tag-manager/navigation/view-dashboard-event'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {hasNewIncidentsSelector} from '../../../../store/state/incident-overview/selectors'
import {InventoryLink} from './inventory-link'
import {viewInventoryEvent} from '../../../../core/google-tag-manager/navigation/view-inventory-event'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'
import {SystemLink} from './system-link'
import {viewSystemEvent} from '../../../../core/google-tag-manager/navigation/view-system-event'

export function NavBarLinks(): JSX.Element {
    const shouldShowIncidentsAlert = useTypedSelector(hasNewIncidentsSelector)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const showInventoryLink =
        fleetConfig.networkAssets ||
        fleetConfig.usbInventory ||
        fleetConfig.softwareInventory ||
        fleetConfig.nmeaInventory

    return (
        <Styles.NavBarLinks>
            <DashboardLink gtmLogger={viewDashboardEvent} />
            <NavBarLink
                id="navigation-my-vessels"
                path="/my-vessels"
                navLinkText="Vessels"
                gtmLogger={() => viewVesselsEvent('mainNavigation')}
            />
            <NavBarLink
                id="navigation-incidents"
                path="/incidents"
                navLinkText="Incidents"
                showIndicator={shouldShowIncidentsAlert}
                gtmLogger={() => viewIncidentsEvent('mainNavigation')}
            />
            {(isInternalUser || fleetConfig.reports) && (
                <NavBarLink
                    id="navigation-reports"
                    path="/reports"
                    navLinkText="Reports"
                    gtmLogger={() => viewReportsEvent('mainNavigation')}
                />
            )}
            <NavBarLink
                id="navigation-metrics"
                path="/metrics"
                navLinkText="Metrics"
                gtmLogger={() => viewVesselsEvent('mainNavigation')}
            />
            {(isInternalUser || showInventoryLink) && (
                <InventoryLink gtmLogger={viewInventoryEvent} />
            )}
            <SystemLink gtmLogger={viewSystemEvent} />
        </Styles.NavBarLinks>
    )
}
