import {useDispatch} from 'react-redux'
import * as Styled from './additional-identifier.styled'
import {setSearchProperties} from '../../../../../../store/state/network-assets-filter/action-creators'
import {X} from 'react-feather'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {networkAssetsFilterSelector} from '../../../../../../store/state/network-assets-filter/selectors'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

export function AdditionalIdentifierFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {properties} = useTypedSelector(networkAssetsFilterSelector)

    function onXClick() {
        dispatch(setSearchProperties(''))
    }
    return (
        <div style={{position: 'relative'}}>
            <Styled.SearchInput
                id={`${PageType.NETWORK_ASSETS}_filter-bar_properties-search`}
                placeholder="Filter by VLAN, MAC Address"
                autoComplete="off"
                value={properties ?? ''}
                onChange={(e) => dispatch(setSearchProperties(e.target.value))}
            />
            {properties && (
                <Styled.XIcon
                    onClick={() => onXClick()}
                    id={`${PageType.NETWORK_ASSETS}_filter-bar_properties-search_x-icon`}
                >
                    <X onClick={() => onXClick()} height={20} width={20} />
                </Styled.XIcon>
            )}

            <Styled.SearchIcon>
                <Icon glyph="Search" height={20} width={20} />
            </Styled.SearchIcon>
        </div>
    )
}
