import {TriStateButtonState} from '../../../components/form-elements/tri-state-button/tri-state-button-state'
import {addEvent} from '../add-event'

export function clickViewAllNetworksEvent(
    currentState: TriStateButtonState,
    newState: TriStateButtonState,
): boolean {
    return addEvent('networkAssetsPageClickViewAllNetworks', {
        currentState,
        newState,
    })
}
