import {spacing} from '../../../theme/spacing'
import {AssetCreationForm} from './asset-creation-form'
import {OTAsset, CreateOTAssetReq} from '../../../store/state/ot-assets/state'
import {assetTableConfig} from './asset-table.config'
import * as Style from './asset-table.styled'
import {closeModal, removeOTAsset} from '../../../store/state/ot-assets/action-creators'
import {ReduxDispatch} from '../../../hooks/useReduxDispatch'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {
    OTAssetDeleteErrorSelector,
    OTAssetDeleteLoadingStateSelector,
    OTAssetOpenedModalSelector,
    OTAssetSelector,
} from '../../../store/state/ot-assets/selectors'
import {ActionModal} from '../../../components/dialog-modal/action-modal'
import {useDispatch} from 'react-redux'
import {StringUtils} from '../../../utils/Utils'
import {ConfirmBtn} from '../../../components/dialog-modal/modal.styled'
import {useContext, useState} from 'react'
import {
    LocationInventoryPageContext,
    AssetOptionKey,
    defaultLocationInventoryContextState,
} from '../contexts/location-inventory-page-context'
import {CreateAssetOptionModal} from './create-asset-option-modal'
import {AssetTableExpandedRow} from './asset-table-expanded-row'
import {useAssetOptions} from '../contexts/hooks/use-asset-options'
import ActionType from '../contexts/state/asset-form/action-type'
import {editAsset} from '../contexts/state/asset-form/action-creators'
import _ from 'lodash'
import PageActionType from '../contexts/state/page/action-type'

interface ConfigItem {
    label: string
    render: (item: OTAsset, dispatch?: ReduxDispatch) => JSX.Element | string
    width: string
}
export type Config = ConfigItem[]

interface TableProps {
    data: OTAsset[]
    locationID: string
    keyGenerator: (item: OTAsset, type: 'main-row' | 'detail-row') => string
    showForm: boolean
    onDismiss: () => void
    onSubmit: (req: CreateOTAssetReq) => void
}

export function AssetTable(props: TableProps): JSX.Element {
    const dispatch = useDispatch()
    const [openedCreateAssetOptionModal, setOpenedCreateAssetOptionModal] = useState(false)
    const [assetOptionKey, setAssetOptionKey] = useState<AssetOptionKey | null>(null)
    const openedModal = useTypedSelector(OTAssetOpenedModalSelector)
    const selectedOTAsset = useTypedSelector(OTAssetSelector)
    const deleteAssetLoadingState = useTypedSelector(OTAssetDeleteLoadingStateSelector)
    const deleteAssetError = useTypedSelector(OTAssetDeleteErrorSelector)

    const {assetList, assetForm, page} = useContext(LocationInventoryPageContext)

    const {assetFormOptions} = useAssetOptions()
    const {formOptions: assetOptions, formOptionsLoadingState: assetOptionsLoadingState} =
        assetFormOptions.state
    const [isOpenConfirmDiscardModal, setIsOpenConfirmDiscardModal] = useState(false)

    function clearForm() {
        // Reset form field errors
        assetForm.dispatch?.({
            type: ActionType.SET_ASSET_FORM_ERRORS,
            payload: _.cloneDeep(
                defaultLocationInventoryContextState.assetForm.state.formFieldError,
            ),
        })

        // Reset form submit errors
        assetForm.dispatch?.({type: ActionType.SET_ASSET_FORM_SUBMIT_ERROR, payload: {error: ''}})

        // Reset form state
        assetForm.dispatch?.({type: ActionType.RESET_ASSET_CREATION_FORM})

        // Enable add button
        page.dispatch?.({type: PageActionType.DISABLE_ADD_BUTTON, payload: {disabled: false}})
    }

    function onSubmitHandler(data: CreateOTAssetReq) {
        data.revision = assetForm.state.editingAssetIdRevision ?? 0

        if (!assetForm.state.editingAssetId) {
            throw new Error('onSubmitHandler for editing asset called, but Asset ID is missing!')
        }

        editAsset(
            props.locationID,
            assetForm.state.editingAssetId,
            assetForm.dispatch,
            page.dispatch,
            dispatch,
            data,
        )
    }

    function closeForm() {
        setIsOpenConfirmDiscardModal(true)
    }

    const renderedHeaders = assetTableConfig.map((item) => (
        <Style.Header key={item.label} style={{width: item.width}}>
            {item.label}
        </Style.Header>
    ))

    let renderedRows
    if (props.data.length === 0) {
        renderedRows = (
            <Style.Row>
                <td colSpan={assetTableConfig.length} style={{padding: spacing(3)}}>
                    No asset data available
                </td>
            </Style.Row>
        )
    } else {
        renderedRows = []

        props.data.forEach((rowData) => {
            // Check if the row is being expanded
            const isExpanded = assetList.state.expandedAssetId.includes(rowData.id)
            // Check if the row is being edited
            const isBeingEdited = assetForm.state.editingAssetId === rowData.id

            const renderedRow = assetTableConfig.map((item) => (
                // TODO: Clean this up once we move
                // stuff to context
                <Style.Cell
                    key={item.label}
                    formColumn={
                        item.label === 'Type' ||
                        item.label === 'Vessel System' ||
                        item.label === 'Zone'
                    }
                >
                    {item.render({
                        asset: rowData,
                        assetOptions: assetOptions,
                        assetOptionsLoadingState: assetOptionsLoadingState,
                        formData: assetForm.state.formData,
                        formFieldError: assetForm.state.formFieldError,
                        isBeingEdited: isBeingEdited,
                        isExpanded: isExpanded,
                        dispatchAssetForm: assetForm.dispatch,
                        dispatchAssetList: assetList.dispatch,
                        reduxDispatch: dispatch,
                        dispatchPage: page.dispatch,
                        isOpenAssetCreationForm: page.state.isOpenAssetCreationForm,
                    })}
                </Style.Cell>
            ))

            // Render the main row
            renderedRows?.push(
                <Style.Row key={props.keyGenerator(rowData, 'main-row')}>{renderedRow}</Style.Row>,
            )

            // Render the editing form if it is being edited
            if (isBeingEdited) {
                renderedRows.push(
                    <AssetCreationForm
                        mode={isBeingEdited ? 'edit' : 'create'}
                        key={props.keyGenerator(rowData, 'detail-row')}
                        onDismiss={clearForm}
                        onSubmit={onSubmitHandler}
                        closeForm={closeForm}
                        onClickAddAssetOption={setOpenedCreateAssetOptionModal}
                        assetOptionKeySetter={setAssetOptionKey}
                    />,
                )
            }

            // Render the detail row if it is expanded
            if (isExpanded) {
                renderedRows.push(
                    <AssetTableExpandedRow
                        otAsset={rowData}
                        key={props.keyGenerator(rowData, 'detail-row')}
                    />,
                )
            }
        })
    }

    const removeOTAssetErrorHasError = StringUtils.validString(deleteAssetError)

    const actionModalTitle = removeOTAssetErrorHasError ? 'Something went wrong' : 'Confirm delete'

    const actionModalMessage = removeOTAssetErrorHasError
        ? deleteAssetError
        : 'Are you sure you want to delete this asset?'

    const actions = removeOTAssetErrorHasError ? (
        <>
            <ConfirmBtn onClick={() => dispatch(closeModal())}>Ok</ConfirmBtn>
        </>
    ) : undefined

    return (
        <>
            <ActionModal
                isOpen={openedModal}
                onClose={() => dispatch(closeModal())}
                onConfirm={() => dispatch(removeOTAsset(selectedOTAsset!))}
                confirmBtnText="Yes, delete"
                message={actionModalMessage}
                title={actionModalTitle}
                closeOnConfirm={false}
                isLoading={deleteAssetLoadingState}
                errorText={deleteAssetError}
                actions={actions}
            />
            <CreateAssetOptionModal
                assetOptionKey={assetOptionKey}
                isOpen={openedCreateAssetOptionModal}
                onCloseHandler={() => setOpenedCreateAssetOptionModal(false)}
            />
            <ActionModal
                isOpen={isOpenConfirmDiscardModal}
                onConfirm={clearForm}
                onClose={() => setIsOpenConfirmDiscardModal(false)}
                confirmBtnText="Yes, discard"
                message="Are your sure you want to discard the changes?"
                title="Confirm Discard"
                closeOnConfirm={true}
            />
            <Style.Table>
                <thead>
                    <Style.Row>{renderedHeaders}</Style.Row>
                </thead>
                <tbody>
                    {props.showForm && (
                        <AssetCreationForm
                            onClickAddAssetOption={setOpenedCreateAssetOptionModal}
                            assetOptionKeySetter={setAssetOptionKey}
                            mode="create"
                            onDismiss={props.onDismiss}
                            onSubmit={props.onSubmit}
                        />
                    )}
                    {renderedRows}
                </tbody>
            </Style.Table>
        </>
    )
}
