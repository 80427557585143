import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../../store/state/session-data/actions'
import SessionActionType from '../../../../../store/state/session-data/action-type'
import LoadingState from '../../../../../values/loading-state-enum'
import {IncidentsPolicyState} from '../type/incidents-policy-state'
import {
    DEFAULT_INCIDENTS_POLICY_STATE,
    emptyIncidentPolicyDetail,
} from '../type/default-incidents-policy-state'
import {getFormattedIncidentPolicyType, getFormattedReminderDuration} from '../../data-helper'

export const incidentsPolicyReducer = produce(
    (draft: IncidentsPolicyState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_POLICY_DATA_ACTION:
                draft.loadingRequestedIncidentsDataState = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_REQUESTED_POLICY_DATA_ACTION:
                draft.loadingRequestedIncidentsDataState = LoadingState.Loaded
                draft.activeIncidentPolicyData = action.payload.receivedPolicy
                draft.initialIncidentPolicyDetails = action.payload.receivedPolicy
                draft.displayIncidentPolicyDetails = action.payload.receivedPolicy
                draft.formattedIncidentPolicyTypes = getFormattedIncidentPolicyType(
                    action.payload.incidentResponsePolicyIdentity,
                    action.payload.allIncidentTypes,
                    action.payload.receivedPolicy.types,
                )
                draft.enableIncidentReminder =
                    action.payload.receivedPolicy.sendReminderAfterDuration !== null
                draft.initialEnableIncidentReminder =
                    action.payload.receivedPolicy.sendReminderAfterDuration !== null
                break

            case ActionType.SET_CHANGE_POLICY_NAME:
                draft.displayIncidentPolicyDetails.name = action.payload
                draft.activeIncidentPolicyData.name = action.payload.trim()
                break

            case ActionType.SET_CHANGE_CC_VESSEL_EMAILS_TO:
                if (action.payload.length === 0) {
                    draft.displayIncidentPolicyDetails.ccVesselEmailsTo = []
                    draft.activeIncidentPolicyData.ccVesselEmailsTo = []
                } else {
                    draft.displayIncidentPolicyDetails.ccVesselEmailsTo = action.payload
                    draft.activeIncidentPolicyData.ccVesselEmailsTo = action.payload
                }
                break

            case ActionType.ENABLE_AUTO_ASSIGN:
                draft.displayIncidentPolicyDetails.enabledForIncidentManagement = action.payload
                draft.activeIncidentPolicyData.enabledForIncidentManagement = action.payload
                break

            case ActionType.SELECT_SEVERITY:
                if (action.payload.value) {
                    draft.formattedIncidentPolicyTypes
                        .find((type) => type.incidentResponseType === action.payload.incidentType)
                        ?.incidentResponseSeverities.push(action.payload.severity)
                } else {
                    const severityArray =
                        draft.formattedIncidentPolicyTypes.find(
                            (type) => type.incidentResponseType === action.payload.incidentType,
                        )?.incidentResponseSeverities ?? []

                    draft.formattedIncidentPolicyTypes.find(
                        (type) => type.incidentResponseType === action.payload.incidentType,
                    )!.incidentResponseSeverities = severityArray.filter(
                        (severity) => severity !== action.payload.severity,
                    )
                }
                break

            case ActionType.SET_CHANGE_REASSIGN_USER:
                draft.displayIncidentPolicyDetails.reassignToUser = action.payload
                draft.activeIncidentPolicyData.reassignToUser = action.payload
                break

            case ActionType.SET_CHANGE_AUTO_ASSIGN_USER:
                draft.displayIncidentPolicyDetails.otherwiseAssignToUser = action.payload
                draft.activeIncidentPolicyData.otherwiseAssignToUser = action.payload
                break

            case ActionType.ENABLE_INCIDENT_REMINDER:
                draft.enableIncidentReminder = action.payload
                break

            case ActionType.SET_CHANGE_REMINDER_DURATION:
                draft.displayIncidentPolicyDetails.sendReminderAfterDuration =
                    getFormattedReminderDuration(action.payload)
                draft.activeIncidentPolicyData.sendReminderAfterDuration =
                    getFormattedReminderDuration(action.payload)
                // FIXME: For now, sendEscalationReminderAfterDuration is double of sendReminderAfterDuration for now.
                draft.displayIncidentPolicyDetails.sendEscalationReminderAfterDuration =
                    getFormattedReminderDuration(action.payload ? action.payload * 2 : null)

                draft.activeIncidentPolicyData.sendEscalationReminderAfterDuration =
                    getFormattedReminderDuration(action.payload ? action.payload * 2 : null)
                break

            case ActionType.SET_CHANGE_ESCALATE_TO_USER:
                draft.displayIncidentPolicyDetails.escalateToUser = action.payload
                draft.activeIncidentPolicyData.escalateToUser = action.payload
                break
            case ActionType.SET_DEFAULT_POLICY_VALUES:
                draft.activeIncidentPolicyData = emptyIncidentPolicyDetail
                draft.initialIncidentPolicyDetails = emptyIncidentPolicyDetail
                draft.displayIncidentPolicyDetails = emptyIncidentPolicyDetail
                draft.formattedIncidentPolicyTypes = draft.formattedIncidentPolicyTypes =
                    getFormattedIncidentPolicyType('', action.payload, [])
                draft.enableIncidentReminder = true

                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_INCIDENTS_POLICY_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
