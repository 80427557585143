import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {useEffect} from 'react'
import {viewPageEvent} from '../../core/google-tag-manager/system-page/view-page-event'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'
import {ContentContainerMiddle} from '../../templates/fixed-page/content-container-middle.styled'
import {ContentArea} from './system-page-content.styled'
import {EngineStatuses} from './components/engine-status/engine-statuses'
import {useTheme} from 'styled-components'

export default function EngineStatusPage(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    useEffect(() => {
        viewPageEvent()
    }, [])

    return (
        <FixedPageTemplate>
            <ContentContainerTop width={width}></ContentContainerTop>
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.colors.background.lowContrast}
            >
                <ContentArea width={width}>
                    <EngineStatuses />
                </ContentArea>
            </ContentContainerMiddle>
        </FixedPageTemplate>
    )
}
