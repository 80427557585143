import * as styled from './event-details-data.styled'
import {LeftHandPanel} from './left-hand-panel'
import {RightHandPanel} from './right-hand-panel'
import {LogData} from './log-data/log-data'
import {EventDetail} from '../contexts/types/event-detail'
import {EventDetailOutputModel} from './event-detail.model'
import {indicatorsSelector} from '../../../store/state/indicators/selectors'
import usedTypeSelector from '../../../hooks/use-typed-selector'
import {gfcFlagSelector} from '../../../store/state/config/selectors'
import useTypedSelector from '../../../hooks/use-typed-selector'

interface EventDetailsDataProps {
    eventDetail: EventDetail | undefined
}

export function EventDetailsData({eventDetail}: EventDetailsDataProps): JSX.Element {
    const indicators = usedTypeSelector(indicatorsSelector)
    const gfcFlag = useTypedSelector(gfcFlagSelector)
    const model = new EventDetailOutputModel(eventDetail, indicators, gfcFlag)

    return (
        <>
            <styled.EventDetailsData>
                <LeftHandPanel eventDetails={model} />
                <RightHandPanel eventDetails={model} />
            </styled.EventDetailsData>
            <LogData logData={model.logData} />
        </>
    )
}
