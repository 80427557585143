import {addEvent} from '../add-event'

export function vesselFilterClickedEvent(
    checked: boolean,
    numberOfOtherVesselsSelected: number,
): boolean {
    return addEvent('vesselFilterClicked', {
        newState: checked ? 'Selected' : 'Not selected',
        numberOfOtherVesselsSelected,
    })
}
