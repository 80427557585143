import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import * as Styled from './analysis-selection.styled'
import {getAnalysisTypeDisplayName} from '../../data-helper'
import {AnalysisType} from '../../../../../store/state/metrics-filter-beta/state'
import {allAnalysisTypes} from '../../../contexts/types/metrics-response'
import {setAnalysisType} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {filterByEvent} from '../../../../../core/google-tag-manager/metrics-beta/filter-by-event'

export function AnalysisTypeFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {analysisTypes} = useTypedSelector(metricsBetaFilterSelector)
    const {highlightedFilerValue} = usePagedMetricsBeta()

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(setAnalysisType(event.target.value as AnalysisType))
        filterByEvent('Analysis type', event.target.value)
    }

    return (
        <Styled.Wrapper
            highlightedFilerValue={highlightedFilerValue === 'Analysis type'}
            id={`${PageType.METRICS}_filter-bar_analysis-type-filter`}
        >
            <Styled.Label>Analysis Type:</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapper}
                    value={analysisTypes}
                    id={`${PageType.METRICS}_filter-bar_searched-analysis-type-value`}
                >
                    <option
                        key="default-option"
                        value={AnalysisType.SCORECARD}
                        id={`${PageType.METRICS}_filter-bar_analysis-type_default-option`}
                    >
                        {getAnalysisTypeDisplayName(AnalysisType.SCORECARD)}
                    </option>
                    {allAnalysisTypes?.map((value) => (
                        <option
                            key={value}
                            value={value}
                            id={`${PageType.METRICS}_filter-bar_analysis-type_${value}-option`}
                        >
                            {getAnalysisTypeDisplayName(value)}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
