import styled from 'styled-components'
import {IncidentStatus} from '../../../incidents-v3/contexts/types/incident-status'
import {CyberOwlTheme} from '../../../../theme/theme'
import {mediumFont, mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'
import {narrowLayoutFn} from '../../incidents-report-page.styled'

interface Props {
    incidentStatus: IncidentStatus
    width: number
}
export const IncidentsWrapper = styled.div<Props>`
    display: flex;
    flex: 1;
    flex-direction: column;
    ${(props) => (narrowLayoutFn(props.width) ? '' : 'width: 160px')};
    color: ${(props) => props.theme.incidents.dataRow.text};
    border-left: 8px solid ${(props) => getIncidentsStatusColour(props.incidentStatus, props.theme)};
`
export function getIncidentsStatusColour(status: string | undefined, theme: CyberOwlTheme): string {
    switch (status) {
        case IncidentStatus.NEW:
            return theme.incidents.dataRow.statusBar.new
        case IncidentStatus.OPEN:
            return theme.incidents.dataRow.statusBar.open
        case IncidentStatus.CLOSED:
        case IncidentStatus.CLOSED_FALSE_POSITIVE:
        case IncidentStatus.CLOSED_MONITOR:
            return theme.incidents.dataRow.statusBar.closed
        default:
            return theme.incidents.incidentStatus.draft
    }
}
export const IncidentsCount = styled.a`
    padding-left: ${spacing(1)};
    ${mediumFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    pointer-events: none;
`
export const IncidentsText = styled.h2`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
