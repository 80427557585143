import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ProcessingStatus} from '../../values/processing-save-state-enum'
import {Overlay} from '../shared-components/modal-overlay'
import CLOSE_ICON from '../../@assets/icons/close-assign-to-icon.svg'
import UPLOAD_DOC from './upload-dcoument.svg'
import * as Styled from './upload-document-modal.styled'
import {removeQueryParam} from '../../helpers/navigation'
import {UploadSucceded} from './components/upload-succeded'
import {UploadError} from './components/upload-error'
import {UploadInProgress} from './components/upload-in-progress'
import {NativeDragAndDrop} from './components/native-drag-and-drop'
import {useState} from 'react'

export function UploadDocumentModal(): JSX.Element {
    return (
        <Overlay>
            <UploadDocumentContent />
        </Overlay>
    )
}

function UploadDocumentContent(): JSX.Element {
    const [file, setFile] = useState<File | null>(null)
    const [isProcessingSave, setIsProcessingSave] = useState<ProcessingStatus>(
        ProcessingStatus.NOT_PROCESSING,
    )
    const {width} = useDimensions()
    return (
        <Styled.Section onClick={(e) => e.stopPropagation()} width={width} id="edit-asset-section">
            <Styled.Header id="upload-document-Header">
                <Styled.Title id="upload-document-Title">
                    <Styled.TitleImage src={UPLOAD_DOC} id="detail-panel-header_title-icon" />
                    Upload custom PDF
                </Styled.Title>
                <Styled.CloseImage
                    src={CLOSE_ICON}
                    onClick={() => {
                        removeQueryParam('uploadDocument')
                    }}
                    id="detail-panel-header_close-details-icon"
                />
            </Styled.Header>
            <Styled.DragAndDropSection width={width} id="upload-document-DragAndDropSection">
                <Styled.SubTitle>
                    Import your own document to be displayed on the vessel
                </Styled.SubTitle>
                <NativeDragAndDrop
                    setFile={setFile}
                    isSelectFileDisabled={isProcessingSave === ProcessingStatus.PROCESSED}
                    setIsProcessingSave={setIsProcessingSave}
                />
            </Styled.DragAndDropSection>
            <Styled.ProcessingDocumentSection id="upload-document-Actions">
                {isProcessingSave === ProcessingStatus.PROCESSING && (
                    <UploadInProgress
                        fileName={file?.name ?? ''}
                        fileSize={formatFileSize(file?.size ?? 0)}
                    />
                )}
                {isProcessingSave === ProcessingStatus.PROCESSED && (
                    <UploadSucceded
                        fileName={file?.name ?? ''}
                        fileSize={formatFileSize(file?.size ?? 0)}
                    />
                )}
                {isProcessingSave === ProcessingStatus.FAILED && (
                    <UploadError
                        fileName={file?.name ?? ''}
                        fileSize={formatFileSize(file?.size ?? 0)}
                    />
                )}
            </Styled.ProcessingDocumentSection>
        </Styled.Section>
    )
}
// Utility function to format file size
function formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    let unitIndex = 0
    let formattedSize = size

    while (formattedSize >= 1024 && unitIndex < units.length - 1) {
        formattedSize /= 1024
        unitIndex += 1
    }

    return `${formattedSize.toFixed(1)} ${units[unitIndex]}`
}
