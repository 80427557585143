import {XCircle} from 'react-feather'
import {LoadingSpinner} from '../../../../components/loading/loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {showFilterEvent} from '../../../../core/google-tag-manager/usb-devices/show-filter-event'
import {usePagedUSBDevices} from '../../contexts/use-paged-usb-devices'
import * as Styles from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {FirstDetectedFilter} from './timestamp-filter/first-seen-filter'
import {LastActiveFilter} from './timestamp-filter/last-active-filter'
import {ProductNameFilter} from './product-name-filter/product-name-filter'
import {USBTypeFilter} from './usb-type-filter/usb-type-filter'
import {VendorFilter} from './vendor-filter/vendor-filter'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {usbInventoryFilterLoadingSelector} from '../../../../store/state/usb-inventory-filter/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {SavedSearch} from '../../../../components/saved-search/saved-search'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {isInactiveSelector} from '../../../../store/state/saved-filters/selectors'
import {UsbDeviceStatusFilter} from './usb-device-status-filter/usb-device-status-filter'
import {ResetFilterButton} from '../../../../components/page-filter/components/shared/reset-filter/reset-filter-button'
import {resetFilter} from '../../../../store/state/usb-inventory-filter/action-creators'
import {PurposeFilter} from './purpose-filter/purpose-filter'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {displayFilterBar} = usePagedUSBDevices()
    const isLoadingFilter = useTypedSelector(usbInventoryFilterLoadingSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    if (isLoadingFilter) {
        return (
            <Styles.Loading>
                <LoadingSpinner />
            </Styles.Loading>
        )
    }
    return (
        <Styles.FilterBarWrapper width={width} id={`${PageType.USB_DEVICES}_filter-bar`}>
            <div
                id={`${PageType.USB_DEVICES}_filter-bar_close-icon`}
                style={{position: 'absolute', right: 0, cursor: 'pointer'}}
                onClick={() => {
                    displayFilterBar(false)
                    showFilterEvent(false)
                }}
            >
                <XCircle color="white" fill="blue" />
            </div>
            <Styles.FilterBarStyled>
                <FilterBarHeader />
                <Styles.SavedFilterContent isInactive={isInactive}>
                    <SavedSearch savedFilterPageType={SavedFilterPageType.USB_INVENTORY} />
                </Styles.SavedFilterContent>
                <Styles.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.USB_DEVICES}_filter-bar_section-content-1`}
                >
                    <FirstDetectedFilter />
                    <LastActiveFilter />
                    <VendorFilter />
                    <ProductNameFilter />
                    <USBTypeFilter />
                    <PurposeFilter />
                </Styles.SectionContent>
                <Styles.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.USB_DEVICES}_filter-bar_section-content-2`}
                >
                    <UsbDeviceStatusFilter />
                </Styles.SectionContent>
                <VesselFilters pageType={PageType.USB_DEVICES} />
                <ResetFilterButton resetFn={resetFilter} />
            </Styles.FilterBarStyled>
        </Styles.FilterBarWrapper>
    )
}
