import styled from 'styled-components'
import {smallFont} from '../../../theme/font-styles'

export const Button = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 7px 13px;
    ${smallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-transform: uppercase;

    &:hover {
        background-color: rgba(31, 136, 229, 0.1);
    }
`
