import {useEffect} from 'react'
import {Redirect, RouteComponentProps} from 'react-router-dom'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {useDispatch} from 'react-redux'
import {deepLinkVessel} from '../../../store/state/my-vessels-filters/action-creators'
import {deepLinkingEvent} from '../../../core/google-tag-manager/my-vessels/deep-linking-event'
import * as Styled from './vessel-not-found.styled'
import {ScrollablePageTemplate} from '../../../templates/scrollable-page/scrollable-page-template'

export function MyVesselsDeepLinking({
    match,
}: RouteComponentProps<{location: string}>): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    const dispatch = useDispatch()
    const locationCode = match.params.location

    const currentLocation = locations.find(
        (location) => location.code.localeCompare(locationCode, 'en', {sensitivity: 'base'}) === 0,
    )?.location

    if (!currentLocation) {
        return VesselNotFound()
    }
    useEffect(() => {
        deepLinkingEvent()
        dispatch(
            deepLinkVessel(
                locations.map((location) => location.location),
                currentLocation,
            ),
        )
    }, [currentLocation])
    return <Redirect to={{pathname: '/my-vessels', state: {from: 'deep-linking'}}} />
}

function VesselNotFound(): JSX.Element {
    return (
        <ScrollablePageTemplate>
            <Styled.NoDataLayout>
                <Styled.NoDataMessage>Vessel can not be found</Styled.NoDataMessage>
            </Styled.NoDataLayout>
        </ScrollablePageTemplate>
    )
}
