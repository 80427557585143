import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {DetailsButton} from '../../general/header/details-button/details-button'
import {ReactNode, ReactNodeArray} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {viewVesselsEvent} from '../../../../../core/google-tag-manager/navigation/view-vessels-event'
interface WidgetWrapperProps {
    gridLayout: GridLayout
    children?: ReactNode | ReactNodeArray
    noData?: boolean
}

export function WidgetWrapper({
    gridLayout,
    children,
    noData = false,
}: WidgetWrapperProps): JSX.Element {
    return (
        <Widget>
            <Header gridLayout={gridLayout}>
                <HeaderContent gridLayout={gridLayout}>
                    <TitleText gridLayout={gridLayout}>My Fleet</TitleText>
                    {!noData && (
                        <DetailsButton
                            linkTo="/my-vessels"
                            linkIdPrefix="vessels"
                            onClick={() => viewVesselsEvent('dashboardWidget')}
                        />
                    )}
                </HeaderContent>
            </Header>
            {children}
        </Widget>
    )
}
