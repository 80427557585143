import {TableDataGrid} from './_styled/table-data-grid.styled'
import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {NoData} from './no-data/no-data'
import {TableRow} from './table-row'
import {SoftwareDetailsModal} from '../software-details-modal/software-details-modal'
import {addQueryParam} from '../../../../helpers/navigation'
import {getFormattedSoftwareInventoryData, SOFTWARE_INVENTORY_DETAILS} from '../data-helpers'
import {openSoftwareDetailsModalEvent} from '../../../../core/google-tag-manager/software-inventory/open-software-details-modal'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../store/state/audit-log/state'

export function SoftwareInventoryTablePopulated(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()

    const {
        dataSoftwareInventory,
        totalNumberOfFilteredSoftwareInventory,
        showFilterBar,
        modalIdForSoftwareDetails,
        displayFilterBar,
        displaySoftwareDetailsModal,
    } = usePagedSoftwareInventory()
    const shouldDisplaySoftwareDetailsModal = modalIdForSoftwareDetails != null

    if (!dataSoftwareInventory || totalNumberOfFilteredSoftwareInventory === 0) {
        return <NoData text="There is no software matching your filtering criteria" />
    }

    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
            <TableDataGrid
                width={width}
                showReducedGrid={showFilterBar || modalIdForSoftwareDetails !== null}
                id="table-data-grid-style"
            >
                {dataSoftwareInventory?.map((software, index) => {
                    const formattedSoftwareData = getFormattedSoftwareInventoryData(software)
                    return (
                        <TableRow
                            key={`${software.identity}`}
                            software={software}
                            index={index}
                            onClickFn={() => {
                                addQueryParam(
                                    SOFTWARE_INVENTORY_DETAILS,
                                    formattedSoftwareData.identity,
                                )
                                displaySoftwareDetailsModal(formattedSoftwareData.identity)
                                displayFilterBar(false)
                                openSoftwareDetailsModalEvent(formattedSoftwareData)
                                dispatch(
                                    logDetailsOpen(
                                        AuditLogPageType.SOFTWARE_INVENTORY,
                                        AuditLogAuditType.DETAIL_PANEL_ACCESS,
                                        software.identity,
                                    ),
                                )
                            }}
                        />
                    )
                })}
            </TableDataGrid>
            {shouldDisplaySoftwareDetailsModal && <SoftwareDetailsModal />}
        </div>
    )
}
